import { createSlice } from '@reduxjs/toolkit';
import { fetchMobileyeLiveEvents, fetchMobileyeRealTimeTraffic } from 'features/thunks';

export const mobileyeSlice = createSlice({
  name: 'mobileye',
  initialState: {
    liveEvents: {
      type: 'FeatureCollection',
      features: [],
    },
    realTimeTraffic: {
      type: 'FeatureCollection',
      features: [],
    },
  },
  reducers: {},
  extraReducers: {
    [fetchMobileyeLiveEvents.fulfilled]: (state, action) => {
      state.liveEvents = action.payload;
    },
    [fetchMobileyeRealTimeTraffic.fulfilled]: (state, action) => {
      state.realTimeTraffic = action.payload;
    },
  },
});

export const selectMobileyeLiveEvents = ({ mobileye }) => mobileye.liveEvents;
export const selectMobileyeRealTimeTraffic = ({ mobileye }) => mobileye.realTimeTraffic;

export default mobileyeSlice.reducer;
