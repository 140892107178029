/* eslint-disable no-nested-ternary */
import { metricTypes } from 'consts';

export const getGeneralViewFeatureHoverText = (props, intl, storeState) => {
  const {
    unitSystem: { speedMultiplier, distanceMultiplier, speedUnit, distanceUnit },
    generalStats,
  } = storeState;

  const impactText = {
    veryHigh: intl.formatMessage({ description: 'Very High', defaultMessage: 'Very High' }),
    high: intl.formatMessage({ description: 'High', defaultMessage: 'High' }),
    medium: intl.formatMessage({ description: 'Medium', defaultMessage: 'Medium' }),
    low: intl.formatMessage({ description: 'Low', defaultMessage: 'Low' }),
  };

  const impactTextLight = {
    veryHigh: intl.formatMessage({ description: 'Extremely High', defaultMessage: 'Extremely High' }),
    high: intl.formatMessage({ description: 'Very High', defaultMessage: 'Very High' }),
    medium: intl.formatMessage({ description: 'High', defaultMessage: 'High' }),
    low: intl.formatMessage({ description: 'Light', defaultMessage: 'Light' }),
  };

  const impactTextUnsafe = {
    veryHigh: intl.formatMessage({ description: 'Extremely Unsafe', defaultMessage: 'Extremely Unsafe' }),
    high: intl.formatMessage({ description: 'Very Unsafe', defaultMessage: 'Very Unsafe' }),
    medium: intl.formatMessage({ description: 'Unsafe', defaultMessage: 'Unsafe' }),
    low: intl.formatMessage({ description: 'Unsafe on Occasion', defaultMessage: 'Unsafe on Occasion' }),
  };

  const impactTextBusy = {
    veryHigh: intl.formatMessage({ description: 'Extremely Busy', defaultMessage: 'Extremely Busy' }),
    high: intl.formatMessage({ description: 'Very Busy', defaultMessage: 'Very Busy' }),
    medium: intl.formatMessage({ description: 'Busy', defaultMessage: 'Busy' }),
    low: intl.formatMessage({ description: 'Busy on Occasion', defaultMessage: 'Busy on Occasion' }),
  };

  const impactTextPoor = {
    veryHigh: intl.formatMessage({ description: 'Extremely Poor', defaultMessage: 'Extremely Poor' }),
    high: intl.formatMessage({ description: 'Very Poor', defaultMessage: 'Very Poor' }),
    medium: intl.formatMessage({ description: 'Poor', defaultMessage: 'Poor' }),
    low: intl.formatMessage({ description: 'Poor on Occasion', defaultMessage: 'Poor on Occasion' }),
  };

  switch (generalStats.metricType) {
    case metricTypes.QUEUE_LENGTH:
      return intl.formatMessage(
        {
          description: 'Average queue length statistic tooltip',
          defaultMessage: 'AVG Queue Length = {queueLength} {unit}<br/>Approach Length = {approachLength} {unit}',
        },
        {
          queueLength: Math.round(props.avgQueueLength * distanceMultiplier),
          approachLength: Math.round(props.approachLength * distanceMultiplier),
          unit: distanceUnit,
        },
      );
    case metricTypes.NUMBER_OF_STOPS:
      return intl.formatMessage(
        {
          description: 'Probability to stop statistic tooltip',
          defaultMessage: 'Probability to Stop = {probability}%',
        },
        { probability: Math.round(props.probabilityToStop * 100) },
      );
    case metricTypes.WAITING_TIME:
      return intl.formatMessage(
        {
          description: 'Waiting/driving time statistic tooltip',
          defaultMessage: 'AVG Queue Time = {waiting} s<br/>AVG Travel Time = {driving} s',
        },
        { waiting: Math.round(props.avgWaiting), driving: Math.round(props.avgDriving) },
      );
    case metricTypes.SPEED:
      return intl.formatMessage(
        {
          description: 'Average speed statistic tooltip',
          defaultMessage: 'AVG Speed = {speed} {unit}<br/>Speed Limit = {speedLimit} {unit}',
        },
        {
          speed: Math.round(props.avgSpeed * speedMultiplier),
          speedLimit: Math.round(props.speedLimit * speedMultiplier),
          unit: speedUnit,
        },
      );
    case metricTypes.DWELL_TIME:
      return intl.formatMessage(
        { description: 'Average dwell time statistic tooltip', defaultMessage: 'AVG Dwell Time = {dwellTime} s' },
        { dwellTime: Math.round(props.avgDwellTime) },
      );
    case metricTypes.PEDESTRIANS_SIGNAL_PROGRESSION:
      return `
    ${intl.formatMessage({
      description: 'pedestrianSignalProgression statistic tooltip',
      defaultMessage: 'Dangerous Crossings: ',
    })}
    ${
      props.colorGroupId === 1
        ? impactTextUnsafe.veryHigh
        : props.colorGroupId === 2
        ? impactTextUnsafe.high
        : props.colorGroupId === 3
        ? impactTextUnsafe.medium
        : impactTextUnsafe.low
    }
  `;
    case metricTypes.RED_LIGHT:
      return `
    ${intl.formatMessage({
      description: 'Red light crossing statistic tooltip',
      defaultMessage: 'Traffic Signal Progression: ',
    })}
    ${
      props.colorGroupId === 1
        ? impactTextPoor.veryHigh
        : props.colorGroupId === 2
        ? impactTextPoor.high
        : props.colorGroupId === 3
        ? impactTextPoor.medium
        : impactTextPoor.low
    }
    `;
    case metricTypes.JAYWALKERS:
      return `
    ${intl.formatMessage({
      description: 'Jaywalkers statistic tooltip',
      defaultMessage: 'Jaywalking: ',
    })}
    ${
      props.colorGroupId === 1
        ? impactTextLight.veryHigh
        : props.colorGroupId === 2
        ? impactTextLight.high
        : props.colorGroupId === 3
        ? impactTextLight.medium
        : impactTextLight.low
    }
  `;
    case metricTypes.ACTIVE_CROSSWALK:
      return `
    ${intl.formatMessage({
      description: 'Active crosswalk statistic tooltip',
      defaultMessage: 'Crosswalk: ',
    })}
    ${
      props.colorGroupId === 1
        ? impactTextBusy.veryHigh
        : props.colorGroupId === 2
        ? impactTextBusy.high
        : props.colorGroupId === 3
        ? impactTextBusy.medium
        : impactTextBusy.low
    }
  `;
    case metricTypes.TOTAL_DENSITY:
      return `
    ${intl.formatMessage({
      description: 'Total density statistic tooltip',
      defaultMessage: 'Pedestrian & Micromobility Density: ',
    })}
      ${
        props.colorGroupId === 1
          ? impactText.veryHigh
          : props.colorGroupId === 2
          ? impactText.high
          : props.colorGroupId === 3
          ? impactText.medium
          : impactText.low
      }
    `;
    case metricTypes.PEDESTRIAN_DENSITY:
      return `
    ${intl.formatMessage({
      description: 'Pedestrians density statistic tooltip',
      defaultMessage: 'Pedestrian Density: ',
    })}
      ${
        props.colorGroupId === 1
          ? impactText.veryHigh
          : props.colorGroupId === 2
          ? impactText.high
          : props.colorGroupId === 3
          ? impactText.medium
          : impactText.low
      }
    `;
    case metricTypes.SCOOTER_DENSITY:
      return `
    ${intl.formatMessage({
      description: 'Scooters density statistic tooltip',
      defaultMessage: 'Scooter Density: ',
    })}
      ${
        props.colorGroupId === 1
          ? impactText.veryHigh
          : props.colorGroupId === 2
          ? impactText.high
          : props.colorGroupId === 3
          ? impactText.medium
          : impactText.low
      }
    `;
    case metricTypes.CYCLIST_DENSITY:
      return `
    ${intl.formatMessage({
      description: 'Cyclists density statistic tooltip',
      defaultMessage: 'Cyclist Density: ',
    })}
      ${
        props.colorGroupId === 1
          ? impactText.veryHigh
          : props.colorGroupId === 2
          ? impactText.high
          : props.colorGroupId === 3
          ? impactText.medium
          : impactText.low
      }
    `;
    case metricTypes.FACE_MASK_USAGE:
      return `
    ${intl.formatMessage({
      description: 'Face mask usage statistic tooltip',
      defaultMessage: 'Face Mask Usage: ',
    })}
      ${
        props.colorGroupId === 4
          ? impactText.veryHigh
          : props.colorGroupId === 3
          ? impactText.high
          : props.colorGroupId === 2
          ? impactText.medium
          : impactText.low
      }
    `;
    case metricTypes.AIR_QUALITY:
      return `
      <div style="display:flex; flex-direction: column; width: 150px;">
        <div style="display:flex; border-bottom: 1px solid #eee; padding-bottom: 10px; margin-bottom: 10px;">
          <strong>PM<sub>2.5</sub></strong>
          <span style="margin-left: auto">${props.pm25} ug/m3</span>
        </div>
        <div style="display:flex">
          <strong>PM<sub>10</sub></strong>
          <span style="margin-left: auto">${props.pm10} ug/m3</span>
        </div>
      </div>
    `;

    default:
      return '';
  }
};
