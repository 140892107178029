import { maps } from 'utils/openlayers-utils/initMap';
import layerStyles from 'utils/openlayers-utils/layersStyles';

export const centerMapOnIntersection = ({ intersection, minRes, padding = [10, 10, 10, 10] }) => {
  const olMap = maps.TrafficInsights;

  if (!olMap) return;
  const minResolution = minRes || 3;
  const intersectionsLayers = olMap.getLayers().getArray()[2].getLayers().getArray();
  const features = intersectionsLayers.map((layer) => layer.getSource().getFeatures()).flat();
  const feature = features.find((f) => f.values_.name === intersection);

  if (feature) {
    olMap.getView().fit(feature.getGeometry(), { duration: 1000, padding, minResolution });
  }
};

export const highlightIntersections = (featureIds) => {
  const olMap = maps.TrafficInsights;

  if (!olMap) return;
  const intersectionsLayers = olMap.getLayers().getArray()[2].getLayers().getArray();
  const features = intersectionsLayers.map((layer) => layer.getSource().getFeatures()).flat();

  if (features) {
    features.forEach((f) => {
      if (featureIds?.includes(f.get('id').toString())) {
        f.setStyle(layerStyles.corridorView.intersectionsActive);
      } else {
        f.setStyle(layerStyles.corridorView.intersection);
      }
    });
  }
};
