import { useSelector } from 'react-redux';
import { createIntl, createIntlCache } from 'react-intl';
import languages from 'lang/compiled-lang';
import { selectLocale } from 'features/userSlice';

let cachedIntl;

let cachedLocale;

export const useIntl = () => {
  const cache = createIntlCache();
  const locale = useSelector(selectLocale);

  if (cachedLocale === locale) return cachedIntl;

  cachedLocale = locale;
  const intl = createIntl(
    {
      locale,
      messages: languages[locale],
    },
    cache,
  );

  cachedIntl = intl;

  return intl;
};
