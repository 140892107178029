export const signalPlansMock = {
  programs: {
    146: {
      programs: {
        properties: {},
        guiViewInfoDescriptor: {
          columns: [
            {
              code: 'number',
              width: 60,
            },
            {
              code: 'fixed-cycle',
              width: 60,
            },
          ],
          sortByColumn: '',
          tableDataFilter: null,
          title: '',
          columnCount: 9,
          id: 'table',
        },
        programs: [2, 18, 19, 20, 5, 9, 41, 28, 13],
        ownerCode: 'Program',
        programsData: [
          {
            properties: {},
            number: 2,
            fixedCycle: false,
            cycleLength: 'CycleLength',
            cycleOffset: '0',
            description: 'לילה',
            specialCondition: '',
            comment: '',
            showTvInBP: false,
            operationMode: 'Internal',
          },
          {
            properties: {},
            number: 5,
            fixedCycle: false,
            cycleLength: 'CycleLength',
            cycleOffset: '0',
            description: 'לילה',
            specialCondition: '',
            comment: '',
            showTvInBP: false,
            operationMode: 'Internal',
          },
        ],
        empty: false,
        printViewInfoDescriptor: [
          {
            columns: [
              {
                code: 'number',
                width: 60,
              },
              {
                code: 'fixed-cycle',
                width: 60,
              },
            ],
            sortByColumn: '',
            tableDataFilter: null,
            title: '',
            columnCount: 8,
            id: 'table',
          },
        ],
      },
      timings: {
        groupTypes: {
          0: {
            id: 0,
            name: 'Test',
            displayName: 'Test',
          },
        },
        timeSlotsPerDay: 48,
        programTimingGroups: [],
      },
    },
  },
  programsLoading: 'loaded',
  phaseDiagrams: {
    146: {
      1: {
        'scenarios-list': {
          scenario: {
            duration: 80,
            number: 0,
            name: '',
            program: 1,
            'block-groups-list': {
              'block-group': [
                {
                  'junction-number': 146,
                  name: 1,
                  block: [
                    {
                      duration: 19,
                      visible: true,
                      name: 19,
                      start: 0,
                      fault: false,
                      type: 'SolidMove',
                    },
                    {
                      duration: 1,
                      visible: true,
                      name: 1,
                      start: 79,
                      fault: false,
                      type: 'SolidMove',
                    },
                  ],
                  type: 'Move',
                },
                {
                  'junction-number': 146,
                  name: 2,
                  block: [
                    {
                      duration: 21,
                      visible: true,
                      name: 21,
                      start: 0,
                      fault: false,
                      type: 'SolidMove',
                    },
                    {
                      duration: 1,
                      visible: true,
                      name: 1,
                      start: 79,
                      fault: false,
                      type: 'SolidMove',
                    },
                  ],
                  type: 'Move',
                },
              ],
              'cycles-list': {
                cycle: {
                  'junction-number': 146,
                  'branch-name': 'A, eqA, A1, B, C, A',
                  'branch-number': 1,
                  second: 80,
                },
              },
            },
          },
        },
        version: 101,
        'junction-data-list': {
          junction: {
            number: 146,
            'project-name': 'Tel Aviv AI Pilot',
            'move-signal-info-list': {
              move: [
                {
                  'yellow-time': 3,
                  'painter-id': 'move.solid',
                  color: 'MOVE_GREEN',
                  name: 1,
                  'red-yellow-time': 2,
                  'flashing-green-time': 0,
                },
                {
                  'yellow-time': 3,
                  'painter-id': 'move.solid',
                  color: 'MOVE_GREEN',
                  name: 2,
                  'red-yellow-time': 2,
                  'flashing-green-time': 0,
                },
              ],
            },
            'junction-name': 'Namir-Begin-Tnova',
            'project-version': 102,
            'axl-version': 122,
            version: 15,
          },
        },
      },
    },
  },
  phaseDiagramLoading: 'loaded',
};
