import theme from 'theme';
import store from 'store';
import * as mapboxgl from 'mapbox-gl';
import { icons as pinsIcons } from 'utils/findFeatureIcon';
import directionArrow from 'assets/images/arrow-direction.svg';
import carIcon from 'assets/images/current-frame.svg';
import { metricTypes } from 'consts';
import { setClickedGeneralViewFeatureId } from 'features';

// eslint-disable-next-line import/no-mutable-exports
export let clickedMapboxFeature;

let hoveredFeatureId = null;

const images = {
  directionArrow,
  carIcon,
};

pinsIcons.forEach((src) => {
  images[src] = src;
});

const tooltipPopup = new mapboxgl.Popup({
  closeButton: false,
  anchor: 'left',
  offset: 15,
});

export const initMapboxEvents = (mapboxglRef) => {
  mapboxglRef.on('load', ({ target: mapboxTarget }) => {
    Object.entries(images).forEach(([name, src]) => {
      const img = new Image();

      img.src = src;
      img.onload = () => mapboxTarget.addImage(name, img);
    });

    mapboxTarget.addSource('stats-data', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    mapboxTarget.addSource('current-frame', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    mapboxTarget.addLayer({
      id: 'stats-data-point',
      type: 'symbol',
      source: 'stats-data',
      layout: {
        'icon-image': ['get', 'iconImage'],
        'icon-allow-overlap': true,
        'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.5, 13, 1],
        'icon-anchor': 'bottom',
      },
      filter: ['==', '$type', 'Point'],
    });

    mapboxTarget.addLayer({
      id: 'stats-data-line-hover',
      type: 'line',
      source: 'stats-data',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': [
          'case',
          ['any', ['boolean', ['feature-state', 'hovered'], false], ['boolean', ['feature-state', 'selected'], false]],
          theme.color01,
          theme.colorTransparent,
        ],
        'line-width': 12,
        'line-offset': 4,
      },
      filter: ['==', '$type', 'LineString'],
    });

    mapboxTarget.addLayer({
      id: 'stats-data-line-border',
      type: 'line',
      source: 'stats-data',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': theme.colorWhite,
        'line-width': 6,
        'line-offset': 4,
      },
      filter: ['==', '$type', 'LineString'],
    });

    mapboxTarget.addLayer({
      id: 'stats-data-line',
      type: 'line',
      source: 'stats-data',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': ['get', 'lineColor'],
        'line-width': 4,
        'line-offset': 4,
      },
      filter: ['==', '$type', 'LineString'],
    });

    mapboxTarget.addLayer({
      id: 'stats-data-line-arrows',
      type: 'symbol',
      source: 'stats-data',
      layout: {
        'symbol-placement': 'line',
        'icon-image': 'directionArrow',
        'icon-anchor': 'top',
        'icon-size': 0.9,
        'symbol-spacing': 5,
      },
      filter: ['in', ['get', 'metricType'], ['literal', [metricTypes.QUEUE_LENGTH]]],
    });

    mapboxTarget.addLayer({
      id: 'current-frame',
      source: 'current-frame',
      type: 'circle',
    });
  });

  ['stats-data-point', 'stats-data-line-hover'].forEach((layerName) => {
    mapboxglRef.on('mousemove', layerName, (e) => {
      if (e.features.length) {
        mapboxglRef.getCanvas().style.cursor = 'pointer'; //eslint-disable-line
      }
    });

    mapboxglRef.on('mouseleave', layerName, () => {
      mapboxglRef.getCanvas().style.cursor = ''; //eslint-disable-line
    });

    mapboxglRef.on('mousemove', layerName, (e) => {
      if (!e.features.length) return;

      if (hoveredFeatureId) {
        mapboxglRef.setFeatureState({ source: 'stats-data', id: hoveredFeatureId }, { hovered: false });
      }
      hoveredFeatureId = e.features[0].id;
      mapboxglRef.setFeatureState({ source: 'stats-data', id: hoveredFeatureId }, { hovered: true });
      const { clickedGeneralViewFeatureId } = store.getState().appState;
      const condition = clickedGeneralViewFeatureId
        ? [
            'any',
            ['==', ['get', 'id'], hoveredFeatureId.toString()],
            ['==', ['get', 'id'], clickedGeneralViewFeatureId.toString()],
          ]
        : ['==', ['get', 'id'], hoveredFeatureId.toString()];

      mapboxglRef.setLayoutProperty('stats-data-point', 'icon-image', [
        'case',
        condition,
        ['get', 'iconImageSelected'],
        ['get', 'iconImage'],
      ]);

      const tooltipText = e.features[0].properties.tooltipContent;

      if (tooltipText) {
        tooltipPopup.setLngLat(e.lngLat).setHTML(tooltipText).addTo(mapboxglRef);
      }
    });

    mapboxglRef.on('mouseleave', layerName, () => {
      const { clickedGeneralViewFeatureId } = store.getState().appState;

      mapboxglRef.setFeatureState({ source: 'stats-data', id: hoveredFeatureId }, { hovered: false });
      if (clickedGeneralViewFeatureId) {
        mapboxglRef.setLayoutProperty('stats-data-point', 'icon-image', [
          'case',
          ['==', ['get', 'id'], clickedGeneralViewFeatureId?.toString()],
          ['get', 'iconImageSelected'],
          ['get', 'iconImage'],
        ]);
      } else {
        mapboxglRef.setLayoutProperty('stats-data-point', 'icon-image', ['get', 'iconImage']);
      }
      hoveredFeatureId = null;
      tooltipPopup.remove();
    });
  });

  mapboxglRef.on('click', (e) => {
    const features = mapboxglRef.queryRenderedFeatures(e.point);
    const { clickedGeneralViewFeatureId } = store.getState().appState;

    if (clickedGeneralViewFeatureId) {
      mapboxglRef.setFeatureState({ source: 'stats-data', id: clickedGeneralViewFeatureId }, { selected: false });
      mapboxglRef.setLayoutProperty('stats-data-point', 'icon-image', ['get', 'iconImage']);
      store.dispatch(setClickedGeneralViewFeatureId(null));
    }
    if (features?.[0]?.source === 'stats-data') {
      const { id: clickedFeatureId } = features[0];

      // eslint-disable-next-line prefer-destructuring
      clickedMapboxFeature = features[0];
      store.dispatch(setClickedGeneralViewFeatureId(clickedFeatureId));
      mapboxglRef.setFeatureState({ source: 'stats-data', id: clickedFeatureId }, { selected: true });
      mapboxglRef.setLayoutProperty('stats-data-point', 'icon-image', [
        'case',
        ['==', ['get', 'id'], clickedFeatureId.toString()],
        ['get', 'iconImageSelected'],
        ['get', 'iconImage'],
      ]);
    }
  });
};
