import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Divider, Drawer } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ArrowBack } from '@material-ui/icons';
import { Header } from 'components';
import {
  selectIsNavigationOpen,
  setIsNavigationOpen,
  selectIsUserAdmin,
  selectUser,
  selectIsSidebarVisible,
  selectCanRunSimulations,
} from 'features';
import NavigationDrawerStyles from './NavigationDrawer.styles';

const useStyles = makeStyles(NavigationDrawerStyles);

export const NavigationDrawer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isNavigationOpen = useSelector(selectIsNavigationOpen);
  const isSidebarVisible = useSelector(selectIsSidebarVisible);
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const canUserRunSimulations = useSelector(selectCanRunSimulations);
  const user = useSelector(selectUser);

  const closeNavigation = () => {
    dispatch(setIsNavigationOpen(false));
  };

  // eslint-disable-next-line react/prop-types
  const ProtectedLink = ({ children, adminRole, digitalTwinRole }) => {
    if (user.loading !== 'loaded') {
      return (
        <ListItem classes={{ root: classes.listItem }}>
          <Skeleton variant="text" animation="wave" className={classes.listItemSkeleton} />
        </ListItem>
      );
    }
    if ((adminRole && isUserAdmin) || (digitalTwinRole && canUserRunSimulations)) {
      return children;
    }

    return null;
  };

  const renderNavigationList = () => (
    <div role="presentation" onClick={closeNavigation} onKeyDown={closeNavigation}>
      <List>
        <NavLink exact to="/home">
          <ListItem button classes={{ root: classes.listItem }}>
            <ListItemText
              primary={<FormattedMessage defaultMessage="Home" description="Navigates to Home screen" />}
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </NavLink>
        <NavLink to="/general">
          <ListItem button classes={{ root: classes.listItem }}>
            <ListItemText
              primary={<FormattedMessage defaultMessage="City View" description="Navigates to City View screen" />}
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </NavLink>
        <NavLink to="/corridors">
          <ListItem button classes={{ root: classes.listItem }}>
            <ListItemText
              primary={<FormattedMessage defaultMessage="Corridor View" description="Navigates to Corridors screen" />}
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </NavLink>
        <ProtectedLink adminRole>
          <NavLink to="/tenants" exact>
            <ListItem button classes={{ root: classes.listItem }}>
              <ListItemText
                primary={<FormattedMessage defaultMessage="Tenants" description="Navigates to tenants" />}
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          </NavLink>
        </ProtectedLink>
        <ProtectedLink adminRole>
          <NavLink to="/admin/all">
            <ListItem button classes={{ root: classes.listItem }}>
              <ListItemText
                primary={<FormattedMessage defaultMessage="All data" description="Navigates to Admin All data" />}
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          </NavLink>
        </ProtectedLink>
        <ProtectedLink adminRole>
          <NavLink to="/admin/edit-grid">
            <ListItem button classes={{ root: classes.listItem }}>
              <ListItemText
                primary={<FormattedMessage defaultMessage="Edit grid" description="Navigates to Edit grid" />}
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          </NavLink>
        </ProtectedLink>
        <ProtectedLink digitalTwinRole>
          <NavLink to="/simulation">
            <ListItem button classes={{ root: classes.listItem }}>
              <ListItemText
                primary={<FormattedMessage defaultMessage="Digital Twin" description="Navigates to Digital Twin" />}
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          </NavLink>
        </ProtectedLink>
        <ProtectedLink adminRole>
          <NavLink to="/about">
            <ListItem button classes={{ root: classes.listItem }}>
              <ListItemText
                primary={<FormattedMessage defaultMessage="About" description="Navigates to About" />}
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          </NavLink>
        </ProtectedLink>
        <Divider variant="middle" />
        <ProtectedLink adminRole>
          <NavLink to="/mobileye">
            <ListItem button classes={{ root: classes.listItem }}>
              <ListItemText
                primary={<FormattedMessage defaultMessage="Mobileye" description="Navigates to Mobileye" />}
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          </NavLink>
        </ProtectedLink>
      </List>
    </div>
  );

  return (
    <Drawer
      open={isNavigationOpen && isSidebarVisible}
      classes={{ paperAnchorLeft: classes.drawerAnchor, root: classes.drawer }}
      onClose={closeNavigation}
    >
      <Header color="black" bgColor="transparent" icon={<ArrowBack onClick={closeNavigation} />} />
      {renderNavigationList()}
    </Drawer>
  );
};
