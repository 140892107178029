import React, { useEffect } from 'react';
import { Divider } from '@material-ui/core';
import { postMobileyeLogin } from 'api/endpoints';
import { MOBILEYE_TOKEN_KEY } from 'consts';
import { SidebarContainer } from 'components';
import { LiveEventsForm } from './LiveEventsForm/LiveEventsForm';
import { RealTimeTrafficForm } from './RealTimeTrafficform/RealTimeTrafficForm';
import { MapboxMap } from './MapboxMap/MapboxMap';
import styles from './Mobileye.module.scss';

export const Mobileye = () => {
  /* ---------------------------------- Hooks --------------------------------- */

  /* -------------------------------- Selectors ------------------------------- */

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const login = async () => {
    const currentToken = window.sessionStorage.getItem(MOBILEYE_TOKEN_KEY);

    if (!currentToken) {
      const { data } = await postMobileyeLogin();

      window.sessionStorage.setItem(MOBILEYE_TOKEN_KEY, data.token);
    }
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    login();
  }, []);

  return (
    <>
      <SidebarContainer>
        <div className={styles.sidebarContent}>
          <LiveEventsForm />
          <Divider />
          <RealTimeTrafficForm />
        </div>
      </SidebarContainer>
      <MapboxMap />
    </>
  );
};
