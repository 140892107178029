import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TabbedMenus } from '@axilion/ui-components';
import {
  selectStatisticType,
  setStatisticType,
  setMetricType,
  selectIsUserAdmin,
  resetActiveFrameCords,
} from 'features';
import { GENERAL_VIEW_MENUS, ADMIN_ONLY_MENU_ITEMS, getGeneralViewTabs } from 'consts';

export const StatisticsMenu = () => {
  const dispatch = useDispatch();
  const statisticType = useSelector(selectStatisticType);
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const tabs = getGeneralViewTabs(isUserAdmin);

  const menus = useMemo(() => {
    return Object.entries(GENERAL_VIEW_MENUS).reduce((acc, [tabName, menuItems]) => {
      acc[tabName] = menuItems.filter((menuItem) => isUserAdmin || !ADMIN_ONLY_MENU_ITEMS.includes(menuItem.name));

      return acc;
    }, {});
  }, [isUserAdmin]);

  const initialTab = tabs.find(({ name }) => name === statisticType);

  const onTabSelect = (activeTab) => {
    dispatch(setStatisticType(activeTab.name));
  };

  const onMenuSelect = async (menuItem) => {
    dispatch(setMetricType(menuItem.name));
    dispatch(resetActiveFrameCords());
  };

  const getMenuHeight = () => {
    const maxMenuItemsCount = Math.max(...Object.values(menus).map(({ length }) => length));

    return maxMenuItemsCount * 70;
  };

  return (
    <>
      <TabbedMenus
        {...{
          tabs,
          menus,
          onTabSelect,
          onMenuSelect,
          initialTab,
          menuHeight: getMenuHeight(),
        }}
      />
    </>
  );
};
