import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, TableContainer, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as PlayVideoIcon } from 'assets/images/Play Video@3x.svg';
import { selectExpandedPanel, selectCurrentTotalRides } from 'features/appStateSlice';
import { useFormat } from 'utils/hooks';
import { EnhancedTableHead } from 'components';
import tableStyles from '../table.styles';
import { getComparator, stableSort } from './sortingFunctions';

const useStyles = makeStyles(tableStyles);

export const TotalRidesTable = ({ totalRides, onRideClick }) => {
  const { fromUtcToProjectTime } = useFormat();
  const classes = useStyles();
  const expandedPanel = useSelector(selectExpandedPanel);
  const currentTotalRides = useSelector(selectCurrentTotalRides);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('startRideDate');
  const [selectedRowId, setSelectedRowId] = useState(undefined);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onRowClick = (ride) => () => {
    setSelectedRowId(ride.rideId);
    onRideClick(ride);
  };

  useEffect(() => {
    setSelectedRowId(undefined);
  }, [expandedPanel, currentTotalRides]);

  return (
    <Box className={classes.boxContainer}>
      <div className={classes.tableContainer}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={totalRides[currentTotalRides].length}
              classes={classes}
            />
            <TableBody>
              {totalRides[currentTotalRides].length ? (
                stableSort(totalRides[currentTotalRides], getComparator(order, orderBy)).map((ride, index) => {
                  const isRowSelected = ride.rideId === selectedRowId;

                  return (
                    <TableRow
                      key={index.toString()}
                      className={`${classes.tableRow} ${isRowSelected ? classes.selectedRow : ''}`}
                      onClick={onRowClick(ride)}
                    >
                      <TableCell align="center" className={`${isRowSelected ? classes.selectedCell : ''}`}>
                        {ride.cameraId}
                      </TableCell>
                      <TableCell align="center" className={`${isRowSelected ? classes.selectedCell : ''}`}>
                        {ride.startRideDate && fromUtcToProjectTime(ride.startRideDate, 'DATE_MED')}
                      </TableCell>
                      <TableCell align="center" className={`${isRowSelected ? classes.selectedCell : ''}`}>
                        {ride.startRideDate && fromUtcToProjectTime(ride.startRideDate, 'TIME_SIMPLE')}
                      </TableCell>
                      <TableCell align="center" className={`${isRowSelected ? classes.selectedCell : ''}`}>
                        {ride.startIntersectionName}
                      </TableCell>
                      <TableCell align="center" className={`${isRowSelected ? classes.selectedCell : ''}`}>
                        {ride.endIntersectionName}
                      </TableCell>
                      <TableCell align="center" className={`${isRowSelected ? classes.selectedCell : ''}`}>
                        {ride.numberOfIntersections}
                      </TableCell>
                      <TableCell align="center" className={`${isRowSelected ? classes.selectedCell : ''}`}>
                        {ride.vehicle}
                      </TableCell>
                      <TableCell align="center" className={`${isRowSelected ? classes.selectedCell : ''}`}>
                        <PlayVideoIcon className={classes.playVideoIcon} />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center" className={classes.tableRowNoData}>
                    No information for selected data.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );
};

TotalRidesTable.propTypes = {
  totalRides: propTypes.object,
  onRideClick: propTypes.func,
};

TotalRidesTable.defaultProps = {
  totalRides: { first: [], second: [] },
  onRideClick() {},
};
