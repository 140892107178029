import React from 'react';
import propTypes from 'prop-types';
import { Box } from '@material-ui/core';
import styles from './ChartBar.module.scss';

export const ChartBar = ({ values, isGreen, isOneBar, size }) => {
  const parsed = values.map((val) => parseInt(val, 10));
  const maxVal = Math.max(...parsed);
  const maxWidth = {
    normal: 30,
    small: 20,
  }[size];

  if (isOneBar) {
    parsed.length = 1;
  }

  return (
    <div className={`${styles.container} ${styles[size]}`}>
      {parsed.map((val, i) => (
        <Box key={i.toString()} className={styles.item}>
          <Box
            className={`
                ${styles.boxHeaderInner}
                ${isGreen || i > 0 ? styles.greenBackground : styles.redBackground}
              `}
            style={{ width: (val === maxVal ? maxWidth : (val / maxVal) * maxWidth) || 0 }}
          />
          <span className={styles.mainInfo}>{values[i]}</span>
        </Box>
      ))}
    </div>
  );
};

ChartBar.propTypes = {
  values: propTypes.array.isRequired,
  isGreen: propTypes.bool,
  isOneBar: propTypes.bool,
  size: propTypes.string,
};

ChartBar.defaultProps = {
  isGreen: false,
  isOneBar: false,
  size: 'normal',
};
