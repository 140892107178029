import React, { useEffect } from 'react';
import { changeMapSource } from 'utils';
import { useSelector, useDispatch } from 'react-redux';
import klokantechTile from 'assets/images/klokantech-tile.png';
import olmTile from 'assets/images/osm-tile.png';
import { selectActiveTileLayerIndex, setActiveTileLayerIndex } from 'features';
import classes from './TileLayerSwitch.module.scss';

export const TileLayerSwitch = () => {
  const dispatch = useDispatch();
  const activeTileLayerIndex = useSelector(selectActiveTileLayerIndex);

  const toggleTileLayer = () => {
    dispatch(setActiveTileLayerIndex(activeTileLayerIndex ? 0 : 1));
  };

  useEffect(() => {
    changeMapSource(activeTileLayerIndex);
  }, [activeTileLayerIndex]);

  return (
    <div>
      <img
        src={activeTileLayerIndex ? olmTile : klokantechTile}
        alt="Toggle map tiles"
        className={classes.tileImg}
        onClick={toggleTileLayer}
      />
    </div>
  );
};
