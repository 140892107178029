export const simulationsMock = {
  items: {
    'simulation-123': {
      id: 'simulation-123',
      stats: {
        greenTime: {
          junctions: {
            136: {
              moves: {
                1: {
                  avg: 20,
                  min: 1,
                  max: 20,
                },
                2: {
                  avg: 22,
                  min: 1,
                  max: 22,
                },
              },
            },
            146: {
              moves: {
                1: {
                  avg: 44,
                  min: 44,
                  max: 44,
                },
                2: {
                  avg: 21,
                  min: 21,
                  max: 21,
                },
              },
            },
          },
        },
        cycleLength: {
          avg: 97.0810775756836,
          min: 82,
          max: 100,
        },
      },
      result: [
        {
          junctionId: '136',
          approaches: {
            E: {
              HCMDelay: 0,
              LOS: 'A',
              avgCO2EmissionPerVehicleGram: 0,
              avgDelay: 0,
              avgQueueLength: 0,
              volume: 0,
            },
            N: {
              HCMDelay: 28.489999771118164,
              LOS: 'C',
              avgCO2EmissionPerVehicleGram: 98,
              avgDelay: 60.36000061035156,
              avgQueueLength: 43.31490707397461,
              volume: 0,
            },
            S: {
              HCMDelay: 354.8399963378906,
              LOS: 'F',
              avgCO2EmissionPerVehicleGram: 263,
              avgDelay: 366.79998779296875,
              avgQueueLength: 124.83042907714844,
              volume: 0,
            },
            W: {
              HCMDelay: 1276.0799560546875,
              LOS: 'F',
              avgCO2EmissionPerVehicleGram: 1108,
              avgDelay: 1304.4200439453125,
              avgQueueLength: 245.23802185058594,
              volume: 0,
            },
          },
        },
        {
          junctionId: '146',
          approaches: {
            E: {
              HCMDelay: 689.75,
              LOS: 'F',
              avgCO2EmissionPerVehicleGram: 484,
              avgDelay: 698.6500244140625,
              avgQueueLength: 185.5761260986328,
              volume: 0,
            },
            N: {
              HCMDelay: 39.72999954223633,
              LOS: 'D',
              avgCO2EmissionPerVehicleGram: 93,
              avgDelay: 63.47999954223633,
              avgQueueLength: 67.9996109008789,
              volume: 0,
            },
            S: {
              HCMDelay: 276.6499938964844,
              LOS: 'F',
              avgCO2EmissionPerVehicleGram: 256,
              avgDelay: 311.8800048828125,
              avgQueueLength: 132.06333923339844,
              volume: 0,
            },
            W: {
              HCMDelay: 1448.6099853515625,
              LOS: 'F',
              avgCO2EmissionPerVehicleGram: 980,
              avgDelay: 1465.1700439453125,
              avgQueueLength: 279.76751708984375,
              volume: 0,
            },
          },
        },
      ],
      diagram: [
        {
          junctionId: 136,
          name: 'Namir-Begin-Tnova',
          moveSignals: {
            move: [
              {
                'yellow-time': 3,
                'painter-id': 'move.solid',
                color: 'MOVE_GREEN',
                name: 1,
                'red-yellow-time': 2,
                'flashing-green-time': 0,
              },
              {
                'yellow-time': 3,
                'painter-id': 'move.solid',
                color: 'MOVE_GREEN',
                name: 2,
                'red-yellow-time': 2,
                'flashing-green-time': 0,
              },
            ],
          },
          scenarioList: {
            scenario: {
              'block-groups-list': {
                'block-group': [
                  {
                    'junction-number': 136,
                    name: 1,
                    block: [
                      {
                        duration: 19,
                        visible: true,
                        name: 19,
                        start: 0,
                        fault: false,
                        type: 'SolidMove',
                      },
                      {
                        duration: 20,
                        visible: true,
                        name: 20,
                        start: 79,
                        fault: false,
                        type: 'SolidMove',
                      },
                    ],
                    type: 'Move',
                  },
                ],
                'cycles-list': {
                  cycle: [
                    {
                      'junction-number': 136,
                      'branch-name': 'A, eqA, A1, B, C, A',
                      'branch-number': 1,
                      second: 80,
                    },
                    {
                      'junction-number': 136,
                      'branch-name': 'A, eqA, A1, B, C, A',
                      'branch-number': 1,
                      second: 160,
                    },
                  ],
                },
              },
              duration: 10,
              name: 'StandAloneEmulator',
              number: 1,
              program: 25,
            },
          },
        },
        {
          junctionId: 146,
          name: 'Namir-Arlozerov-AlParashatDrachim',
          moveSignals: {
            move: [
              {
                'yellow-time': 3,
                'painter-id': 'move.solid',
                color: 'MOVE_GREEN',
                name: 1,
                'red-yellow-time': 2,
                'flashing-green-time': 3,
              },
              {
                'yellow-time': 3,
                'painter-id': 'move.solid',
                color: 'MOVE_GREEN',
                name: 2,
                'red-yellow-time': 2,
                'flashing-green-time': 3,
              },
            ],
          },
          scenarioList: {
            scenario: {
              'block-groups-list': {
                'block-group': [
                  {
                    'junction-number': 146,
                    name: 1,
                    block: [
                      {
                        duration: 44,
                        visible: true,
                        name: 44,
                        start: 59,
                        fault: false,
                        type: 'SolidMove',
                      },
                      {
                        duration: 44,
                        visible: true,
                        name: 44,
                        start: 169,
                        fault: false,
                        type: 'SolidMove',
                      },
                    ],
                    type: 'Move',
                  },
                ],
                'cycles-list': {
                  cycle: [
                    {
                      'junction-number': 146,
                      'branch-name': 'A, eqA, B, C, A',
                      'branch-number': 1,
                      second: 110,
                    },
                    {
                      'junction-number': 146,
                      'branch-name': 'A, eqA, B, C, A',
                      'branch-number': 1,
                      second: 220,
                    },
                  ],
                },
              },
              duration: 3600,
              name: 'StandAloneEmulator',
              number: 1,
              program: 25,
            },
          },
        },
      ],
    },
  },
  currentId: 'simulation-123',
  loading: 'loaded',
};
