import React from 'react';
import propTypes from 'prop-types';
import styles from './ColorLegend.module.scss';

export const ColorLegend = ({ values }) => {
  if (!values.length) return null;

  const makeRange = (endValue, i) => {
    const startValue = i === values.length - 1 ? 0 : values[i + 1] + 1;

    if (startValue === endValue) {
      return startValue;
    }

    return `${startValue}-${endValue}`;
  };

  return (
    <div className={styles.legendContainer}>
      {values.map((value, i) => (
        <div key={i.toString()} className={`${styles.item} ${styles[['red', 'orange', 'yellow', 'green'][i]]}`}>
          {makeRange(value, i)}
        </div>
      ))}
    </div>
  );
};

ColorLegend.propTypes = {
  values: propTypes.array.isRequired,
};
