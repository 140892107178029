import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as AccessTimeIcon } from 'assets/images/ic_access_time@3x.svg';
import { ReactComponent as LocalShippingIcon } from 'assets/images/ic_local_shipping@3x.svg';
import { ReactComponent as VideoCamIcon } from 'assets/images/ic_videocam@3x.svg';
import { ReactComponent as CalendarDateIcon } from 'assets/images/Calendar Date@3x.svg';
import { ReactComponent as HashTag } from 'assets/images/hash-tag.svg';
import { ReactComponent as PlayHeader } from 'assets/images/play-header.svg';
import { ReactComponent as Flag } from 'assets/images/flag.svg';

export const headCells = [
  {
    id: 'cameraId',
    icon: <FormattedMessage defaultMessage="ID" description="Total rides table column ID" />,
  },
  {
    id: 'startRideDate',
    icon: <CalendarDateIcon />,
  },
  {
    id: 'startRideTime',
    icon: <AccessTimeIcon />,
  },
  {
    id: 'startIntersectionName',
    icon: <Flag />,
  },
  {
    id: 'endIntersectionName',
    icon: <PlayHeader />,
  },
  {
    id: 'numberOfIntersections',
    icon: <HashTag />,
  },
  {
    id: 'vehicle',
    icon: <LocalShippingIcon />,
  },
  {
    id: 'videoCam',
    icon: <VideoCamIcon />,
  },
];
