import { createSlice } from '@reduxjs/toolkit';
import { fetchTimeIntervals, handleEditTimeIntervals } from 'features/thunks';
import { DateTime } from 'luxon';

export const timeIntervalsSlice = createSlice({
  name: 'timeIntervals',
  initialState: {
    intervals: [],
    loading: 'idle',
    loadingEditTimeIntervals: 'idle',
  },
  reducers: {
    resetLoadingEditTimeIntervals: (state) => {
      state.loadingEditTimeIntervals = 'idle';
    },
  },
  extraReducers: {
    [fetchTimeIntervals.pending]: (state) => {
      state.loading = 'loading';
    },
    [fetchTimeIntervals.fulfilled]: (state, action) => {
      state.intervals = action.payload.map((interval) => ({
        ...interval,
        startTime: DateTime.fromISO(interval.startTime).toLocaleString(DateTime.TIME_24_SIMPLE),
        endTime: DateTime.fromISO(interval.endTime).toLocaleString(DateTime.TIME_24_SIMPLE),
        startDay: 0,
        endDay: 4,
        default: interval.label === 'morning_rush_hour',
      }));
      state.loading = 'loaded';
    },
    [fetchTimeIntervals.rejected]: (state) => {
      state.loading = 'error';
    },
    [handleEditTimeIntervals.pending]: (state) => {
      state.loadingEditTimeIntervals = 'loading';
    },
    [handleEditTimeIntervals.fulfilled]: (state) => {
      state.loadingEditTimeIntervals = 'loaded';
    },
    [handleEditTimeIntervals.rejected]: (state) => {
      state.loadingEditTimeIntervals = 'error';
    },
  },
});

export const selectTimeIntervals = ({ timeIntervals }) => timeIntervals.intervals;
export const selectLoadingTimeIntervals = ({ timeIntervals }) => timeIntervals.loading;
export const selectLoadingEditTimeIntervals = ({ timeIntervals }) => timeIntervals.loadingEditTimeIntervals;

export const { resetLoadingEditTimeIntervals } = timeIntervalsSlice.actions;

export default timeIntervalsSlice.reducer;
