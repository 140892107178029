import React from 'react';
import propTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog } from 'screens/EditGrid/Dialog';
import { DateTime } from 'luxon';
import { selectTimeIntervals, handleEditTimeIntervals } from 'features';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import styles from './EditTimeIntervalsDialog.module.scss';

export const EditTimeIntervalsDialog = ({ isOpen, close, submit }) => {
  const dispatch = useDispatch();
  const timeIntervals = useSelector(selectTimeIntervals);

  const onSubmit = (event) => {
    const form = event.target;
    const requestObject = [];

    timeIntervals.forEach(({ id, label }) => {
      const startInput = form.elements[`start${id}`];
      const endInput = form.elements[`end${id}`];
      const startValue = startInput.value;
      const endValue = endInput.value;

      let validity = '';

      if (startValue === endValue || (startValue > endValue && endValue !== '00:00')) {
        validity = 'End Time must be after Start Time';
      }

      endInput.setCustomValidity(validity);

      requestObject.push({
        id,
        label,
        startTime: DateTime.fromISO(startValue).toLocaleString(DateTime.TIME_24_WITH_SECONDS),
        endTime: DateTime.fromISO(endValue).toLocaleString(DateTime.TIME_24_WITH_SECONDS),
      });
    });

    if (form.reportValidity()) {
      dispatch(handleEditTimeIntervals(requestObject));
      submit();
    }
  };

  const onChange = (event) => {
    if (event.target.type === 'time') {
      event.target.form.querySelectorAll('[type=time]').forEach((el) => el.setCustomValidity(''));
    }
  };

  return (
    <Dialog
      title="Set Time Intervals"
      isOpen={isOpen}
      onClose={close}
      onSubmit={onSubmit}
      className={styles.root}
      onChange={onChange}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Label</TableCell>
              <TableCell>Start time</TableCell>
              <TableCell>End time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timeIntervals.map(({ id, label, startTime, endTime }) => (
              <TableRow key={id}>
                <TableCell>{id}</TableCell>
                <TableCell>{label}</TableCell>
                <TableCell>
                  <input type="time" defaultValue={startTime} step="1800" name={`start${id}`} required />
                </TableCell>
                <TableCell>
                  <input type="time" defaultValue={endTime} step="1800" name={`end${id}`} required />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
};

EditTimeIntervalsDialog.propTypes = {
  isOpen: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  submit: propTypes.func.isRequired,
};
