import React from 'react';
import propTypes from 'prop-types';
import styles from './IconButton.module.scss';

export const IconButton = ({ icon, buttonProps, dataTestid }) => (
  <button type="button" className={styles.button} {...buttonProps} data-testid={dataTestid}>
    {icon}
  </button>
);

IconButton.propTypes = {
  icon: propTypes.node.isRequired,
  buttonProps: propTypes.object,
  dataTestid: propTypes.string,
};

IconButton.defaultProps = {
  buttonProps: {},
  dataTestid: undefined,
};
