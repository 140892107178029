const OperatingScheduleStyles = (theme) => ({
  container: {
    position: 'absolute',
    left: 0,
    top: 70,
    right: 0,
    bottom: 0,
    overflowX: 'auto',
  },
  hintStyle: {
    background: theme.colorWhite,
    color: theme.colorBlack,
    fontSize: 11,
    fontWeight: 700,
    padding: '2px 2px',
    borderRadius: 50,
    minWidth: 12,
    textAlign: 'center',
    transform: 'translate(50%, 50%)',
  },
});

export default OperatingScheduleStyles;
