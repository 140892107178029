import { createSlice } from '@reduxjs/toolkit';

export const unitSystemSlice = createSlice({
  name: 'unitSystem',
  initialState: {
    unitSystemName: undefined,
    speedMultiplier: undefined,
    distanceMultiplier: undefined,
    speedUnit: undefined,
    distanceUnit: undefined,
  },
  reducers: {
    setUnitSystem: (state, { payload }) => {
      const { unitSystem, intl } = payload;

      state.unitSystemName = unitSystem;
      if (unitSystem === 'metric') {
        state.speedMultiplier = 1;
        state.distanceMultiplier = 1;
        state.speedUnit = intl.formatMessage({ description: 'Kilometers per hour', defaultMessage: 'km/h' });
        state.distanceUnit = intl.formatMessage({ description: 'Meters', defaultMessage: 'm' });
      } else {
        state.speedMultiplier = 0.621371;
        state.distanceMultiplier = 3.28084;
        state.speedUnit = intl.formatMessage({ description: 'Miles per hour', defaultMessage: 'mph' });
        state.distanceUnit = intl.formatMessage({ description: 'Feet', defaultMessage: 'ft' });
      }
    },
  },
});

export const { setUnitSystem } = unitSystemSlice.actions;

export const selectUnitSystemName = ({ unitSystem }) => unitSystem.unitSystemName;
export const selectSpeedMultiplier = ({ unitSystem }) => unitSystem.speedMultiplier;
export const selectDistanceMultiplier = ({ unitSystem }) => unitSystem.distanceMultiplier;
export const selectSpeedUnit = ({ unitSystem }) => unitSystem.speedUnit;
export const selectDistanceUnit = ({ unitSystem }) => unitSystem.distanceUnit;

export default unitSystemSlice.reducer;
