import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { BottomContainer } from 'components';
import {
  selectSpeedMultiplier,
  selectDistanceMultiplier,
  selectSpeedUnit,
  selectDistanceUnit,
  fetchRidesFrames,
  selectRidesFrames,
  selectCurrentCameraId,
  selectLoadingAdminFrames,
  selectSelectedApproachId,
  setSelectedApproachId,
  setActiveFrameCords,
  setActiveFrameDetails,
} from 'features';
import { usePostData, useFormat } from 'utils/hooks';
import { FramesSlider } from '@axilion/ui-components';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import styles from './AdminRidesContainer.module.scss';

export const AdminRidesContainer = ({ rides, addRideLayerToMap, slideIn, onClose }) => {
  const { framesPostData } = usePostData();
  const dispatch = useDispatch();
  const { fromUtcToProjectTime } = useFormat();
  const speedMultiplier = useSelector(selectSpeedMultiplier);
  const distanceMultiplier = useSelector(selectDistanceMultiplier);
  const speedUnit = useSelector(selectSpeedUnit);
  const distanceUnit = useSelector(selectDistanceUnit);
  const ridesFrames = useSelector(selectRidesFrames);
  const currentCameraId = useSelector(selectCurrentCameraId);
  const loadingFrames = useSelector(selectLoadingAdminFrames);
  const selectedApproachId = useSelector(selectSelectedApproachId);
  const [selectedRideIndex, setSelectedRideIndex] = useState();
  const [isRideRowExpanded, setIsRideRowExpanded] = useState();

  const onRideClick = (ride, index) => {
    if (selectedRideIndex !== index) {
      setIsRideRowExpanded(true);
      addRideLayerToMap(ride.approach);
      setSelectedRideIndex(index);
      dispatch(setSelectedApproachId());

      const startDate = new Date(ride.approach[0].startTime);
      const endDate = new Date(ride.approach[ride.approach.length - 1].endTime);

      startDate.setMinutes(startDate.getMinutes() - 3);
      endDate.setMinutes(endDate.getMinutes() + 3);
      dispatch(
        fetchRidesFrames(
          framesPostData({
            cameraId: currentCameraId,
            startDate,
            endDate,
          }),
        ),
      );
    } else {
      setIsRideRowExpanded(!isRideRowExpanded);
    }
  };

  const onApproachClick = (approachId) => {
    if (selectedApproachId !== approachId) {
      dispatch(setSelectedApproachId(approachId));
    }
  };

  const onActiveFrameCoordsChange = (frameCoords) => {
    dispatch(setActiveFrameCords(frameCoords));
  };

  const onActiveFrameDetailsChange = (frameDetails) => {
    dispatch(setActiveFrameDetails(frameDetails));
  };

  const renderRidesTable = () => {
    if (!rides.length) return <div className={styles.noData}>No data</div>;

    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ride ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rides.map((ride, index) => (
              <Fragment key={ride.id}>
                <TableRow
                  hover
                  className={styles.tableRow}
                  onClick={() => onRideClick(ride, index)}
                  selected={selectedRideIndex === index}
                >
                  <TableCell className={styles.tableCell}>
                    {ride.id}
                    {selectedRideIndex === index && isRideRowExpanded ? <ExpandLess /> : <ExpandMore />}
                  </TableCell>
                </TableRow>
                <TableRow className={styles.tableRow}>
                  <TableCell className={styles.collapseTableCell}>
                    <Collapse in={selectedRideIndex === index && isRideRowExpanded} timeout="auto" unmountOnExit>
                      <Table className={styles.innerTable}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Approach ID</TableCell>
                            <TableCell>Blockage (%)</TableCell>
                            <TableCell>Travel time (s)</TableCell>
                            <TableCell>Dwell time (s)</TableCell>
                            <TableCell>Queue time (s)</TableCell>
                            <TableCell>{`Queue length (${distanceUnit})`}</TableCell>
                            <TableCell>Section order</TableCell>
                            <TableCell>{`Speed (${speedUnit})`}</TableCell>
                            <TableCell>Stops</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ride.approach.map((approach) => {
                            const parsedApproachGeoJson = JSON.parse(approach.approachGeoJson);
                            const approachId = parsedApproachGeoJson.id;

                            return (
                              <TableRow
                                hover
                                key={approachId}
                                onClick={() => onApproachClick(approachId)}
                                selected={selectedApproachId === approachId}
                              >
                                <TableCell>{approachId}</TableCell>
                                <TableCell>{approach.blockage}</TableCell>
                                <TableCell>{approach.driveTime}</TableCell>
                                <TableCell>{approach.dwellTime}</TableCell>
                                <TableCell>{approach.queueTime}</TableCell>
                                <TableCell>{approach.queueLength * distanceMultiplier}</TableCell>
                                <TableCell>{approach.sectionOrder}</TableCell>
                                <TableCell>{approach.speed * speedMultiplier}</TableCell>
                                <TableCell>{approach.stops}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderFramesSlider = () => (
    <FramesSlider
      framesLists={[ridesFrames]}
      isLoading={loadingFrames === 'loading'}
      showNotFound={loadingFrames === 'error'}
      showFrameDetails
      fillParentHeight
      showTags
      fromUtcToProjectTime={fromUtcToProjectTime}
      onActiveFrameCoordsChange={onActiveFrameCoordsChange}
      onActiveFrameDetailsChange={onActiveFrameDetailsChange}
    />
  );

  useEffect(() => {
    setSelectedRideIndex(undefined);
  }, [rides]);

  useEffect(() => {
    if (!selectedApproachId) return;

    const approach = rides[selectedRideIndex].approach.find(
      (currApproach) => JSON.parse(currApproach.approachGeoJson).id === selectedApproachId,
    );

    dispatch(
      fetchRidesFrames(
        framesPostData({
          cameraId: currentCameraId,
          startDate: approach.startTime,
          endDate: approach.endTime,
        }),
      ),
    );
  }, [selectedApproachId]); //eslint-disable-line

  return (
    <BottomContainer
      slideIn={slideIn}
      canCollapseTable
      mainContent={renderRidesTable()}
      mediaContent={renderFramesSlider()}
      onClose={onClose}
    />
  );
};

AdminRidesContainer.propTypes = {
  rides: propTypes.array,
  addRideLayerToMap: propTypes.func.isRequired,
  slideIn: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

AdminRidesContainer.defaultProps = {
  rides: [],
};
