const NavigationDrawerStyles = (theme) => ({
  drawer: {
    zIndex: '10000 !important',
  },
  drawerAnchor: {
    width: 410,
    boxShadow: `0 18px 31px 0 ${theme.color111}`,
    zIndex: 100000,
  },
  listItemText: {
    fontWeight: 500,
  },
  listItem: {
    height: 56,
    marginTop: 4,
    paddingLeft: 19,
  },
  listItemSkeleton: {
    width: '40%',
    height: 40,
  },
  '@global': {
    a: {
      color: theme.color112,
      textDecoration: 'none',
    },
    '.active': {
      color: theme.color117,
    },
  },
});

export default NavigationDrawerStyles;
