import React, { useState, useLayoutEffect, useEffect } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as mapboxgl from 'mapbox-gl';
import {
  selectCorridors,
  selectBusStops,
  selectIntersections,
  selectApproaches,
  selectActiveFrameCords,
  selectIsUserLoaded,
  selectTotalRides,
  selectFromIntersection,
} from 'features';
import { initMapboxMap, setMapboxSource } from 'utils';
import { mapboxMapNames } from 'consts';
import { usePostData } from 'utils/hooks';
import theme from 'theme';
import { initMapboxEvents, preselectIntersections } from './initMapboxEvents';
import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './MapboxMap.module.scss';

export const MapboxMap = ({ setClickedIntersectionsArr, hasInteractions }) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const { searchPostData } = usePostData();
  /* -------------------------------- Selectors ------------------------------- */
  const corridors = useSelector(selectCorridors);
  const busStops = useSelector(selectBusStops);
  const intersections = useSelector(selectIntersections);
  const approaches = useSelector(selectApproaches);
  const activeFrameCords = useSelector(selectActiveFrameCords);
  const isUserLoaded = useSelector(selectIsUserLoaded);
  const totalRides = useSelector(selectTotalRides(JSON.stringify(searchPostData('first'))));
  const fromIntersection = useSelector(selectFromIntersection);

  /* ---------------------------------- State --------------------------------- */
  const [mapboxMap, setMapboxMap] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [frameMarkers, setFrameMarkers] = useState([]);
  /* -------------------------------- Variables ------------------------------- */
  /* -------------------------------- Functions ------------------------------- */
  /* --------------------------------- Effects -------------------------------- */

  useLayoutEffect(() => {
    if (!isUserLoaded) return;
    const map = initMapboxMap({ mapName: mapboxMapNames.corridorView });

    setMapboxMap(map);
    initMapboxEvents(map, setClickedIntersectionsArr, hasInteractions);

    return () => map.remove(); // eslint-disable-line
  }, [isUserLoaded]); // eslint-disable-line

  useEffect(() => {
    if (!mapboxMap) return;
    mapboxMap.on('load', () => setIsMapLoaded(true));
  }, [mapboxMap]); // eslint-disable-line

  useEffect(() => {
    if (!mapboxMap || !corridors?.data?.length || !isMapLoaded) return;
    setMapboxSource({
      sourceName: 'corridors',
      mapboxMap,
      features: corridors.data[corridors.selectedCorridorIndex].features,
    });
  }, [mapboxMap, corridors?.data?.length, corridors.selectedCorridorIndex, isMapLoaded]); // eslint-disable-line

  useEffect(() => {
    if (!mapboxMap || !busStops.data.features.length || !isMapLoaded) return;
    setMapboxSource({ sourceName: 'bus-stops', mapboxMap, features: busStops.data.features });
  }, [mapboxMap, busStops.data.features, isMapLoaded]);

  useEffect(() => {
    if (!isMapLoaded || !intersections.byCorridor.length || !corridors.selectedCorridorId) return;
    const features = intersections.byCorridor.find(({ corridorId }) => corridorId === corridors.selectedCorridorId)
      .intersections;

    setMapboxSource({ sourceName: 'intersections', mapboxMap, features });
    mapboxMap.setFeatureState({ source: 'intersections', id: features[0].id }, { selected: true });
    mapboxMap.setFeatureState({ source: 'intersections', id: features[features.length - 1].id }, { selected: true });
    preselectIntersections(mapboxMap, features, setClickedIntersectionsArr);
  }, [mapboxMap, isMapLoaded, intersections.byCorridor, corridors.selectedCorridorId]); // eslint-disable-line

  useEffect(() => {
    if (!isMapLoaded) return;
    const features = approaches?.features || [];

    setMapboxSource({ sourceName: 'total-rides-approaches', mapboxMap, features });
  }, [mapboxMap, isMapLoaded, approaches]); // eslint-disable-line

  useEffect(() => {
    if (!mapboxMap || !isMapLoaded) return;

    frameMarkers.forEach((m) => m.remove());
    const marker = new mapboxgl.Marker({ color: theme.color01 });

    marker.setLngLat([activeFrameCords.curr.lonCurr, activeFrameCords.curr.latCurr]).addTo(mapboxMap);
    setFrameMarkers([...frameMarkers, marker]);
  }, [mapboxMap, activeFrameCords, isMapLoaded]); // eslint-disable-line

  useEffect(() => {
    if (!mapboxMap || !totalRides?.approaches.features) return;
    setMapboxSource({ sourceName: 'segments-direction', mapboxMap, features: totalRides.approaches.features });
  }, [mapboxMap, totalRides?.approaches.features]); // eslint-disable-line

  useEffect(() => {
    if (!mapboxMap) return;
    setMapboxSource({ sourceName: 'segments-direction', mapboxMap, features: [] });
  }, [mapboxMap, fromIntersection]); // eslint-disable-line

  return <div id="map-mapboxgl" className={styles.map} />;
};

MapboxMap.propTypes = {
  setClickedIntersectionsArr: propTypes.func,
  hasInteractions: propTypes.bool,
};

MapboxMap.defaultProps = {
  setClickedIntersectionsArr: undefined,
  hasInteractions: true,
};
