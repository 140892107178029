import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ListMenu } from '@axilion/ui-components';
import {
  selectCanRunSimulations,
  selectRidesError,
  selectStatisticType,
  setStatisticType,
  selectIsUserAdmin,
} from 'features';
import { DigitalTwin, TotalRides } from 'components';
import { getGeneralViewTabs, statisticTypes } from 'consts';
import SearchDetailsStyles from './SearchDetails.styles';

const useStyles = makeStyles(SearchDetailsStyles);

export const SearchDetails = ({ onAnalyzeClick, selectionData, topPosition, children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userCanRunSimulations = useSelector(selectCanRunSimulations);
  const statisticType = useSelector(selectStatisticType);
  const ridesError = useSelector(selectRidesError);
  const isUserAdmin = useSelector(selectIsUserAdmin);

  const tabs = getGeneralViewTabs(isUserAdmin).map((tab) => {
    const newTab = { ...tab };

    if (newTab.name === statisticTypes.PEDESTRIANS) {
      newTab.disabled = true;
    }

    return newTab;
  });

  const notDisabledTabs = tabs.filter((tab) => !tab.disabled);
  const initialTab = tabs.find((tab) => !tab.disabled && tab.name === statisticType) || notDisabledTabs[0];

  const handleActiveTab = (tab) => {
    if (tab.name !== statisticType) {
      dispatch(setStatisticType(tab.name));
      onAnalyzeClick();
    }
  };

  return (
    <div className={classes.tabContent} style={{ top: topPosition() }}>
      <ListMenu type="tabbed" colorScheme="light" items={tabs} onSelect={handleActiveTab} initialItem={initialTab} />
      <div className={`${classes.wrapper}`}>
        {ridesError && <div className={classes.ridesError}>{ridesError}</div>}
        {!ridesError && (
          <>
            <div className={classes.content}>
              {userCanRunSimulations && <DigitalTwin selectionData={selectionData} />}
              {children}
              <TotalRides />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

SearchDetails.propTypes = {
  onAnalyzeClick: propTypes.func.isRequired,
  selectionData: propTypes.object.isRequired,
  topPosition: propTypes.func.isRequired,
  children: propTypes.node.isRequired,
};
