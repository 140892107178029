import { useStore } from 'react-redux';
import { useFormat } from 'utils/hooks/useFormat';
import { WEEKDAYS } from 'consts';
import { DateTime } from 'luxon';

const framesPostData = (fromUtcToProjectTime, { cameraId, startDate, endDate, playTime }) => {
  const videoPlayTime = playTime || startDate;

  return {
    cameraId,
    startDate: fromUtcToProjectTime(startDate, 'ISO').replace('T', ' '),
    endDate: fromUtcToProjectTime(endDate, 'ISO').replace('T', ' '),
    videoPlayTime: fromUtcToProjectTime(videoPlayTime, 'ISO').replace('T', ' '),
  };
};

const searchPostData = (store, fromProjectTimeToUtc, firstOrSecond) => {
  const { appState, generalStats, corridors } = store.getState();
  const { geoJSON, selectedCorridorIndex } = corridors;
  const dateRange = appState.dateRange[firstOrSecond];
  const planRange = appState.planRange[firstOrSecond];

  if (!corridors.geoJSON.length || !planRange) return null;

  const { startDay, endDay, startTime, endTime } = planRange;
  const days = WEEKDAYS.filter((day, i) => i >= startDay && i <= endDay);
  const [startTimeHour, startTimeMinute] = startTime.split(':');
  const [endTimeHour, endTimeMinute] = endTime.split(':');

  return {
    fromIntersectionId: appState.fromIntersection,
    toIntersectionId: appState.toIntersection,
    interval: {
      days: days.map(({ fullName }) => fullName),
      startDate: fromProjectTimeToUtc(dateRange[0], 'ISO_NO_MS'),
      endDate: fromProjectTimeToUtc(dateRange[1], 'ISO_NO_MS'),
      startTime: fromProjectTimeToUtc(
        DateTime.fromMillis(dateRange[0]).set({ hour: startTimeHour, minute: startTimeMinute }),
        'TIME_24_SIMPLE',
      ),
      endTime: fromProjectTimeToUtc(
        DateTime.fromMillis(dateRange[1]).set({ hour: endTimeHour, minute: endTimeMinute }),
        'TIME_24_SIMPLE',
      ),
    },
    vehicleTypes: [generalStats.statisticType],
    corridorId: geoJSON[selectedCorridorIndex].features[0].properties.corridorId,
  };
};

const generalStatsPostData = (store, fromClientToProjectTime) => {
  const { appState, generalStats } = store.getState();

  if (!appState.planRange.first) return null;

  const { label } = appState.planRange.first;
  const { statisticType, metricType, startDate, daysInPast, filterNumberOfDays, filterNumberOfSamples } = generalStats;

  const postData = {
    type: metricType,
    days: daysInPast,
    timeIntervalLabel: label,
    resultDate: fromClientToProjectTime(startDate, 'ISO_DATE'),
    filterNumberOfDays,
    filterNumberOfSamples,
  };

  if (statisticType !== 'pedestrians') {
    postData.vehicleTypes = [statisticType];
  }

  return postData;
};

export const usePostData = () => {
  const store = useStore();
  const { fromClientToProjectTime, fromUtcToProjectTime, fromProjectTimeToUtc } = useFormat();

  return {
    framesPostData: (...args) => framesPostData(fromUtcToProjectTime, ...args),
    searchPostData: (...args) => searchPostData(store, fromProjectTimeToUtc, ...args),
    generalStatsPostData: (...args) => generalStatsPostData(store, fromClientToProjectTime, ...args),
  };
};
