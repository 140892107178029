import { createSlice, createSelector } from '@reduxjs/toolkit';
import { loadPrograms, loadPhaseDiagram } from 'features/thunks';

export const signalPlansSlice = createSlice({
  name: 'signalPlans',
  initialState: {
    programs: {},
    programsLoading: 'idle',
    phaseDiagrams: {},
    phaseDiagramLoading: 'idle',
  },
  extraReducers: {
    [loadPrograms.pending]: (state) => {
      state.programsLoading = 'loading';
    },
    [loadPrograms.fulfilled]: (state, action) => {
      const { stopId } = action.meta.arg;

      action.payload.programs.programsData.sort((a, b) => a.number - b.number);

      state.programs[stopId] = action.payload;
      state.programsLoading = 'loaded';
    },
    [loadPrograms.rejected]: (state) => {
      state.programsLoading = 'error';
    },
    [loadPhaseDiagram.pending]: (state) => {
      state.phaseDiagramLoading = 'loading';
    },
    [loadPhaseDiagram.fulfilled]: (state, action) => {
      const { stopId, programNumber } = action.meta.arg;

      state.phaseDiagrams[stopId] = state.phaseDiagrams[stopId] || {};
      state.phaseDiagrams[stopId][programNumber] = action.payload['scenarios-result-file'];
      state.phaseDiagramLoading = 'loaded';
    },
    [loadPhaseDiagram.rejected]: (state) => {
      state.phaseDiagramLoading = 'error';
    },
  },
});

export const selectPrograms = createSelector(
  [({ signalPlans }) => signalPlans.programs, ({ signalPlans }) => signalPlans.programsLoading],
  (programs, programsLoading) => ({
    programs: Object.keys(programs).reduce((newObj, key) => {
      newObj[key] = programs[key].programs.programsData;

      return newObj;
    }, {}),
    programsLoading,
  }),
);

export const selectTimings = createSelector(
  ({ signalPlans }) => signalPlans.programs,
  (programs) =>
    Object.keys(programs).reduce((newObj, key) => {
      newObj[key] = programs[key].timings;

      return newObj;
    }, {}),
);

export const selectPhaseDiagrams = createSelector(
  [({ signalPlans }) => signalPlans.phaseDiagrams, ({ signalPlans }) => signalPlans.phaseDiagramLoading],
  (phaseDiagrams, phaseDiagramLoading) => ({
    phaseDiagrams,
    phaseDiagramLoading,
  }),
);
