import store from 'store';
import { setSelectedApproachId } from 'features/adminSlice';

export const initAdminViewEvents = (olMap, setLayerNames) => {
  const map = olMap;

  const getTextContent = (props) => {
    const propKeys = Object.keys(props);
    const {
      unitSystem: { speedMultiplier, distanceMultiplier, speedUnit, distanceUnit },
    } = store.getState();

    if (propKeys.includes('responseProps')) {
      const {
        blockage,
        driveTime,
        dwellTime,
        queueLength,
        queueTime,
        sectionOrder,
        speed,
        stops,
      } = props.responseProps;
      const blockageText = `Probability for blockage: ${blockage}%`;
      const driveTimeText = `Travel time: ${driveTime} s`;
      const queueTimeText = `Queue time: ${queueTime} s`;
      const dwellTimeText = `Dwell time: ${dwellTime} s`;
      const queueLengthText = `Queue length: ${Math.round(queueLength * distanceMultiplier)} ${distanceUnit}`;
      const sectionOrderText = `Section order: ${sectionOrder}`;
      const speedText = `Speed: ${Math.round(speed * speedMultiplier)} ${speedUnit}`;
      const stopsText = `Stops: ${stops}`;

      return `${blockageText}<br />${driveTimeText}<br />${queueTimeText}<br />${queueLengthText}
        <br />${dwellTimeText}<br />${sectionOrderText}<br />${speedText}<br />${stopsText}<br />`;
    }

    return '';
  };

  let hoveredFeature;

  map.on('pointermove', (e) => {
    if (e.dragging) return;
    let hoveredLayer;
    const pixel = map.getEventPixel(e.originalEvent);
    const feature = map.forEachFeatureAtPixel(pixel, (f) => f, {
      hitTolerance: 0,
      layerFilter: (layer) => {
        hoveredLayer = layer.get('name');

        return true;
      },
    });
    const statsOverlay = map.getOverlayById('feature-stats-tooltip');
    const statsOverlayEl = statsOverlay.getElement();

    if (hoveredFeature && hoveredFeature !== feature) {
      hoveredFeature.set('isHovered', false);
    }
    if (feature && hoveredLayer === 'Rides') {
      feature.set('isHovered', true);
      hoveredFeature = feature;
      map.getViewport().style.cursor = 'pointer';
      const statsEl = statsOverlayEl.querySelector('.stats-text');
      const textContent = getTextContent(feature.getProperties());

      if (textContent) {
        statsEl.innerHTML = textContent;
        statsOverlay.setPosition(e.coordinate);
      }
    } else {
      map.getViewport().style.cursor = 'default';
      statsOverlay.setPosition(undefined);
    }
  });

  let clickedFeature;

  map.on('click', (e) => {
    if (e.dragging) return;
    const pixel = map.getEventPixel(e.originalEvent);
    const feature = map.forEachFeatureAtPixel(pixel, (f) => f);
    const layer = map.forEachLayerAtPixel(pixel, (l) => l);

    if (layer.get('name') !== 'Current Frame') {
      return;
    }

    if (feature) {
      if (clickedFeature) clickedFeature.set('isClicked', false);
      feature.set('isClicked', true);
      clickedFeature = feature;

      store.dispatch(setSelectedApproachId(feature.getId()));
    }
  });

  map.getLayerGroup().on('change', () => {
    setLayerNames(
      map
        .getLayers()
        .getArray()
        .filter((layer) => layer.getProperties().name)
        .map((layer) => layer.getProperties().name),
    );
  });
};
