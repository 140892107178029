export const getMeta = (metaName) => {
  const metaTags = document.getElementsByTagName('meta');

  let metaContent;

  for (let i = 0; i < metaTags.length; i += 1) {
    if (metaTags[i].getAttribute('name') === metaName) {
      metaContent = metaTags[i].getAttribute('content');
    }
  }

  return metaContent;
};
