const RidesTableStyles = (theme) => ({
  boxContainer: {
    padding: '0 5px',
  },
  tableContainerInner: {
    height: '100%',
  },
  tableRow: {
    cursor: 'pointer',
    '& td': {
      fontSize: 12,
      height: 20,
    },
    '&:not(.isComparing):hover': {
      backgroundColor: theme.color03,
    },
    '& .borderCell': {
      borderBottomWidth: 0,
      position: 'relative',
      width: 23,
      minWidth: 23,
    },
    '&.isComparing': {
      '&:hover .dataCell': {
        backgroundColor: theme.color03,
      },
      '& .borderCell, & .stopNameCell, & .stopIdCell': {
        cursor: 'default',
        pointerEvents: 'none',
      },
    },
  },
  firstDataCell: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: 4,
      background: theme.color10,
    },
    '&.even::before': {
      background: theme.color11,
    },
  },
  selectedRow: {
    '& td': {
      backgroundColor: theme.color03,
      fontWeight: 500,
    },
  },
  selectedCell: {
    backgroundColor: theme.color03,
    fontWeight: 500,
  },
  tableRowNoData: {
    borderBottom: 0,
    height: 260,
  },
  visuallyHidden: {
    border: 1,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: '1px 0',
    position: 'absolute',
    top: 15,
    width: 1,
  },
  tableSortLabel: {
    borderBottom: '1px solid transparent',
  },
  tabTitle: {
    color: theme.color01,
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    borderRadius: 6,
    padding: 10,
    width: '50%',
    cursor: 'pointer',
    border: '1px solid transparent',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottom: `1px solid ${theme.color02}`,

    '&.border': {
      border: `1px solid ${theme.color02}`,
      borderBottom: 0,
    },
  },
  criticalRow: {
    '& td': {
      fontWeight: 600,
      color: theme.color06,
    },
  },
  tableHeadCell: {
    textAlign: 'center',
    fontSize: 13,
    fontWeight: 400,
  },
  borderCellBorder1: {
    position: 'absolute',
    top: 1,
    left: '47%',
    right: 0,
    bottom: 'calc(50% - -18px)',
    borderLeft: `3px dotted ${theme.color01}`,
  },
  borderCellBorder2: {
    width: 9,
    height: 9,
    backgroundColor: theme.colorWhite,
    borderRadius: '50%',
    border: `2px solid ${theme.color01}`,
    margin: '0 auto',
  },
  borderCellBorder3: {
    position: 'absolute',
    bottom: 1,
    left: '47%',
    right: 0,
    top: 'calc(50% + 20px )',
    borderLeft: `3px dotted ${theme.color01}`,
  },
  borderCellIcon: {
    position: 'absolute',
    width: 10,
    height: 12,
    left: '40%',
    transform: 'translateY(calc(-19px))',
  },
  playVideoIcon: {
    marginBottom: 5,
    paddingTop: 1,
  },
});

export default RidesTableStyles;
