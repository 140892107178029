import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { transformExtent } from 'ol/proj';
import styles from './MapExtentOpenLayers.module.scss';

export const MapExtentOpenLayers = ({ map }) => {
  const [extent, setExtent] = useState();

  useEffect(() => {
    if (!map) return;
    map.on('moveend', () => {
      const rawExtent = map.getView().calculateExtent();

      setExtent(transformExtent(rawExtent, 'EPSG:3857', 'EPSG:4326'));
    });
  }, [map]);

  return (
    !!extent && (
      <div className={styles.extentCoordsContainer}>
        <div className={styles.extentCoordsDiv} data-testid="map-extent-top-left">
          {`Top left: [${extent[3]}, ${extent[0]}]`}
        </div>
        <div className={styles.extentCoordsDiv} data-testid="map-extent-top-right">
          {`Top right: [${extent[3]}, ${extent[2]}]`}
        </div>
        <div className={styles.extentCoordsDiv} data-testid="map-extent-bottom-left">
          {`Bottom left: [${extent[1]}, ${extent[0]}]`}
        </div>
        <div className={styles.extentCoordsDiv} data-testid="map-extent-bottom-right">
          {`Bottom right: [${extent[1]}, ${extent[2]}]`}
        </div>
      </div>
    )
  );
};

MapExtentOpenLayers.propTypes = {
  map: propTypes.object,
};

MapExtentOpenLayers.defaultProps = {
  map: undefined,
};
