import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTimeIntervals, editTimeIntervals } from 'api/endpoints';

export const fetchTimeIntervals = createAsyncThunk('timeIntervals/fetch', async (_, thunkAPI) => {
  const timeIntervalsRes = await getTimeIntervals();

  if (timeIntervalsRes.status && timeIntervalsRes.status !== 200) {
    return thunkAPI.rejectWithValue();
  }

  return timeIntervalsRes.data;
});

export const handleEditTimeIntervals = createAsyncThunk('timeIntervals/edit', async (requestObject, thunkAPI) => {
  const editTimeIntervalsRes = await editTimeIntervals(requestObject);

  if (editTimeIntervalsRes.status && editTimeIntervalsRes.status !== 200) {
    return thunkAPI.rejectWithValue();
  }

  return editTimeIntervalsRes.data;
});
