import axios from 'axios';
import { API_BASE_URL } from 'consts/url-related';
import { MOBILEYE_TOKEN_KEY } from 'consts';

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const defaultOptions = {
  withCredentials: true,
  validateStatus: false,
};

const requester = (method) => (url, data = {}, headers = {}, options) =>
  axios({
    baseURL: API_BASE_URL,
    method,
    url,
    data,
    ...defaultHeaders,
    headers,
    ...defaultOptions,
    ...options,
  });

export const get = requester('get');
export const post = requester('post');
export const del = requester('delete');
export const promiseAll = (promises = []) => axios.all(promises);

export const mobileyeAPI = axios.create({
  baseURL: 'https://prod-external-api.mobileye-data-services.com',
  headers: {
    ...defaultHeaders,
  },
});

mobileyeAPI.interceptors.request.use((config) => {
  /* eslint-disable no-param-reassign */
  if (config.url !== 'login') {
    config.url = `v1/${config.url}`;
    config.headers.Authorization = window.sessionStorage.getItem(MOBILEYE_TOKEN_KEY);
  }
  /* eslint-enable no-param-reassign */

  return config;
});
