export const appStateMock = {
  expandedPanel: 'total-rides',
  expandedPanelTitle: null,
  isComparing: false,
  ridesError: null,
  isLoading: false,
  dateRange: {
    first: [+new Date('2020-01-31T22:00:00.000Z'), +new Date('2020-01-31T22:00:00.000Z')],
    second: [+new Date('2020-01-31T22:00:00.000Z'), +new Date('2020-01-31T22:00:00.000Z')],
  },
  planRange: {
    first: {
      id: 2,
      label: 'morning_rush_hour',
      startTime: '07:00',
      endTime: '10:00',
      startDay: 0,
      endDay: 4,
    },
    second: {
      id: 2,
      label: 'morning_rush_hour',
      startTime: '07:00',
      endTime: '10:00',
      startDay: 0,
      endDay: 4,
    },
  },
  fromIntersection: 1153,
  toIntersection: 136,
  currentTotalRides: 'first',
  generalViewRadialChart: null,
  toastMessage: null,
  activeTileLayerIndex: 0,
};
