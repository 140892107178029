import { createSlice } from '@reduxjs/toolkit';
import { fetchBusStops, fetchAllBusStops } from 'features/thunks';

export const busStopsSlice = createSlice({
  name: 'busStops',
  initialState: {
    data: {
      features: [],
    },
    loading: 'idle',
    allBusStops: undefined,
  },
  extraReducers: {
    [fetchBusStops.pending]: (state) => {
      state.loading = 'loading';
    },
    [fetchBusStops.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = 'loaded';
    },
    [fetchBusStops.rejected]: (state) => {
      state.loading = 'error';
    },
    [fetchAllBusStops.fulfilled]: (state, action) => {
      state.allBusStops = action.payload;
    },
  },
});

export const selectBusStops = ({ busStops }) => ({ data: busStops.data, loading: busStops.loading });
export const selectAllBusStops = ({ busStops }) => busStops.allBusStops;

export default busStopsSlice.reducer;
