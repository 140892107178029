import { createAsyncThunk } from '@reduxjs/toolkit';
import { postMobileyeLiveEvents, postRealTimeTraffic } from 'api/endpoints';

export const fetchMobileyeLiveEvents = createAsyncThunk('mobileye/fetchLiveEvents', async (requestData, thunkAPI) => {
  const res = await postMobileyeLiveEvents(requestData);

  if (res.status && res.status !== 200) {
    return thunkAPI.rejectWithValue();
  }

  return res.data;
});

export const fetchMobileyeRealTimeTraffic = createAsyncThunk(
  'mobileye/fetchRealTimeTraffic',
  async (requestData, thunkAPI) => {
    const res = await postRealTimeTraffic(requestData);

    if (res.status && res.status !== 200) {
      return thunkAPI.rejectWithValue();
    }

    return res.data;
  },
);
