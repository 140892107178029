import { createSlice } from '@reduxjs/toolkit';

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    isNavigationOpen: false,
  },
  reducers: {
    setIsNavigationOpen: (state, action) => {
      state.isNavigationOpen = action.payload;
    },
  },
});

export const { setIsNavigationOpen } = navigationSlice.actions;

export const selectIsNavigationOpen = ({ navigation }) => navigation.isNavigationOpen;

export default navigationSlice.reducer;
