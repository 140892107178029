import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FramesSlider } from '@axilion/ui-components';
import { BottomContainer, CorridorGraphs, GeneralMetricsTable } from 'components';
import { ReactComponent as GraphsIconWhite } from 'assets/images/Graphs White@3x.svg';
import { ReactComponent as VideoIconWhite } from 'assets/images/Video White@3x.svg';
import { usePostData, useFormat } from 'utils/hooks';
import {
  fetchCorridorsMetricsRides,
  selectCorridorsMetricsRidesFirst,
  selectCorridorsMetricsRidesSecond,
  fetchGeneralMetricsFrames,
  selectGeneralMetricsFrames,
  selectLoadingGeneralMetricsFrames,
  selectUnitSystemName,
  selectSpeedMultiplier,
  selectDistanceMultiplier,
  selectExpandedPanel,
  setExpandedPanel,
  selectIsComparing,
} from 'features';
import { fromLonLat } from 'ol/proj';
import {
  maps,
  layers,
  popupOverlays,
  initLayer,
  layerStyles,
  centerMapOnIntersection,
  metricByPanel,
  popupByPanel,
  generalMetricsPanels,
} from 'utils';

export const GeneralMetricsContainer = () => {
  const { searchPostData, framesPostData } = usePostData();
  const { fromUtcToProjectTime } = useFormat();
  const dispatch = useDispatch();
  const allMetricFrames = useSelector(selectGeneralMetricsFrames);
  const loadingFrames = useSelector(selectLoadingGeneralMetricsFrames);
  const isComparing = useSelector(selectIsComparing);
  const unitSystemName = useSelector(selectUnitSystemName);
  const speedMultiplier = useSelector(selectSpeedMultiplier);
  const distanceMultiplier = useSelector(selectDistanceMultiplier);
  const expandedPanel = useSelector(selectExpandedPanel);
  const metricRidesFirst = useSelector(selectCorridorsMetricsRidesFirst(metricByPanel[expandedPanel]));
  const metricRidesSecond = useSelector(selectCorridorsMetricsRidesSecond(metricByPanel[expandedPanel]));
  const [activeMediaTab, setActiveMediaTab] = useState('frames');
  const [selectedRide, setSelectedRide] = useState(null);
  const [framesKey, setFramesKey] = useState(null);
  const olMap = maps.TrafficInsights;
  const rideHasGraph = selectedRide && Object.keys(selectedRide.graphs).length;
  const frames = allMetricFrames[framesKey];
  const mediaTabs = [
    {
      id: 'graphs',
      text: 'Graphs',
      icon: <GraphsIconWhite />,
    },
    {
      id: 'frames',
      text: 'Video',
      icon: <VideoIconWhite />,
    },
  ];

  const onTableRowHandler = (rowEl, stop) => {
    setSelectedRide(stop);

    const popup = popupOverlays[popupByPanel[expandedPanel]];

    const popupEl = popup.getElement();

    if (expandedPanel !== 'speed' && expandedPanel !== 'queue-length') {
      popup.setPosition(fromLonLat(stop.feature.geometry.coordinates));
      centerMapOnIntersection({ intersection: stop.name });
    } else {
      olMap.removeLayer(layers.approachesQueue);

      layers.approachesQueue = initLayer(
        expandedPanel,
        stop.feature,
        layerStyles.corridorView[expandedPanel](stop.critical),
        3,
      );

      olMap.addLayer(layers.approachesQueue);

      if (stop.feature?.geometry) {
        const featureCords =
          stop.feature.geometry.type === 'Point'
            ? stop.feature.geometry.coordinates
            : stop.feature.geometry.coordinates[Math.ceil((stop.feature.geometry.coordinates.length - 1) / 2)];

        popup.setPosition(fromLonLat(featureCords));
      }
      const feature = layers.approachesQueue.getSource().getFeatures()[0];

      if (feature) {
        olMap.getView().fit(feature.getGeometry(), {
          duration: 1000,
          padding: [10, 10, 250, 10],
          minResolution: 2,
        });
      }
    }

    popupEl.classList.toggle('isCritical', stop.critical);

    switch (expandedPanel) {
      case 'stops-number':
        popupEl
          .querySelector('.probabilityToStopChart')
          .replaceWith(rowEl.querySelector('.probabilityToStopChart').cloneNode(true));
        break;
      case 'waiting-time':
        popupEl.querySelector('.avgWaiting').textContent = Math.round(stop.avgWaiting * 10) / 10;
        popupEl.querySelector('.avgDriving').textContent = Math.round(stop.avgDriving * 10) / 10;

        popupEl
          .querySelector('.contributionChartWaiting')
          .replaceWith(rowEl.querySelector('.contributionChartWaiting').cloneNode(true));
        popupEl
          .querySelector('.contributionChartDriving')
          .replaceWith(rowEl.querySelector('.contributionChartDriving').cloneNode(true));
        break;
      case 'speed':
        popupEl.querySelector('.avgSpeed').textContent = Math.round(stop.avgSpeed * speedMultiplier * 10) / 10;
        break;
      case 'blockage':
        popupEl
          .querySelector('.probabilityOfBlockage')
          .replaceWith(rowEl.querySelector('.probabilityOfBlockage').cloneNode(true));
        break;
      case 'queue-length':
        popupEl.querySelector('.avgQueueLength').textContent =
          Math.round(stop.avgQueueLength * distanceMultiplier * 10) / 10;
        break;
      case 'effective-los':
        popupEl.querySelector('.los').textContent = stop.los;
        break;
      case 'dwell-time':
        popupEl.querySelector('.avgDwellTime').textContent = stop.avgDwellTime;
        break;
      default:
    }

    const postData = framesPostData({
      cameraId: stop.videoCameraId,
      startDate: stop.videoStartTime,
      endDate: stop.videoEndTime,
      playTime: stop.videoPlayTime,
    });

    dispatch(fetchGeneralMetricsFrames({ postData }));
    setFramesKey(JSON.stringify(postData));
  };

  useEffect(() => {
    const run = async () => {
      setSelectedRide(null);
      setFramesKey(null);

      if (generalMetricsPanels.includes(expandedPanel)) {
        const metricType = metricByPanel[expandedPanel];
        const postDataArr = [{ ...searchPostData('first'), type: metricType }];

        if (isComparing) {
          postDataArr.push({ ...searchPostData('second'), type: metricType });
        }
        dispatch(fetchCorridorsMetricsRides({ metricType, postDataArr }));
      }
    };

    run();
  }, [expandedPanel]); //eslint-disable-line

  useEffect(() => {
    setSelectedRide(null);
    dispatch(setExpandedPanel(null));
  }, [unitSystemName]); //eslint-disable-line

  return (
    <BottomContainer
      slideIn={generalMetricsPanels.includes(expandedPanel)}
      canCollapseTable
      graphsTwoRows={expandedPanel === 'waiting-time' && activeMediaTab === 'graphs'}
      {...(rideHasGraph && {
        mediaTabs,
        activeMediaTab,
        setActiveMediaTab,
      })}
      mainContent={
        <GeneralMetricsTable
          onTableRowHandler={onTableRowHandler}
          activeRidesMediaTab={activeMediaTab}
          metricRidesFirst={metricRidesFirst}
          metricRidesSecond={metricRidesSecond}
        />
      }
      mediaContent={
        !rideHasGraph || (selectedRide && activeMediaTab === 'frames') ? (
          <FramesSlider
            framesLists={[frames]}
            showFrameDetails
            isLoading={loadingFrames === 'loading'}
            showNotFound={loadingFrames === 'error'}
            fillParentHeight
            fromUtcToProjectTime={fromUtcToProjectTime}
          />
        ) : (
          Object.keys(selectedRide.graphs).map(
            (key, i) =>
              /cdf/.test(key) && (
                <CorridorGraphs
                  cdf={selectedRide.graphs[key]}
                  pdf={selectedRide.graphs[`pdf${key.substring(3)}`]}
                  panelName={expandedPanel}
                  valueKey={key}
                  key={i.toString()}
                  {...(Object.keys(selectedRide.graphs).length > 2 && { height: 200 })}
                />
              ),
          )
        )
      }
    />
  );
};
