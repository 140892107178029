import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { handleDeleteBusStop } from 'features';
import { Dialog } from 'screens/EditGrid/Dialog';

export const DeleteBusStopDialog = ({ isOpen, close, busStopId }) => {
  const dispatch = useDispatch();

  const submit = () => {
    dispatch(handleDeleteBusStop({ id: busStopId }));

    close();
  };

  if (!busStopId) return null;

  return (
    <Dialog title="Delete Bus Stop" isOpen={isOpen} onClose={close} onSubmit={submit}>
      {`busStop ID: ${busStopId}`}
    </Dialog>
  );
};

DeleteBusStopDialog.propTypes = {
  isOpen: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  busStopId: propTypes.string,
};

DeleteBusStopDialog.defaultProps = {
  busStopId: null,
};
