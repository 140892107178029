import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DirectionsCar, Block, DirectionsBus, Report, AccessTime } from '@material-ui/icons';
import {
  selectCorridorsMetricsFramesFirst,
  selectCorridorsMetricsFramesSecond,
  fetchFrames,
  selectSpeedMultiplier,
  selectDistanceMultiplier,
  selectSpeedUnit,
  selectDistanceUnit,
  selectExpandedPanel,
  setExpandedPanel,
  selectIsComparing,
} from 'features';
import { highlightIntersections } from 'utils/map';
import { metricByPanel } from 'utils/mappings';
import { usePostData } from 'utils/hooks';
import { ReactComponent as AvgSpeedIcon } from 'assets/images/Speed@3x.svg';
import { ChartBar, PieChart, PanelGroup } from 'components';

import styles from './SidebarPanels.module.scss';

export const SidebarPanels = ({ statisticsDataFirst, statisticsDataSecond }) => {
  const { framesPostData } = usePostData();
  const dispatch = useDispatch();
  const isComparing = useSelector(selectIsComparing);
  const expandedPanel = useSelector(selectExpandedPanel);
  const generalMetricsFramesFirst = useSelector(selectCorridorsMetricsFramesFirst(metricByPanel[expandedPanel]));
  const generalMetricsFramesSecond = useSelector(selectCorridorsMetricsFramesSecond(metricByPanel[expandedPanel]));
  const speedMultiplier = useSelector(selectSpeedMultiplier);
  const distanceMultiplier = useSelector(selectDistanceMultiplier);
  const speedUnit = useSelector(selectSpeedUnit);
  const distanceUnit = useSelector(selectDistanceUnit);
  const [mainMetricType, setMainMetricType] = useState('');
  const [cameraDetails, setCameraDetails] = useState(undefined);
  const expansionPanels = [
    {
      name: 'stops-number',
      headerIcon: <Report className={styles.headerIcon} />,
      headerContent: (
        <PieChart
          percent={Math.round(
            100 *
              (statisticsDataFirst?.['stops-number']?.data[1]?.value /
                statisticsDataFirst?.['stops-number']?.data[0]?.value),
          )}
          threshold={40}
          size="small"
        />
      ),
      headerContentCompare: (
        <PieChart
          percent={Math.round(
            100 *
              (statisticsDataSecond?.['stops-number']?.data[1]?.value /
                statisticsDataSecond?.['stops-number']?.data[0]?.value),
          )}
          threshold={40}
          size="small"
        />
      ),
      detailsFirst: statisticsDataFirst?.['stops-number']?.data,
      detailsSecond: statisticsDataSecond?.['stops-number']?.data,
      graphsFirst: {
        pdf: statisticsDataFirst?.['stops-number']?.graphs?.pdf,
        cdf: statisticsDataFirst?.['stops-number']?.graphs?.cdf,
      },
      graphsSecond: {
        pdf: statisticsDataSecond?.['stops-number']?.graphs?.pdf,
        cdf: statisticsDataSecond?.['stops-number']?.graphs?.cdf,
      },
      framesFirst: {
        videoCameraId: statisticsDataFirst?.['stops-number']?.videoCameraId,
        videoEndTime: statisticsDataFirst?.['stops-number']?.videoEndTime,
        videoStartTime: statisticsDataFirst?.['stops-number']?.videoStartTime,
      },
      framesSecond: {
        videoCameraId: statisticsDataSecond?.['stops-number']?.videoCameraId,
        videoEndTime: statisticsDataSecond?.['stops-number']?.videoEndTime,
        videoStartTime: statisticsDataSecond?.['stops-number']?.videoStartTime,
      },
    },
    {
      name: 'waiting-time',
      headerIcon: <AccessTime className={styles.headerIcon} />,
      headerContent: (
        <ChartBar
          values={[
            `${Math.ceil(statisticsDataFirst?.['waiting-time']?.data[0]?.value)}s`,
            `${Math.ceil(statisticsDataFirst?.['waiting-time']?.data[1]?.value)}s`,
          ]}
          size="small"
        />
      ),
      headerContentCompare: (
        <ChartBar
          values={[
            `${Math.ceil(statisticsDataSecond?.['waiting-time']?.data[0]?.value)}s`,
            `${Math.ceil(statisticsDataSecond?.['waiting-time']?.data[1]?.value)}s`,
          ]}
          size="small"
        />
      ),
      dataInGroupsOf: 2,
      detailsFirst: statisticsDataFirst?.['waiting-time']?.data.map((item) => ({
        ...item,
        value: `${Math.ceil(item.value)}s`,
      })),
      detailsSecond: statisticsDataSecond?.['waiting-time']?.data.map((item) => ({
        ...item,
        value: `${Math.ceil(item.value)}s`,
      })),
      graphsFirst: {
        pdfWaiting: statisticsDataFirst?.['waiting-time']?.graphs?.pdfWaiting,
        cdfWaiting: statisticsDataFirst?.['waiting-time']?.graphs?.cdfWaiting,
      },
      graphsSecond: {
        pdfWaiting: statisticsDataSecond?.['waiting-time']?.graphs?.pdfWaiting,
        cdfWaiting: statisticsDataSecond?.['waiting-time']?.graphs?.cdfWaiting,
      },
      framesFirst: {
        videoCameraId: statisticsDataFirst?.['waiting-time']?.videoCameraId,
        videoEndTime: statisticsDataFirst?.['waiting-time']?.videoEndTime,
        videoStartTime: statisticsDataFirst?.['waiting-time']?.videoStartTime,
      },
      framesSecond: {
        videoCameraId: statisticsDataSecond?.['waiting-time']?.videoCameraId,
        videoEndTime: statisticsDataSecond?.['waiting-time']?.videoEndTime,
        videoStartTime: statisticsDataSecond?.['waiting-time']?.videoStartTime,
      },
    },
    {
      name: 'speed',
      headerIcon: <AvgSpeedIcon className={`${styles.headerIcon} ${styles.avgSpeedIconStyle}`} />,
      headerContent: `${Math.ceil(statisticsDataFirst?.speed?.data[0]?.value * speedMultiplier)} ${speedUnit}`,
      headerContentCompare: `${Math.ceil(statisticsDataSecond?.speed?.data[0]?.value * speedMultiplier)} ${speedUnit}`,
      detailsFirst: statisticsDataFirst?.speed?.data.map((item) => ({
        ...item,
        value: `${Math.ceil(item.value * speedMultiplier)} ${speedUnit}`,
      })),
      detailsSecond: statisticsDataSecond?.speed?.data.map((item) => ({
        ...item,
        value: `${Math.ceil(item.value * speedMultiplier)} ${speedUnit}`,
      })),
      graphsFirst: {
        pdf: statisticsDataFirst?.speed?.graphs?.pdf,
        cdf: statisticsDataFirst?.speed?.graphs?.cdf,
      },
      graphsSecond: {
        pdf: statisticsDataSecond?.speed?.graphs?.pdf,
        cdf: statisticsDataSecond?.speed?.graphs?.cdf,
      },
      framesFirst: {
        videoCameraId: statisticsDataFirst?.speed?.videoCameraId,
        videoEndTime: statisticsDataFirst?.speed?.videoEndTime,
        videoStartTime: statisticsDataFirst?.speed?.videoStartTime,
      },
      framesSecond: {
        videoCameraId: statisticsDataSecond?.speed?.videoCameraId,
        videoEndTime: statisticsDataSecond?.speed?.videoEndTime,
        videoStartTime: statisticsDataSecond?.speed?.videoStartTime,
      },
    },
    {
      name: 'queue-length',
      headerIcon: <DirectionsCar className={styles.headerIcon} />,
      headerContent: (
        <PieChart percent={Math.round(statisticsDataFirst?.['queue-length']?.data[6]?.value)} size="small" />
      ),
      headerContentCompare: (
        <PieChart percent={Math.round(statisticsDataSecond?.['queue-length']?.data[6]?.value)} size="small" />
      ),
      detailsFirst: statisticsDataFirst?.['queue-length']?.data.map((item, index) => {
        if (index === statisticsDataFirst?.['queue-length']?.data.length - 1) {
          return { ...item, value: `${Math.round(item.value)} %` };
        }

        return {
          ...item,
          value: `${`${Math.ceil(item.value * distanceMultiplier).toString()} ${distanceUnit}`}`,
        };
      }),
      detailsSecond: statisticsDataSecond?.['queue-length']?.data.map((item, index) => {
        if (index === statisticsDataSecond?.['queue-length']?.data.length - 1) {
          return { ...item, value: `${Math.round(item.value)} %` };
        }

        return {
          ...item,
          value: `${Math.ceil(item.value * distanceMultiplier)} ${distanceUnit}`,
        };
      }),
      graphsFirst: {
        pdf: statisticsDataFirst?.['queue-length']?.graphs?.pdf,
        cdf: statisticsDataFirst?.['queue-length']?.graphs?.cdf,
      },
      graphsSecond: {
        pdf: statisticsDataSecond?.['queue-length']?.graphs?.pdf,
        cdf: statisticsDataSecond?.['queue-length']?.graphs?.cdf,
      },
      framesFirst: {
        videoCameraId: statisticsDataFirst?.['queue-length']?.videoCameraId,
        videoEndTime: statisticsDataFirst?.['queue-length']?.videoEndTime,
        videoStartTime: statisticsDataFirst?.['queue-length']?.videoStartTime,
      },
      framesSecond: {
        videoCameraId: statisticsDataSecond?.['queue-length']?.videoCameraId,
        videoEndTime: statisticsDataSecond?.['queue-length']?.videoEndTime,
        videoStartTime: statisticsDataSecond?.['queue-length']?.videoStartTime,
      },
    },
    {
      name: 'blockage',
      headerIcon: <Block className={styles.headerIcon} />,
      headerContent: (
        <PieChart percent={Math.round(statisticsDataFirst?.['blockage-panel']?.data[0]?.value * 100)} size="small" />
      ),
      headerContentCompare: (
        <PieChart percent={Math.round(statisticsDataSecond?.['blockage-panel']?.data[0]?.value * 100)} size="small" />
      ),
      detailsFirst: statisticsDataFirst?.['blockage-panel']?.data.map((item, index) => {
        if (index === 0) {
          return { ...item, value: `${Math.round(item.value * 100)} %` };
        }

        return { ...item, value: item.value };
      }),
      detailsSecond: statisticsDataSecond?.['blockage-panel']?.data.map((item, index) => {
        if (index === 0) {
          return { ...item, value: `${Math.round(item.value * 100)} %` };
        }

        return { ...item, value: item.value };
      }),
      framesFirst: {
        videoCameraId: statisticsDataFirst?.['blockage-panel']?.videoCameraId,
        videoEndTime: statisticsDataFirst?.['blockage-panel']?.videoEndTime,
        videoStartTime: statisticsDataFirst?.['blockage-panel']?.videoStartTime,
      },
      framesSecond: {
        videoCameraId: statisticsDataSecond?.['blockage-panel']?.videoCameraId,
        videoEndTime: statisticsDataSecond?.['blockage-panel']?.videoEndTime,
        videoStartTime: statisticsDataSecond?.['blockage-panel']?.videoStartTime,
      },
    },
    {
      name: 'dwell-time',
      headerIcon: <DirectionsBus className={styles.headerIcon} />,
      headerContent: `${Math.ceil(statisticsDataFirst?.['dwell-time']?.data[0]?.value)}s`,
      detailsFirst: statisticsDataFirst?.['dwell-time']?.data.map((item) => ({
        ...item,
        value: `${Math.ceil(item.value)}s`,
      })),
      detailsSecond: statisticsDataSecond?.['dwell-time']?.data.map((item) => ({
        ...item,
        value: `${Math.ceil(item.value)}s`,
      })),
      graphsFirst: {
        pdf: statisticsDataFirst?.['dwell-time']?.graphs?.pdf,
        cdf: statisticsDataFirst?.['dwell-time']?.graphs?.cdf,
      },
      graphsSecond: {
        pdf: statisticsDataSecond?.['dwell-time']?.graphs?.pdf,
        cdf: statisticsDataSecond?.['dwell-time']?.graphs?.cdf,
      },
      framesFirst: {
        videoCameraId: statisticsDataFirst?.['dwell-time']?.videoCameraId,
        videoEndTime: statisticsDataFirst?.['dwell-time']?.videoEndTime,
        videoStartTime: statisticsDataFirst?.['dwell-time']?.videoStartTime,
      },
      framesSecond: {
        videoCameraId: statisticsDataSecond?.['dwell-time']?.videoCameraId,
        videoEndTime: statisticsDataSecond?.['dwell-time']?.videoEndTime,
        videoStartTime: statisticsDataSecond?.['dwell-time']?.videoStartTime,
      },
    },
  ];

  const getFramesData = async (metricName, framesInfo) => {
    const postDataArr = [];

    postDataArr.push(
      framesPostData({
        cameraId: framesInfo[0].videoCameraId,
        startDate: framesInfo[0].videoStartTime,
        endDate: framesInfo[0].videoEndTime,
      }),
    );

    if (isComparing) {
      postDataArr.push(
        framesPostData({
          cameraId: framesInfo[1].videoCameraId,
          startDate: framesInfo[1].videoStartTime,
          endDate: framesInfo[1].videoEndTime,
        }),
      );
    }
    dispatch(
      fetchFrames({
        metricType: metricName,
        postDataArr,
      }),
    );
  };

  const onClickMainMetric = (metricName) => () => {
    if (metricName === mainMetricType) {
      setMainMetricType('');
    } else {
      setMainMetricType(metricName);
    }
  };

  const handleChange = (panelName) => (event, newExpanded) => {
    const panel = expansionPanels.find((p) => p.name === panelName);
    const metricName = metricByPanel[panelName];

    setMainMetricType('');
    highlightIntersections(null);
    dispatch(setExpandedPanel(newExpanded ? panelName : null));

    if (newExpanded) {
      getFramesData(metricName, [panel.framesFirst, panel.framesSecond]);
      setCameraDetails(panel.framesFirst);
    }
  };

  return (
    <div className={isComparing ? styles.isComparing : ''}>
      <PanelGroup
        panels={expansionPanels.filter((p) => p.name !== 'dwell-time')}
        handleChange={handleChange}
        onClickMainMetric={onClickMainMetric}
        mainMetricType={mainMetricType}
        generalMetricsFramesFirst={generalMetricsFramesFirst}
        cameraDetails={cameraDetails}
        generalMetricsFramesSecond={generalMetricsFramesSecond}
      />
      <hr className={styles.separator} />
      <PanelGroup
        panels={expansionPanels.filter((p) => p.name === 'dwell-time')}
        handleChange={handleChange}
        onClickMainMetric={onClickMainMetric}
        mainMetricType={mainMetricType}
        generalMetricsFramesFirst={generalMetricsFramesFirst}
        cameraDetails={cameraDetails}
        generalMetricsFramesSecond={generalMetricsFramesSecond}
      />
    </div>
  );
};

SidebarPanels.propTypes = {
  statisticsDataFirst: propTypes.object.isRequired,
  statisticsDataSecond: propTypes.object.isRequired,
};
