import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  odMatrixSlice,
  userSlice,
  settingsSlice,
  corridorsStatsSlice,
  unitSystemSlice,
  appStateSlice,
  simulationsSlice,
  intersectionsSlice,
  corridorsSlice,
  busStopsSlice,
  signalPlansSlice,
  navigationSlice,
  generalStatsSlice,
  adminSlice,
  homeStatsSlice,
  timeIntervalsSlice,
  mobileyeSlice,
} from 'features';

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    settings: settingsSlice.reducer,
    generalStats: generalStatsSlice.reducer,
    corridorsStats: corridorsStatsSlice.reducer,
    unitSystem: unitSystemSlice.reducer,
    appState: appStateSlice.reducer,
    simulations: simulationsSlice.reducer,
    intersections: intersectionsSlice.reducer,
    corridors: corridorsSlice.reducer,
    busStops: busStopsSlice.reducer,
    odMatrix: odMatrixSlice.reducer,
    signalPlans: signalPlansSlice.reducer,
    navigation: navigationSlice.reducer,
    admin: adminSlice.reducer,
    homeStats: homeStatsSlice.reducer,
    timeIntervals: timeIntervalsSlice.reducer,
    mobileye: mobileyeSlice.reducer,
  },
  middleware: [
    ...getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredPaths: [
      //     'simulations', // slow
      //     'signalPlans', // slow
      //     'appState.dateRange',
      //   ],
      // },
      // serializableCheck: false,
      // immutableCheck: {
      //   ignoredPaths: [
      //     'simulations', // slow
      //     'signalPlans', // slow
      //     'appState.expandedPanelTitle',
      //   ],
      // },
      serializableCheck: false,
      immutableCheck: false,
    }),
  ],
});
