import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlanRange, setPlanRange, selectTimeIntervals } from 'features';
import { Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Slider, Button } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CalendarTimeIcon } from 'assets/images/Calendar Time@3x.svg';
import { WEEKDAYS } from 'consts';
import { timeSlider, planRangeToText } from 'utils';
import { FormattedMessage } from 'react-intl';
import SelectPlanRangeStyles from './SelectPlanRange.styles';
import styles from './SelectPlanRange.module.scss';

const useStyles = makeStyles(SelectPlanRangeStyles);

const sliderMarks = timeSlider.hours.reduce((acc, val, i) => {
  if (val.includes(':30')) return acc;
  acc.push({
    value: timeSlider.step * i,
    label: <p className={`${styles.hoursTooltips}`}>{String(+val.split(':')[0])}</p>,
  });

  return acc;
}, []);

export const SelectPlanRange = ({ firstOrSecond, isSelectTimeRangeVisible, setIsSelectTimeRangeVisible }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedPlan = useSelector(selectPlanRange)[firstOrSecond];
  const timeIntervals = useSelector(selectTimeIntervals);
  const [sliderValue, setSliderValue] = useState(null);

  const isDaySelected = (index) => {
    const { startDay, endDay } = selectedPlan;

    if (![startDay, endDay].includes(null)) {
      return index >= startDay && index <= endDay;
    }

    return index === startDay;
  };

  const onOkButtonClick = () => {
    setIsSelectTimeRangeVisible({
      first: false,
      second: false,
    });
  };

  const onLabelClick = () => {
    setIsSelectTimeRangeVisible({
      first: firstOrSecond === 'first',
      second: firstOrSecond === 'second',
    });
  };

  const onListClick = () => {
    setIsSelectTimeRangeVisible({
      first: false,
      second: false,
    });
  };

  const onPlanChange = (event) => {
    dispatch(
      setPlanRange({
        [firstOrSecond]: timeIntervals.find(({ label }) => label === event.target.value),
      }),
    );
  };

  const onSliderChange = (_, val) => {
    setSliderValue(val);
  };

  const onSliderChangeCommitted = (_, val) => {
    const startTime = timeSlider.valToTime(val[0]);
    const endTime = timeSlider.valToTime(val[1]);

    dispatch(
      setPlanRange({
        [firstOrSecond]: {
          ...selectedPlan,
          startTime,
          endTime,
          label: 'custom',
        },
      }),
    );
  };

  const onDayClick = (i) => {
    const { startDay, endDay } = selectedPlan;
    const days = [startDay === endDay ? startDay : i, endDay === i ? endDay : i];

    days.sort();

    dispatch(
      setPlanRange({
        [firstOrSecond]: {
          ...selectedPlan,
          startDay: days[0],
          endDay: days[1],
          label: 'custom',
        },
      }),
    );
  };

  useEffect(() => {
    if (!selectedPlan) return;
    setSliderValue([timeSlider.timeToVal(selectedPlan.startTime), timeSlider.timeToVal(selectedPlan.endTime)]);
  }, [selectedPlan]);

  if (!selectedPlan) {
    return null;
  }

  return (
    <>
      <Box className={classes.selectLabel} data-testid="box-collapsed" onClick={onLabelClick}>
        <CalendarTimeIcon />
        <Typography className={classes.selectText}>{planRangeToText(selectedPlan)}</Typography>
        <ArrowDropDown color="primary" className={classes.arrowDown} />
      </Box>
      {isSelectTimeRangeVisible && (
        <Box boxShadow={3} className={classes.selectList}>
          <Box className={classes.selectListInner} data-testid="box-expanded" onClick={onListClick}>
            <CalendarTimeIcon />
            <Typography className={classes.selectText}>
              <FormattedMessage defaultMessage="Signal's plan range" description="Plan range" />
            </Typography>
            <ArrowDropUp color="primary" className={classes.arrowUp} />
          </Box>
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup aria-label="gender" name="gender1" value={selectedPlan.label} onChange={onPlanChange}>
              {timeIntervals.map((interval) => (
                <FormControlLabel
                  key={interval.label}
                  value={interval.label}
                  control={<Radio color="primary" className="radio" />}
                  label={<Typography className={classes.formControlLabel}>{planRangeToText(interval)}</Typography>}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <hr className={classes.horizontalLine} />
          <div className="weekdays">
            {WEEKDAYS.map((day, i) => (
              <span
                key={i.toString()}
                className={`weekday ${isDaySelected(i) ? 'selected' : ''}`}
                data-testid={`weekday${i}`}
                onClick={() => onDayClick(i)}
              >
                {day.code}
              </span>
            ))}
          </div>
          <div className="hours-slider">
            <Slider
              value={sliderValue}
              onChange={onSliderChange}
              onChangeCommitted={onSliderChangeCommitted}
              valueLabelDisplay="off"
              aria-labelledby="range-slider"
              getAriaValueText={(val) => `${val}hours`}
              step={timeSlider.step}
              marks={sliderMarks}
              min={0}
              max={100}
            />
          </div>
          <Button
            color="primary"
            data-testid="timeRangeOkButton"
            onClick={onOkButtonClick}
            className={classes.buttonOk}
          >
            OK
          </Button>
        </Box>
      )}
    </>
  );
};

SelectPlanRange.propTypes = {
  isSelectTimeRangeVisible: propTypes.bool.isRequired,
  setIsSelectTimeRangeVisible: propTypes.func.isRequired,
  firstOrSecond: propTypes.string.isRequired,
};
