import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Box,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  Paper,
  Tab,
  AppBar,
  Tabs,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { selectSimulations, selectIntersections } from 'features';
import { PhaseDiagram } from 'components';
import { FormattedMessage } from 'react-intl';
import SimulationStatisticsTablesStyles from './SimulationStatisticsTable.styles';

const useStyles = makeStyles(SimulationStatisticsTablesStyles);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: propTypes.node.isRequired,
  index: propTypes.any.isRequired,
  value: propTypes.any.isRequired,
};

const a11yProps = (index) => ({
  id: `scrollable-auto-tab-${index}`,
  'aria-controls': `scrollable-auto-tabpanel-${index}`,
});

const renderTableHead = (emptyCellsLength) => (
  <TableHead>
    <TableRow>
      <TableCell align="center">
        <FormattedMessage defaultMessage="Name" description="Simulation statistics table column Name" />
      </TableCell>
      <TableCell align="center">
        <FormattedMessage
          defaultMessage="Intersection ID"
          description="Simulation statistics table column Intersection ID"
        />
      </TableCell>
      <TableCell align="center">
        <FormattedMessage defaultMessage="Moves" description="Simulation statistics table column Moves" />
      </TableCell>
      {emptyCellsLength(1).map((_, i) => (
        <TableCell align="center" key={i.toString()} />
      ))}
    </TableRow>
  </TableHead>
);

const renderTableBody = (items, emptyCellsLength) => {
  if (!items) {
    return null;
  }

  return (
    <TableBody>
      {items.map((item, index) => (
        <React.Fragment key={index.toString()}>
          <TableRow key={index.toString()}>
            <TableCell align="center" rowSpan={2}>
              {item.name}
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              {item.junctionId}
            </TableCell>
            {item.movesKeys.map((move, i) => (
              <TableCell align="center" key={i.toString()}>
                {move}
              </TableCell>
            ))}
            {emptyCellsLength(item.movesKeys.length).map((_, i) => (
              <TableCell align="center" key={i.toString()} />
            ))}
          </TableRow>
          <TableRow key={item.junctionId}>
            {item.movesValues.map((move, ind) => (
              <TableCell align="center" key={ind.toString()}>
                {Math.round(move.avg * 100) / 100}
              </TableCell>
            ))}
            {emptyCellsLength(item.movesKeys.length).map((_, i) => (
              <TableCell align="center" key={i.toString()} />
            ))}
          </TableRow>
        </React.Fragment>
      ))}
    </TableBody>
  );
};

export const SimulationStatisticsTables = () => {
  const classes = useStyles();
  const {
    current: { stats: junctions, diagram: phaseDiagramData },
  } = useSelector(selectSimulations);
  const intersections = useSelector(selectIntersections);
  const [value, setValue] = useState(0);
  const [selected, setSelected] = useState();
  const [phaseDiagramDisplayData, setPhaseDiagramDisplayData] = useState();
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [orderedPhaseDiagramData, setOrderedPhaseDiagramData] = useState(null);
  const intersectionsData = intersections.signalizedByCorridor[1].intersections;

  let rows = null;

  if (junctions) {
    rows = Object.keys(junctions.greenTime.junctions);

    rows = rows.map((item, index) => ({
      junctionId: item,
      movesKeys: Object.keys(Object.values(junctions.greenTime.junctions)[index].moves),
      movesValues: Object.values(Object.values(junctions.greenTime.junctions)[index].moves),
    }));
  }
  const [tableRows, setTableRows] = useState(rows);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const maxCellsLength = Math.max(...tableRows.map((row) => row.movesKeys?.length));
  const emptyCellsLength = (currentCellsLength) => [...Array(maxCellsLength - currentCellsLength).keys()];

  const onTableRowClick = (item, index) => () => {
    setSelected({ item, index });
    setSelectedRowIndex(index);
  };

  useEffect(() => {
    if (!tableRows || !intersectionsData.length) {
      return;
    }
    let data = intersectionsData.map((intersection) => ({
      id: intersection.id,
      name: intersection.properties.name,
    }));

    data = data.reduce((arr, item) => {
      const intersection = tableRows.find((row) => row.junctionId === item.id);

      if (intersection) {
        arr.push({
          name: item.name,
          ...intersection,
        });
      }

      return arr;
    }, []);

    setTableRows(data);
  }, [intersectionsData, junctions]); //eslint-disable-line

  useEffect(() => {
    if (!selected || !phaseDiagramData.length) {
      return;
    }
    setPhaseDiagramDisplayData({
      'junction-data-list': {
        junction: {
          'move-signal-info-list': {
            move: selected.item.moveSignals.move,
          },
          number: selected.item.junctionId,
        },
      },
      'scenarios-list': phaseDiagramData[selected.index].scenarioList,
    });
  }, [selected, phaseDiagramData.length]); //eslint-disable-line

  useEffect(() => {
    if (!tableRows && !phaseDiagramData.length) {
      return;
    }
    const arr = [];

    tableRows.forEach((item) => {
      const findItem = phaseDiagramData.find((innerItem) => Number(innerItem.junctionId) === Number(item.junctionId));

      arr.push(findItem);
    });

    setOrderedPhaseDiagramData(arr);
  }, [tableRows, phaseDiagramData.length]); //eslint-disable-line

  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
        >
          <Tab label="Phase diagram" {...a11yProps(0)} />
          <Tab label="Green time" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Box display="flex">
          <Box flex="0 0 30%" height={535} overflow="auto">
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Intersection ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderedPhaseDiagramData &&
                  orderedPhaseDiagramData.map((item, index) => {
                    const isRowSelected = index === selectedRowIndex;

                    return (
                      <TableRow
                        key={index.toString()}
                        hover
                        onClick={onTableRowClick(item, index)}
                        className={`${classes.tableRow} ${isRowSelected ? classes.selectedRow : ''}`}
                      >
                        <TableCell align="center">{item.name}</TableCell>
                        <TableCell align="center">{item.junctionId}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
          <Box flex="0 0 70%" height={535} overflow="auto">
            <PhaseDiagram phaseDiagramData={phaseDiagramDisplayData} />
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table size="small" aria-label="a dense table">
            {renderTableHead(emptyCellsLength)}
            {renderTableBody(tableRows, emptyCellsLength)}
          </Table>
        </TableContainer>
      </TabPanel>
    </>
  );
};
