import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import LayerGroup from 'ol/layer/Group';
import DragRotate from 'ol/interaction/DragRotate';
import PinchRotate from 'ol/interaction/PinchRotate';
import { XYZ } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { MAPPED_TENANT_POSITIONS } from 'consts';
import store from 'store';

let tileLayer;
const subscriptionKey = '9BZL87dlWB2JkUimFSbL0EO7FjuLmyDlm4YBIffOcTc';
const mapSources = [
  new XYZ({ url: 'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png', maxZoom: 19 }),
  new XYZ({ url: 'https://maps-cdn.salesboard.biz/styles/klokantech-3d-gl-style/{z}/{x}/{y}.png', maxZoom: 19 }),
  new XYZ({
    url: `https://atlas.microsoft.com/map/tile?subscription-key=${subscriptionKey}&api-version=2.0&tilesetId=microsoft.base.road&zoom={z}&x={x}&y={y}&tileSize=512&language=en-US`, // eslint-disable-line
    maxZoom: 19,
  }),
];

export const changeMapSource = (index) => {
  if (tileLayer) tileLayer.setSource(mapSources[index]);
};
export const maps = {};

export const centerMap = (map, layer) => {
  const extent = layer.getSource().getExtent();

  if (!Number.isFinite(extent[0]) || !map) return;
  map.getView().fit(extent, { duration: 1000, padding: [20, 20, 20, 20], minResolution: 1.5 });
};

/**
 * @module initMap
 * @description function which initializes the openlayers map and controls the events of it
 * @param {object} configObject configuration object of the function
 * @param {Array} configObject.layers layers of the map
 * @param {Array} configObject.overlays overlays to add to the map
 * @param {string} configObject.name name of the map
 * @param {string} configObject.target target element to attach map to
 * @param {Array} configObject.layersToAdd array of layer names to add
 * @returns {object} initialized OL map
 */
const initMap = ({ layers = [], overlays = [], name, target = 'map', layersToAdd = [] }) => {
  tileLayer = new TileLayer({ source: mapSources[store.getState().appState.activeTileLayerIndex] });
  layers.push(tileLayer);

  layersToAdd.forEach((l) => {
    layers.push(
      new LayerGroup({
        layers: [],
        name: l,
        target,
      }),
    );
  });

  const tenantPosition = MAPPED_TENANT_POSITIONS[store.getState().user.tenants?.currentTenant];
  const map = new Map({
    layers,
    overlays,
    target,
    view: new View({
      center: fromLonLat(tenantPosition?.center),
      zoom: tenantPosition?.zoom,
      maxZoom: 30,
    }),
    renderer: 'canvas',
  });

  map.set('name', name);

  const dragRotateInteractions = map
    .getInteractions()
    .getArray()
    .filter((interaction) => interaction instanceof DragRotate || interaction instanceof PinchRotate);

  dragRotateInteractions.forEach((rotateInteraction) => rotateInteraction.setActive(false));

  maps[name] = map;

  return map;
};

export default initMap;
