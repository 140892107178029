import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { handleAddIntersection } from 'features';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import { Dialog } from 'screens/EditGrid/Dialog';

export const AddIntersectionDialog = ({ isOpen, close, name, coords, isSignalized }) => {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(isSignalized);

  const submit = (event) => {
    const id = event.target.id.value;
    const formName = event.target.name.value;
    const lat = event.target.lat.value;
    const lon = event.target.lon.value;

    dispatch(
      handleAddIntersection({
        id: id || undefined,
        name: formName,
        lat,
        lon,
        signalized: isChecked,
      }),
    );

    close();
  };

  const toggleIsChecked = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Dialog title="Add Intersection Details" isOpen={isOpen} onClose={close} onSubmit={submit}>
      <TextField label="ID" name="id" />
      <TextField label="Name" defaultValue={name} name="name" />
      <TextField label="Latitude" defaultValue={coords[1]} name="lat" />
      <TextField label="Longitude" defaultValue={coords[0]} name="lon" />
      <FormControlLabel
        control={<Checkbox checked={isChecked} onClick={toggleIsChecked} color="primary" />}
        label="Is signalized"
      />
    </Dialog>
  );
};

AddIntersectionDialog.propTypes = {
  isOpen: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  name: propTypes.string,
  coords: propTypes.array.isRequired,
  isSignalized: propTypes.bool.isRequired,
};

AddIntersectionDialog.defaultProps = {
  name: undefined,
};
