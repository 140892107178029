import React from 'react';
import { statisticTypes } from 'consts/statisticTypes';
import { Eco } from '@material-ui/icons';
import { ReactComponent as PedestriansIcon } from 'assets/images/Pedestrians White@3x.svg';
import { ReactComponent as PrivateCars } from 'assets/images/Private cars White@3x.svg';
import { ReactComponent as Public } from 'assets/images/Public Transit White@3x.svg';

export const getGeneralViewTabs = (isAdmin) => [
  {
    name: statisticTypes.PEDESTRIANS,
    icon: <PedestriansIcon />,
  },
  {
    name: statisticTypes.PRIVATE,
    icon: <PrivateCars />,
  },
  {
    name: statisticTypes.PUBLIC,
    icon: <Public />,
  },
  {
    name: statisticTypes.ECO,
    icon: <Eco />,
    disabled: !isAdmin,
  },
];
