export const MOBILEYE_TOKEN_KEY = 'mobileye_session_token';

export const MOBILEYE_REQUEST_POLYGONS = [
  [
    [34.79951752936115, 32.14616114025103],
    [34.79645830835077, 32.13899691018049],
    [34.806049379626565, 32.13638310957302],
    [34.80786837590301, 32.14522772197074],
  ],
];
