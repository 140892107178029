import * as turf from '@turf/turf';
import store from 'store';

export const fitMapToFeatures = (mapboxMap, features) => {
  if (!features?.length) return;

  const bounds = turf.bbox({
    type: 'FeatureCollection',
    features,
  });

  const leftPadding = store.getState().appState.isSidebarVisible ? 450 : 40;

  mapboxMap.fitBounds(bounds, {
    padding: { top: 40, bottom: 40, left: leftPadding, right: 40 },
    duration: 1000,
    maxZoom: 18,
  });
};
