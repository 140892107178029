import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllIntersections, getIntersectionsByCorridor } from 'api/endpoints';
import { promiseAll } from 'api/api';

export const fetchAllIntersections = createAsyncThunk('intersectionsAll/fetch', async (_, thunkAPI) => {
  const res = await getAllIntersections();

  if (res.status && res.status !== 200) {
    return thunkAPI.rejectWithValue();
  }

  return res.data;
});

export const fetchIntersectionsByCorridor = createAsyncThunk(
  'intersectionsByCorridor/fetch',
  async (corridorsIds, thunkAPI) => {
    const promises = [];

    corridorsIds.forEach((id) => promises.push(getIntersectionsByCorridor(id)));
    const responses = await promiseAll(promises);

    let hasError = false;

    responses.forEach((response) => {
      if (response.status && response.status !== 200) {
        hasError = true;
      }
    });

    if (hasError) return thunkAPI.rejectWithValue();

    return responses.reduce((payload, res, index) => {
      payload.push({
        ...res.data,
        corridorId: corridorsIds[index],
      });

      return payload;
    }, []);
  },
);
