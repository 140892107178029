import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select, MenuItem, FormControl, InputLabel, Button, TextField } from '@material-ui/core';
import { DateTime } from 'luxon';
import { fetchMobileyeLiveEvents } from 'features';
import { capitalizeFirstLetter } from 'utils';
import { MOBILEYE_REQUEST_POLYGONS } from 'consts';
import styles from './LiveEventsForm.module.scss';

export const LiveEventsForm = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */

  /* ---------------------------------- State --------------------------------- */
  const [selectedEventType, setSelectedEventType] = useState('');

  /* -------------------------------- Variables ------------------------------- */
  const EVENT_TYPES = [
    'fog',
    'vehicle_stopped_on_road_shoulder',
    'pedestrian_on_high_speed_road',
    'cyclist_on_high_speed_road',
    'construction_zone',
    'hazard_on_road',
  ];

  /* -------------------------------- Functions ------------------------------- */
  const handleSelectEventType = (event) => {
    setSelectedEventType(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    dispatch(
      fetchMobileyeLiveEvents({
        event_types: [selectedEventType],
        expiration_start: event.target.expirationStart.value,
        expiration_end: event.target.expirationEnd.value,
        polygons: MOBILEYE_REQUEST_POLYGONS,
      }),
    );
  };

  /* --------------------------------- Effects -------------------------------- */

  return (
    <form className={styles.container} onSubmit={handleFormSubmit}>
      <FormControl size="small" variant="outlined" className={styles.eventsSelect}>
        <InputLabel>Live Events</InputLabel>
        <Select label="Live Events" value={selectedEventType} onChange={handleSelectEventType}>
          {EVENT_TYPES.map((eventType) => (
            <MenuItem key={eventType} value={eventType}>
              {capitalizeFirstLetter(eventType).split('_').join(' ')}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        type="datetime-local"
        label="Start time"
        name="expirationStart"
        defaultValue={DateTime.now().plus({ days: -1 }).set({ hour: 7, minutes: 0, seconds: 0 }).toISO().split('.')[0]}
        required
        variant="outlined"
        size="small"
      />
      <TextField
        type="datetime-local"
        label="End time"
        name="expirationEnd"
        defaultValue={DateTime.now().set({ hour: 10, minutes: 0, seconds: 0 }).toISO().split('.')[0]}
        required
        variant="outlined"
        size="small"
      />
      <Button type="submit" color="primary" variant="contained" disabled={!selectedEventType}>
        Show Events
      </Button>
    </form>
  );
};
