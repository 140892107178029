import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setStatsFramesKey,
  setGeneralViewRadialChart,
  selectStatisticType,
  fetchGeneralStats,
  selectMetricType,
  selectTimeZone,
  selectDaysInPast,
  selectStartDate,
  selectPlanRange,
  selectFilterNumberOfDays,
  selectFilterNumberOfSamples,
} from 'features';
import { usePostData } from 'utils/hooks/usePostData';
import { GENERAL_VIEW_MENUS } from 'consts';

export const useFetchGeneralViewStats = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();
  const { generalStatsPostData } = usePostData();

  /* -------------------------------- Selectors ------------------------------- */
  const statisticType = useSelector(selectStatisticType);
  const metricType = useSelector(selectMetricType);
  const timeZone = useSelector(selectTimeZone);
  const daysInPast = useSelector(selectDaysInPast);
  const startDate = useSelector(selectStartDate);
  const currentPlanRanges = useSelector(selectPlanRange);
  const filterNumberOfDays = useSelector(selectFilterNumberOfDays);
  const filterNumberOfSamples = useSelector(selectFilterNumberOfSamples);

  /* ---------------------------------- State --------------------------------- */
  /* -------------------------------- Variables ------------------------------- */
  /* -------------------------------- Functions ------------------------------- */
  /* --------------------------------- Effects -------------------------------- */

  useEffect(() => {
    const menuIsActivated = GENERAL_VIEW_MENUS[statisticType].some(({ name }) => name === metricType);

    if (!timeZone || !menuIsActivated || !daysInPast || !currentPlanRanges.first) return;

    dispatch(setStatsFramesKey(null));
    dispatch(setGeneralViewRadialChart(null));

    const requestBody = generalStatsPostData();

    if (requestBody) {
      dispatch(
        fetchGeneralStats({
          statisticType,
          metricType,
          requestBody,
          serializedRequestBody: JSON.stringify(requestBody),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    timeZone,
    daysInPast,
    startDate,
    metricType,
    statisticType,
    currentPlanRanges.first,
    currentPlanRanges.second,
    filterNumberOfDays,
    filterNumberOfSamples,
  ]);
};
