import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBusStops, getAllBusStops } from 'api/endpoints';

export const fetchBusStops = createAsyncThunk('busStops/fetchBusStops', async ({ corridorId }, thunkAPI) => {
  const busStopsRes = await getBusStops(corridorId);

  if (busStopsRes.status && busStopsRes.status !== 200) {
    return thunkAPI.rejectWithValue();
  }

  return busStopsRes.data;
});

export const fetchAllBusStops = createAsyncThunk('busStops/fetchAllBusStops', async (_, thunkAPI) => {
  const busStopsRes = await getAllBusStops();

  if (busStopsRes.status && busStopsRes.status !== 200) {
    return thunkAPI.rejectWithValue();
  }

  return busStopsRes.data;
});
