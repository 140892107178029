const [startHour, endHour] = [4, 18];
const hours = [];

for (let i = startHour; i <= endHour; i += 1) {
  let hour = i;

  if (i < 10) hour = `0${hour}`;
  hours.push(`${hour}:00`);
  if (i < endHour) hours.push(`${hour}:30`);
}

const step = 100 / (hours.length - 1);
const valToTime = (val) => hours[val / step];
const timeToVal = (time) => hours.indexOf(time) * step;

export const timeSlider = {
  step,
  hours,
  valToTime,
  timeToVal,
};
