import axios from 'axios';
import { logout } from 'features/userSlice';
import { setToastMessage } from 'features/appStateSlice';
import { logError } from 'utils/logError';
import store from 'store';

export const handleResponseErrors = (intl) => {
  axios.interceptors.response.use((response) => {
    if (response.status !== 200) {
      logError.get(response);
    }

    if (response.status === 401) {
      store.dispatch(logout());
    }

    if (![200, 401].includes(response.status)) {
      store.dispatch(
        setToastMessage(
          intl.formatMessage({
            defaultMessage: 'Something went wrong',
            description: 'Toast message',
          }),
        ),
      );
    }

    return response;
  });
};
