import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as AvgSpeedIcon } from 'assets/images/Speed@3x.svg';
import { ReactComponent as QueueLengthIcon } from 'assets/images/Queue Length@3x.svg';
import { ReactComponent as DwellTimeIcon } from 'assets/images/Dwell time@3x.svg';
import { ReactComponent as RefugeeIslands } from 'assets/images/Crowded Refuge Islands@3x.svg';
import { ReactComponent as RedLightCrossings } from 'assets/images/Red Light Crossings@3x.svg';
import { ReactComponent as Jaywalking } from 'assets/images/Jaywalking@3x.svg';
import { ReactComponent as PedestriansDensity } from 'assets/images/Density@3x.svg';
import { ReactComponent as PassengersDensity } from 'assets/images/Passenger Density@3x.svg';
import { ReactComponent as Pedestrians } from 'assets/images/Pedestrians@3x.svg';
import { ReactComponent as Scooters } from 'assets/images/Scooters@3x.svg';
import { ReactComponent as Cyclist } from 'assets/images/Cyclist@3x.svg';
import { ReactComponent as ProbabilityToStop } from 'assets/images/Probability to Stop@3x.svg';
import { ReactComponent as QueueTime } from 'assets/images/Queue time@3x.svg';
import { ReactComponent as Crosswalk } from 'assets/images/Crosswalk@3x.svg';
import { ReactComponent as FaceMask } from 'assets/images/Face@3x.svg';
import { Eco } from '@material-ui/icons';

import { metricTypes } from './metricTypes';

export const ADMIN_ONLY_MENU_ITEMS = [metricTypes.JAYWALKERS, metricTypes.PEDESTRIANS_SIGNAL_PROGRESSION];

export const GENERAL_VIEW_MENUS = {
  pedestrians: [
    {
      name: metricTypes.PEDESTRIANS_SIGNAL_PROGRESSION,
      text: <FormattedMessage defaultMessage="Dangerous Crossings" description="Statistics menu" />,
      icon: <RefugeeIslands />,
    },
    {
      name: metricTypes.JAYWALKERS,
      text: <FormattedMessage defaultMessage="Jaywalking" description="Statistics menu" />,
      icon: <Jaywalking />,
    },
    {
      name: metricTypes.RED_LIGHT,
      text: <FormattedMessage defaultMessage="Pedestrian Traffic Signal Progression" description="Statistics menu" />,
      icon: <RedLightCrossings />,
    },
    {
      name: metricTypes.ACTIVE_CROSSWALK,
      text: <FormattedMessage defaultMessage="Frequently Used Crosswalks" description="Statistics menu" />,
      icon: <Crosswalk />,
    },
    {
      name: metricTypes.TOTAL_DENSITY,
      text: <FormattedMessage defaultMessage="Pedestrian & Micromobility Density" description="Statistics menu" />,
      icon: <PedestriansDensity />,
    },
    {
      name: metricTypes.PEDESTRIAN_DENSITY,
      text: <FormattedMessage defaultMessage="Pedestrian Density" description="Statistics menu" />,
      icon: <Pedestrians />,
      nested: true,
    },
    {
      name: metricTypes.SCOOTER_DENSITY,
      text: <FormattedMessage defaultMessage="Scooter Density" description="Statistics menu" />,
      icon: <Scooters />,
      nested: true,
    },
    {
      name: metricTypes.CYCLIST_DENSITY,
      text: <FormattedMessage defaultMessage="Cyclist Density" description="Statistics menu" />,
      icon: <Cyclist />,
      nested: true,
    },
    {
      name: metricTypes.FACE_MASK_USAGE,
      text: <FormattedMessage defaultMessage="Face Mask Detection" description="Statistics menu" />,
      icon: <FaceMask />,
    },
  ],
  private: [
    {
      name: metricTypes.NUMBER_OF_STOPS,
      text: <FormattedMessage defaultMessage="Traffic Signal Progression" description="Statistics menu" />,
      icon: <ProbabilityToStop />,
    },
    {
      name: metricTypes.WAITING_TIME,
      text: <FormattedMessage defaultMessage="Queue Time" description="Statistics menu" />,
      icon: <QueueTime />,
    },
    {
      name: metricTypes.QUEUE_LENGTH,
      text: <FormattedMessage defaultMessage="Queue Length" description="Statistics menu" />,
      icon: <QueueLengthIcon />,
    },
    {
      name: metricTypes.SPEED,
      text: <FormattedMessage defaultMessage="Speed" description="Statistics menu" />,
      icon: <AvgSpeedIcon />,
    },
  ],
  public: [
    {
      name: metricTypes.NUMBER_OF_STOPS,
      text: <FormattedMessage defaultMessage="Traffic Signal Progression" description="Statistics menu" />,
      icon: <ProbabilityToStop />,
    },
    {
      name: metricTypes.WAITING_TIME,
      text: <FormattedMessage defaultMessage="Queue Time" description="Statistics menu" />,
      icon: <QueueTime />,
    },
    {
      name: metricTypes.QUEUE_LENGTH,
      text: <FormattedMessage defaultMessage="Queue Length" description="Statistics menu" />,
      icon: <QueueLengthIcon />,
    },
    {
      name: metricTypes.SPEED,
      text: <FormattedMessage defaultMessage="Speed" description="Statistics menu" />,
      icon: <AvgSpeedIcon />,
    },
    {
      name: metricTypes.DWELL_TIME,
      text: <FormattedMessage defaultMessage="Dwell Time" description="Statistics menu" />,
      icon: <DwellTimeIcon />,
    },
    {
      name: metricTypes.PASSENGERS_DENSITY,
      text: <FormattedMessage defaultMessage="Passenger Density" description="Statistics menu" />,
      icon: <PassengersDensity />,
    },
    {
      name: metricTypes.FACE_MASK_USAGE,
      text: <FormattedMessage defaultMessage="Face Mask Detection" description="Statistics menu" />,
      icon: <FaceMask />,
    },
  ],
  eco: [
    {
      name: metricTypes.AIR_QUALITY,
      text: <FormattedMessage defaultMessage="Air Quality" description="Statistics menu" />,
      icon: <Eco />,
    },
  ],
};
