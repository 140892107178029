import { createSlice } from '@reduxjs/toolkit';
import { fetchOdMatrix } from 'features/thunks';

export const odMatrixSlice = createSlice({
  name: 'odMatrix',
  initialState: {
    odApproaches: [],
    odApproachesPublicTransport: [],
    odMatrix: [],
    odMatrixPublicTransport: [],
  },
  extraReducers: {
    [fetchOdMatrix.fulfilled]: (state, { payload }) => {
      state.odApproaches = payload.odApproaches;
      state.odApproachesPublicTransport = payload.odApproachesPublicTransport;
      state.odMatrix = payload.odMatrix;
      state.odMatrixPublicTransport = payload.odMatrixPublicTransport;
    },
  },
});

export const selectOdMatrix = ({ odMatrix }) => odMatrix;

export default odMatrixSlice.reducer;
