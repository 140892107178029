export const adminMock = {
  allApproaches: {
    features: [],
    type: 'FeatureCollection',
  },
  loadingAllApproaches: 'loaded',
  allCameraIds: ['312521351', '12331241234', '1234312312'],
  currentCameraId: '12331241234',
  backendVersion: { build: { version: '1.0' } },
  cameraDays: ['2020-08-20', '2020-08-23'],
  rides: [],
  ridesFrames: [
    {
      cameraId: '1000000099b5a26c',
      frameTime: '2020-10-01 10:43:33.999',
      frameId: '61cfaf1f3b3b41978202ee7d19fcbab6',
      frameUrl:
        'https://axilioncameradev.blob.core.windows.net/1000000099b5a26c/2020-10-01_anonymized/2020-10-01.10-43-33.999.jpg?sig=SrUl3WVX2NHSe',
      lat: 32.05767,
      lon: 34.77779,
      speed: 12.02,
      tags: ['signalized', 'red', 'driving'],
      boundingBoxes: [
        {
          topLeftX: 169,
          topLeftY: 483,
          bottomRightX: 180,
          bottomRightY: 528,
          type: 'PERSON',
        },
      ],
    },
  ],
  loadingFrames: 'loaded',
  selectedApproachId: '12345',
  osmExport: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [34.779469, 32.065833],
      },
      properties: {
        id: '0',
        in: '[Koresh]',
        out: '[Koresh]',
      },
      id: '0',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [34.780116, 32.072148],
          [34.780155, 32.072273],
          [34.78024, 32.072598],
        ],
      },
      properties: {
        id: '10',
        type: 'edge',
        name: 'way/354193919',
        speed: 5.555560000000001,
        uid: 'way/354193919',
      },
      id: '10',
    },
  ],
  loadingOsmExport: 'loaded',
  segments: [
    {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [34.780116, 32.072148],
          [34.780155, 32.072273],
          [34.78024, 32.072598],
        ],
      },
      properties: {
        id: '10',
        type: 'edge',
        name: 'way/354193919',
        speed: 5.555560000000001,
        uid: 'way/354193919',
      },
      id: '10',
    },
  ],
  loadingSegments: 'loaded',
  directions: [
    { id: 1, name: 'South', code: 'S' },
    { id: 2, name: 'North', code: 'N' },
    { id: 3, name: 'East', code: 'E' },
    { id: 4, name: 'West', code: 'W' },
    { id: 5, name: 'South-East', code: 'SE' },
    { id: 6, name: 'South-West', code: 'SW' },
    { id: 7, name: 'North-East', code: 'NE' },
    { id: 8, name: 'North-West', code: 'NW' },
  ],
  loadingDirections: 'loaded',
  detectionAreaCoords: [
    [34.780116, 32.072148],
    [34.780155, 32.072273],
    [34.78024, 32.072598],
  ],
  loadingDetectionArea: 'loaded',
  loadingAddGeofenceArea: 'loaded',
  loadingAddDetectionArea: 'loaded',
  osmBusStops: [
    {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [34.780116, 32.072148],
          [34.780155, 32.072273],
          [34.78024, 32.072598],
        ],
      },
      id: '10',
    },
  ],
  loadingOsmBusStops: 'loaded',
  loadingGeofenceArea: 'loaded',
  geofenceAreaCoords: [
    [34.771203, 32.088968],
    [34.77125, 32.089095],
    [34.771251, 32.089214],
  ],
};
