import React, { useEffect } from 'react';
import { Header, UserMenu, StatisticsCard } from 'components';
import { statisticTypes, mapboxMapNames } from 'consts';
import { Menu, Eco } from '@material-ui/icons';
import { ReactComponent as PedestriansIcon } from 'assets/images/Pedestrians White@3x.svg';
import { ReactComponent as PrivateCars } from 'assets/images/Private cars White@3x.svg';
import { ReactComponent as Public } from 'assets/images/Public Transit White@3x.svg';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  setIsNavigationOpen,
  fetchHomeStats,
  selectTimeZone,
  selectUser,
  loadCorridors,
  selectIsUserAdmin,
  selectTenants,
} from 'features';
import { initMapboxMap } from 'utils';
import { useFormat } from 'utils/hooks';
import HomeViewStyles from './HomeView.styles';

const useStyles = makeStyles(HomeViewStyles);

export const HomeView = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const { fromUtcToProjectTime } = useFormat();
  const classes = useStyles();
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const timeZone = useSelector(selectTimeZone);
  const user = useSelector(selectUser);
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const tenants = useSelector(selectTenants);
  /* ---------------------------------- State --------------------------------- */
  /* -------------------------------- Functions ------------------------------- */
  const openNavigation = () => {
    dispatch(setIsNavigationOpen(true));
  };
  const chunksFunc = (chunks) => <b>{chunks}</b>;
  /* --------------------------------- Effects -------------------------------- */

  useEffect(() => {
    const map = initMapboxMap({
      mapName: mapboxMapNames.homeView,
      hasNavigationControls: false,
    });

    if (!tenants?.currentTenant) return;

    map.on('load', () => {
      map.flyTo({
        zoom: 17,
        bearing: 60,
        pitch: 60,
        speed: 0.7,
        curve: 1,
        essential: true,

        easing(t) {
          return t;
        },
      });
    });

    return () => map.remove(); // eslint-disable-line
  }, [tenants?.currentTenant]); // eslint-disable-line

  useEffect(() => {
    if (user.isLoggedIn) {
      dispatch(loadCorridors());
    }
  }, [user.isLoggedIn]); //eslint-disable-line

  useEffect(() => {
    if (!timeZone) return;
    dispatch(fetchHomeStats({ fromUtcToProjectTime }));
  }, [timeZone]); // eslint-disable-line

  return (
    <div className={classes.homeContainer}>
      <div className={classes.header}>
        <div className={classes.navigation}>
          <Header icon={<Menu onClick={openNavigation} />} hasText={false} bgColor="transparent" />
        </div>
        <div className={classes.userMenu}>
          <UserMenu position="top-right" />
        </div>
      </div>

      <span className={classes.title}>
        <FormattedMessage
          defaultMessage="Know <b>Your City</b>"
          description="Home page title"
          values={{ b: chunksFunc }}
        />
      </span>
      <Container className={classes.cards} maxWidth="md">
        <Grid container spacing={3}>
          <StatisticsCard
            generalStatisticType={statisticTypes.PEDESTRIANS}
            pathname="/general"
            dataTestId="pedestriansCard"
          >
            <PedestriansIcon className={classes.cardIcon} />
            <FormattedMessage defaultMessage="Pedestrians & Micromobility" description="Card title" />
          </StatisticsCard>
          <StatisticsCard
            generalStatisticType={statisticTypes.PRIVATE}
            pathname="/general"
            hasPopup
            dataTestId="privateVehiclesCard"
          >
            <PrivateCars className={classes.cardIcon} />
            <FormattedMessage defaultMessage="Private Vehicles" description="Card title" />
          </StatisticsCard>
          <StatisticsCard
            generalStatisticType={statisticTypes.PUBLIC}
            pathname="/general"
            hasPopup
            dataTestId="publicTransportCard"
          >
            <Public className={classes.cardIcon} />
            <FormattedMessage defaultMessage="Public Transit" description="Card title" />
          </StatisticsCard>
          <StatisticsCard
            generalStatisticType={statisticTypes.ECO}
            pathname="/general"
            disabled={!isUserAdmin}
            dataTestId="airQualityCard"
          >
            <Eco className={classes.cardIcon} />
            <FormattedMessage defaultMessage="Air Quality" description="Card title" />
          </StatisticsCard>
        </Grid>
      </Container>
      <div className={classes.overlay} />
      <div className={classes.olMap} id="map-mapboxgl" />
    </div>
  );
};
