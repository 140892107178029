import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ChordDiagram, TopTabs, BottomContainer, SimulationTables, SimulationStatisticsTables } from 'components';
import { selectOdMatrix, selectExpandedPanel } from 'features';
import { useSelector } from 'react-redux';
import classes from './SimulationContainer.module.scss';

export const SimulationContainer = () => {
  const expandedPanel = useSelector(selectExpandedPanel);
  const { odApproaches, odApproachesPublicTransport, odMatrix, odMatrixPublicTransport } = useSelector(selectOdMatrix);
  const [activeSideTab, setActiveSideTab] = useState('performance');
  const [activeODMatrixTab, setActiveODMatrixTab] = useState('generalTraffic');
  const sideTabs = [
    {
      id: 'performance',
      text: <FormattedMessage description="Traffic Performance tab" defaultMessage="Traffic Performance" />,
    },
    {
      id: 'statistics',
      text: <FormattedMessage description="Signal plans statistics tab" defaultMessage="Signal plans statistics" />,
    },
    { id: 'odmatrix', text: <FormattedMessage description="OD Matrix tab" defaultMessage="OD Matrix" /> },
  ];
  const odMatrixTabs = [
    {
      id: 'generalTraffic',
      text: <FormattedMessage description="General traffic tab" defaultMessage="General traffic" />,
    },
    { id: 'transitLanes', text: <FormattedMessage description="Transit lanes tab" defaultMessage="Transit lanes" /> },
  ];

  return (
    <BottomContainer
      slideIn={expandedPanel === 'simulation'}
      sideTabs={sideTabs}
      activeSideTab={activeSideTab}
      setActiveSideTab={setActiveSideTab}
      mainContent={
        <div className={classes.wrap}>
          {activeSideTab === 'performance' && <SimulationTables />}
          {activeSideTab === 'statistics' && <SimulationStatisticsTables />}
          {activeSideTab === 'odmatrix' && (
            <>
              <TopTabs tabs={odMatrixTabs} activeTab={activeODMatrixTab} setActiveTab={setActiveODMatrixTab} />
              {activeODMatrixTab === 'generalTraffic' && <ChordDiagram odLabels={odApproaches} odMatrix={odMatrix} />}
              {activeODMatrixTab === 'transitLanes' && (
                <ChordDiagram odLabels={odApproachesPublicTransport} odMatrix={odMatrixPublicTransport} />
              )}
            </>
          )}
        </div>
      }
    />
  );
};
