export const geofenceAreaZIndex = 100;
export const selectedPointZIndex = 95;
export const intersectionsZIndex = 90;
export const detectionAreaZIndex = 85;
export const corridorsZIndex = 80;
export const approachesZIndex = 75;
export const segmentsZIndex = 70;
export const osmBusStopsZIndex = 65;
export const osmNodesZIndex = 60;
export const osmEdgesZIndex = 55;
