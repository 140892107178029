import * as mapboxgl from 'mapbox-gl';
import { MAPPED_TENANT_POSITIONS } from 'consts';
import { setIsSidebarVisible } from 'features';
import { setupNavigationControl } from 'utils/mapbox/setupNavigationControl';
import store from 'store';

export const mapboxMaps = {};

export const initMapboxMap = ({ mapName, hasNavigationControls = true }) => {
  const tenantPosition = MAPPED_TENANT_POSITIONS[store.getState().user.tenants?.currentTenant];

  // Workaround for mapbox build bug - https://github.com/mapbox/mapbox-gl-js/issues/10173
  // TODO remove workaround and "worker-loader" package when fixed
  mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; //eslint-disable-line

  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

  // mapbox://styles/mapbox/streets-v11
  // mapbox://styles/mapbox/dark-v10
  // mapbox://styles/mapbox/outdoors-v11
  const mapboxglRef = new mapboxgl.Map({
    style: 'mapbox://styles/mapbox/outdoors-v11',
    center: tenantPosition?.center || [34.7818, 32.0853],
    zoom: tenantPosition?.zoom || 16,
    pitch: 0,
    bearing: 0,
    container: 'map-mapboxgl',
    antialias: true,
    keyboard: false,
    attributionControl: false,
  });

  if (hasNavigationControls) {
    mapboxglRef.addControl(setupNavigationControl(mapboxglRef));
    mapboxglRef.addControl(new mapboxgl.FullscreenControl({ container: document.querySelector('body') }));
  }

  window.onresize = () => store.dispatch(setIsSidebarVisible(window.screen.height !== window.innerHeight));

  // The 'building' layer in the mapbox-streets vector source contains building-height
  // data from OpenStreetMap.
  mapboxglRef.on('load', ({ target: mapboxTarget }) => {
    // Insert the layer beneath any symbol layer.
    const { layers } = mapboxTarget.getStyle();

    let labelLayerId;

    for (let i = 0; i < layers.length; i += 1) {
      if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
        labelLayerId = layers[i].id;
        break;
      }
    }

    mapboxTarget.addLayer(
      {
        id: '3d-buildings',
        source: 'composite',
        'source-layer': 'building',
        filter: ['==', 'extrude', 'true'],
        type: 'fill-extrusion',
        minzoom: 15,
        paint: {
          'fill-extrusion-color': '#aaa',

          // use an 'interpolate' expression to add a smooth transition effect to the
          // buildings as the user zooms in
          'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 5, 0, 5.05, ['get', 'height']],
          'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 5, 0, 5.05, ['get', 'min_height']],
          'fill-extrusion-opacity': 0.6,
        },
      },
      labelLayerId,
    );

    mapboxTarget.addLayer({
      id: 'sky',
      type: 'sky',
      paint: {
        'sky-type': 'atmosphere',
        'sky-atmosphere-sun': [0.0, 0.0],
        'sky-atmosphere-sun-intensity': 15,
      },
    });
  });

  mapboxMaps[mapName] = mapboxglRef;

  return mapboxglRef;
};
