import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { handleEditBusStop } from 'features';
import { TextField } from '@material-ui/core';
import { Dialog } from 'screens/EditGrid/Dialog';

export const EditBusStopDialog = ({ isOpen, close, busStop }) => {
  const dispatch = useDispatch();

  const submit = (event) => {
    dispatch(
      handleEditBusStop({
        id: event.target.id.value,
        name: event.target.name.value,
        currentId: event.target.currentId.value,
      }),
    );

    close();
  };

  if (!busStop) return null;

  return (
    <Dialog title="Edit Bus Stop" isOpen={isOpen} onClose={close} onSubmit={submit}>
      <TextField label="id" defaultValue={busStop.get('id')} name="id" />
      <TextField label="name" defaultValue={busStop.get('name')} name="name" />
      <TextField label="currentId" defaultValue={busStop.get('id')} InputProps={{ readOnly: true }} name="currentId" />
    </Dialog>
  );
};

EditBusStopDialog.propTypes = {
  isOpen: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  busStop: propTypes.object,
};

EditBusStopDialog.defaultProps = {
  busStop: null,
};
