export const logError = {
  get: (e) => {
    if (e && e.status) {
        console.log({ //eslint-disable-line
        status: e.status,
        message: e.data,
      });
    }
  },
};

export default logError;
