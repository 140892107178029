import React, { useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ArrowBlue } from 'assets/images/arrow-direction-blue.svg';
import { selectExpandedPanel, selectIsComparing, selectLoadingCorridorsMetricsRides } from 'features';
import { FormattedMessage } from 'react-intl';
import { generalMetricsTableData } from 'components';
import tableStyles from '../table.styles';

const useStyles = makeStyles(tableStyles);

export const GeneralMetricsTable = ({ metricRidesFirst, metricRidesSecond, onTableRowHandler }) => {
  const classes = useStyles();
  const tableRef = useRef();
  const isComparing = useSelector(selectIsComparing);
  const expandedPanel = useSelector(selectExpandedPanel);
  const loadingMetrics = useSelector(selectLoadingCorridorsMetricsRides);
  const [selectedRowId, setSelectedRowId] = useState(undefined);
  const [metricRides, setMetricRides] = useState([]);
  const tableData = generalMetricsTableData(expandedPanel) || [];

  const onRowClick = (stop, i) => (e) => {
    onTableRowHandler(e.currentTarget, stop);
    setSelectedRowId(i);
  };

  useEffect(() => {
    if (!isComparing) {
      setMetricRides(metricRidesFirst);
    } else if (metricRidesSecond.length) {
      setMetricRides(
        metricRidesFirst.reduce((acc, val, i) => {
          acc.push(metricRidesFirst[i]);
          acc.push(metricRidesSecond[i]);

          return acc;
        }, []),
      );
    }
  }, [metricRidesFirst, metricRidesSecond, isComparing]);

  useEffect(() => {
    setSelectedRowId(undefined);
  }, [expandedPanel]);

  useEffect(() => {
    setTimeout(() => {
      const firstCriticalIndex = metricRides.findIndex((ride) => ride.critical);
      const tableRowHeight = tableRef.current?.children[0].children[1].children[0]?.clientHeight;

      if (tableRef.current) {
        tableRef.current.scrollTo(0, tableRowHeight * firstCriticalIndex - tableRowHeight / 2);
      }
    });
  }, [metricRides]);

  return (
    <Box className={classes.boxContainer}>
      <div
        className={`
        ${classes.tableContainer}
      `}
      >
        <TableContainer ref={tableRef} className={classes.tableContainerInner}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className={classes.tableHeadCell}>
                  <FormattedMessage
                    defaultMessage="Section name"
                    description="General metrics table column Section header"
                  />
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  <FormattedMessage
                    defaultMessage="Intersection ID"
                    description="General metrics table column Section header"
                  />
                </TableCell>
                {tableData.map(({ head }, i) => (
                  <TableCell className={classes.tableHeadCell} key={String(i)}>
                    {head()}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingMetrics !== 'loaded' ? (
                <TableRow>
                  <TableCell align="center" colSpan={3 + Object.values(tableData).length}>
                    <CircularProgress size={26} thickness={4} />
                  </TableCell>
                </TableRow>
              ) : (
                metricRides.map((stop, i) => (
                  <TableRow
                    className={`
                      ${classes.tableRow}
                      ${stop.critical ? classes.criticalRow : ''}
                      ${selectedRowId === i ? classes.selectedRow : ''}
                      ${isComparing ? 'isComparing' : ''}
                    `}
                    onClick={onRowClick(stop, i)}
                    key={i.toString()}
                  >
                    {(!isComparing || i % 2 === 0) && (
                      <>
                        <TableCell
                          className={`
                            borderCell
                            ${isComparing && selectedRowId === i + 1 ? classes.selectedCell : ''}
                          `}
                          rowSpan={isComparing ? 2 : 1}
                          align="center"
                        >
                          {i !== 0 && (
                            <>
                              <div className={classes.borderCellBorder1} />
                              <ArrowBlue className={classes.borderCellIcon} />
                            </>
                          )}
                          <div className={classes.borderCellBorder2} />
                          <div className={classes.borderCellBorder3} />
                        </TableCell>
                        <TableCell
                          className={`
                            stopNameCell
                            ${isComparing && selectedRowId === i + 1 ? classes.selectedCell : ''}
                          `}
                          rowSpan={isComparing ? 2 : 1}
                          align="center"
                        >
                          {stop.name}
                        </TableCell>
                        <TableCell
                          className={`
                            stopIdCell
                            ${isComparing && selectedRowId === i + 1 ? classes.selectedCell : ''}
                          `}
                          rowSpan={isComparing ? 2 : 1}
                          align="center"
                        >
                          {stop.id}
                        </TableCell>
                      </>
                    )}
                    {tableData.map(({ data }, index) => (
                      <TableCell
                        className={`
                          dataCell
                          ${isComparing && index === 0 ? classes.firstDataCell : ''}
                          ${i % 2 !== 0 ? 'even' : 'odd'}
                        `}
                        align="center"
                        key={String(index)}
                      >
                        {data(stop)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );
};

GeneralMetricsTable.propTypes = {
  metricRidesFirst: propTypes.array,
  metricRidesSecond: propTypes.array,
  onTableRowHandler: propTypes.func,
};

GeneralMetricsTable.defaultProps = {
  metricRidesFirst: [],
  metricRidesSecond: [],
  onTableRowHandler() {},
};
