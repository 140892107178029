import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Header, FeatureStatsPopupOverlay } from 'components';
import { List, ListItem, Checkbox, FormControlLabel, Button, Collapse, Fab, Menu, MenuItem } from '@material-ui/core';
import { tenantNames } from '@axilion/ui-components';
import {
  initMap,
  initLayer,
  initClusterLayer,
  featureStatsOverlay,
  changeMapSource,
  initFeatureStatsOverlay,
  layerStyles,
  initEditGridEvents,
  centerMap,
} from 'utils';
import { useIntl } from 'utils/hooks';
import {
  selectIsLoading,
  setToastMessage,
  fetchAllIntersections,
  fetchAllApproaches,
  selectIntersections,
  selectLoadingAllApproaches,
  selectAllApproaches,
  setIsNavigationOpen,
  fetchOsmExport,
  selectOsmExportFeatures,
  selectSegments,
  fetchSegments,
  fetchDirections,
  selectLoadingAddIntersection,
  selectLoadingAddSegment,
  selectDetectionAreaCoords,
  selectLoadingDetectionArea,
  fetchDetectionArea,
  selectLoadingAddApproach,
  selectLoadingAddDetectionArea,
  selectLoadingDeleteApproach,
  selectLoadingDeleteIntersection,
  selectLoadingDeleteSegment,
  fetchOsmBusStops,
  selectLoadingEditBusStop,
  selectLoadingDeleteBusStop,
  fetchGeofenceArea,
  selectLoadingGeofenceArea,
  selectGeofenceAreaCoords,
  selectLoadingAddGeofenceArea,
  selectCorridors,
  selectLoadingAddCorridor,
  selectLoadingDeleteCorridor,
  loadCorridors,
  fetchAllBusStops,
  selectAllBusStops,
  selectLoadingOsmBusStops,
  selectTenants,
  resetLoadingAddIntersection,
  resetLoadingDeleteIntersection,
  resetLoadingAddSegment,
  resetLoadingDeleteSegment,
  resetLoadingAddApproach,
  resetLoadingDeleteApproach,
  resetLoadingEditBusStop,
  resetLoadingDeleteBusStop,
  resetLoadingAddCorridor,
  resetLoadingDeleteCorridor,
  resetLoadingAddDetectionArea,
  resetLoadingAddGeofenceArea,
  resetLoadingOSMBusStops,
} from 'features';
import {
  intersectionsLayerName,
  approachesLayerName,
  selectedPointLayerName,
  detectionAreaLayerName,
  geofenceAreaLayerName,
  segmentsLayerName,
  osmEdgesLayerName,
  osmNodesLayerName,
  osmBusStopsLayerName,
  geofenceAreaZIndex,
  detectionAreaZIndex,
  selectedPointZIndex,
  intersectionsZIndex,
  corridorsZIndex,
  approachesZIndex,
  segmentsZIndex,
  osmBusStopsZIndex,
  osmNodesZIndex,
  osmEdgesZIndex,
} from 'consts';
import { Menu as MenuIcon, ArrowLeft, Settings as SettingsIcon } from '@material-ui/icons';
import { Select, DragBox, Draw } from 'ol/interaction';
import { shiftKeyOnly, singleClick } from 'ol/events/condition';
import { toLonLat } from 'ol/proj';
import * as olExtent from 'ol/extent';
import { Polygon } from 'ol/geom';
import Feature from 'ol/Feature';

import { RightClickMenu } from './RightClickMenu/RightClickMenu';
import {
  AddIntersectionDialog,
  DeleteIntersectionDialog,
  AddSegmentDialog,
  DeleteSegmentDialog,
  CreateApproachDialog,
  DeleteApproachDialog,
  EditBusStopDialog,
  DeleteBusStopDialog,
  CreateCorridorDialog,
  DeleteCorridorDialog,
  AddDetectionAreaDialog,
  AddGeofenceAreaDialog,
  EditTimeIntervalsDialog,
} from './Dialog';
import styles from './EditGrid.module.scss';

export const EditGrid = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const drawDetectionAreaRef = useRef(null);
  const drawGeofenceAreaRef = useRef(null);
  const fabButtonRef = useRef();

  /* -------------------------------- Selectors ------------------------------- */
  const corridors = useSelector(selectCorridors);
  const intersections = useSelector(selectIntersections);
  const allApproaches = useSelector(selectAllApproaches);
  const geofenceAreaCoords = useSelector(selectGeofenceAreaCoords);
  const isLoading = useSelector(selectIsLoading);
  const loadingAllApproaches = useSelector(selectLoadingAllApproaches);
  const osmExport = useSelector(selectOsmExportFeatures);
  const loadingAddIntersection = useSelector(selectLoadingAddIntersection);
  const loadingDeleteIntersection = useSelector(selectLoadingDeleteIntersection);
  const loadingAddSegment = useSelector(selectLoadingAddSegment);
  const loadingDeleteSegment = useSelector(selectLoadingDeleteSegment);
  const loadingAddApproach = useSelector(selectLoadingAddApproach);
  const segments = useSelector(selectSegments);
  const detectionAreaCoords = useSelector(selectDetectionAreaCoords);
  const loadingIntersectionArea = useSelector(selectLoadingDetectionArea);
  const loadingAddDetectionArea = useSelector(selectLoadingAddDetectionArea);
  const loadingAddGeofenceArea = useSelector(selectLoadingAddGeofenceArea);
  const loadingDeleteApproach = useSelector(selectLoadingDeleteApproach);
  const loadingOSMBusStops = useSelector(selectLoadingOsmBusStops);
  const loadingEditBusStop = useSelector(selectLoadingEditBusStop);
  const loadingDeleteBusStop = useSelector(selectLoadingDeleteBusStop);
  const osmBusStops = useSelector(selectAllBusStops);
  const loadingAddCorridor = useSelector(selectLoadingAddCorridor);
  const loadingDeleteCorridor = useSelector(selectLoadingDeleteCorridor);
  const loadingGeofenceArea = useSelector(selectLoadingGeofenceArea);
  const { currentTenant } = useSelector(selectTenants);

  /* ---------------------------------- State --------------------------------- */
  const [olMap, setOlMap] = useState();
  const [activeLayers, setActiveLayers] = useState([]);
  const [addIntersectionName, setAddIntersectionName] = useState();
  const [rightClickCoords, setRightClickCoords] = useState([]);
  const [rightClickPosition, setRightClickPosition] = useState([]);
  const [rightClickFeatures, setRightClickFeatures] = useState([]);
  const [addIntersectionSignalized, setAddIntersectionSignalized] = useState(false);
  const [isAddIntersectionDialogOpen, setIsAddIntersectionDialogOpen] = useState(false);
  const [isDeleteIntersectionDialogOpen, setIsDeleteIntersectionDialogOpen] = useState(false);
  const [isAddSegmentDialogOpen, setIsAddSegmentDialogOpen] = useState(false);
  const [isDeleteSegmentDialogOpen, setIsDeleteSegmentDialogOpen] = useState(false);
  const [isEditBusStopDialogOpen, setIsEditBusStopDialogOpen] = useState(false);
  const [isDeleteBusStopDialogOpen, setIsDeleteBusStopDialogOpen] = useState(false);
  const [isCreateApproachDialogOpen, setIsCreateApproachDialogOpen] = useState(false);
  const [isAddDetectionAreaDialogOpen, setIsAddDetectionAreaDialogOpen] = useState(false);
  const [isAddGeofenceAreaDialogOpen, setIsAddGeofenceAreaDialogOpen] = useState(false);
  const [isEditTimeIntervalsDialogOpen, setIsEditTimeIntervalsDialogOpen] = useState(false);
  const [isDeleteApproachDialogOpen, setIsDeleteApproachDialogOpen] = useState(false);
  const [isCreateCorridorDialogOpen, setIsCreateCorridorDialogOpen] = useState(false);
  const [isDeleteCorridorDialogOpen, setIsDeleteCorridorDialogOpen] = useState(false);
  const [selectedIntersectionId, setSelectedIntersectionId] = useState('');
  const [startSegmentId, setStartSegmentId] = useState('');
  const [endSegmentId, setEndSegmentId] = useState('');
  const [segmentSpeed, setSegmentSpeed] = useState('');
  const [segmentEdges, setSegmentEdges] = useState([]);
  const [olSelect, setOlSelect] = useState();
  const [isCorridorsListVisible, setIsCorridorsListVisible] = useState();
  const [selectedSegmentsFeatures, setSelectedSegmentsFeatures] = useState([]);
  const [drawnDetectionAreaCoords, setDrawnDetectionAreaCoords] = useState();
  const [drawnGeofenceAreaCoords, setDrawnGeofenceAreaCoords] = useState();
  const [selectedApproachesFeatures, setSelectedApproachesFeatures] = useState([]);
  const [corridorsLayersNames, setCorridorsLayersNames] = useState([]);
  const [selectedCorridorId, setSelectedCorridorId] = useState(null);
  const [isHideAllActive, setIsHideAllActive] = useState(true);
  const [isEnglishTileActive, setIsEnglishTileActive] = useState(false);
  const [selectedOlFeatures, setSelectedOlFeatures] = useState([]);
  const [currentZoom, setCurrentZoom] = useState();
  const [isFabMenuVisible, setIsFabMenuVisible] = useState(false);

  /* -------------------------------- Functions ------------------------------- */
  const openNavigation = () => {
    dispatch(setIsNavigationOpen(true));
  };

  const openFabMenu = () => {
    setIsFabMenuVisible(true);
  };

  const closeFabMenu = () => {
    setIsFabMenuVisible(false);
  };

  const setupMap = () => {
    initFeatureStatsOverlay();
    const map = initMap({
      name: 'EditGrid',
      layers: [
        initLayer(detectionAreaLayerName, undefined, undefined, detectionAreaZIndex),
        initLayer(geofenceAreaLayerName, undefined, layerStyles.editGrid.geofence, geofenceAreaZIndex, false),
      ],
      overlays: [...Object.values(featureStatsOverlay)],
    });

    setOlMap(map);
    initEditGridEvents(map, setActiveLayers, setSelectedCorridorId);
  };

  const findLayerByName = (name) =>
    olMap
      ?.getLayers()
      .getArray()
      .find((layer) => layer.getProperties().name === name);

  const findLayersByNames = (namesArr) =>
    olMap
      ?.getLayers()
      .getArray()
      .filter((l) => namesArr.includes(l.getProperties().name));

  const updateSelectedClusters = (collection, updateFunc) => {
    const selectedClusteredFeatures = [];

    collection.forEach((feature) => {
      const clusteredFeature = feature.get('features')?.[0];

      if (clusteredFeature) {
        selectedClusteredFeatures.push(clusteredFeature);
      }
    });

    if (!selectedClusteredFeatures.length) return;

    const allClusters = [...findLayerByName(intersectionsLayerName).getSource().getFeatures()];

    allClusters.forEach((cluster) => {
      const clusteredFeature = cluster.get('features')[0];

      if (selectedClusteredFeatures.includes(clusteredFeature)) {
        updateFunc(cluster, clusteredFeature);
      }
    });
  };

  const removeIntersectionAreaFeatures = () => {
    findLayerByName(detectionAreaLayerName).getSource().clear();
  };

  const removeGeofenceAreaFeatures = () => {
    findLayerByName(geofenceAreaLayerName).getSource().clear();
  };

  const processAddIntersectionFeatures = () => {
    const names = [];

    setAddIntersectionSignalized(false);

    olSelect.getFeatures().forEach((feature) => {
      const featureProps = feature.getProperties();

      if (featureProps.type === 'edge' && !names.includes(featureProps.name)) {
        names.push(featureProps.name);
      }

      if (featureProps.features) {
        featureProps.features.forEach((childFeature) => {
          if (childFeature.getProperties().type === 'signal') {
            setAddIntersectionSignalized(true);
          }
        });
      }
    });

    setAddIntersectionName(names.join('-'));
  };

  const processAddSegmentFeatures = () => {
    const edges = [];

    olSelect.getFeatures().forEach((feature) => {
      const featureProps = feature.getProperties();

      if (featureProps.type === 'edge') {
        const speed = String(Math.round(featureProps.speed * 3.6)); // transform m/s to km/h

        if (speed > segmentSpeed) setSegmentSpeed(speed);

        const coords = [];

        featureProps.geometry.getCoordinates().forEach((coordinate) => coords.push(toLonLat(coordinate)));
        edges.push(coords);
      }
    });

    setSegmentEdges(edges);
  };

  const addSelectedPointLayer = (coords) => {
    olMap.addLayer(
      initLayer(
        selectedPointLayerName,
        { type: 'Feature', geometry: { type: 'Point', coordinates: coords } },
        layerStyles.adminView.selectedPoint,
        selectedPointZIndex,
      ),
    );
  };

  const filterSelectableFeatures = (feature, layer) => {
    if (layer.get('name') === intersectionsLayerName && feature.getProperties().features.length !== 1) return false;

    if (layer.get('name') === intersectionsLayerName && feature.getProperties()?.features?.length === 1) {
      setSelectedIntersectionId(feature.getProperties().features[0].getId());
    }

    return true;
  };

  const handleSelectChange = (selectedFeaturesCollection) => () => {
    if (!selectedFeaturesCollection.getLength()) {
      setStartSegmentId('');
      setEndSegmentId('');
      setSelectedIntersectionId('');
    }

    const selectedFeatures = selectedFeaturesCollection.getArray();

    if (findLayerByName(segmentsLayerName)) {
      setSelectedSegmentsFeatures(
        selectedFeatures.filter((feature) =>
          findLayerByName(segmentsLayerName).getSource().getFeatures().includes(feature),
        ),
      );
    }

    if (findLayerByName(approachesLayerName)) {
      setSelectedApproachesFeatures(
        selectedFeatures.filter((feature) =>
          findLayerByName(approachesLayerName).getSource().getFeatures().includes(feature),
        ),
      );
    }
  };

  const handleDragEnd = (dragBox, selectedFeaturesCollection) => () => {
    const extent = dragBox.getGeometry().getExtent();

    if (findLayerByName(segmentsLayerName)) {
      findLayerByName(segmentsLayerName)
        .getSource()
        .forEachFeatureIntersectingExtent(extent, (feature) => {
          selectedFeaturesCollection.push(feature);
        });
    }

    if (findLayerByName(osmEdgesLayerName)) {
      findLayerByName(osmEdgesLayerName)
        .getSource()
        .forEachFeatureIntersectingExtent(extent, (feature) => {
          selectedFeaturesCollection.push(feature);
        });
    }

    if (findLayerByName(osmNodesLayerName)) {
      findLayerByName(osmNodesLayerName)
        .getSource()
        .forEachFeatureIntersectingExtent(extent, (feature) => {
          selectedFeaturesCollection.push(feature);
        });
    }
  };

  const handleMapMove = () => {
    setCurrentZoom(olMap.getView().getZoom());
  };

  const selectCondition = (event) => {
    if (olMap.getInteractions().getArray().includes(drawDetectionAreaRef.current)) {
      return false;
    }

    return singleClick(event);
  };

  const setupFeatureSelection = () => {
    const selectableLayersNames = [
      segmentsLayerName,
      intersectionsLayerName,
      osmEdgesLayerName,
      osmNodesLayerName,
      approachesLayerName,
      osmBusStopsLayerName,
    ];
    const select = new Select({
      layers: (layer) => selectableLayersNames.includes(layer.getProperties().name),
      condition: selectCondition,
      filter: filterSelectableFeatures,
      style: (feature) => {
        if (findLayerByName(osmBusStopsLayerName).getSource().getFeatures().includes(feature)) {
          feature.setStyle(layerStyles.corridorView.busStopsSelected);
        } else if (feature.getGeometry().getType() === 'LineString') {
          feature.setStyle(layerStyles.editGrid.lineActive);
        } else {
          feature.setStyle(layerStyles.editGrid.pointActive);
        }
      },
    });

    setOlSelect(select);

    const dragBox = new DragBox({ condition: shiftKeyOnly });
    const selectedFeatures = select.getFeatures();

    setSelectedOlFeatures(selectedFeatures);

    olMap.addInteraction(select);
    olMap.addInteraction(dragBox);

    select.on('select', handleSelectChange(selectedFeatures));
    dragBox.on('boxend', handleDragEnd(dragBox, selectedFeatures));
    olMap.on('moveend', handleMapMove);
  };

  const generateBusStopsHandler = () => {
    dispatch(fetchOsmBusStops());
    closeFabMenu();
  };

  const handleSwitchTiles = () => {
    setIsEnglishTileActive(!isEnglishTileActive);
  };

  const handleHideLayers = () => {
    const layersToHide = findLayersByNames([
      intersectionsLayerName,
      approachesLayerName,
      selectedPointLayerName,
      detectionAreaLayerName,
      geofenceAreaLayerName,
      osmEdgesLayerName,
      osmNodesLayerName,
      segmentsLayerName,
      osmBusStopsLayerName,
      ...corridorsLayersNames,
    ]);

    layersToHide.forEach((layer) => {
      layer.setVisible(!isHideAllActive);
    });

    setIsHideAllActive(!isHideAllActive);
  };

  const toggleLayer = (name) => {
    const layer = findLayerByName(name);

    if (layer) {
      layer.setVisible(!layer.getVisible());
    }
  };

  const areCorridorsLayersVisible = () => {
    const layers = findLayersByNames(corridorsLayersNames);

    if (!layers?.length) return false;

    return layers.every((layer) => layer.getVisible());
  };

  const toggleCorridorsLayers = (event) => {
    event.stopPropagation();
    const areVisible = areCorridorsLayersVisible();

    findLayersByNames(corridorsLayersNames).forEach((layer) => {
      layer.setVisible(!areVisible);
    });
  };

  const handleCorridorsListLabelClick = (event) => {
    event.preventDefault();
    setIsCorridorsListVisible(!isCorridorsListVisible);
  };

  const resetGeofenceAreaLayer = () => {
    removeGeofenceAreaFeatures();
    const geometry = new Polygon([geofenceAreaCoords]);
    const geofenceLayer = findLayerByName(geofenceAreaLayerName);

    geometry.transform('EPSG:4326', 'EPSG:3857');
    geofenceLayer.getSource().addFeature(new Feature({ geometry }));
  };

  const removeSelectedPointLayer = () => {
    olMap.removeLayer(findLayerByName(selectedPointLayerName));
  };

  const openAddIntersectionDialog = () => {
    processAddIntersectionFeatures();
    setIsAddIntersectionDialogOpen(true);
  };

  const onCloseAddIntersectionDialog = () => {
    setIsAddIntersectionDialogOpen(false);
    setAddIntersectionName();
  };

  const openAddSegmentDialog = () => {
    processAddSegmentFeatures();
    setIsAddSegmentDialogOpen(true);
  };

  const closeAddSegmentDialog = () => {
    setIsAddSegmentDialogOpen(false);
  };

  const closeDeleteSegmentDialog = () => {
    setIsDeleteSegmentDialogOpen(false);
  };

  const openCreateApproachDialog = () => {
    setIsCreateApproachDialogOpen(true);
  };

  const closeCreateApproachDialog = () => {
    setIsCreateApproachDialogOpen(false);
  };

  const openAddDetectionAreaDialog = () => {
    setIsAddDetectionAreaDialogOpen(true);
  };

  const cancelAddDetectionAreaDialog = () => {
    removeIntersectionAreaFeatures();
    setIsAddDetectionAreaDialogOpen(false);
  };

  const submitAddDetectionAreaDialog = () => {
    setIsAddDetectionAreaDialogOpen(false);
  };

  const openAddGeofenceAreaDialog = () => {
    setIsAddGeofenceAreaDialogOpen(true);
  };

  const cancelAddGeofenceAreaDialog = () => {
    resetGeofenceAreaLayer();
    setIsAddGeofenceAreaDialogOpen(false);
  };

  const submitAddGeofenceAreaDialog = () => {
    setIsAddGeofenceAreaDialogOpen(false);
  };

  const closeDeleteApproachDialog = () => {
    setIsDeleteApproachDialogOpen(false);
  };

  const onAddIntersectionClick = () => {
    openAddIntersectionDialog();
  };

  const onDeleteIntersectionClick = () => {
    setIsDeleteIntersectionDialogOpen(true);
  };

  const closeDeleteIntersectionDialog = () => {
    setIsDeleteIntersectionDialogOpen(false);
  };

  const onAddSegmentClick = () => {
    openAddSegmentDialog();
  };

  const onDeleteSegmentClick = () => {
    setIsDeleteSegmentDialogOpen(true);
  };

  const onCreateApproachClick = () => {
    openCreateApproachDialog();
  };

  const onDeleteApproachClick = () => {
    setIsDeleteApproachDialogOpen(true);
  };

  const onEditBusStopClick = () => {
    setIsEditBusStopDialogOpen(true);
  };

  const onDeleteBusStopClick = () => {
    setIsDeleteBusStopDialogOpen(true);
  };

  const closeEditBusStopDialog = () => {
    setIsEditBusStopDialogOpen(false);
  };

  const closeDeleteBusStopDialog = () => {
    setIsDeleteBusStopDialogOpen(false);
  };

  const onCreateCorridorClick = () => {
    setIsCreateCorridorDialogOpen(true);
  };

  const closeCreateCorridor = () => {
    setIsCreateCorridorDialogOpen(false);
  };

  const onDeleteCorridorClick = () => {
    setIsDeleteCorridorDialogOpen(true);
  };

  const closeDeleteCorridor = () => {
    setIsDeleteCorridorDialogOpen(false);
  };

  const openEditTimeIntervalsDialog = () => {
    setIsEditTimeIntervalsDialogOpen(true);
    closeFabMenu();
  };

  const cancelEditTimeIntervalsDialog = () => {
    setIsEditTimeIntervalsDialogOpen(false);
  };

  const submitEditTimeIntervalsDialog = () => {
    setIsEditTimeIntervalsDialogOpen(false);
  };

  const getRightClickedFeatureByLayer = (layerName) =>
    findLayerByName(layerName)
      ?.getSource()
      .getFeatures()
      .filter((f) => rightClickFeatures?.includes(f))[0];

  const onShowDetectionAreaClick = () => {
    removeIntersectionAreaFeatures();
    const features = getRightClickedFeatureByLayer(intersectionsLayerName).get('features');

    if (features.length > 1) {
      const extent = olExtent.createEmpty();

      features.forEach((feature) => {
        olExtent.extend(extent, feature.getGeometry().getExtent());
      });
      olMap.getView().fit(extent, { duration: 1000, padding: [400, 400, 400, 400] });
    } else {
      dispatch(fetchDetectionArea(features[0].get('id')));
    }
  };

  const onAddDetectionAreaClick = () => {
    olMap.addInteraction(drawDetectionAreaRef.current);

    drawDetectionAreaRef.current.on('drawend', (event) => {
      removeIntersectionAreaFeatures();

      const transformedCoords = [];

      event.feature
        .getProperties()
        .geometry.getCoordinates()[0]
        .forEach((coords) => {
          transformedCoords.push(toLonLat(coords));
        });
      setDrawnDetectionAreaCoords(transformedCoords);
      window.setTimeout(() => olMap.removeInteraction(drawDetectionAreaRef.current), 1000);
      openAddDetectionAreaDialog();
    });
  };

  const onAddGeofenceAreaClick = () => {
    olMap.addInteraction(drawGeofenceAreaRef.current);

    drawGeofenceAreaRef.current.on('drawend', (event) => {
      removeGeofenceAreaFeatures();

      const transformedCoords = [];

      event.feature
        .getProperties()
        .geometry.getCoordinates()[0]
        .forEach((coords) => {
          transformedCoords.push(toLonLat(coords));
        });
      setDrawnGeofenceAreaCoords(transformedCoords);
      olMap.removeInteraction(drawGeofenceAreaRef.current);
      openAddGeofenceAreaDialog();
    });
  };

  const initDrawRefs = () => {
    if (!drawDetectionAreaRef.current) {
      drawDetectionAreaRef.current = new Draw({
        type: 'Polygon',
        source: findLayerByName(detectionAreaLayerName).getSource(),
      });
    }

    if (!drawGeofenceAreaRef.current) {
      drawGeofenceAreaRef.current = new Draw({
        type: 'Polygon',
        source: findLayerByName(geofenceAreaLayerName).getSource(),
      });
    }
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    dispatch(fetchAllIntersections());
    dispatch(fetchAllApproaches());
    dispatch(fetchOsmExport());
    dispatch(fetchSegments());
    dispatch(fetchDirections());
    dispatch(fetchAllBusStops());
    dispatch(fetchGeofenceArea());
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!currentTenant) return;

    setupMap();
  }, [currentTenant]);

  useEffect(() => {
    if (intersections.loadingAll !== 'loaded' || !olMap) return;
    olMap.removeLayer(findLayerByName(intersectionsLayerName));
    olMap.addLayer(
      initClusterLayer(
        intersectionsLayerName,
        { type: 'FeatureCollection', features: intersections.all },
        layerStyles.editGrid.intersections,
        intersectionsZIndex,
        40,
      ),
    );
  }, [intersections.loadingAll, olMap]); //eslint-disable-line

  useEffect(() => {
    if (!olMap || loadingIntersectionArea !== 'loaded') return;
    if (detectionAreaCoords.length) {
      const geometry = new Polygon([detectionAreaCoords]);

      geometry.transform('EPSG:4326', 'EPSG:3857');
      findLayerByName(detectionAreaLayerName).getSource().addFeature(new Feature({ geometry }));
    } else {
      dispatch(
        setToastMessage(
          formatMessage({
            defaultMessage: 'There is no detection area for this intersection',
            description: 'Toast message',
          }),
        ),
      );
    }
  }, [olMap, detectionAreaCoords]); // eslint-disable-line

  useEffect(() => {
    if (!olMap || loadingAllApproaches !== 'loaded') return;
    const approachesLayer = initLayer(
      approachesLayerName,
      allApproaches,
      layerStyles.corridorView.corridor,
      approachesZIndex,
    );

    olMap.removeLayer(findLayerByName(approachesLayerName));
    olMap.addLayer(approachesLayer);
  }, [olMap, loadingAllApproaches]); //eslint-disable-line

  useEffect(() => {
    if ([loadingAddIntersection, loadingDeleteIntersection].includes('loaded')) {
      dispatch(fetchAllIntersections());
    }
    dispatch(resetLoadingAddIntersection());
    dispatch(resetLoadingDeleteIntersection());
  }, [loadingAddIntersection, loadingDeleteIntersection]); //eslint-disable-line

  useEffect(() => {
    if ([loadingAddSegment, loadingDeleteSegment].includes('loaded')) {
      dispatch(fetchSegments());
    }
    dispatch(resetLoadingAddSegment());
    dispatch(resetLoadingDeleteSegment());
  }, [loadingAddSegment, loadingDeleteSegment]); //eslint-disable-line

  useEffect(() => {
    if ([loadingAddApproach, loadingDeleteApproach].includes('loaded')) {
      dispatch(fetchAllApproaches());
    }
    dispatch(resetLoadingAddApproach());
    dispatch(resetLoadingDeleteApproach());
  }, [loadingAddApproach, loadingDeleteApproach]); //eslint-disable-line

  useEffect(() => {
    if ([loadingEditBusStop, loadingDeleteBusStop].includes('loaded')) {
      dispatch(fetchAllBusStops());
    }
    dispatch(resetLoadingEditBusStop());
    dispatch(resetLoadingDeleteBusStop());
  }, [loadingEditBusStop, loadingDeleteBusStop]); //eslint-disable-line

  useEffect(() => {
    if ([loadingAddCorridor, loadingDeleteCorridor].includes('loaded')) {
      dispatch(loadCorridors());
    }
    dispatch(resetLoadingAddCorridor());
    dispatch(resetLoadingDeleteCorridor());
  }, [loadingAddCorridor, loadingDeleteCorridor]); //eslint-disable-line

  useEffect(() => {
    if (!olMap || !osmExport.length) return;
    const edges = [];
    const nodes = [];

    osmExport.forEach((feature) => {
      if (feature.geometry.type === 'LineString') {
        edges.push(feature);
      }
      if (feature.geometry.type === 'Point') {
        nodes.push(feature);
      }
    });

    const edgesLayer = initLayer(
      osmEdgesLayerName,
      { type: 'FeatureCollection', features: edges },
      layerStyles.editGrid.edges,
      osmEdgesZIndex,
    );
    const nodesLayer = initClusterLayer(
      osmNodesLayerName,
      { type: 'FeatureCollection', features: nodes },
      layerStyles.editGrid.nodes,
      osmNodesZIndex,
      40,
    );

    olMap.removeLayer(findLayerByName(osmEdgesLayerName));
    olMap.addLayer(edgesLayer);
    olMap.removeLayer(findLayerByName(osmNodesLayerName));
    olMap.addLayer(nodesLayer);

    setupFeatureSelection();
    centerMap(olMap, edgesLayer);
  }, [olMap, osmExport]); //eslint-disable-line

  useEffect(() => {
    if (!olMap || !segments.length) return;

    olMap.removeLayer(findLayerByName(segmentsLayerName));
    olMap.addLayer(
      initLayer(
        segmentsLayerName,
        { type: 'FeatureCollection', features: segments },
        layerStyles.editGrid.segments,
        segmentsZIndex,
      ),
    );
  }, [olMap, segments]); //eslint-disable-line

  useEffect(() => {
    if (!olMap || !osmBusStops) return;

    const busStopsLayer = findLayerByName(osmBusStopsLayerName);

    let isBusStopsLayerVisible = false;

    if (busStopsLayer) {
      olMap.removeLayer(busStopsLayer);
      isBusStopsLayerVisible = true;
    }

    olMap.removeLayer(findLayerByName(osmBusStopsLayerName));
    const osmBusStopsLayer = initLayer(
      osmBusStopsLayerName,
      osmBusStops,
      layerStyles.corridorView.busStops,
      osmBusStopsZIndex,
      isBusStopsLayerVisible,
    );

    olMap.addLayer(osmBusStopsLayer);
  }, [olMap, osmBusStops]); // eslint-disable-line

  useEffect(() => {
    if (!olMap) return;

    corridorsLayersNames.forEach((name) => {
      olMap.removeLayer(findLayerByName(name));
    });

    const names = corridors.corridorsNames.map(({ corridorName }) => corridorName);

    setCorridorsLayersNames(names);

    names.forEach((name, index) => {
      const layer = initLayer(name, corridors.data[index], layerStyles.editGrid.corridors, corridorsZIndex);

      layer.set('isCorridor', true);
      layer.set('corridorId', corridors.ids[index]);

      olMap.addLayer(layer);
    });
  }, [olMap, corridors]); // eslint-disable-line

  useEffect(() => {
    if (!olMap) return;

    olMap.getViewport().addEventListener('contextmenu', (evt) => {
      evt.preventDefault();

      const coords = toLonLat(olMap.getEventCoordinate(evt));

      setRightClickCoords(coords);
      addSelectedPointLayer(coords);
      setRightClickPosition([evt.clientX, evt.clientY]);

      const rightClickedFeatures = [];

      olMap.forEachFeatureAtPixel([evt.clientX, evt.clientY], (f) => rightClickedFeatures.push(f));
      setRightClickFeatures(rightClickedFeatures);
    });

    initDrawRefs();
  }, [olMap]); //eslint-disable-line

  useEffect(() => {
    if (!startSegmentId) {
      setStartSegmentId(selectedIntersectionId);
    } else if (!endSegmentId) {
      setEndSegmentId(selectedIntersectionId);
      const startIntersectionFeature = findLayerByName(intersectionsLayerName)
        .getSource()
        .getFeatures()
        .find((feature) => feature.get('features')[0].getId() === startSegmentId);

      if (startIntersectionFeature) olSelect.getFeatures().push(startIntersectionFeature);
    } else {
      setStartSegmentId(selectedIntersectionId);
      setEndSegmentId('');
    }
  }, [selectedIntersectionId]); //eslint-disable-line

  useEffect(() => {
    if (loadingAddDetectionArea === 'error') {
      removeIntersectionAreaFeatures();
    }
    dispatch(resetLoadingAddDetectionArea());
  }, [loadingAddDetectionArea]); //eslint-disable-line

  useEffect(() => {
    if (loadingAddGeofenceArea === 'loaded') {
      dispatch(fetchGeofenceArea());
    } else if (loadingAddGeofenceArea === 'error') {
      resetGeofenceAreaLayer();
    }
    dispatch(resetLoadingAddGeofenceArea());
  }, [loadingAddGeofenceArea]); //eslint-disable-line

  useEffect(() => {
    if (!olMap || loadingGeofenceArea !== 'loaded' || !geofenceAreaCoords.length) return;

    resetGeofenceAreaLayer();
  }, [olMap, loadingGeofenceArea]); //eslint-disable-line

  useEffect(() => {
    if (loadingOSMBusStops === 'loaded') {
      dispatch(fetchAllBusStops());
    }
    dispatch(resetLoadingOSMBusStops());
  }, [loadingOSMBusStops]); //eslint-disable-line

  useEffect(() => {
    changeMapSource(isEnglishTileActive ? 2 : 0);

    return () => {
      changeMapSource(0);
    };
  }, [isEnglishTileActive]);

  useEffect(() => {
    if (!olMap) {
      return;
    }

    updateSelectedClusters(selectedOlFeatures.getArray(), (newCluster) => {
      selectedOlFeatures.push(newCluster);
    });

    updateSelectedClusters(rightClickFeatures, (newCluster, featureInCluster) => {
      const updatedRightClickFeatures = rightClickFeatures.map((feature) => {
        if (!feature.get('features') || feature.get('features')[0] !== featureInCluster) {
          return feature;
        }

        return newCluster;
      });

      setRightClickFeatures(updatedRightClickFeatures);
    });
  }, [currentZoom]); //eslint-disable-line

  return (
    <div
      className={`
      ${styles.root}
      ${isLoading ? 'loadingOverlayShown' : ''}
    `}
    >
      <FeatureStatsPopupOverlay />
      <div id="map" className={styles.map} />

      <div className={styles.navigation}>
        <Header icon={<MenuIcon onClick={openNavigation} />} hasText={false} bgColor="transparent" />
      </div>

      <div className={styles.topRight}>
        <div className={styles.box}>
          <p className={styles.tenantName}>{tenantNames[currentTenant]}</p>
          <div className={styles.title}>Map Layers</div>
          <div className={styles.content}>
            <List dense className={styles.List}>
              {activeLayers
                ?.filter((layer) => {
                  const isLayerDetectionArea = layer.get('name') === detectionAreaLayerName;
                  const isLayerSelectedPoint = layer.get('name') === selectedPointLayerName;
                  const isDetectionAreaAvailable = findLayerByName(detectionAreaLayerName).getSource().getFeatures()
                    .length;
                  const isLayerNameCorridor = corridorsLayersNames.includes(layer.get('name'));

                  return (
                    !(isLayerDetectionArea && !isDetectionAreaAvailable) &&
                    !isLayerSelectedPoint &&
                    !isLayerNameCorridor
                  );
                })
                .map((layer, index, layers) => {
                  const renderCorridorsSublist = () => (
                    <ListItem
                      className={`
                        ${styles.listItemWithChildren}
                        ${isCorridorsListVisible ? 'expanded' : 'collapsed'}
                      `}
                      key={layer.get('name')}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={areCorridorsLayersVisible()}
                            color="primary"
                            onClick={toggleCorridorsLayers}
                          />
                        }
                        label={
                          <>
                            Corridors
                            <ArrowLeft />
                          </>
                        }
                        classes={{ label: styles.listItemWithChildrenLabel }}
                        onClick={handleCorridorsListLabelClick}
                      />
                      <Collapse in={isCorridorsListVisible}>
                        <List>
                          {corridorsLayersNames.map((layerName) => {
                            const corridorLayer = findLayerByName(layerName);

                            return (
                              <ListItem key={layerName} className={styles.nestedListItem}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={corridorLayer.getVisible()}
                                      color="primary"
                                      onClick={() => toggleLayer(layerName)}
                                    />
                                  }
                                  label={layerName}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Collapse>
                    </ListItem>
                  );

                  return (
                    <Fragment key={layer.ol_uid}>
                      {layers[index - 1]?.get('name') === geofenceAreaLayerName &&
                        !!corridorsLayersNames.length &&
                        renderCorridorsSublist()}
                      <ListItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={layer.getVisible()}
                              color="primary"
                              onClick={() => toggleLayer(layer.getProperties().name)}
                            />
                          }
                          label={layer.getProperties().name}
                        />
                      </ListItem>
                    </Fragment>
                  );
                })}
            </List>
            <Button variant="outlined" color="primary" onClick={handleHideLayers} className={styles.hideLayersButton}>
              {`${isHideAllActive ? 'Hide' : 'Show'} all layers`}
            </Button>
            <Button variant="outlined" color="primary" onClick={handleSwitchTiles} className={styles.hideLayersButton}>
              {`Switch to ${isEnglishTileActive ? 'OSM' : 'English'} tiles`}
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.bottomLeft}>
        <Fab color="primary" onClick={openFabMenu} ref={fabButtonRef}>
          <SettingsIcon />
        </Fab>

        <Menu anchorEl={fabButtonRef.current} open={isFabMenuVisible} onClose={closeFabMenu}>
          <MenuItem onClick={generateBusStopsHandler}>Generate bus stops from OSM</MenuItem>
          <MenuItem onClick={openEditTimeIntervalsDialog}>Set time intervals</MenuItem>
        </Menu>
      </div>
      <RightClickMenu
        mousePosition={rightClickPosition}
        onClose={removeSelectedPointLayer}
        onAddIntersection={onAddIntersectionClick}
        onDeleteIntersection={getRightClickedFeatureByLayer(intersectionsLayerName) ? onDeleteIntersectionClick : null}
        onAddSegment={onAddSegmentClick}
        onDeleteSegment={getRightClickedFeatureByLayer(segmentsLayerName) ? onDeleteSegmentClick : null}
        onCreateApproach={selectedSegmentsFeatures.length ? onCreateApproachClick : null}
        onDeleteApproach={getRightClickedFeatureByLayer(approachesLayerName) ? onDeleteApproachClick : null}
        onEditBusStop={getRightClickedFeatureByLayer(osmBusStopsLayerName) ? onEditBusStopClick : null}
        onDeleteBusStop={getRightClickedFeatureByLayer(osmBusStopsLayerName) ? onDeleteBusStopClick : null}
        onCreateCorridor={selectedApproachesFeatures.length ? onCreateCorridorClick : null}
        onDeleteCorridor={selectedCorridorId ? onDeleteCorridorClick : null}
        onShowDetectionArea={onShowDetectionAreaClick}
        onAddDetectionArea={onAddDetectionAreaClick}
        onAddGeofenceArea={onAddGeofenceAreaClick}
        isShowDetectionAreaVisible={!!getRightClickedFeatureByLayer(intersectionsLayerName)}
      />
      <AddIntersectionDialog
        isOpen={isAddIntersectionDialogOpen}
        close={onCloseAddIntersectionDialog}
        name={addIntersectionName}
        coords={rightClickCoords}
        isSignalized={addIntersectionSignalized}
      />
      <AddSegmentDialog
        isOpen={isAddSegmentDialogOpen}
        close={closeAddSegmentDialog}
        edges={segmentEdges}
        speed={segmentSpeed}
        startSegmentId={startSegmentId}
        endSegmentId={endSegmentId}
      />
      <CreateApproachDialog
        isOpen={isCreateApproachDialogOpen}
        close={closeCreateApproachDialog}
        segments={selectedSegmentsFeatures}
      />
      <AddDetectionAreaDialog
        isOpen={isAddDetectionAreaDialogOpen}
        close={cancelAddDetectionAreaDialog}
        submit={submitAddDetectionAreaDialog}
        coords={drawnDetectionAreaCoords}
        intersectionId={getRightClickedFeatureByLayer(intersectionsLayerName)?.getProperties().features?.[0].getId()}
      />
      <AddGeofenceAreaDialog
        isOpen={isAddGeofenceAreaDialogOpen}
        close={cancelAddGeofenceAreaDialog}
        submit={submitAddGeofenceAreaDialog}
        coords={drawnGeofenceAreaCoords}
      />
      <EditBusStopDialog
        isOpen={isEditBusStopDialogOpen}
        close={closeEditBusStopDialog}
        busStop={getRightClickedFeatureByLayer(osmBusStopsLayerName)}
      />
      <CreateCorridorDialog
        isOpen={isCreateCorridorDialogOpen}
        close={closeCreateCorridor}
        approaches={selectedApproachesFeatures}
      />
      <DeleteIntersectionDialog
        isOpen={isDeleteIntersectionDialogOpen}
        close={closeDeleteIntersectionDialog}
        intersectionId={getRightClickedFeatureByLayer(intersectionsLayerName)?.getProperties().features?.[0].getId()}
      />
      <DeleteSegmentDialog
        isOpen={isDeleteSegmentDialogOpen}
        close={closeDeleteSegmentDialog}
        segmentId={getRightClickedFeatureByLayer(segmentsLayerName)?.getId()}
      />
      <DeleteApproachDialog
        isOpen={isDeleteApproachDialogOpen}
        close={closeDeleteApproachDialog}
        approachId={getRightClickedFeatureByLayer(approachesLayerName)?.getId()}
      />
      <DeleteBusStopDialog
        isOpen={isDeleteBusStopDialogOpen}
        close={closeDeleteBusStopDialog}
        busStopId={getRightClickedFeatureByLayer(osmBusStopsLayerName)?.getId()}
      />
      <DeleteCorridorDialog
        isOpen={isDeleteCorridorDialogOpen}
        close={closeDeleteCorridor}
        corridorId={selectedCorridorId}
        setSelectedCorridorId={setSelectedCorridorId}
      />
      <EditTimeIntervalsDialog
        isOpen={isEditTimeIntervalsDialogOpen}
        close={cancelEditTimeIntervalsDialog}
        submit={submitEditTimeIntervalsDialog}
      />
    </div>
  );
};
