import { getMeta } from 'utils/getMetaData';

const metaApiBaseUrl = getMeta('backend');
const metaLoginURL = getMeta('login');
const metaLogOutURL = getMeta('logout');

export const API_BASE_URL =
  metaApiBaseUrl && !metaApiBaseUrl.includes('CLUSTER_BACKEND') ? metaApiBaseUrl : process.env.REACT_APP_API_BASE_URL;
export const LOG_IN_URL =
  metaLoginURL && !metaLoginURL.includes('CLUSTER_LOGIN') ? metaLoginURL : `${API_BASE_URL}/oauth2/authorization/azure`;
export const LOG_OUT_URL =
  metaLogOutURL && !metaLogOutURL.includes('CLUSTER_LOGOUT')
    ? metaLogOutURL
    : `https://login.windows.net/common/oauth2/logout?post_logout_redirect_uri=${API_BASE_URL}/logout`;

export const MAPBOX_MATCHING_BASE_URL = 'https://api.mapbox.com/matching/v5/mapbox';
export const FRAMES = 'frames/corridor';
export const APPROACHES = 'approaches';
export const RIDES = 'rides';
export const PRINCIPAL = 'principal';
export const CORRIDORS = 'corridors';
export const INTERSECTIONS = 'intersections';
export const BUS_STOPS = 'busstops';
export const CORRIDOR_STAT = 'corridorstat';
export const APPROACH_STAT = 'approachstat';
export const APPROACH_STAT_DAYS = 'approachstatdays';
export const APPROACH_STAT_SAMPLES = 'approachstatsamples';
export const BUS_STOP_STAT_SAMPLES = 'busstopstatsamples';
export const APPROACHES_STAT = 'approachesstat';
export const PEDESTRIANS_STAT = 'pedestrianstat';
export const PEDESTRIANS_STAT_APPROACHES = 'pedestrianstatapproaches';
export const PHASE_DIAGRAM = 'phasediagram';
export const SIMULATION = 'simulation';
export const SIM = 'sim';
export const STATISTIC_STAT = 'stat';
export const SETTINGS = 'project/settings';
export const STATISTIC_RES = 'res';
export const USER = 'user';
export const INFO = 'info';
export const PROGRAMS = 'programs';
export const TENANTS = 'tenants';
export const ADMIN_TENANT = 'admin/tenant';
export const ADMIN_CAMERA_IDS = 'admin/cameras/ids';
export const ADMIN_RIDES = 'admin/rides';
export const ODMATRIX = 'odmatrix';
export const AGGAPPROACHSTAT = 'aggapproachstat';
export const ACTUATOR_INFO = 'actuator/info';
export const CAMERA_DAYS = 'admin/cameradays';
export const OSMEXPORT = 'admin/mapping/osmexport';
export const ADD_INTERSECTION = 'admin/mapping/addintersection';
export const DELETE_INTERSECTION = '/admin/mapping/intersection';
export const ADD_SEGMENT = 'admin/mapping/addsegment';
export const DELETE_SEGMENT = '/admin/mapping/segment';
export const CREATE_APPROACH = '/admin/mapping/addapproach';
export const DELETE_APPROACH = '/admin/mapping/approach';
export const SEGMENTS = 'admin/mapping/segments';
export const DIRECTIONS = 'admin/mapping/directions';
export const INTERSECTION_AREA = 'admin/mapping/intersectionarea';
export const GEOFENCE_AREA = 'admin/mapping/geofence';
export const OSM_BUS_STOPS = '/admin/mapping/osmbusstops';
export const EDIT_BUS_STOP = '/admin/mapping/editbusstop';
export const DELETE_BUS_STOP = '/admin/mapping/busstop';
export const CREATE_DELETE_CORRIDOR = '/admin/mapping/corridor';
export const TIME_INTERVALS = '/settings/timeintervals';
