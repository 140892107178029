import React from 'react';
import propTypes from 'prop-types';
import { ButtonGroup, Button } from '@material-ui/core';
import classes from './TopTabs.module.scss';

export const TopTabs = ({ tabs, activeTab, setActiveTab }) => {
  if (!tabs || !tabs.length) return null;

  const onTabClick = (tab) => () => {
    setActiveTab(tab.id);
    if (tab.onClick) {
      tab.onClick();
    }
  };

  return (
    <div className={classes.topTabs}>
      <ButtonGroup className={classes.topTabsButtonGroup} size="small">
        {tabs.map((tab) => (
          <Button
            key={tab.id}
            onClick={onTabClick(tab)}
            className={`
              ${classes.topTab}
              ${activeTab === tab.id ? classes.activeTopTab : ''}
            `}
            startIcon={tab.icon}
          >
            {tab.text}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

TopTabs.propTypes = {
  tabs: propTypes.array,
  activeTab: propTypes.string,
  setActiveTab: propTypes.func,
};

TopTabs.defaultProps = {
  tabs: null,
  activeTab: null,
  setActiveTab: null,
};
