import theme from 'theme';
import * as mapboxgl from 'mapbox-gl';
import BusStopIcon from 'assets/images/Bus stop@3x.svg';
import carIcon from 'assets/images/current-frame.svg';
import ArrowDirection from 'assets/images/arrow-direction.svg';

const images = {
  busStop: BusStopIcon,
  carIcon,
  ArrowDirection,
};

const intersectionsPopup = new mapboxgl.Popup({
  closeButton: false,
  anchor: 'left',
  offset: -25,
  className: 'intersectionsPopup',
});

const selectedIntersections = [];

const clearIntersections = (map, setClickedIntersectionsArr) => {
  selectedIntersections.forEach(({ id }) => {
    map.setFeatureState({ source: 'intersections', id }, { selected: false });
  });
  selectedIntersections.length = 0;
  if (setClickedIntersectionsArr) setClickedIntersectionsArr([...selectedIntersections]);
};

export const preselectIntersections = (map, features, setClickedIntersectionsArr) => {
  clearIntersections(map, setClickedIntersectionsArr);
  [features[0], features[features.length - 1]].forEach((feature) => {
    map.setFeatureState({ source: 'intersections', id: feature.id }, { selected: true });
    selectedIntersections.push({
      id: feature.id,
      name: feature.properties.name,
    });
  });
  if (setClickedIntersectionsArr) setClickedIntersectionsArr([...selectedIntersections]);
};

export const initMapboxEvents = (mapboxglRef, setClickedIntersectionsArr, hasInteractions) => {
  mapboxglRef.on('load', ({ target: mapboxTarget }) => {
    Object.entries(images).forEach(([name, src]) => {
      const img = new Image();

      img.src = src;
      img.onload = () => mapboxTarget.addImage(name, img);
    });

    mapboxTarget.addSource('corridors', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    mapboxTarget.addSource('bus-stops', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    mapboxTarget.addSource('intersections', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    mapboxTarget.addSource('total-rides-approaches', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    mapboxTarget.addSource('current-frame', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    mapboxTarget.addSource('segments-direction', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    mapboxTarget.addSource('speed-queue-length', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    mapboxTarget.addLayer({
      id: 'corridors',
      type: 'line',
      source: 'corridors',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': theme.color01,
        'line-width': 4,
        'line-offset': 4,
      },
      filter: ['==', '$type', 'LineString'],
    });

    mapboxTarget.addLayer({
      id: 'segments-direction',
      type: 'symbol',
      source: 'segments-direction',
      layout: {
        'symbol-placement': 'line',
        'icon-image': 'ArrowDirection',
        'icon-anchor': 'top',
        'icon-size': 0.9,
        'symbol-spacing': 5,
      },
      filter: ['==', '$type', 'LineString'],
    });

    mapboxTarget.addLayer({
      id: 'total-rides-approaches',
      source: 'total-rides-approaches',
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': theme.color25,
        'line-width': 4,
      },
      filter: ['==', '$type', 'LineString'],
    });

    mapboxTarget.addLayer({
      id: 'bus-stops',
      source: 'bus-stops',
      type: 'symbol',
      layout: {
        'icon-image': 'busStop',
        'icon-allow-overlap': true,
        'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.4, 15, 1],
      },
      filter: ['==', '$type', 'Point'],
    });

    mapboxTarget.addLayer({
      id: 'intersections',
      source: 'intersections',
      type: 'circle',
      paint: {
        'circle-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          10,
          ['case', ['boolean', ['feature-state', 'selected'], false], 4, 2],
          15,
          ['case', ['boolean', ['feature-state', 'selected'], false], 12, 6],
        ],
        'circle-color': ['case', ['boolean', ['feature-state', 'selected'], false], theme.color16, theme.color01],
        'circle-stroke-width': 1,
        'circle-stroke-color': theme.colorWhite,
      },
    });

    mapboxTarget.addLayer({
      id: 'current-frame',
      source: 'current-frame',
      type: 'circle',
    });

    mapboxTarget.addLayer({
      id: 'speed-queue-length',
      source: 'speed-queue-length',
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': ['case', ['boolean', ['get', 'isCritical']], theme.color06, theme.color07],
        'line-width': 10,
      },
      filter: ['==', '$type', 'LineString'],
    });

    mapboxTarget.addLayer({
      id: 'speed-queue-length-arrows',
      source: 'speed-queue-length',
      type: 'symbol',
      layout: {
        'symbol-placement': 'line',
        'icon-image': 'ArrowDirection',
        'icon-anchor': 'left',
        'icon-size': 0.9,
        'symbol-spacing': 5,
      },
      filter: ['==', '$type', 'LineString'],
    });
  });

  if (!hasInteractions) return;

  ['intersections'].forEach((layerName) => {
    mapboxglRef.on('mousemove', layerName, (e) => {
      if (e.features.length) {
        mapboxglRef.getCanvas().style.cursor = 'pointer'; //eslint-disable-line
      }
    });
    mapboxglRef.on('mouseleave', layerName, () => {
      mapboxglRef.getCanvas().style.cursor = ''; //eslint-disable-line
    });
  });

  mapboxglRef.on('mouseenter', 'intersections', (e) => {
    intersectionsPopup
      .setLngLat({
        lng: e.features[0].geometry.coordinates[0],
        lat: e.features[0].geometry.coordinates[1],
      })
      .setHTML(e.features[0].properties.name)
      .addTo(mapboxglRef);
  });
  mapboxglRef.on('mouseleave', 'intersections', () => {
    intersectionsPopup.remove();
  });

  mapboxglRef.on('click', 'intersections', (e) => {
    const features = mapboxglRef.queryRenderedFeatures(e.point);

    if (!features.length) return;
    const feature = features[0];

    if (selectedIntersections.length === 2) {
      clearIntersections(mapboxglRef, setClickedIntersectionsArr);
    }
    mapboxglRef.setFeatureState({ source: 'intersections', id: feature.id }, { selected: true });
    selectedIntersections.push({
      id: feature.id,
      name: feature.properties.name,
    });

    setClickedIntersectionsArr([...selectedIntersections]);
  });
};
