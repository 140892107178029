const SimulationStatisticsTableStyles = (theme) => ({
  tableRow: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.color03,
    },
  },
  selectedRow: {
    backgroundColor: theme.color03,
  },
  tableContainer: {
    height: 535,
  },
});

export default SimulationStatisticsTableStyles;
