import React from 'react';
import propTypes from 'prop-types';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  VerticalBarSeries,
  GradientDefs,
} from 'react-vis';
import { selectXDomain } from 'features';
import { useSelector } from 'react-redux';
import theme from 'theme';
import styles from './StackedVerticalBarChart.module.scss';

const gradients = (
  <GradientDefs>
    <linearGradient id="gradientA" x1="0" x2="0" y1="0" y2="1">
      <stop offset="0" stopColor={theme.color58} />
      <stop offset="100%" stopColor={theme.color59} />
    </linearGradient>
  </GradientDefs>
);

export const StackedVerticalBarChart = ({ legendText, firstChartData, secondChartData }) => {
  const xDomain = useSelector(selectXDomain);

  if (!firstChartData || !secondChartData) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.chart}>
        <FlexibleWidthXYPlot height={202} width={230} xDomain={xDomain} stackBy="y" margin={{ right: 15 }}>
          {gradients}
          <HorizontalGridLines tickTotal={5} tickValues={[0, 25, 50, 75, 100]} />
          <VerticalGridLines />
          <XAxis className={styles.xAxis} tickFormat={(val) => `${val}:00`} />
          <YAxis
            tickTotal={5}
            tickValues={[0, 25, 50, 75, 100]}
            className={styles.yAxis}
            tickFormat={(val) => `${val}%`}
          />
          <VerticalBarSeries data={firstChartData} fill={theme.color11} stroke={theme.colorWhite} barWidth={0.9} />
          <VerticalBarSeries data={secondChartData} fill={theme.color10} stroke={theme.colorWhite} barWidth={0.9} />
        </FlexibleWidthXYPlot>
        <div className={styles.legend}>
          <div className={styles.legendItem}>{legendText}</div>
        </div>
      </div>
    </div>
  );
};

StackedVerticalBarChart.propTypes = {
  legendText: propTypes.object.isRequired,
  firstChartData: propTypes.array,
  secondChartData: propTypes.array,
};

StackedVerticalBarChart.defaultProps = {
  firstChartData: undefined,
  secondChartData: undefined,
};
