import { createSlice } from '@reduxjs/toolkit';
import { fetchSettings } from 'features/thunks';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    loading: 'idle',
    timeZone: '',
  },
  extraReducers: {
    [fetchSettings.fulfilled]: (state, action) => {
      state.timeZone = action.payload.timeZone;
      state.loading = 'loaded';
    },
  },
});

export const selectTimeZone = ({ settings }) => settings.timeZone;
export const selectSettingsLoaded = ({ settings }) => settings.loading === 'loaded';

export default settingsSlice.reducer;
