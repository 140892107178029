import React from 'react';
import propTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { ReactComponent as Logo } from 'assets/images/Logo@3x.svg';
import { FormattedMessage } from 'react-intl';
import HeaderStyles from './HeaderStyles.styles';
import './Header.scss';

const useStyles = makeStyles(HeaderStyles);

export const Header = ({ color, bgColor, icon, hasText }) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.boxHeader} ${bgColor === 'black' ? 'blackBg' : ''}${
        color === 'white' ? ' whiteColor' : ''
      }`}
    >
      <div className={classes.icon}>{icon}</div>
      <Logo className="axilion-logo" />
      {hasText && (
        <>
          <Typography variant="caption" className={classes.boldTextHeader}>
            <FormattedMessage defaultMessage="MOBILITY." description="Header Mobility" />
          </Typography>
          <Typography variant="caption" className={classes.plainTextHeader}>
            <FormattedMessage defaultMessage="THE AI WAY" description="Header THE AI WAY" />
          </Typography>
        </>
      )}
    </Box>
  );
};

Header.propTypes = {
  color: propTypes.oneOf(['black', 'white']),
  bgColor: propTypes.oneOf(['black', 'transparent']),
  icon: propTypes.node,
  hasText: propTypes.bool,
};

Header.defaultProps = {
  color: 'white',
  bgColor: 'black',
  icon: undefined,
  hasText: true,
};
