import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Image404src from 'assets/images/404.svg';
import styles from './NotFound404Page.module.scss';

export const NotFound404Page = () => (
  <div className={styles.container404}>
    <img alt="404image" src={Image404src} />
    <h3>Page not found</h3>
    <Link to="/home" className={styles.btn}>
      <FormattedMessage defaultMessage="HOME" description="Home Link" />
    </Link>
  </div>
);
