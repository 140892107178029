import React from 'react';
import propTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import styles from './MediaBox.module.scss';

export const MediaBox = ({ children, headerContent, closeHandler, className }) => {
  const onClose = () => {
    if (closeHandler) {
      closeHandler();
    }
  };

  return (
    <div className={`${styles.root} ${className}`} data-testid="box">
      <div className={styles.header}>
        <div className={styles.headerContent}>{headerContent}</div>
        {closeHandler && (
          <button
            type="button"
            className={styles.closeBtn}
            onClick={() => {
              onClose();
            }}
            data-testid="videoClose"
          >
            <Close />
          </button>
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

MediaBox.propTypes = {
  children: propTypes.node,
  headerContent: propTypes.node,
  closeHandler: propTypes.func,
  className: propTypes.string,
};

MediaBox.defaultProps = {
  children: null,
  headerContent: null,
  closeHandler: null,
  className: null,
};
