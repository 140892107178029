import React from 'react';
import propTypes from 'prop-types';
import { useIntl, useFormat } from 'utils/hooks';
import styles from './CameraDetails.module.scss';

export const CameraDetails = ({ data }) => {
  const intl = useIntl();
  const { fromUtcToProjectTime } = useFormat();

  return (
    <div className={styles.root}>
      <p>
        {`${intl.formatMessage({
          defaultMessage: 'Camera',
          description: 'Sidebar panel Camera',
        })}: ${data.videoCameraId}`}
      </p>
      <p>
        {`${intl.formatMessage({
          defaultMessage: 'Start',
          description: 'Sidebar panel Camera',
        })}: ${fromUtcToProjectTime(data.videoStartTime, 'ISO_NO_MS').replace('T', ' ')}`}
      </p>
      <p>
        {`${intl.formatMessage({
          defaultMessage: 'End',
          description: 'Sidebar panel Camera',
        })}: ${fromUtcToProjectTime(data.videoEndTime, 'ISO_NO_MS').replace('T', ' ')}`}
      </p>
    </div>
  );
};

CameraDetails.propTypes = {
  data: propTypes.object.isRequired,
};
