import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { selectTimeZone } from 'features';

const data = {};

const format = (dateArg, pattern) => {
  const dt = DateTime.fromJSDate(new Date(dateArg));

  switch (pattern) {
    case 'ISO':
      return dt.toISO({ includeOffset: false });
    case 'ISO_DATE':
      return dt.toISODate();
    case 'ISO_TIME':
      return dt.toISOTime({ includeOffset: false });
    case 'ISO_NO_MS':
      return dt.toISO({ suppressMilliseconds: true, includeOffset: false });

    default:
      return dt.toLocaleString(DateTime[pattern]); // https://moment.github.io/luxon/docs/manual/formatting#presets
  }
};

const convert = ({ from, to, dateArg, pattern }) => {
  let date = dateArg;

  if (typeof dateArg === 'string') {
    date = dateArg.replace(' ', 'T');
  }

  date = new Date(date);

  const dt = DateTime.fromJSDate(date)
    .setZone(from, { keepLocalTime: true })
    .setZone(to)
    .setZone('local', { keepLocalTime: true });

  return pattern ? format(dt.toJSDate(), pattern) : dt;
};

const fromUtcToProjectTime = (dateArg, pattern) =>
  convert({
    from: 'utc',
    to: data.projectTimeZone,
    dateArg,
    pattern,
  });

const fromProjectTimeToUtc = (dateArg, pattern) =>
  convert({
    from: data.projectTimeZone,
    to: 'utc',
    dateArg,
    pattern,
  });

const fromClientToProjectTime = (dateArg, pattern) =>
  convert({
    from: 'local',
    to: data.projectTimeZone,
    dateArg,
    pattern,
  });

export const useFormat = () => {
  data.projectTimeZone = useSelector(selectTimeZone);

  return {
    format,
    fromUtcToProjectTime,
    fromProjectTimeToUtc,
    fromClientToProjectTime,
  };
};
