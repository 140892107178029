const UserMenuStyles = (theme) => ({
  userProfilePicture: {
    cursor: 'pointer',
    borderRadius: '50%',
    boxShadow: `0px 0px 5px 0px ${theme.color109}`,
    width: 40,
    height: 40,
  },
  userProfilePictureInner: {
    width: 80,
    height: 80,
  },
  profileInfoBox: {
    position: 'absolute',
    top: 51,
    zIndex: 9999,
    borderRadius: 9,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 300,
    padding: 20,
    backgroundColor: theme.colorWhite,
    boxShadow: `0px 0px 5px 0px ${theme.color109}`,
    transition: 'right .35s ease-in-out',
    '&.top-right': {
      right: 0,
    },
  },
  fullName: {
    marginTop: 18,
    marginBottom: 18,
    fontWeight: 500,
    color: theme.color112,
  },
  signOutButton: {
    fontSize: 14,
    fontWeight: 400,
    padding: '11px 60px',
    borderRadius: 30,
    color: theme.color112,
  },
  closeIcon: {
    cursor: 'pointer',
    marginLeft: 'auto',
    color: theme.color01,
  },
});

export default UserMenuStyles;
