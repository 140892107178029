/* eslint-disable newline-after-var */
import { Stroke, Style, Fill, Circle, Text } from 'ol/style';
// import * as turf from '@turf/turf';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
// import { intersect } from 'mathjs';
import theme from 'theme';
import { metricTypes } from 'consts';
import { findFeatureColor } from 'utils/findFeatureColor';
import { findFeatureIcon } from 'utils/findFeatureIcon';
import ArrowDirection from 'assets/images/arrow-direction.svg';
import BusStopIcon from 'assets/images/Bus stop@3x.svg';

const styleCache = {
  nodesCluster: {},
  intersectionsCluster: {},
};

const pinStyle = (icon, resolution) => {
  let scale;
  if (resolution < 1) scale = 1.3;
  else if (resolution < 2) scale = 1;
  else if (resolution < 5) scale = 0.8;
  else if (resolution < 7) scale = 0.6;
  else scale = 0.7;

  return new Style({
    image: new Icon({
      src: icon,
      anchor: [0.5, 0.5],
      rotateWithView: true,
      scale,
    }),
  });
};

const calculateSplitPointCoords = (startNode, nextNode, distanceBetweenNodes, distanceToSplitPoint) => {
  const d = distanceToSplitPoint / distanceBetweenNodes;
  const x = nextNode[0] + (startNode[0] - nextNode[0]) * d;
  const y = nextNode[1] + (startNode[1] - nextNode[1]) * d;

  return [x, y];
};

const calculatePointsDistance = (coordinateA, coordinateB) => {
  const dx = coordinateA[0] - coordinateB[0];
  const dy = coordinateA[1] - coordinateB[1];

  return Math.sqrt(dx * dx + dy * dy);
};

const splitLineString = (geometry, n) => {
  const splitPoints = new Array(n);
  const coords = geometry.getCoordinates();
  const segmentLength = geometry.getLength() / n + 1;

  let coordIndex = 0;
  let startPoint = coords[coordIndex];
  let nextPoint = coords[coordIndex + 1];
  let currentSegmentLength = 0;

  for (let i = 0; i <= n; i += 1) {
    const distanceBetweenPoints = calculatePointsDistance(startPoint, nextPoint);

    currentSegmentLength += distanceBetweenPoints;

    if (currentSegmentLength < segmentLength) {
      coordIndex += 1;
      if (coordIndex < coords.length - 1) {
        startPoint = coords[coordIndex];
        nextPoint = coords[coordIndex + 1];
        i -= 1;
      } else {
        break;
      }
    } else {
      const distanceToSplitPoint = currentSegmentLength - segmentLength;
      const splitPointCoords = calculateSplitPointCoords(
        startPoint,
        nextPoint,
        distanceBetweenPoints,
        distanceToSplitPoint,
      );
      const splitPoint = new Point(splitPointCoords);

      splitPoints.push(splitPoint);
      startPoint = splitPoint.getCoordinates();
      currentSegmentLength = 0;
    }
  }

  return splitPoints;
};

const addArrowsToFeature = (styles, feature, splitsDividend = 250, anchor = [0.5, 0.5]) => {
  const geometry = feature.getGeometry();
  if (geometry.getType() === 'LineString') {
    const numberOfSplits = Math.ceil(geometry.getLength() / splitsDividend);
    const splitLine = splitLineString(geometry, numberOfSplits);
    const start = geometry.getSimplifiedGeometry().getCoordinates()[0];
    const end = geometry.getSimplifiedGeometry().getCoordinates()[1];

    splitLine.forEach((point) => {
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);

      styles.push(
        new Style({
          geometry: new Point(point.getCoordinates()),
          image: new Icon({
            src: ArrowDirection,
            anchor,
            rotateWithView: true,
            rotation: -rotation,
            scale: 0.7,
          }),
        }),
      );
    });
  }

  return styles;
};

const setUpTwoWayDirections = (feature, width, resolutionWidth, resolution, colors, isDotted, strokeOptions) => {
  const styles = [];

  colors.forEach((color, index) => {
    const dist = resolutionWidth * resolution * (index - (colors.length - 1) / 2);
    const geom = feature.getGeometry();
    const coords = [];

    // let counter = 0;
    geom.forEachSegment((from, to) => {
      const angle = Math.atan2(to[1] - from[1], to[0] - from[0]);
      const newFrom = [Math.sin(angle) * dist + from[0], -Math.cos(angle) * dist + from[1]];
      const newTo = [Math.sin(angle) * dist + to[0], -Math.cos(angle) * dist + to[1]];

      coords.push(newFrom);
      coords.push(newTo);

      // if (coords.length > 2) {
      //   const intersection = intersect(coords[counter], coords[counter + 1], coords[counter + 2], coords[counter + 3]);
      //   coords[counter + 1] = (intersection) || coords[counter + 1];
      //   coords[counter + 2] = (intersection) || coords[counter + 2];
      //   counter += 2;
      // }
    });

    styles.push(
      new Style({
        geometry: new LineString(coords),
        stroke: new Stroke({
          color,
          width,
          lineDash: isDotted ? [1, 7] : undefined,
          ...strokeOptions,
        }),
      }),
    );
  });

  return styles;
};

const approach = (splitsDividend) => (isCritical) => (feature) => {
  const styles = [
    new Style({
      stroke: new Stroke({
        color: isCritical ? theme.color06 : theme.color07,
        width: 14,
      }),
    }),
  ];

  return addArrowsToFeature(styles, feature, splitsDividend);
};

const newStroke = (color, width) =>
  new Style({
    stroke: new Stroke({
      color,
      width,
    }),
  });

const createCircle = (color, sizeMultiplier, resolution) => {
  let radius = 4 * sizeMultiplier;

  if (resolution <= 1) radius = 12 * sizeMultiplier;
  else if (resolution <= 3) radius = 9 * sizeMultiplier;
  else if (resolution <= 5) radius = 7 * sizeMultiplier;
  else if (resolution <= 7) radius = 5 * sizeMultiplier;

  return new Style({
    image: new Circle({
      radius,
      fill: new Fill({ color }),
      stroke: new Stroke({ color: theme.colorWhite, width: 2 }),
    }),
  });
};

const createClusterCircle = (size, circleFillColor, circleBorderColor, textColor, isSelected) =>
  new Style({
    image: new Circle({
      radius: isSelected ? 14 : 9,
      stroke: new Stroke({ color: circleBorderColor }),
      fill: new Fill({ color: circleFillColor }),
    }),
    text: new Text({
      text: size.toString(),
      fill: new Fill({ color: textColor }),
    }),
  });

const createSegment = (feature, color, width, hasArrows) => {
  const styles = [newStroke(color, width)];

  return hasArrows ? addArrowsToFeature(styles, feature) : styles;
};

const createText = (text) =>
  new Style({
    text: new Text({
      text,
      font: 'bold 20px Roboto',
      fill: new Fill({ color: theme.colorWhite }),
      stroke: new Stroke({
        color: theme.colorBlack,
        width: 2,
      }),
    }),
  });

const transparentOffset = [theme.colorTransparent, theme.colorTransparent];
const colorsBlue = [...transparentOffset, theme.color01];
const colorsWhite = [...transparentOffset, theme.colorWhite];
const colorsBlack = [...transparentOffset, theme.colorBlack];
const colorsGrey = [...transparentOffset, theme.color47];
let resolutionWidth = 5;

/**
 * @description object which holds the styles of each layer
 */
export const layersStyles = {
  corridorView: {
    corridor: (feature, resolution) => {
      const colors = [...transparentOffset, theme.color01];
      resolutionWidth = 3;
      const width = 2;

      return setUpTwoWayDirections(feature, width, resolutionWidth, resolution, colors);
    },
    selectedCorridor: (feature, resolution) => {
      const colors = [...transparentOffset, theme.color01];
      resolutionWidth = 5;
      const width = 5;

      return setUpTwoWayDirections(feature, width, resolutionWidth, resolution, colors);
    },
    corridorSegments: (feature) => createSegment(feature, theme.color01, 5, true),
    ridesSegments: (feature) => {
      const geometry = feature.getGeometry();
      const simplifiedGeometry = geometry.getSimplifiedGeometry().getCoordinates();
      const styles = [newStroke(theme.color25, 5)];

      simplifiedGeometry.forEach((cords) => {
        styles.push(
          new Style({
            geometry: new Point(cords),
            image: new Circle({
              radius: 4,
              fill: new Fill({ color: theme.color01 }),
              stroke: new Stroke({ color: theme.colorWhite, width: 1 }),
            }),
          }),
        );
      });

      return styles;
    },
    intersections: (feature, resolution) => createCircle(theme.color01, 1, resolution),
    intersectionsActive: (feature, resolution) => createCircle(theme.color16, 2, resolution),
    busStops: (feature, resolution) => {
      let scale = 0.5;

      if (resolution < 10) {
        scale = 0.7;
      }
      if (resolution < 7) {
        scale = 0.6;
      }
      if (resolution < 5) {
        scale = 0.8;
      }
      if (resolution < 2) {
        scale = 1;
      }

      return new Style({
        image: new Icon({
          src: BusStopIcon,
          anchor: [0.5, 0.5],
          rotateWithView: true,
          scale,
        }),
      });
    },
    busStopsSelected: (feature, resolution) => {
      let scale = 0.9;

      if (resolution < 10) {
        scale = 1.1;
      }
      if (resolution < 7) {
        scale = 1;
      }
      if (resolution < 5) {
        scale = 1.2;
      }
      if (resolution < 2) {
        scale = 1.4;
      }

      return new Style({
        image: new Icon({
          src: BusStopIcon,
          anchor: [0.5, 0.5],
          rotateWithView: true,
          scale,
        }),
      });
    },
    speed: approach(100),
    'queue-length': approach(10),
  },
  generalViewStats: {
    [metricTypes.PEDESTRIANS_SIGNAL_PROGRESSION]: (feature, resolution) => {
      return pinStyle(
        findFeatureIcon({
          metricType: metricTypes.PEDESTRIANS_SIGNAL_PROGRESSION,
          featureProperties: feature.getProperties(),
          isSelected: feature.get('isClicked'),
        }),
        resolution,
      );
    },
    [metricTypes.RED_LIGHT]: (feature, resolution) => {
      return pinStyle(
        findFeatureIcon({
          metricType: metricTypes.RED_LIGHT,
          featureProperties: feature.getProperties(),
          isSelected: feature.get('isClicked'),
        }),
        resolution,
      );
    },
    [metricTypes.JAYWALKERS]: (feature, resolution) => {
      const isHovered = feature.get('isHovered');
      const isSelected = feature.get('isClicked');
      const styles = [];
      const colorGroupId = feature.get('colorGroupId');
      let featureColor = theme.color55;
      if (colorGroupId === 3) featureColor = theme.color56;
      if (colorGroupId === 2) featureColor = theme.color57;
      if (colorGroupId === 1) featureColor = theme.color06;

      const colors = [...transparentOffset, featureColor];
      const width = resolution >= 2 ? 4 : 6;
      const outlineWidth = resolution >= 2 ? 7 : 10;

      if (isHovered || isSelected) {
        styles.push(
          ...setUpTwoWayDirections(feature, outlineWidth + 5, resolutionWidth, resolution, colorsBlue),
          ...setUpTwoWayDirections(feature, outlineWidth, resolutionWidth, resolution, colorsWhite),
        );
      } else {
        styles.push(...setUpTwoWayDirections(feature, outlineWidth + 3, resolutionWidth, resolution, colorsBlack));
        styles.push(...setUpTwoWayDirections(feature, outlineWidth, resolutionWidth, resolution, colorsGrey));
      }
      styles.push(
        ...setUpTwoWayDirections(feature, width, resolutionWidth, resolution, colors, false, {
          lineDash: [2, 3],
          lineCap: 'butt',
        }),
      );

      return styles;
    },
    [metricTypes.TOTAL_DENSITY]: (feature, resolution) => {
      return pinStyle(
        findFeatureIcon({
          metricType: metricTypes.TOTAL_DENSITY,
          featureProperties: feature.getProperties(),
          isSelected: feature.get('isClicked'),
        }),
        resolution,
      );
    },
    [metricTypes.ACTIVE_CROSSWALK]: (feature, resolution) => {
      return pinStyle(
        findFeatureIcon({
          metricType: metricTypes.ACTIVE_CROSSWALK,
          featureProperties: feature.getProperties(),
          isSelected: feature.get('isClicked'),
        }),
        resolution,
      );
    },
    [metricTypes.PEDESTRIAN_DENSITY]: (feature, resolution) => {
      return pinStyle(
        findFeatureIcon({
          metricType: metricTypes.PEDESTRIAN_DENSITY,
          featureProperties: feature.getProperties(),
          isSelected: feature.get('isClicked'),
        }),
        resolution,
      );
    },

    [metricTypes.SCOOTER_DENSITY]: (feature, resolution) => {
      return pinStyle(
        findFeatureIcon({
          metricType: metricTypes.SCOOTER_DENSITY,
          featureProperties: feature.getProperties(),
          isSelected: feature.get('isClicked'),
        }),
        resolution,
      );
    },

    [metricTypes.CYCLIST_DENSITY]: (feature, resolution) => {
      return pinStyle(
        findFeatureIcon({
          metricType: metricTypes.CYCLIST_DENSITY,
          featureProperties: feature.getProperties(),
          isSelected: feature.get('isClicked'),
        }),
        resolution,
      );
    },
    [metricTypes.FACE_MASK_USAGE]: (feature, resolution) => {
      return pinStyle(
        findFeatureIcon({
          metricType: metricTypes.FACE_MASK_USAGE,
          featureProperties: feature.getProperties(),
          isSelected: feature.get('isClicked'),
        }),
        resolution,
      );
    },

    [metricTypes.QUEUE_LENGTH]: (feature, resolution) => {
      const isHovered = feature.get('isHovered');
      const isSelected = feature.get('isClicked');
      const color = findFeatureColor({
        featureProperties: feature.getProperties(),
        metricType: metricTypes.QUEUE_LENGTH,
      });
      const styles = [];
      const colors = [...transparentOffset, color];
      const width = resolution >= 2 ? 4 : 6;
      const outlineWidth = resolution >= 2 ? 7 : 10;

      if (resolution >= 2) {
        styles.push(newStroke(theme.colorBlack, outlineWidth));
        if (isHovered || isSelected) {
          styles.push(newStroke(theme.color01, outlineWidth + 5));
          styles.push(newStroke(theme.colorWhite, outlineWidth));
        }

        styles.push(newStroke(color, width));

        return styles;
      }

      if (isHovered || isSelected) {
        styles.push(...setUpTwoWayDirections(feature, outlineWidth + 5, resolutionWidth, resolution, colorsBlue));
        styles.push(...setUpTwoWayDirections(feature, outlineWidth, resolutionWidth, resolution, colorsWhite));
      } else {
        styles.push(...setUpTwoWayDirections(feature, outlineWidth, resolutionWidth, resolution, colorsBlack));
      }
      styles.push(...setUpTwoWayDirections(feature, width, resolutionWidth, resolution, colors));

      return addArrowsToFeature(styles, feature, 10, [0.5, -0.15]);
    },
    [metricTypes.NUMBER_OF_STOPS]: (feature, resolution) => {
      const isHovered = feature.get('isHovered');
      const isSelected = feature.get('isClicked');
      const styles = [];
      const color = findFeatureColor({
        featureProperties: feature.getProperties(),
        metricType: metricTypes.NUMBER_OF_STOPS,
      });
      const colors = [...transparentOffset, color];
      const width = resolution >= 2 ? 4 : 6;
      const outlineWidth = resolution >= 2 ? 7 : 10;

      if (isHovered || isSelected) {
        styles.push(...setUpTwoWayDirections(feature, outlineWidth + 5, resolutionWidth, resolution, colorsBlue));
        styles.push(...setUpTwoWayDirections(feature, outlineWidth, resolutionWidth, resolution, colorsWhite));
      } else {
        styles.push(...setUpTwoWayDirections(feature, outlineWidth, resolutionWidth, resolution, colorsBlack));
      }

      styles.push(...setUpTwoWayDirections(feature, width, resolutionWidth, resolution, colors));

      return styles;
    },
    [metricTypes.WAITING_TIME]: (feature, resolution) => {
      const isHovered = feature.get('isHovered');
      const isSelected = feature.get('isClicked');
      const styles = [];
      const color = findFeatureColor({
        featureProperties: feature.getProperties(),
        metricType: metricTypes.WAITING_TIME,
      });
      const colors = [...transparentOffset, color];
      const width = resolution >= 2 ? 4 : 6;
      const outlineWidth = resolution >= 2 ? 7 : 10;

      if (isHovered || isSelected) {
        styles.push(
          ...setUpTwoWayDirections(feature, outlineWidth + 5, resolutionWidth, resolution, colorsBlue),
          ...setUpTwoWayDirections(feature, outlineWidth, resolutionWidth, resolution, colorsWhite),
        );
      } else {
        styles.push(...setUpTwoWayDirections(feature, outlineWidth + 3, resolutionWidth, resolution, colorsBlack));
        styles.push(...setUpTwoWayDirections(feature, outlineWidth, resolutionWidth, resolution, colorsGrey));
      }
      styles.push(...setUpTwoWayDirections(feature, width, resolutionWidth, resolution, colors, true));

      return styles;
    },
    [metricTypes.DWELL_TIME]: (feature, resolution) => {
      return pinStyle(
        findFeatureIcon({
          metricType: metricTypes.DWELL_TIME,
          featureProperties: feature.getProperties(),
          isSelected: feature.get('isClicked'),
        }),
        resolution,
      );
    },
    [metricTypes.PASSENGERS_DENSITY]: (feature, resolution) => {
      return pinStyle(
        findFeatureIcon({
          metricType: metricTypes.PASSENGERS_DENSITY,
          featureProperties: feature.getProperties(),
          isSelected: feature.get('isClicked'),
        }),
        resolution,
      );
    },
    [metricTypes.SPEED]: (feature, resolution) => {
      const color = findFeatureColor({
        featureProperties: feature.getProperties(),
        metricType: metricTypes.SPEED,
      });
      const isHovered = feature.get('isHovered');
      const isSelected = feature.get('isClicked');
      const styles = [];
      const colors = [...transparentOffset, color];
      const width = resolution >= 2 ? 4 : 6;
      const outlineWidth = resolution >= 2 ? 7 : 10;

      if (isHovered || isSelected) {
        styles.push(...setUpTwoWayDirections(feature, outlineWidth + 5, resolutionWidth, resolution, colorsBlue));
        styles.push(...setUpTwoWayDirections(feature, outlineWidth, resolutionWidth, resolution, colorsWhite));
      } else {
        styles.push(...setUpTwoWayDirections(feature, outlineWidth, resolutionWidth, resolution, colorsBlack));
      }
      styles.push(...setUpTwoWayDirections(feature, width, resolutionWidth, resolution, colors));

      return styles;
    },
    [metricTypes.AIR_QUALITY]: (feature, resolution) => {
      return pinStyle(
        findFeatureIcon({
          metricType: metricTypes.AIR_QUALITY,
          featureProperties: feature.getProperties(),
          isSelected: feature.get('isClicked'),
        }),
        resolution,
      );
    },
  },
  adminView: {
    framesPathSegments: (feature) => createSegment(feature, theme.color12, 3, false),
    currentFrame: (feature, resolution) => createCircle(theme.color36, 1, resolution),
    selectedPoint: (feature, resolution) => createCircle(theme.color18, 0.5, resolution),
    totalRidesSegments: (feature) => {
      const isHovered = feature.get('isHovered');
      const isSelected = feature.get('isClicked');

      return isHovered || isSelected
        ? createSegment(feature, theme.color01, 7, true)
        : createSegment(feature, theme.color07, 5, true);
    },
    startText: () => createText('Start'),
    endText: () => createText('End'),
  },

  editGrid: {
    edges: (feature, resolution) => {
      if (resolution < 1) {
        return setUpTwoWayDirections(feature, 2, 3, resolution, [...transparentOffset, theme.color05]);
      }

      return createSegment(feature, theme.color05, 1, false);
    },

    segments: (feature, resolution) => {
      if (resolution < 1) {
        return setUpTwoWayDirections(feature, 2, 3, resolution, [...transparentOffset, theme.color07]);
      }

      return createSegment(feature, theme.color07, 2, false);
    },

    corridors: (feature, resolution) => {
      const isSelected = feature.get('isSelected');
      const colors = [...transparentOffset];
      colors.push(isSelected ? theme.color01 : theme.color62);
      resolutionWidth = 3;
      const width = 3;

      return setUpTwoWayDirections(feature, width, resolutionWidth, resolution, colors);
    },

    nodes: (feature) => {
      const size = feature.get('features').length;
      let style = styleCache.nodesCluster[size];
      if (!style) {
        style = createClusterCircle(size, theme.color05, theme.colorWhite, theme.colorWhite);
        styleCache.nodesCluster[size] = style;
      }

      return style;
    },

    intersections: (feature) => {
      const size = feature.get('features').length;
      const isSelected = feature.get('features').find((f) => f.get('isSelected'));
      const isHovered = feature.get('isHovered');

      let style = styleCache.intersectionsCluster[size];

      const styleParams = [size, theme.color01, theme.colorWhite, theme.colorWhite];

      if (!style) {
        style = createClusterCircle(...styleParams);
        styleCache.intersectionsCluster[size] = style;
      }

      return isSelected || isHovered ? createClusterCircle(...styleParams, true) : style;
    },

    pointActive: (feature, resolution) => createCircle(theme.color07, 1, resolution),

    lineActive: (feature, resolution) => {
      const colors = [...transparentOffset, theme.color01];
      resolutionWidth = 3;
      const width = 2;

      return [
        ...setUpTwoWayDirections(feature, width + 3, resolutionWidth, resolution, colorsBlack),
        ...setUpTwoWayDirections(feature, width, resolutionWidth, resolution, colors),
      ];
    },

    geofence: () =>
      new Style({
        stroke: new Stroke({
          color: theme.color01,
          width: 3,
          lineDash: [5, 10],
        }),
        fill: new Fill({
          color: theme.color119,
        }),
      }),
  },
};

export default layersStyles;
