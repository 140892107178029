export const metricTypes = {
  PEDESTRIANS_SIGNAL_PROGRESSION: 'redLight',
  RED_LIGHT: 'pedestrianSignalProgression',
  JAYWALKERS: 'jaywalkers',
  ACTIVE_CROSSWALK: 'activeCrosswalk',
  TOTAL_DENSITY: 'totalDensity',
  PEDESTRIAN_DENSITY: 'pedestrianDensity',
  SCOOTER_DENSITY: 'scooterDensity',
  CYCLIST_DENSITY: 'cyclistDensity',
  FACE_MASK_USAGE: 'faceMaskUsage',
  QUEUE_LENGTH: 'queueLength',
  NUMBER_OF_STOPS: 'numberOfStops',
  WAITING_TIME: 'waitingTime',
  DWELL_TIME: 'dwellTime',
  SPEED: 'speed',
  PASSENGERS_DENSITY: 'density',
  AIR_QUALITY: 'airQuality',
};

export const pedestriansMetricTypes = [
  metricTypes.PEDESTRIANS_SIGNAL_PROGRESSION,
  metricTypes.RED_LIGHT,
  metricTypes.JAYWALKERS,
  metricTypes.ACTIVE_CROSSWALK,
  metricTypes.TOTAL_DENSITY,
  metricTypes.PEDESTRIAN_DENSITY,
  metricTypes.SCOOTER_DENSITY,
  metricTypes.CYCLIST_DENSITY,
  metricTypes.FACE_MASK_USAGE,
];
