import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPrincipal, getUserInfo, setTenant, getTenants } from 'api/endpoints';

export const fetchUser = createAsyncThunk('user/fetchUser', async (_, thunkAPI) => {
  const principalRes = await getPrincipal();
  const userInfoRes = await getUserInfo();

  if (principalRes.status && principalRes.status !== 200) {
    return thunkAPI.rejectWithValue();
  }

  if (userInfoRes.status && userInfoRes.status !== 200) {
    return thunkAPI.rejectWithValue();
  }

  return {
    ...principalRes.data,
    ...userInfoRes.data,
  };
});

export const setActiveTenant = createAsyncThunk('user/setActiveTenant', async (tenantId, thunkAPI) => {
  const setTenantRes = await setTenant(tenantId);

  if (setTenantRes.status && setTenantRes.status !== 200) {
    return thunkAPI.rejectWithValue();
  }

  return setTenantRes.data;
});

export const fetchTenants = createAsyncThunk('user/fetchTenants', async (_, thunkAPI) => {
  const tenantsRes = await getTenants();

  if (tenantsRes.status && tenantsRes.status !== 200) {
    return thunkAPI.rejectWithValue();
  }

  return tenantsRes.data;
});
