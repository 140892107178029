import React, { useLayoutEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { initChord } from './initChord';
import './ChordDiagram.scss';

export const ChordDiagram = ({ odLabels, odMatrix }) => {
  const containerRef = useRef();

  useLayoutEffect(() => {
    if (!odLabels.length || !odMatrix.length) {
      return;
    }
    initChord(containerRef.current, odLabels, odMatrix);
  }, [odLabels, odMatrix]);

  return (
    <div ref={containerRef} className="chordContainer">
      <div className="chordTooltip" />
    </div>
  );
};

ChordDiagram.propTypes = {
  odLabels: propTypes.array.isRequired,
  odMatrix: propTypes.array.isRequired,
};
