import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { selectUnitSystemName, setUnitSystem } from 'features';
import { useIntl } from 'utils/hooks';
import UnitSystemStyles from './UnitSystemSelect.styles';

const useStyles = makeStyles(UnitSystemStyles);

export const UnitSystemSelect = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const unitSystemName = useSelector(selectUnitSystemName);

  const onUnitChangeHandler = (event) => {
    dispatch(setUnitSystem({ intl, unitSystem: event.target.value }));
  };

  return (
    <FormControl size="small" variant="outlined">
      <InputLabel>
        <FormattedMessage defaultMessage="Unit system" description="Unit system title" />
      </InputLabel>
      <Select
        className={classes.unitSystemSelect}
        variant="outlined"
        MenuProps={{ className: classes.unitSystemSelectDropdown }}
        value={unitSystemName}
        onChange={onUnitChangeHandler}
        label="Unit system"
      >
        <MenuItem value="metric">
          <FormattedMessage defaultMessage="Metric" description="Unit system" />
        </MenuItem>
        <MenuItem value="imperial">
          <FormattedMessage defaultMessage="Imperial" description="Unit system" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};
