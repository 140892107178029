const TotalRides = (theme) => ({
  totalRidesButtonsContainer: {
    '&.isComparing': {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
  searchButton: {
    margin: '20px 0',
    padding: '10px 0',
    width: '100%',
    borderRadius: 0,
    backgroundColor: theme.color03,
    color: theme.color01,
    textTransform: 'none',

    '&:hover': {
      backgroundColor: theme.color13,
    },
  },
  totalRidesButton: {
    '&.isComparing': {
      width: 'calc(50% - 10px)',
      position: 'relative',
      borderRadius: '5px',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        width: 4,
        background: theme.color10,
        borderRadius: '5px 0 0 5px',
      },
    },
    '&.totalRidesButtonSecond': {
      '&::before': {
        background: `${theme.color11} !important`,
      },
    },
  },
  videoCamIconBlue: {
    width: 20,
    height: 20,
  },
});

export default TotalRides;
