import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectIsSidebarVisible,
  loadCorridors,
  selectUser,
  selectCorridors,
  fetchIntersectionsByCorridor,
} from 'features';
import { SelectCorridors, UserMenu, SidebarContainer, DigitalTwin, SimulationContainer } from 'components';
import { MapboxMap } from '../TrafficInsights/MapboxMap/MapboxMap';
import styles from './SimulationView.module.scss';

export const SimulationView = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const isSidebarVisible = useSelector(selectIsSidebarVisible);
  const user = useSelector(selectUser);
  const corridors = useSelector(selectCorridors);
  /* ---------------------------------- State --------------------------------- */
  const [clickedIntersectionsArr, setClickedIntersectionsArr] = useState([]);

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    if (user.isLoggedIn) {
      dispatch(loadCorridors());
    }
  }, [user.isLoggedIn]); //eslint-disable-line

  useEffect(() => {
    if (corridors.loading === 'loaded') {
      dispatch(fetchIntersectionsByCorridor(corridors.ids));
    }
  }, [corridors.loading]); // eslint-disable-line

  return (
    <div className={`${styles.SimulationViewContainer} ${isSidebarVisible ? '' : styles.sidebarHidden}`}>
      <MapboxMap hasInteractions={false} setClickedIntersectionsArr={setClickedIntersectionsArr} />
      <SidebarContainer hasCollapseButton>
        <DigitalTwin
          selectionData={{
            start: clickedIntersectionsArr.length > 0 && clickedIntersectionsArr[0].name,
            end: clickedIntersectionsArr.length > 1 && clickedIntersectionsArr[1].name,
            corridor: corridors.corridorsNames[corridors.selectedCorridorIndex],
          }}
        />
      </SidebarContainer>
      <div className={styles.topLeftArea}>
        <UserMenu />
      </div>
      <div className={styles.topRightArea}>
        <SelectCorridors />
      </div>
      <SimulationContainer />
    </div>
  );
};
