import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TenantSelect as TSelect } from '@axilion/ui-components';
import { useHistory } from 'react-router-dom';
import { setActiveTenant, fetchTenants, selectTenants, selectLoadingActiveTenant, selectTenantsIds } from 'features';
import styles from './TenantSelect.module.scss';

export const TenantSelect = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();
  const history = useHistory();

  /* -------------------------------- Selectors ------------------------------- */
  const tenantIds = useSelector(selectTenantsIds) || [];
  const { currentTenant } = useSelector(selectTenants);
  const loadingActiveTenant = useSelector(selectLoadingActiveTenant);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const onSelect = (id) => {
    dispatch(setActiveTenant(id));
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    dispatch(fetchTenants());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (loadingActiveTenant === 'loaded') {
      history.push('home');
      window.location.reload();
    }
  }, [loadingActiveTenant]); //eslint-disable-line

  return (
    <TSelect
      tenantIds={tenantIds}
      currentTenantId={currentTenant}
      onSelect={onSelect}
      formControlProps={{ className: styles.formControl }}
      selectProps={{
        classes: { selectMenu: styles.select },
        MenuProps: { className: styles.selectMenu },
      }}
    />
  );
};
