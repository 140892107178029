import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { PopupOverlays, FeatureStatsPopupOverlay } from 'components';
import {
  centerMap,
  layers,
  overlays,
  layerStyles,
  initLayer,
  initLayerGroup,
  popupOverlays,
  animateFrameCoords,
  initFeatureStatsOverlay,
  featureStatsOverlay,
  initMap,
  initOverlaysCorridors,
  initTrafficInsightsEvents,
} from 'utils';
import { usePostData } from 'utils/hooks';
import {
  selectCorridors,
  selectExpandedPanel,
  selectLoadingCorridorStats,
  resetLoadingCorridorStats,
  selectLoadingTotalRides,
  resetLoadingTotalRides,
  selectToIntersection,
  setFromIntersection,
  selectTotalRides,
  selectIntersections,
  selectBusStops,
  selectActiveFrameCords,
  selectTenants,
} from 'features';
import CarBlue from 'assets/images/Car Blue@3x.svg';
import styles from './OpenlayersMap.module.scss';

export const OpenlayersMap = ({
  clickedIntersection,
  setClickedIntersection,
  clickedIntersectionsArr,
  setClickedIntersectionsArr,
}) => {
  /* ---------------------------------- State --------------------------------- */
  const [olMap, setOlMap] = useState(null);

  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();
  const { searchPostData } = usePostData();
  const activeFrameCords = useSelector(selectActiveFrameCords);

  /* -------------------------------- Selectors ------------------------------- */
  const corridors = useSelector(selectCorridors);
  const expandedPanel = useSelector(selectExpandedPanel);
  const loadingCorridorStats = useSelector(selectLoadingCorridorStats);
  const toIntersection = useSelector(selectToIntersection);
  const totalRides = useSelector(selectTotalRides(JSON.stringify(searchPostData('first'))));
  const loadingTotalRides = useSelector(selectLoadingTotalRides);
  const intersections = useSelector(selectIntersections);
  const busStops = useSelector(selectBusStops);
  const userTenants = useSelector(selectTenants);

  /* -------------------------------- Variables ------------------------------- */
  const { selectedCorridorId, selectedCorridorIndex } = corridors;
  const currentIntersectionsLayer = layers[`intersections-${selectedCorridorId}`];
  const selectedCorridorLayer = olMap?.getLayers().getArray()[1].getLayersArray()[selectedCorridorIndex];

  /* -------------------------------- Functions ------------------------------- */

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    if (!userTenants?.currentTenant) return;

    initOverlaysCorridors();
    initFeatureStatsOverlay();
    const map = initMap({
      name: 'TrafficInsights',
      overlays: [...Object.values({ ...overlays, ...popupOverlays, ...featureStatsOverlay })],
      layersToAdd: ['corridors', 'intersections', 'busStops'],
    });

    initTrafficInsightsEvents(map, overlays.intersectionName, setClickedIntersection);

    setOlMap(map);
  }, [userTenants?.currentTenant]); // eslint-disable-line

  useEffect(() => {
    if (!olMap || !currentIntersectionsLayer) return;
    centerMap(olMap, currentIntersectionsLayer);
  }, [expandedPanel, currentIntersectionsLayer]); // eslint-disable-line

  useEffect(() => {
    if (!currentIntersectionsLayer) return;
    const inters = intersections.byCorridor.find((c) => c.corridorId === selectedCorridorId).intersections;
    const features = currentIntersectionsLayer.getSource().getFeatures();
    const startId = Number(inters[0].id);
    const endId = Number(inters[inters.length - 1].id);
    const startFeature = features.find((f) => f.get('id') === startId);
    const endFeature = features.find((f) => f.get('id') === endId);

    setFromIntersection(startId);
    setClickedIntersectionsArr([
      {
        id: startFeature.get('id'),
        name: startFeature.get('name'),
      },
    ]);
    setClickedIntersection({
      id: endFeature.get('id'),
      name: endFeature.get('name'),
    });
  }, [currentIntersectionsLayer]); //eslint-disable-line

  useEffect(() => {
    if (loadingCorridorStats !== 'loaded') return;
    dispatch(resetLoadingCorridorStats());
    olMap.removeLayer(layers.segmentsDirection);
    olMap.removeLayer(layers.rideSegments);
    olMap.removeLayer(layers.approachesQueue);
  }, [loadingCorridorStats]); // eslint-disable-line

  useEffect(() => {
    if (toIntersection === null || !olMap) return;

    olMap.removeLayer(layers.segmentsDirection);
    olMap.removeLayer(layers.rideSegments);
    olMap.removeLayer(layers.approachesQueue);
  }, [toIntersection, olMap]); // eslint-disable-line

  useEffect(() => {
    if (loadingTotalRides !== 'loaded' || !totalRides?.approaches) return;
    dispatch(resetLoadingTotalRides());
    olMap.removeLayer(layers.segmentsDirection);
    olMap.removeLayer(layers.rideSegments);
    layers.segmentsDirection = initLayer(
      'direction-approaches',
      totalRides.approaches,
      layerStyles.corridorView.corridorSegments,
      1,
    );

    olMap.addLayer(layers.segmentsDirection);
  }, [loadingTotalRides, totalRides]); // eslint-disable-line

  useEffect(() => {
    if (!selectedCorridorLayer) return;

    olMap
      .getLayers()
      .getArray()[2]
      .getLayersArray()
      .forEach((layer, layerIndex) => {
        layer.setVisible(layerIndex === selectedCorridorIndex);
      });
    olMap
      .getLayers()
      .getArray()[1]
      .getLayersArray()
      .forEach((layer, layerIndex) => {
        layer.setVisible(layerIndex === selectedCorridorIndex);
        layer.setStyle(
          layerIndex === selectedCorridorIndex
            ? layerStyles.corridorView.selectedCorridor
            : layerStyles.corridorView.corridor,
        );
      });
    if (expandedPanel !== 'signal-plan') {
      olMap.getView().fit(selectedCorridorLayer.getSource().getExtent(), { duration: 1000, padding: [50, 50, 50, 50] });
    }
  }, [selectedCorridorIndex, selectedCorridorLayer]); // eslint-disable-line

  useEffect(() => {
    if (!clickedIntersection) {
      return;
    }
    if (clickedIntersectionsArr.length === 0 || clickedIntersectionsArr.length < 2) {
      setClickedIntersectionsArr([...clickedIntersectionsArr, clickedIntersection]);
    } else {
      setClickedIntersectionsArr([clickedIntersection]);
    }
  }, [clickedIntersection]); // eslint-disable-line

  useEffect(() => {
    if (!olMap) {
      return;
    }
    olMap
      .getLayers()
      .getArray()[2]
      .getLayersArray()
      .forEach((layer) => {
        const features = layer.getSource().getFeatures();

        features.forEach((f) => {
          f.set('isSelected', false);
          if (expandedPanel !== 'signal-plan') {
            f.setStyle(layerStyles.corridorView.intersections);
          }
        });
      });

    olMap
      .getLayers()
      .getArray()[2]
      .getLayersArray()
      .forEach((layer) => {
        clickedIntersectionsArr.forEach(({ id }) => {
          const features = layer.getSource().getFeatures();

          features.forEach((f) => {
            if (f.get('id') === id) {
              f.set('isSelected', true);
              f.setStyle(layerStyles.corridorView.intersectionsActive);
            }
          });
        });
      });
  }, [clickedIntersectionsArr, olMap]); // eslint-disable-line

  useEffect(() => {
    if (!olMap || corridors.loading !== 'loaded' || intersections.loadingByCorridor !== 'loaded') {
      return;
    }

    const corridorLayers = [];

    corridors.data.forEach((corridor) => {
      corridorLayers.push(
        initLayer(
          `corridor ${corridor.features[0].properties.corridorName}`,
          corridor,
          layerStyles.corridorView.corridor,
          0,
        ),
      );
    });

    const corridorLayerGroup = initLayerGroup(corridorLayers, 'corridors', 'map');

    const intersectionsLayers = intersections.byCorridor.map(({ corridorId, intersections: intersectionsByLayer }) => {
      const layerName = `intersections-${corridorId}`;

      layers[layerName] = initLayer(
        layerName,
        {
          type: 'FeatureCollection',
          features: intersectionsByLayer,
        },
        layerStyles.corridorView.intersections,
        4,
      );

      return layers[layerName];
    });

    const intersectionsLayerGroup = initLayerGroup(intersectionsLayers, 'intersections', 'map');

    olMap.getLayers().setAt(1, corridorLayerGroup);
    olMap.getLayers().setAt(2, intersectionsLayerGroup);
  }, [olMap, corridors.loading, intersections.loadingByCorridor]); // eslint-disable-line

  useEffect(() => {
    if (!olMap || busStops.loading !== 'loaded' || corridors.loading !== 'loaded') {
      return;
    }

    const busStopsLayerGroup = initLayerGroup(
      [initLayer('busStops', busStops.data, layerStyles.corridorView.busStops, 3)],
      'busStops',
      'map',
    );

    olMap.getLayers().setAt(3, busStopsLayerGroup);
  }, [olMap, busStops.loading, corridors.loading]); //eslint-disable-line

  useEffect(() => {
    Object.values(popupOverlays).forEach((overlay) => overlay.setPosition(undefined));
    if (layers.rideSegments) {
      layers.rideSegments.setVisible(expandedPanel === 'total-rides');
    }

    if (layers.approachesQueue) {
      olMap.removeLayer(layers.approachesQueue);
    }
    if (olMap) {
      olMap.getOverlayById('frames-location').setPosition(null);
    }
  }, [expandedPanel]); // eslint-disable-line

  useEffect(() => {
    if (olMap && expandedPanel === 'total-rides') {
      animateFrameCoords({ olMap, activeFrameCords, iconSrc: CarBlue });
    }
  }, [activeFrameCords]); //eslint-disable-line

  return (
    <>
      <div className={styles.map} id="map" />
      <PopupOverlays />
      <FeatureStatsPopupOverlay />
    </>
  );
};

OpenlayersMap.propTypes = {
  clickedIntersection: propTypes.object,
  clickedIntersectionsArr: propTypes.array.isRequired,
  setClickedIntersection: propTypes.func.isRequired,
  setClickedIntersectionsArr: propTypes.func.isRequired,
};

OpenlayersMap.defaultProps = {
  clickedIntersection: null,
};
