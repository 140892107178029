import * as d3 from 'd3';
import theme from 'theme';

// create the svg area
export const initChord = (containerNode, labels, odmatrix) => {
  const chordLayout = d3.chord().padAngle(0.03).sortSubgroups(d3.descending);
  const width = 600;
  const height = 600;
  const innerRadius = (width / 2) * 0.7;
  const outerRadius = innerRadius + 15;
  const color20 = d3
    .scaleOrdinal()
    .range([
      theme.color11,
      theme.color26,
      theme.color27,
      theme.color28,
      theme.color29,
      theme.color30,
      theme.color31,
      theme.color32,
      theme.color33,
      theme.color34,
      theme.color35,
      theme.color36,
      theme.color37,
      theme.color38,
      theme.color39,
    ]);

  // fade ribbons
  const fade = (opacity, i) => {
    d3.selectAll('.ribbons path')
      .filter((d) => d.source?.index !== i && d.target?.index !== i)
      .style('opacity', opacity);
  };

  // select container node
  const svg = d3.select(containerNode).append('svg').attr('width', width).attr('height', height);

  // init outer arc
  const outerArc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

  const chordsLayer = chordLayout(odmatrix);

  // draw container
  const container = svg
    .append('g')
    .attr('class', 'chord')
    .attr('transform', `translate(${width / 2},${height / 2})`)
    .datum(chordsLayer);

  // draw arcs and add events
  const arcs = container
    .append('g')
    .attr('class', 'arcs')
    .selectAll('g')
    .data((chords) => chords.groups)
    .enter()
    .append('g')
    .on('mouseover', (d, i) => {
      fade(0.1, i);
    })
    .on('mouseout', (d, i) => {
      fade(0.6, i);
    });

  // add color to arcs
  arcs
    .append('path')
    .style('fill', (d) => color20(d.index))
    .style('stroke', (d) => color20(d.index))
    .attr('d', outerArc);

  // add text to arcs
  arcs
    .append('svg:text')
    .each((d) => {
        d.angle = (d.startAngle + d.endAngle) / 2; // eslint-disable-line
    })
    .attr('dy', '.35em')
    .attr('fill', (d) => color20(d.index))
    .attr(
      'transform',
      (d) => `
        rotate(${(d.angle * 180) / Math.PI - 90})
        translate(${innerRadius + 26})
        ${d.angle > Math.PI ? 'rotate(180)' : ''}`,
    )
    .attr('text-anchor', (d) => (d.angle > Math.PI ? 'end' : null))
    .text((d) => labels[d.index]);

  // init ribbons and tooltip
  const innerChord = d3.ribbon().radius(innerRadius);
  const tooltip = d3.select(containerNode).select('div.chordTooltip');

  // draw ribbons with data and style
  container
    .append('g')
    .attr('class', 'ribbons')
    .selectAll('path')
    .data((chords) => chords)
    .enter()
    .append('path')
    .attr('d', innerChord)
    .style('fill', (d) => color20(d.source.index))
    .style('stroke', (d) => color20(d.source.index))
    .style('opacity', 0.6);

  // add hover events on ribbons
  d3.selectAll('.ribbons path')
    .on('mouseover', (d, i, nodes) => {
      d3.select(nodes[i])
        .style('fill', color20(d.source.index))
        .style('stroke', color20(d.source.index))
        .style('opacity', 1);

      d3.selectAll(nodes)
        .filter((node, index) => index !== i)
        .style('opacity', 0.1);

      tooltip.html(`
          <div class="col">
            <div class="row">${labels[d.source.index]}</div>
            <div class="row">${labels[d.target.index]}</div>
          </div>
          <div class="col">
            <div class="row">
              <span class="arrow">▶</span>${labels[d.target.index]} - 
              <strong>${d.source.value.toLocaleString('en')} vehicles</strong>
            </div>
            <div class="row">
              <span class="arrow">▶</span>${labels[d.source.index]} - 
              <strong>${d.target.value.toLocaleString('en')} vehicles</strong>
            </div>
          </div>
        `);
    })
    .on('mouseout', (d, i, nodes) => {
      d3.selectAll(nodes).style('opacity', 0.6);

      tooltip.style('opacity', 0);
    })
    .on('mousemove', () => {
      tooltip.style('opacity', 1);
      tooltip.style('left', `${d3.event.pageX + 30}px`);
      tooltip.style('top', `${d3.event.pageY - 10}px`);
    });
};
