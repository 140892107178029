import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSettings } from 'api/endpoints';

export const fetchSettings = createAsyncThunk('settings', async (_, thunkAPI) => {
  const response = await getSettings();

  if (response.status && response.status !== 200) {
    return thunkAPI.rejectWithValue();
  }

  return response.data;
});
