export const readableMetrics = {
  'stops-number': 'Stop and go',
  'waiting-time': 'Waiting time',
  speed: 'Average speed',
  'queue-length': 'Queue length',
  'dwell-time': 'Dwell time',
  blockage: 'Blockage',
  'signal-plan': 'Signal plan',
  'total-rides': 'Total rides',
};

export const metricByPanel = {
  'stops-number': 'numberOfStops',
  'waiting-time': 'waitingTime',
  speed: 'speed',
  'queue-length': 'queueLength',
  'dwell-time': 'dwellTime',
  blockage: 'blockage',
};

export const popupByPanel = {
  'stops-number': 'probabilityToStop',
  'waiting-time': 'waitingTime',
  speed: 'averageSpeed',
  blockage: 'probabilityForBlockage',
  'queue-length': 'queueLength',
  'effective-los': 'effectiveLos',
  'dwell-time': 'dwellTime',
};

export const generalMetricsPanels = ['stops-number', 'waiting-time', 'speed', 'queue-length', 'dwell-time', 'blockage'];
