import React from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { RadialChart } from 'react-vis';
import theme from 'theme';
import PieChartStyles from './PieChart.styles';

const useStyles = makeStyles(PieChartStyles);

export const PieChart = ({ percent, displayValue, threshold, isRed, className, size }) => {
  const classes = useStyles();
  const isCritical = isRed || (isRed === null && percent > threshold);

  const { innerRadius, radius, widthHeight } = {
    normal: {
      innerRadius: 20,
      radius: 25,
      widthHeight: 55,
    },
    small: {
      innerRadius: 17,
      radius: 20,
      widthHeight: 42,
    },
  }[size];

  const getAngle = (d) => d.theta;

  return (
    <div className={`${classes.container} ${className}`}>
      <RadialChart
        className="donut-chart"
        innerRadius={innerRadius}
        radius={radius}
        getAngle={getAngle}
        width={widthHeight}
        height={widthHeight}
        padAngle={0.04}
        colorType="literal"
        center={{ x: 0, y: 0 }}
        data={[
          {
            theta: percent,
            color: isCritical ? theme.color06 : theme.color07,
          },
          {
            theta: 100 - percent,
            color: theme.color40,
            opacity: 0.7,
          },
        ]}
      />
      <span
        className={`
          ${classes.value}
          ${classes[isCritical ? 'redColor' : 'greenColor']}
        `}
      >
        {displayValue || `${percent}%`}
      </span>
    </div>
  );
};

PieChart.propTypes = {
  percent: propTypes.number.isRequired,
  displayValue: propTypes.string,
  isRed: propTypes.bool,
  threshold: propTypes.number,
  className: propTypes.string,
  size: propTypes.string,
};

PieChart.defaultProps = {
  displayValue: '',
  isRed: null,
  threshold: 50,
  className: '',
  size: 'normal',
};
