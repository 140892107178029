import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { XYPlot, XAxis, YAxis, VerticalGridLines, HorizontalGridLines, HorizontalBarSeries, Hint } from 'react-vis';
import 'react-vis/dist/style.css';
import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';
import { generateHexColors } from 'utils';
import OperatingChartStyle from './OperatingScheduleStyles.styles';

const useStyles = makeStyles(OperatingChartStyle);

let colors = [
  theme.color11,
  theme.color26,
  theme.color27,
  theme.color28,
  theme.color29,
  theme.color30,
  theme.color31,
  theme.color32,
  theme.color33,
  theme.color34,
  theme.color35,
  theme.color36,
  theme.color37,
  theme.color38,
  theme.color39,
];
const generatedColors = generateHexColors(100);

colors = colors.concat(generatedColors);

export const OperatingSchedule = ({ timings, setActiveMediaTab, setActiveProgram }) => {
  const classes = useStyles();
  const [barsData, setBarsData] = useState([]);
  const [hints, setHints] = useState([]);
  const [XYPlotLeftMargin, setXYPlotLeftMargin] = useState(0);
  const groupTypes = timings?.groupTypes;

  const onValueClick = (datapoint) => {
    if (datapoint.program) {
      setActiveMediaTab('phase');
      setActiveProgram(datapoint.program);
      document.body.style.cursor = 'default';
    }
  };

  const onValueMouseOver = (datapoint) => {
    if (datapoint.program) {
      document.body.style.cursor = 'pointer';
    }
  };

  const xTickFormat = (t) => (t === 24 ? null : `${t}:00`);

  const yTickFormat = (t) => timings?.groupTypes[t]?.displayName;

  const onHorizontalBarValueMouseout = () => {
    document.body.style.cursor = 'default';
  };

  useEffect(() => {
    /* istanbul ignore else */
    if (groupTypes) {
      return;
    }
    const longestDisplayName = Object.keys(groupTypes)
      .map((key) => groupTypes[key].displayName)
      .sort((a, b) => b.length - a.length)[0];

    const el = document.createElement('span');

    el.style.visibility = 'hidden';
    el.style.fontSize = '11px';
    el.textContent = longestDisplayName;
    document.body.appendChild(el);
    setXYPlotLeftMargin(el.offsetWidth);
    el.remove();
  }, [groupTypes]);

  useEffect(() => {
    /* istanbul ignore if */
    if (!timings || !Object.keys(timings).length) {
      return;
    }

    const barsDataArray = [];
    const hintsArray = [];

    timings.programTimingGroups.forEach((group) => {
      let currentHour = 0;

      group.elements.forEach((el) => {
        const startTime = el.startTime / (timings.timeSlotsPerDay / 24);
        const duration = el.duration / (timings.timeSlotsPerDay / 24);
        const emptySlotDuration = startTime - currentHour;

        currentHour += emptySlotDuration;

        if (emptySlotDuration) {
          barsDataArray.push({ x: emptySlotDuration, y: group.type, color: 'transparent' });
        }

        /* istanbul ignore next */
        const colorIndex = el.programNumber > 0 ? el.programNumber - 1 : 0;

        barsDataArray.push({ x: duration, y: group.type, color: colors[colorIndex], program: el.programNumber });

        hintsArray.push({
          value: { x: currentHour + duration / 2, y: group.type },
          text: el.programNumber,
        });

        currentHour += duration;
      });
    });

    setBarsData(barsDataArray);
    setHints(hintsArray);
  }, [timings]);

  return (
    <div className={classes.container}>
      <XYPlot
        width={850}
        height={400}
        stackBy="x"
        margin={{ left: XYPlotLeftMargin + 80, right: 10, top: 40, bottom: 30 }}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis orientation="top" tickTotal={23} tickFormat={xTickFormat} />
        <YAxis tickFormat={yTickFormat} />

        <HorizontalBarSeries
          data={barsData}
          barWidth={0.2}
          colorType="literal"
          onValueClick={onValueClick}
          onValueMouseOver={onValueMouseOver}
          onValueMouseOut={onHorizontalBarValueMouseout}
        />

        {hints.map((hint, i) => (
          <Hint key={String(i)} value={hint.value} align={{ horizontal: 'left', vertical: 'top' }}>
            <div className={classes.hintStyle}>{hint.text}</div>
          </Hint>
        ))}
      </XYPlot>
    </div>
  );
};

OperatingSchedule.propTypes = {
  timings: propTypes.object.isRequired,
  setActiveMediaTab: propTypes.func.isRequired,
  setActiveProgram: propTypes.func.isRequired,
};
