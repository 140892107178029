import { createSlice } from '@reduxjs/toolkit';
import { fetchUser, setActiveTenant, fetchTenants } from 'features/thunks';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: 'idle',
    fullName: '',
    profilePicture: undefined,
    isLoggedIn: false,
    roles: [''],
    ID: '',
    email: '',
    locale: 'en', // the locale is user-specific and will be taken from backend response
    loadingActiveTenant: 'idle',
    tenantsIds: undefined,
  },
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
    },
    changeLocale: (state, action) => {
      state.locale = action.payload;
    },
    resetLoadingActiveTenant: (state) => {
      state.loadingActiveTenant = 'idle';
    },
  },
  extraReducers: {
    [fetchUser.pending]: (state) => {
      state.loading = 'loading';
    },
    [fetchUser.fulfilled]: (state, { payload }) => {
      state.loading = 'loaded';
      if (!payload) {
        return;
      }
      if (payload.picture) {
        state.profilePictureUrl = payload.picture;
      }
      state.fullName = payload.fullName;
      state.isLoggedIn = true;
      state.roles = payload.roles;
      state.tenants = {
        userLoginTenant: payload.userLoginTenant,
        currentTenant: payload.currentTenant,
      };
      state.ID = payload.attributes.oid;
      state.email = payload.attributes.unique_name;
    },
    [fetchUser.rejected]: (state) => {
      state.loading = 'error';
    },
    [setActiveTenant.pending]: (state) => {
      state.loadingActiveTenant = 'loading';
    },
    [setActiveTenant.fulfilled]: (state) => {
      state.loadingActiveTenant = 'loaded';
    },
    [setActiveTenant.rejected]: (state) => {
      state.loadingActiveTenant = 'error';
    },
    [fetchTenants.fulfilled]: (state, action) => {
      state.tenantsIds = action.payload;
    },
  },
});

export const selectUser = ({ user }) => user;
export const selectIsLoggedIn = ({ user }) => user.isLoggedIn;
export const selectIsUserLoaded = ({ user }) => user.loading === 'loaded' || user.loading === 'error';
export const selectTenants = ({ user }) => user.tenants;
export const selectIsUserAdmin = ({ user }) => user.roles.includes('ROLE_ADMIN');
export const selectCanRunSimulations = ({ user }) => user.roles.includes('ROLE_DIGITAL_TWIN');
export const selectLocale = ({ user }) => user.locale;
export const selectLoadingActiveTenant = ({ user }) => user.loadingActiveTenant;
export const selectTenantsIds = ({ user }) => user.tenantsIds;

export const { logout, changeLocale, resetLoadingActiveTenant } = userSlice.actions;

export default userSlice.reducer;
