import axios from 'axios';
import { setLoadingCount } from 'features/appStateSlice';
import { MAPBOX_MATCHING_BASE_URL } from 'consts';
import store from 'store';

const incrementLoadingCount = () => store.dispatch(setLoadingCount(store.getState().appState.loadingCount + 1));
const decrementLoadingCount = () => store.dispatch(setLoadingCount(store.getState().appState.loadingCount - 1));

export const handleLoadingState = () => {
  axios.interceptors.response.use(
    (response) => {
      if (!response.config.skipLoader && !response.config.url.includes(MAPBOX_MATCHING_BASE_URL)) {
        setTimeout(() => decrementLoadingCount(response.config.url), 200);
      }

      return response;
    },
    (error) => {
      if (!error.message?.skipLoader && !error.message?.url.includes(MAPBOX_MATCHING_BASE_URL)) {
        decrementLoadingCount();
        // TODO: get skipLoader from config when implemented:
        // https://github.com/axios/axios/issues/2712
      }
    },
  );

  axios.interceptors.request.use((request) => {
    if (!request.skipLoader && !request.url.includes(MAPBOX_MATCHING_BASE_URL)) {
      incrementLoadingCount();
    }

    return request;
  });
};
