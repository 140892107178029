import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Slide, CircularProgress } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import { CollapseButton } from '@axilion/ui-components';
import { useSelector, useDispatch } from 'react-redux';
import { TopTabs } from 'components';
import { selectExpandedPanel, setExpandedPanel } from 'features';
import classes from './BottomContainer.module.scss';

export const BottomContainer = ({
  slideIn,
  onClose,
  mainContent,
  mediaContent,
  isLoadingMediaContent,
  sideTabs,
  activeSideTab,
  setActiveSideTab,
  mediaTabs,
  activeMediaTab,
  setActiveMediaTab,
  canCollapseTable,
  graphsTwoRows,
}) => {
  const dispatch = useDispatch();
  const expandedPanel = useSelector(selectExpandedPanel);
  const [isMainContentCollapsed, setIsMainContentCollapsed] = useState(false);
  const uniqueTabs = [...new Map(sideTabs?.map((tab) => [tab.id, tab])).values()];

  const onSideTabClick = (tab) => () => {
    setActiveSideTab(tab.id);
    if (tab.onClick) {
      tab.onClick();
    }
  };

  const toggleMainContentCollapsed = () => {
    setIsMainContentCollapsed(!isMainContentCollapsed);
  };

  const onCloseClick = () => {
    dispatch(setExpandedPanel(null));
    setIsMainContentCollapsed(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Slide in={slideIn} direction="up" mountOnEnter unmountOnExit>
      <div
        className={`
        ${classes.wrap}
        ${sideTabs ? classes.withSideTabs : ''}
        ${isMainContentCollapsed ? classes.mainContentCollapsed : ''}
        ${graphsTwoRows ? 'graphsTwoRows' : ''}
        ${expandedPanel}
      `}
      >
        <CancelIcon color="primary" className={classes.cancelIcon} onClick={onCloseClick} />
        {sideTabs && (
          <div className={classes.sideTabs}>
            {uniqueTabs.map((tab) => (
              <div
                key={tab.id}
                onClick={onSideTabClick(tab)}
                className={`
                  ${classes.sideTab}
                  ${activeSideTab === tab.id ? classes.activeSideTab : ''}
                `}
              >
                {tab.text}
              </div>
            ))}
          </div>
        )}
        {canCollapseTable && (
          <CollapseButton
            className={classes.mainContentCollapseButton}
            onClick={toggleMainContentCollapsed}
            pointer={isMainContentCollapsed ? 'right' : 'left'}
          />
        )}

        {!isMainContentCollapsed && <div className={classes.mainContent}>{mainContent}</div>}

        {mediaContent && (
          <div className={classes.mediaBox}>
            <TopTabs tabs={mediaTabs} activeTab={activeMediaTab} setActiveTab={setActiveMediaTab} />
            {isLoadingMediaContent && (
              <div className={classes.loadingMediaIndicator}>
                <CircularProgress size={26} thickness={4} />
              </div>
            )}
            <div
              className={`
              ${classes.mediaContent}
              ${isLoadingMediaContent ? 'loading' : ''}
            `}
            >
              {mediaContent}
            </div>
          </div>
        )}
      </div>
    </Slide>
  );
};

BottomContainer.propTypes = {
  mainContent: propTypes.node.isRequired,
  mediaContent: propTypes.node,
  isLoadingMediaContent: propTypes.bool,
  slideIn: propTypes.bool.isRequired,
  onClose: propTypes.func,
  sideTabs: propTypes.array,
  activeSideTab: propTypes.string,
  setActiveSideTab: propTypes.func,
  mediaTabs: propTypes.array,
  activeMediaTab: propTypes.string,
  setActiveMediaTab: propTypes.func,
  canCollapseTable: propTypes.bool,
  graphsTwoRows: propTypes.bool,
};

BottomContainer.defaultProps = {
  mediaContent: null,
  isLoadingMediaContent: false,
  sideTabs: null,
  activeSideTab: null,
  setActiveSideTab: null,
  mediaTabs: null,
  activeMediaTab: null,
  setActiveMediaTab: null,
  canCollapseTable: false,
  onClose: null,
  graphsTwoRows: false,
};
