import { createSlice } from '@reduxjs/toolkit';

export const appStateSlice = createSlice({
  name: 'appState',
  initialState: {
    expandedPanel: null,
    isComparing: false,
    ridesError: null,
    dateRange: {
      first: [null, null],
      second: [null, null],
    },
    planRange: {
      first: null,
      second: null,
    },
    fromIntersection: null,
    toIntersection: null,
    currentTotalRides: 'first',
    isRunningQuery: false,
    isSidebarVisible: true,
    isCompareSelectVisible: true,
    loadingCount: 0,
    generalViewRadialChart: null,
    toastMessage: null,
    activeTileLayerIndex: 0,
    clickedGeneralViewFeatureId: null,
  },
  reducers: {
    setExpandedPanel: (state, { payload }) => {
      state.expandedPanel = payload;
    },
    setIsComparing: (state, { payload }) => {
      state.isComparing = payload;
    },
    setRidesError: (state, { payload }) => {
      state.ridesError = payload;
    },
    setDateRange: (state, { payload }) => {
      Object.keys(payload).forEach((key) => {
        payload[key] = [+payload[key][0], +payload[key][1]];
      });
      state.dateRange = { ...state.dateRange, ...payload };
    },
    setPlanRange: (state, { payload }) => {
      state.planRange = {
        ...state.planRange,
        ...payload,
      };
    },
    setFromIntersection: (state, { payload }) => {
      state.fromIntersection = payload;
    },
    setToIntersection: (state, { payload }) => {
      state.toIntersection = payload;
    },
    setCurrentTotalRides: (state, { payload }) => {
      state.currentTotalRides = payload;
    },
    setIsRunningQuery: (state, { payload }) => {
      state.isRunningQuery = payload;
    },
    setIsSidebarVisible: (state, { payload }) => {
      state.isSidebarVisible = payload;
    },
    setIsCompareSelectVisible: (state, { payload }) => {
      state.isCompareSelectVisible = payload;
    },
    setLoadingCount: (state, { payload }) => {
      state.loadingCount = payload;
    },
    setGeneralViewRadialChart: (state, { payload }) => {
      if (typeof payload === 'string') {
        state.generalViewRadialChart = JSON.parse(payload);
      } else {
        state.generalViewRadialChart = payload;
      }
    },
    setToastMessage: (state, { payload }) => {
      state.toastMessage = payload;
    },
    setActiveTileLayerIndex: (state, { payload }) => {
      state.activeTileLayerIndex = payload;
    },
    setClickedGeneralViewFeatureId: (state, { payload }) => {
      state.clickedGeneralViewFeatureId = payload;
    },
  },
});

export const selectExpandedPanel = ({ appState }) => appState.expandedPanel;
export const selectIsComparing = ({ appState }) => appState.isComparing;
export const selectRidesError = ({ appState }) => appState.ridesError;
export const selectDateRange = ({ appState }) => {
  return {
    first: [new Date(appState.dateRange.first[0]), new Date(appState.dateRange.first[1])],
    second: [new Date(appState.dateRange.second[0]), new Date(appState.dateRange.second[1])],
  };
};
export const selectPlanRange = ({ appState }) => appState.planRange;
export const selectFromIntersection = ({ appState }) => appState.fromIntersection;
export const selectToIntersection = ({ appState }) => appState.toIntersection;
export const selectCurrentTotalRides = ({ appState }) => appState.currentTotalRides;
export const selectIsRunningQuery = ({ appState }) => appState.isRunningQuery;
export const selectIsSidebarVisible = ({ appState }) => appState.isSidebarVisible;
export const selectIsCompareSelectVisible = ({ appState }) => appState.isCompareSelectVisible;
export const selectIsLoading = ({ appState }) => !!appState.loadingCount;
export const selectGeneralViewRadialChart = ({ appState }) => appState.generalViewRadialChart;
export const selectToastMessage = ({ appState }) => appState.toastMessage;
export const selectActiveTileLayerIndex = ({ appState }) => appState.activeTileLayerIndex;
export const selectClickedGeneralViewFeatureId = ({ appState }) => appState.clickedGeneralViewFeatureId;

export const {
  setExpandedPanel,
  setIsComparing,
  setRidesError,
  setDateRange,
  setPlanRange,
  setCurrentTotalRides,
  setIsRunningQuery,
  setIsSidebarVisible,
  setIsCompareSelectVisible,
  setLoadingCount,
  setGeneralViewRadialChart,
  setToastMessage,
  setActiveTileLayerIndex,
  setClickedGeneralViewFeatureId,
  setFromIntersection,
  setToIntersection,
} = appStateSlice.actions;

export default appStateSlice.reducer;
