import React from 'react';
import propTypes from 'prop-types';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import { headCells } from './ridesHeaders';

export const EnhancedTableHead = ({ classes, order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          if (index !== 7) {
            return (
              <TableCell key={index.toString()} align="center" sortDirection={orderBy === headCell.id ? order : false}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  className={classes.tableSortLabel}
                >
                  {orderBy === headCell.id ? <span className={classes.visuallyHidden} /> : null}
                  {headCell.icon}
                </TableSortLabel>
              </TableCell>
            );
          }

          return (
            <TableCell key={index.toString()} align="center">
              {headCell.icon}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: propTypes.object.isRequired,
  order: propTypes.string.isRequired,
  orderBy: propTypes.string.isRequired,
  onRequestSort: propTypes.func.isRequired,
};
