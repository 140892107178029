import { createAsyncThunk } from '@reduxjs/toolkit';
import { statisticTypes, GRAPH_START_TIME, GRAPH_END_TIME } from 'consts';
import { getHomeStats } from 'api/endpoints';
import { DateTime } from 'luxon';

export const fetchHomeStats = createAsyncThunk(
  'homeStats/fetchHomeStats',
  async ({ fromUtcToProjectTime }, thunkAPI) => {
    const homeStatsRes = await getHomeStats({
      startTime: `${GRAPH_START_TIME < 10 ? '0' : ''}${GRAPH_START_TIME}:00`,
      endTime: `${GRAPH_END_TIME < 10 ? '0' : ''}${GRAPH_END_TIME}:00`,
      days: thunkAPI.getState().generalStats.daysInPast,
    });

    if (homeStatsRes.status && homeStatsRes.status !== 200) {
      return thunkAPI.rejectWithValue();
    }

    const placeholders = [];

    for (let i = GRAPH_START_TIME; i <= GRAPH_END_TIME; i += 1) {
      placeholders.push({ x: i, y: 0 });
    }

    const mergeWithPlaceholders = (stats) =>
      placeholders.map((placeholder) => {
        const overrideValue = stats.find((stat) => stat.x === placeholder.x);

        return overrideValue || placeholder;
      });
    const filterHomeStats = (homeStats, statisticType) =>
      homeStats.filter(({ vehicleType }) => vehicleType === statisticType);
    const getProjectTimeHour = (time) =>
      fromUtcToProjectTime(DateTime.fromFormat(time, 'hh:mm').toJSDate(), 'TIME_24_SIMPLE').split(':')[0];

    const transformQueueOccupancies = (queueOccupancies, isFiller) => {
      const transformedStats = queueOccupancies.map(({ time, statistic }) => {
        const projectTimeHour = getProjectTimeHour(time);

        return {
          x: Number(projectTimeHour),
          y: isFiller ? 100 - statistic : statistic,
        };
      });

      return mergeWithPlaceholders(transformedStats);
    };

    const transformQueueTravelRatio = (homeStats, comparingHomeStats, isFiller) => {
      const transformedStats = homeStats.map(({ time, statistic }, index) => {
        const projectTimeHour = getProjectTimeHour(time);

        let ratio = (statistic / comparingHomeStats[index].statistic) * 100;

        if (ratio > 100) ratio = 100;

        return {
          x: Number(projectTimeHour),
          y: isFiller ? 100 - ratio : ratio,
        };
      });

      return mergeWithPlaceholders(transformedStats);
    };

    const privateQueueOccupancies = filterHomeStats(homeStatsRes.data.queueOccupancies, statisticTypes.PRIVATE);
    const privateQueueTimes = filterHomeStats(homeStatsRes.data.queueTimes, statisticTypes.PRIVATE);
    const privateDriveTimes = filterHomeStats(homeStatsRes.data.driveTimes, statisticTypes.PRIVATE);

    const publicQueueOccupancies = filterHomeStats(homeStatsRes.data.queueOccupancies, statisticTypes.PUBLIC);
    const publicQueueTimes = filterHomeStats(homeStatsRes.data.queueTimes, statisticTypes.PUBLIC);
    const publicDriveTimes = filterHomeStats(homeStatsRes.data.driveTimes, statisticTypes.PUBLIC);

    return {
      [statisticTypes.PRIVATE]: {
        queueOccupancies: transformQueueOccupancies(privateQueueOccupancies),
        queueOccupanciesFiller: transformQueueOccupancies(privateQueueOccupancies, true),
        queueTravelRatio: transformQueueTravelRatio(privateQueueTimes, privateDriveTimes),
        queueTravelRatioFiller: transformQueueTravelRatio(privateQueueTimes, privateDriveTimes, true),
      },
      [statisticTypes.PUBLIC]: {
        queueOccupancies: transformQueueOccupancies(publicQueueOccupancies),
        queueOccupanciesFiller: transformQueueOccupancies(publicQueueOccupancies, true),
        queueTravelRatio: transformQueueTravelRatio(publicQueueTimes, publicDriveTimes),
        queueTravelRatioFiller: transformQueueTravelRatio(publicQueueTimes, publicDriveTimes, true),
      },
    };
  },
);
