/* eslint-disable no-bitwise */

export const generateHexColors = (number) => {
  const colors = [];

  for (let i = 0; i < number; i += 1) {
    colors.push(`#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0')}`);
  }

  return colors;
};
