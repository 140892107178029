import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from '@material-ui/core';
import { selectExpandedPanel, selectCurrentTotalRides } from 'features/appStateSlice';
import { selectIntersections } from 'features/intersectionsSlice';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { selectCorridors } from 'features/corridorsSlice';
import tableStyles from '../table.styles';

const useStyles = makeStyles(tableStyles);

export const SignalPlansTable = ({ setSignalPlansActiveStop }) => {
  const classes = useStyles();
  const expandedPanel = useSelector(selectExpandedPanel);
  const currentTotalRides = useSelector(selectCurrentTotalRides);
  const corridors = useSelector(selectCorridors);
  const intersections = useSelector(selectIntersections);
  const [selectedRowId, setSelectedRowId] = useState(undefined);
  const intersectionsData = intersections.signalizedByCorridor.find(({ corridorId }) => corridorId === corridors.selectedCorridorId).intersections || []; //eslint-disable-line

  const onRowClick = (stop) => () => {
    setSignalPlansActiveStop(stop);
    setSelectedRowId(stop.id);
  };

  useEffect(() => {
    setSelectedRowId(undefined);
  }, [expandedPanel, currentTotalRides]);

  useEffect(() => {
    if (!intersectionsData.length) {
      return;
    }
    if (expandedPanel === 'signal-plan') {
      setSignalPlansActiveStop(intersectionsData[0]);
      setSelectedRowId(intersectionsData[0].id);
    }
  }, [corridors.selectedCorridorId]); /* eslint-disable-line */

  return (
    <Box className={classes.boxContainer}>
      <div className={classes.tableContainer}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeadCell}>
                  <FormattedMessage
                    defaultMessage="Section name"
                    description="Signal plans table column Section name"
                  />
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  <FormattedMessage
                    defaultMessage="Intersection ID"
                    description="Signal plans table column Intersection ID"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {intersections.loadingByCorridor !== 'loaded' ? (
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    <CircularProgress size={26} thickness={4} />
                  </TableCell>
                </TableRow>
              ) : (
                intersectionsData.map((stop, i) => {
                  const isRowSelected = stop.id === selectedRowId;

                  return (
                    <TableRow
                      className={`${classes.tableRow} ${isRowSelected ? classes.selectedRow : ''} ${
                        stop.critical && classes.criticalRow
                      }`}
                      onClick={onRowClick(stop)}
                      key={i.toString()}
                    >
                      <TableCell align="center" className={`${isRowSelected ? classes.selectedCell : ''}`}>
                        {stop.properties.name}
                      </TableCell>
                      <TableCell align="center" className={`${isRowSelected ? classes.selectedCell : ''}`}>
                        {stop.id}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );
};

SignalPlansTable.propTypes = {
  setSignalPlansActiveStop: propTypes.func,
};

SignalPlansTable.defaultProps = {
  setSignalPlansActiveStop() {},
};
