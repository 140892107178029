import React from 'react';
import propTypes from 'prop-types';
import { render } from '@testing-library/react';
import { MemoryRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import {
  simulationsMock,
  intersectionsMock,
  appStateMock,
  generalStatsMock,
  corridorsStatsMock,
  corridorsMock,
  odMatrixMock,
  userMock,
  settingsMock,
  signalPlansMock,
  unitSystemMock,
  navigationMock,
  busStopsMock,
  adminMock,
  homeStatsMock,
  timeIntervalsMock,
} from 'utils/mocks';
import configureStore from 'redux-mock-store';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

const mockState = {
  simulations: simulationsMock,
  intersections: intersectionsMock,
  appState: appStateMock,
  generalStats: generalStatsMock,
  corridorsStats: corridorsStatsMock,
  corridors: corridorsMock,
  odMatrix: odMatrixMock,
  user: userMock,
  settings: settingsMock,
  signalPlans: signalPlansMock,
  unitSystem: unitSystemMock,
  navigation: navigationMock,
  busStops: busStopsMock,
  admin: adminMock,
  homeStats: homeStatsMock,
  timeIntervals: timeIntervalsMock,
};

const createMockStore = configureStore([thunk]);

let store = createMockStore(mockState);

const Providers = ({ children }) => (
  <Provider store={store}>
    <IntlProvider locale="en">
      <MemoryRouter initialEntries={['/']}>
        <MuiPickersUtilsProvider utils={LuxonUtils}>{children}</MuiPickersUtilsProvider>
      </MemoryRouter>
    </IntlProvider>
  </Provider>
);

const customRender = (ui, options) => render(ui, { wrapper: Providers, ...options });

Providers.propTypes = {
  children: propTypes.node.isRequired,
};

export * from '@testing-library/react';

export { customRender as render };

export const mockStore = (newState) => {
  store = createMockStore(newState);
};
export const mockSlice = (sliceName, newState) => {
  store = createMockStore({ ...mockState, [sliceName]: newState });
};
export const resetMockStore = () => {
  store = createMockStore(mockState);
};
export const getStore = () => store;
export const flushPromises = () => new Promise(setImmediate);
export const loadFailureSrc = 'loadFailureSrc';
