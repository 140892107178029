import React, { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { Menu, MenuItem, Divider } from '@material-ui/core';

export const RightClickMenu = ({
  mousePosition,
  onClose,
  onAddIntersection,
  onDeleteIntersection,
  onAddSegment,
  onDeleteSegment,
  onShowDetectionArea,
  onAddDetectionArea,
  isShowDetectionAreaVisible,
  onCreateApproach,
  onDeleteApproach,
  onEditBusStop,
  onDeleteBusStop,
  onCreateCorridor,
  onDeleteCorridor,
  onAddGeofenceArea,
}) => {
  const coordElRef = useRef();
  const [anchorEl, setAnchorEl] = useState();

  const close = () => {
    setAnchorEl();
    onClose();
  };

  const clickAction = (action) => {
    action();
    close();
  };

  useEffect(() => {
    if (mousePosition.length) {
      coordElRef.current.style.position = 'absolute';
      coordElRef.current.style.left = `${mousePosition[0] + 10}px`;
      coordElRef.current.style.top = `${mousePosition[1] + 30}px`;
      setAnchorEl(coordElRef.current);
    }
  }, [mousePosition]);

  return (
    <>
      <div ref={coordElRef} />
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={close}>
        <MenuItem onClick={() => clickAction(onAddIntersection)}>Add Intersection</MenuItem>
        <MenuItem onClick={() => clickAction(onAddSegment)}>Add Segment</MenuItem>
        {onCreateApproach && <MenuItem onClick={() => clickAction(onCreateApproach)}>Add Approach</MenuItem>}
        {isShowDetectionAreaVisible && (
          <MenuItem onClick={() => clickAction(onShowDetectionArea)}>Show Detection Area</MenuItem>
        )}
        {isShowDetectionAreaVisible && (
          <MenuItem onClick={() => clickAction(onAddDetectionArea)}>Add Detection Area</MenuItem>
        )}
        <MenuItem onClick={() => clickAction(onAddGeofenceArea)}>Add Geofence Area</MenuItem>
        {onEditBusStop && <MenuItem onClick={() => clickAction(onEditBusStop)}>Edit Bus Stop</MenuItem>}
        {onCreateCorridor && <MenuItem onClick={() => clickAction(onCreateCorridor)}>Add Corridor</MenuItem>}
        {(onDeleteApproach || onDeleteIntersection || onDeleteSegment || onDeleteBusStop || onDeleteCorridor) && (
          <Divider />
        )}
        {onDeleteApproach && <MenuItem onClick={() => clickAction(onDeleteApproach)}>Delete Approach</MenuItem>}
        {onDeleteIntersection && (
          <MenuItem onClick={() => clickAction(onDeleteIntersection)}>Delete Intersection</MenuItem>
        )}
        {onDeleteSegment && <MenuItem onClick={() => clickAction(onDeleteSegment)}>Delete Segment</MenuItem>}
        {onDeleteBusStop && <MenuItem onClick={() => clickAction(onDeleteBusStop)}>Delete Bus Stop</MenuItem>}
        {onDeleteCorridor && <MenuItem onClick={() => clickAction(onDeleteCorridor)}>Delete Corridor</MenuItem>}
      </Menu>
    </>
  );
};

RightClickMenu.propTypes = {
  mousePosition: propTypes.array.isRequired,
  onClose: propTypes.func.isRequired,
  onAddIntersection: propTypes.func.isRequired,
  onDeleteIntersection: propTypes.func,
  onAddSegment: propTypes.func.isRequired,
  onDeleteSegment: propTypes.func,
  onShowDetectionArea: propTypes.func.isRequired,
  onAddDetectionArea: propTypes.func.isRequired,
  onAddGeofenceArea: propTypes.func.isRequired,
  isShowDetectionAreaVisible: propTypes.bool.isRequired,
  onCreateApproach: propTypes.func,
  onDeleteApproach: propTypes.func,
  onEditBusStop: propTypes.func,
  onDeleteBusStop: propTypes.func,
  onCreateCorridor: propTypes.func,
  onDeleteCorridor: propTypes.func,
};

RightClickMenu.defaultProps = {
  onCreateApproach: null,
  onDeleteApproach: null,
  onDeleteIntersection: null,
  onDeleteSegment: null,
  onEditBusStop: null,
  onDeleteBusStop: null,
  onCreateCorridor: null,
  onDeleteCorridor: null,
};
