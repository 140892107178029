import React from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { selectSpeedMultiplier, selectDistanceMultiplier, selectSpeedUnit, selectDistanceUnit } from 'features';
import { Graph } from 'components';
import { useIntl } from 'utils/hooks';
import { panelTitle } from 'utils';
import CorridorGraphsStyles from './CorridorGraphsStyles.styles';

const useStyles = makeStyles(CorridorGraphsStyles);

const applyMultiplier = (data, conversionMultiplier) => {
  if (Array.isArray(data)) {
    return data.map((item) => ({ ...item, x: item.x * conversionMultiplier }));
  }

  return {
    first: data.first?.map((item) => ({ ...item, x: item.x * conversionMultiplier })),
    second: data.second?.map((item) => ({ ...item, x: item.x * conversionMultiplier })),
  };
};

export const CorridorGraphs = ({ yTitle, panelName, cdf, pdf, valueKey, isFullWidth, height }) => {
  const intl = useIntl();
  const classes = useStyles({ height });
  const speedMultiplier = useSelector(selectSpeedMultiplier);
  const distanceMultiplier = useSelector(selectDistanceMultiplier);
  const speedUnit = useSelector(selectSpeedUnit);
  const distanceUnit = useSelector(selectDistanceUnit);

  let unit;

  let cdfData = cdf;

  let pdfData = pdf;

  if (['waiting-time', 'dwell-time'].includes(panelName)) {
    unit = intl.formatMessage({ description: 'Seconds', defaultMessage: 's' });
  } else if (['queue-length'].includes(panelName)) {
    unit = distanceUnit;
    cdfData = applyMultiplier(cdf, distanceMultiplier);
    pdfData = applyMultiplier(pdf, distanceMultiplier);
  } else if (['speed'].includes(panelName)) {
    unit = speedUnit;
    cdfData = applyMultiplier(cdf, speedMultiplier);
    pdfData = applyMultiplier(pdf, speedMultiplier);
  }

  let title =
    panelName === 'waiting-time' && valueKey.includes('Driving')
      ? intl.formatMessage({
          description: 'Bottom container graphs Driving time label',
          defaultMessage: 'Driving time',
        })
      : panelTitle(panelName, intl);

  if (unit) {
    title = `${title} [${unit}]`;
  }

  return (
    <div className={classes.wrapper}>
      {cdf && (
        <>
          <Box className={`${classes.root} ${isFullWidth && classes.rootFullWidth}`} style={{ height: height || 300 }}>
            <Graph data={cdfData} xTitle={title} yTitle={yTitle || 'CDF'} />
          </Box>
          <Box className={`${classes.root} ${isFullWidth && classes.rootFullWidth}`} style={{ height: height || 300 }}>
            <Graph data={pdfData} xTitle={title} yTitle={yTitle || 'PDF'} />
          </Box>
        </>
      )}
    </div>
  );
};

CorridorGraphs.propTypes = {
  isFullWidth: propTypes.bool,
  cdf: propTypes.oneOfType([propTypes.object, propTypes.array]).isRequired,
  pdf: propTypes.oneOfType([propTypes.object, propTypes.array]).isRequired,
  height: propTypes.number,
  yTitle: propTypes.string,
  panelName: propTypes.string.isRequired,
  valueKey: propTypes.string.isRequired,
};

CorridorGraphs.defaultProps = {
  isFullWidth: false,
  height: null,
  yTitle: null,
};
