import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllApproaches,
  getAllCameras,
  getRidesByCameraAndDay,
  getBackendVersion,
  getGeofenceArea,
  getCameraDays,
  getFrames,
  addIntersection,
  addSegment,
  getOsmExport,
  getSegments,
  getDirections,
  getDetectionArea,
  createApproach,
  deleteApproach,
  deleteIntersection,
  deleteSegment,
  getOsmBusStops,
  editBusStop,
  deleteBusStop,
  createCorridor,
  deleteCorridor,
  addDetectionArea,
  addGeofenceArea,
} from 'api/endpoints';
import { setToastMessage } from 'features/appStateSlice';

export const fetchAllApproaches = createAsyncThunk('admin/fetchAllApproaches', async (_, thunkAPI) => {
  const allApproachesRes = await getAllApproaches();

  if (allApproachesRes.status && allApproachesRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(allApproachesRes.data));

    return thunkAPI.rejectWithValue();
  }

  return allApproachesRes.data;
});

export const fetchAllCameraIds = createAsyncThunk('admin/fetchAllCameraIds', async (_, thunkAPI) => {
  const allCameraIdsRes = await getAllCameras();

  if (allCameraIdsRes.status && allCameraIdsRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(allCameraIdsRes.data));

    return thunkAPI.rejectWithValue();
  }

  return allCameraIdsRes.data;
});

export const fetchRides = createAsyncThunk('admin/fetchRides', async ({ cameraId, day }, thunkAPI) => {
  const ridesRes = await getRidesByCameraAndDay(cameraId, day);

  if (ridesRes.status && ridesRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(ridesRes.data));

    return thunkAPI.rejectWithValue();
  }

  return ridesRes.data;
});

export const fetchBackendVersion = createAsyncThunk('admin/fetchBackendVersion', async (_, thunkAPI) => {
  const backendVersionRes = await getBackendVersion();

  if (backendVersionRes.status && backendVersionRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(backendVersionRes.data));

    return thunkAPI.rejectWithValue();
  }

  return backendVersionRes.data;
});

export const fetchCameraDays = createAsyncThunk('admin/fetchCameraDays', async ({ cameraId }, thunkAPI) => {
  const cameraDaysRes = await getCameraDays(cameraId);

  if (cameraDaysRes.status && cameraDaysRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(cameraDaysRes.data));

    return thunkAPI.rejectWithValue();
  }

  return cameraDaysRes.data;
});

export const fetchRidesFrames = createAsyncThunk('admin/fetchRidesFrames', async (postData, thunkAPI) => {
  const ridesFramesRes = await getFrames(postData);

  if (ridesFramesRes.status && ridesFramesRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(ridesFramesRes.data));

    return thunkAPI.rejectWithValue({ error: ridesFramesRes.error });
  }

  return ridesFramesRes.data;
});

export const handleAddIntersection = createAsyncThunk('admin/handleAddIntersection', async (requestBody, thunkAPI) => {
  const addIntersectionRes = await addIntersection(requestBody);

  if (addIntersectionRes.status && addIntersectionRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(addIntersectionRes.data));

    return thunkAPI.rejectWithValue();
  }

  return undefined;
});

export const handleDeleteIntersection = createAsyncThunk(
  'admin/handleDeleteIntersection',
  async ({ intersectionId }, thunkAPI) => {
    const handleDeleteIntersectionRes = await deleteIntersection(intersectionId);

    if (handleDeleteIntersectionRes.status && handleDeleteIntersectionRes.status !== 200) {
      thunkAPI.dispatch(setToastMessage(handleDeleteIntersectionRes.data));

      return thunkAPI.rejectWithValue();
    }

    return undefined;
  },
);

export const handleAddSegment = createAsyncThunk('admin/handleAddSegment', async (requestBody, thunkAPI) => {
  const addSegmentRes = await addSegment(requestBody);

  if (addSegmentRes.status && addSegmentRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(addSegmentRes.data));

    return thunkAPI.rejectWithValue();
  }

  return undefined;
});

export const handleDeleteSegment = createAsyncThunk('admin/handleDeleteSegment', async ({ segmentId }, thunkAPI) => {
  const handleDeleteSegmentRes = await deleteSegment(segmentId);

  if (handleDeleteSegmentRes.status && handleDeleteSegmentRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(handleDeleteSegmentRes.data));

    return thunkAPI.rejectWithValue();
  }

  return undefined;
});

export const handleCreateApproach = createAsyncThunk('admin/handleCreateApproach', async (requestBody, thunkAPI) => {
  const createApproachRes = await createApproach(requestBody);

  if (createApproachRes.status && createApproachRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(createApproachRes.data));

    return thunkAPI.rejectWithValue();
  }

  return undefined;
});

export const handleDeleteApproach = createAsyncThunk('admin/handleDeleteApproach', async ({ approachId }, thunkAPI) => {
  const deleteApproachRes = await deleteApproach(approachId);

  if (deleteApproachRes.status && deleteApproachRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(deleteApproachRes.data));

    return thunkAPI.rejectWithValue();
  }

  return undefined;
});

export const fetchOsmExport = createAsyncThunk('admin/fetchOsmExport', async (_, thunkAPI) => {
  const osmExportRes = await getOsmExport();

  if (osmExportRes.status && osmExportRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(osmExportRes.data));

    return thunkAPI.rejectWithValue();
  }

  return osmExportRes.data;
});

export const fetchSegments = createAsyncThunk('admin/fetchSegments', async (_, thunkAPI) => {
  const segmentsRes = await getSegments();

  if (segmentsRes.status && segmentsRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(segmentsRes.data));

    return thunkAPI.rejectWithValue();
  }

  return segmentsRes.data;
});

export const fetchDirections = createAsyncThunk('admin/fetchDirections', async (_, thunkAPI) => {
  const directionsRes = await getDirections();

  if (directionsRes.status && directionsRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(directionsRes.data));

    return thunkAPI.rejectWithValue();
  }

  return directionsRes.data;
});

export const fetchDetectionArea = createAsyncThunk('admin/fetchDetectionArea', async (id, thunkAPI) => {
  const detectionAreaRes = await getDetectionArea(id);

  if (detectionAreaRes.status && detectionAreaRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(detectionAreaRes.data));

    return thunkAPI.rejectWithValue();
  }

  return detectionAreaRes.data;
});

export const handleAddDetectionArea = createAsyncThunk(
  'admin/handleAddDetectionArea',
  async (requestBody, thunkAPI) => {
    const addDetectionAreaRes = await addDetectionArea(requestBody);

    if (addDetectionAreaRes.status && addDetectionAreaRes.status !== 200) {
      thunkAPI.dispatch(setToastMessage(addDetectionAreaRes.data));

      return thunkAPI.rejectWithValue();
    }

    return undefined;
  },
);

export const fetchGeofenceArea = createAsyncThunk('admin/fetchGeofenceArea', async (_, thunkAPI) => {
  const geofenceAreaRes = await getGeofenceArea();

  if (geofenceAreaRes.status && geofenceAreaRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(geofenceAreaRes.data));

    return thunkAPI.rejectWithValue();
  }

  return geofenceAreaRes.data;
});

export const handleAddGeofenceArea = createAsyncThunk('admin/handleAddGeofenceArea', async (requestBody, thunkAPI) => {
  const addGeofenceAreaRes = await addGeofenceArea(requestBody);

  if (addGeofenceAreaRes.status && addGeofenceAreaRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(addGeofenceAreaRes.data));

    return thunkAPI.rejectWithValue();
  }

  return undefined;
});

export const fetchOsmBusStops = createAsyncThunk('admin/fetchOsmBusStops', async (_, thunkAPI) => {
  const osmBusStopsRes = await getOsmBusStops();

  if (osmBusStopsRes.status && osmBusStopsRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(osmBusStopsRes.data));

    return thunkAPI.rejectWithValue();
  }

  return osmBusStopsRes.data;
});

export const handleEditBusStop = createAsyncThunk('admin/handleEditBusStop', async (requestObject, thunkAPI) => {
  const editBusStopRes = await editBusStop(requestObject);

  if (editBusStopRes.status && editBusStopRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(editBusStopRes.data));

    return thunkAPI.rejectWithValue();
  }

  return editBusStopRes.data;
});

export const handleDeleteBusStop = createAsyncThunk('admin/handleDeleteBusStop', async ({ id }, thunkAPI) => {
  const deleteBusStopRes = await deleteBusStop(id);

  if (deleteBusStopRes.status && deleteBusStopRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(deleteBusStopRes.data));

    return thunkAPI.rejectWithValue();
  }

  return deleteBusStopRes.data;
});

export const handleCreateCorridor = createAsyncThunk('admin/handleCreateCorridor', async (requestObject, thunkAPI) => {
  const createCorridorRes = await createCorridor(requestObject);

  if (createCorridorRes.status && createCorridorRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(createCorridorRes.data));

    return thunkAPI.rejectWithValue();
  }

  return createCorridorRes.data;
});

export const handleDeleteCorridor = createAsyncThunk('admin/handleDeleteCorridor', async (id, thunkAPI) => {
  const deleteCorridorRes = await deleteCorridor(id);

  if (deleteCorridorRes.status && deleteCorridorRes.status !== 200) {
    thunkAPI.dispatch(setToastMessage(deleteCorridorRes.data));

    return thunkAPI.rejectWithValue();
  }

  return deleteCorridorRes.data;
});
