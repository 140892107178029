import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as turf from '@turf/turf';
import {
  selectIsLoading,
  setIsNavigationOpen,
  setCurrentCameraId,
  selectIntersections,
  selectAllApproaches,
  selectLoadingAllApproaches,
  selectAllCameraIds,
  selectRides,
  selectCameraDays,
  selectCurrentCameraId,
  selectRidesFrames,
  selectActiveFrameCords,
  selectSelectedApproachId,
  selectLoadingAdminFrames,
  selectAllBusStops,
  fetchAllIntersections,
  fetchAllApproaches,
  fetchAllCameraIds,
  fetchRides,
  fetchCameraDays,
  clearCameraDays,
  clearRidesFrames,
  fetchAllBusStops,
  selectTenants,
} from 'features';
import {
  initMap,
  initLayer,
  layerStyles,
  initAdminViewEvents,
  initFeatureStatsOverlay,
  featureStatsOverlay,
  centerMap,
} from 'utils';
import { Header, SidebarContainer, AdminRidesContainer, FeatureStatsPopupOverlay, TileLayerSwitch } from 'components';
import { Menu, ExpandLess, ExpandMore } from '@material-ui/icons';
import { List, ListItem, ListItemText, Checkbox, FormControlLabel, Collapse } from '@material-ui/core';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import styles from './AdminData.module.scss';

export const AdminData = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const intersections = useSelector(selectIntersections);
  const allApproaches = useSelector(selectAllApproaches);
  const loadingAllApproaches = useSelector(selectLoadingAllApproaches);
  const allCameraIds = useSelector(selectAllCameraIds);
  const rides = useSelector(selectRides);
  const isLoading = useSelector(selectIsLoading);
  const cameraDays = useSelector(selectCameraDays);
  const currentCameraId = useSelector(selectCurrentCameraId);
  const ridesFrames = useSelector(selectRidesFrames);
  const activeFrameCords = useSelector(selectActiveFrameCords);
  const selectedApproachId = useSelector(selectSelectedApproachId);
  const loadingAdminFrames = useSelector(selectLoadingAdminFrames);
  const busStops = useSelector(selectAllBusStops);
  const tenants = useSelector(selectTenants);

  /* ---------------------------------- State --------------------------------- */
  const [olMap, setOlMap] = useState();
  const [selectedDayIndex, setSelectedDayIndex] = useState();
  const [isRidesListOpen, setIsRidesListOpen] = useState(false);
  const [layerNames, setLayerNames] = useState([]);
  const [isCameraIdExpanded, setIsCameraIdExpanded] = useState(false);
  const [prevSelectedApproachId, setPrevSelectedApproachId] = useState();

  /* -------------------------------- Functions ------------------------------- */
  const handleHeaderClick = () => {
    dispatch(setIsNavigationOpen(true));
  };

  const initIntersectionsLayer = () => {
    const intersectionsLayer = initLayer(
      'Intersections',
      {
        type: 'FeatureCollection',
        features: intersections.all,
      },
      layerStyles.corridorView.intersections,
      1,
    );

    olMap.addLayer(intersectionsLayer);
  };

  const findLayerByName = (name) =>
    olMap
      .getLayers()
      .getArray()
      .find((layer) => layer.getProperties().name === name);

  const toggleLayer = (name) => {
    const layer = findLayerByName(name);

    if (layer) layer.setVisible(!layer.getVisible());
  };

  const hideLayer = (name) => {
    const layer = findLayerByName(name);

    if (layer) layer.setVisible(false);
  };

  const onCameraIdClick = (cameraId) => {
    if (currentCameraId !== cameraId) {
      dispatch(clearCameraDays());
      setIsCameraIdExpanded(true);
      dispatch(setCurrentCameraId(cameraId));
      setSelectedDayIndex();
      hideLayer('Rides');
      dispatch(fetchCameraDays({ cameraId }));
      setIsRidesListOpen(false);
    } else {
      setIsCameraIdExpanded(!isCameraIdExpanded);
    }
  };

  const onCameraDayClick = (cameraId, day, index) => {
    if (selectedDayIndex !== index) {
      setSelectedDayIndex(index);
      hideLayer('Rides');
      dispatch(fetchRides({ cameraId, day }));
    }
    setIsRidesListOpen(true);
  };

  const addRideLayerToMap = (approaches) => {
    const ridesCollection = {
      type: 'FeatureCollection',
      features: approaches.map((approach) => {
        const rideFeature = JSON.parse(approach.approachGeoJson);
        const { blockage, driveTime, dwellTime, queueLength, queueTime, sectionOrder, speed, stops } = approach;

        return {
          ...rideFeature,
          properties: {
            ...rideFeature.properties,
            responseProps: { blockage, driveTime, dwellTime, queueLength, queueTime, sectionOrder, speed, stops },
          },
        };
      }),
    };

    const ridesLayer = initLayer('Rides', ridesCollection, layerStyles.adminView.totalRidesSegments, 1);

    olMap.removeLayer(findLayerByName('Rides'));
    olMap.addLayer(ridesLayer);
    olMap
      .getView()
      .fit(ridesLayer.getSource().getExtent(), { duration: 1000, padding: [50, 50, 50, 50], minResolution: 2 });
  };

  const getFeatureById = (id) => findLayerByName('Rides').getSource().getFeatureById(id);

  const selectApproach = (approachId) => {
    if (prevSelectedApproachId && getFeatureById(prevSelectedApproachId)) {
      getFeatureById(prevSelectedApproachId).set('isClicked', false);
    }
    getFeatureById(approachId).set('isClicked', true);
    setPrevSelectedApproachId(approachId);
  };

  const addFramesPathLayerToMap = () => {
    const framesLineString = turf.lineString(ridesFrames.map((frame) => [frame.lon, frame.lat]));
    const framesPathLayer = initLayer('Frames Path', framesLineString, layerStyles.adminView.framesPathSegments);
    const lineCoords = framesLineString.geometry.coordinates;
    const startPoint = new Feature({ geometry: new Point(fromLonLat(lineCoords[0])) });
    const endPoint = new Feature({ geometry: new Point(fromLonLat(lineCoords[lineCoords.length - 1])) });

    startPoint.setStyle(layerStyles.adminView.startText);
    endPoint.setStyle(layerStyles.adminView.endText);
    framesPathLayer.getSource().addFeature(startPoint);
    framesPathLayer.getSource().addFeature(endPoint);

    olMap.removeLayer(findLayerByName('Frames Path'));
    olMap.addLayer(framesPathLayer);
  };

  const addCurrentFrameLayerToMap = () => {
    const currentFrame = turf.point([activeFrameCords.curr.lonCurr, activeFrameCords.curr.latCurr]);
    const currentFrameLayer = initLayer('Current Frame', currentFrame, layerStyles.adminView.currentFrame, 2);

    olMap.removeLayer(findLayerByName('Current Frame'));
    olMap.addLayer(currentFrameLayer);
  };

  const onBottomContainerClose = () => {
    setIsRidesListOpen(false);
    hideLayer('Rides');
    dispatch(clearRidesFrames());
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    if (!ridesFrames.length || !olMap || !findLayerByName('Rides')) return;

    addFramesPathLayerToMap();
  }, [ridesFrames, olMap]); //eslint-disable-line

  useEffect(() => {
    if (!activeFrameCords.curr.lonCurr || !olMap || !findLayerByName('Rides')) return;

    addCurrentFrameLayerToMap();
  }, [activeFrameCords, olMap]); //eslint-disable-line

  useEffect(() => {
    dispatch(fetchAllIntersections());
    dispatch(fetchAllApproaches());
    dispatch(fetchAllCameraIds());
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!tenants?.currentTenant) return;

    initFeatureStatsOverlay();
    if (!busStops) {
      dispatch(fetchAllBusStops());
    }
    const map = initMap({ name: 'AllData', overlays: [...Object.values(featureStatsOverlay)] });

    setOlMap(map);
    initAdminViewEvents(map, setLayerNames);
  }, [tenants?.currentTenant]); //eslint-disable-line

  useEffect(() => {
    if (intersections.loadingAll !== 'loaded' || !olMap) return;
    initIntersectionsLayer();
  }, [intersections.loadingAll, olMap]); //eslint-disable-line

  useEffect(() => {
    if (!olMap || loadingAllApproaches !== 'loaded') return;

    const approachesLayer = initLayer('Approaches', allApproaches, layerStyles.corridorView.corridor, 1);

    olMap.addLayer(approachesLayer);
    centerMap(olMap, approachesLayer);
  }, [olMap, loadingAllApproaches]); //eslint-disable-line

  useEffect(() => {
    if (!busStops || !olMap) return;

    const busStopsLayer = initLayer('Bus Stops', busStops, layerStyles.corridorView.busStops);

    olMap.addLayer(busStopsLayer);
  }, [busStops, olMap]);

  useEffect(() => {
    if (selectedApproachId) {
      selectApproach(selectedApproachId);
    }
  }, [selectedApproachId]); //eslint-disable-line

  useEffect(() => {
    if (olMap && loadingAdminFrames === 'error') {
      olMap.removeLayer(findLayerByName('Frames Path'));
    }
  }, [loadingAdminFrames]); //eslint-disable-line

  useEffect(() => {
    dispatch(clearRidesFrames());
  }, [currentCameraId, selectedDayIndex]); //eslint-disable-line

  return (
    <div
      className={`
      ${styles.root}
      ${isLoading ? 'loadingOverlayShown' : ''}
    `}
    >
      <FeatureStatsPopupOverlay />
      <div id="map" className={styles.map} />
      <div className={styles.header}>
        <Header icon={<Menu onClick={handleHeaderClick} />} hasText={false} bgColor="transparent" />
      </div>
      <SidebarContainer>
        <div className={styles.contentWrapper}>
          <span className={styles.title}>All Camera IDs</span>
          {allCameraIds && (
            <List dense>
              {allCameraIds.map((cameraId) => (
                <Fragment key={cameraId}>
                  <ListItem button onClick={() => onCameraIdClick(cameraId)} selected={currentCameraId === cameraId}>
                    <ListItemText primary={cameraId} />
                    {isCameraIdExpanded && currentCameraId === cameraId ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={isCameraIdExpanded && currentCameraId === cameraId} timeout="auto" unmountOnExit>
                    <List dense className={styles.nestedList}>
                      {cameraDays.map((day, nestedIndex) => (
                        <ListItem
                          button
                          key={day}
                          onClick={() => onCameraDayClick(cameraId, day, nestedIndex)}
                          selected={selectedDayIndex === nestedIndex}
                        >
                          <ListItemText primary={day} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </Fragment>
              ))}
            </List>
          )}
        </div>
      </SidebarContainer>
      <div className={styles.topRight}>
        <TileLayerSwitch />
        <div className={styles.mapLayers}>
          <span className={styles.title}>Map Layers</span>
          <List dense>
            {layerNames?.map((layerName) => {
              const layer = findLayerByName(layerName);

              return (
                <ListItem key={layer.ol_uid}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={layer.getVisible()} color="primary" onClick={() => toggleLayer(layerName)} />
                    }
                    label={layerName}
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
      <AdminRidesContainer
        rides={rides}
        addRideLayerToMap={addRideLayerToMap}
        slideIn={isRidesListOpen}
        onClose={onBottomContainerClose}
      />
    </div>
  );
};
