import axios from 'axios';
import {
  CORRIDORS,
  FRAMES,
  INTERSECTIONS,
  PHASE_DIAGRAM,
  PRINCIPAL,
  RIDES,
  APPROACHES,
  BUS_STOPS,
  CORRIDOR_STAT,
  APPROACH_STAT,
  APPROACHES_STAT,
  APPROACH_STAT_DAYS,
  APPROACH_STAT_SAMPLES,
  PEDESTRIANS_STAT,
  PEDESTRIANS_STAT_APPROACHES,
  ODMATRIX,
  ADMIN_CAMERA_IDS,
  SIMULATION,
  SIM,
  STATISTIC_STAT,
  SETTINGS,
  STATISTIC_RES,
  USER,
  INFO,
  PROGRAMS,
  TENANTS,
  ADMIN_TENANT,
  AGGAPPROACHSTAT,
  ADMIN_RIDES,
  INTERSECTION_AREA,
  ACTUATOR_INFO,
  CAMERA_DAYS,
  BUS_STOP_STAT_SAMPLES,
  OSMEXPORT,
  ADD_INTERSECTION,
  ADD_SEGMENT,
  SEGMENTS,
  DIRECTIONS,
  CREATE_APPROACH,
  DELETE_APPROACH,
  DELETE_INTERSECTION,
  DELETE_SEGMENT,
  OSM_BUS_STOPS,
  EDIT_BUS_STOP,
  DELETE_BUS_STOP,
  CREATE_DELETE_CORRIDOR,
  GEOFENCE_AREA,
  TIME_INTERVALS,
  MAPBOX_MATCHING_BASE_URL,
} from 'consts';
import { get, post, del, mobileyeAPI } from './api';

export const getFrames = (requestObject, headers = {}, options) => post(FRAMES, requestObject, headers, options);
export const getApproaches = (requestObject, headers = {}, options) =>
  post(APPROACHES, requestObject, headers, options);
export const getAllApproaches = () => get(APPROACHES);
export const getRides = (requestObject, headers = {}, options) => post(RIDES, requestObject, headers, options);
export const getStatistics = (requestObject, headers = {}) => post(CORRIDOR_STAT, requestObject, headers);
export const getApproachStats = (requestObject, headers = {}, options) =>
  post(APPROACH_STAT, requestObject, headers, options);
export const getApproachesStats = (requestObject, headers = {}, options) =>
  post(APPROACHES_STAT, requestObject, headers, options);
export const getApproachesStatDays = (requestObject, headers = {}, options) =>
  post(APPROACH_STAT_DAYS, requestObject, headers, options);
export const getApproachesStatSamples = (requestObject, headers = {}, options) =>
  post(APPROACH_STAT_SAMPLES, requestObject, headers, options);
export const getBusStopStatSamples = (requestObject, headers = {}, options) =>
  post(BUS_STOP_STAT_SAMPLES, requestObject, headers, options);
export const getPedestriansStats = (requestObject, headers = {}, options) =>
  post(PEDESTRIANS_STAT, requestObject, headers, options);
export const getPedestriansStatApproaches = (requestObject, headers = {}, options) =>
  post(PEDESTRIANS_STAT_APPROACHES, requestObject, headers, options);
export const getHomeStats = (requestObject, headers = {}, options) =>
  post(`${AGGAPPROACHSTAT}`, requestObject, headers, options);
export const getPrincipal = () => get(PRINCIPAL);
export const getUserInfo = () => get(`${USER}/${INFO}`);
export const getCorridors = async () => get(CORRIDORS);
export const getAllIntersections = () => get(INTERSECTIONS);
export const getIntersectionsByCorridor = (corridorId) => get(`${INTERSECTIONS}${`?corridorId=${corridorId}`}`);
export const getBusStops = (corridorId) => get(`${BUS_STOPS}/${corridorId}`);
export const getAllBusStops = () => get(BUS_STOPS);
export const getPhaseDiagramData = (corridor, junctionID, program) =>
  get(`${PHASE_DIAGRAM}/${corridor}/${junctionID}/${program}`);
export const getSimulationStatus = (id) => get(`${SIM}/${id}/status`);
export const getSimulationResult = (id) => get(`${SIM}/${id}`);
export const getSimulationStatistics = (id) => get(`${SIM}/${id}/${STATISTIC_STAT}`);
export const getSettings = () => get(SETTINGS);
export const getSimulationDiagram = (id) => get(`${SIM}/${id}/${STATISTIC_RES}`);
export const getPrograms = (corridorId, junctionId) => get(`${PROGRAMS}/${corridorId}/${junctionId}`);
export const getTenants = () => get(TENANTS);
export const setTenant = (id) => get(`${ADMIN_TENANT}/${id}`);
export const getChordDiagramData = (simulationId, corridorId, threshold) =>
  get(`${SIMULATION}/${simulationId}/${ODMATRIX}?corridorId=${corridorId}&threshold=${threshold}`);
export const getAllCameras = () => get(ADMIN_CAMERA_IDS);
export const getRidesByCameraAndDay = (cameraId, day) => get(`${ADMIN_RIDES}?cameraId=${cameraId}&day=${day}`);
export const getBackendVersion = () => get(ACTUATOR_INFO);
export const getCameraDays = (cameraId) => get(`${CAMERA_DAYS}?cameraId=${cameraId}`);
export const getOsmExport = () => get(OSMEXPORT);
export const addIntersection = (requestObject, headers = {}, options) =>
  post(ADD_INTERSECTION, requestObject, headers, options);
export const deleteIntersection = (id, headers = {}, options) => del(`${DELETE_INTERSECTION}/${id}`, headers, options);
export const addSegment = (requestObject, headers = {}, options) => post(ADD_SEGMENT, requestObject, headers, options);
export const deleteSegment = (id, headers = {}, options) => del(`${DELETE_SEGMENT}/${id}`, headers, options);
export const createApproach = (requestObject, headers = {}, options) =>
  post(CREATE_APPROACH, requestObject, headers, options);
export const deleteApproach = (id, headers = {}, options) => del(`${DELETE_APPROACH}/${id}`, headers, options);
export const getSegments = () => get(SEGMENTS);
export const getDirections = () => get(DIRECTIONS);
export const getDetectionArea = (id) => get(`${INTERSECTION_AREA}/${id}`);
export const addDetectionArea = (requestObject, headers = {}, options) =>
  post(INTERSECTION_AREA, requestObject, headers, options);
export const getGeofenceArea = () => get(GEOFENCE_AREA);
export const addGeofenceArea = (requestObject, headers = {}, options) =>
  post(GEOFENCE_AREA, requestObject, headers, options);
export const getOsmBusStops = () => get(OSM_BUS_STOPS);
export const editBusStop = (requestObject, headers = {}, options) =>
  post(EDIT_BUS_STOP, requestObject, headers, options);
export const deleteBusStop = (id, headers = {}, options) => del(`${DELETE_BUS_STOP}/${id}`, headers, options);
export const createCorridor = (requestObject, headers = {}, options) =>
  post(CREATE_DELETE_CORRIDOR, requestObject, headers, options);
export const deleteCorridor = (id, headers = {}, options) => del(`${CREATE_DELETE_CORRIDOR}/${id}`, headers, options);
export const getTimeIntervals = () => get(TIME_INTERVALS);
export const editTimeIntervals = (requestObject, headers = {}, options) =>
  post(TIME_INTERVALS, requestObject, headers, options);

export const getSnappedCoords = (formattedCoords) =>
  axios({
    method: 'get',
    url: `${MAPBOX_MATCHING_BASE_URL}/driving/${formattedCoords}?&access_token=${process.env.REACT_APP_MAPBOX_KEY}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });

// Mobileye
export const postMobileyeLogin = () => mobileyeAPI.post('login', { username: 'foo', password: 'bar' });
export const postMobileyeLiveEvents = (data) => mobileyeAPI.post('events', data);
export const postRealTimeTraffic = (data) => mobileyeAPI.post('real-time-traffic-flow', data);
