import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAllApproaches,
  fetchAllCameraIds,
  fetchRides,
  fetchBackendVersion,
  fetchCameraDays,
  fetchRidesFrames,
  handleAddDetectionArea,
  fetchOsmExport,
  fetchSegments,
  fetchDirections,
  fetchDetectionArea,
  handleAddIntersection,
  handleAddSegment,
  handleCreateApproach,
  handleDeleteApproach,
  handleDeleteIntersection,
  handleDeleteSegment,
  fetchOsmBusStops,
  handleEditBusStop,
  handleDeleteBusStop,
  fetchGeofenceArea,
  handleCreateCorridor,
  handleDeleteCorridor,
  handleAddGeofenceArea,
} from 'features/thunks';

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    allApproaches: undefined,
    loadingAllApproaches: 'idle',
    allCameraIds: undefined,
    currentCameraId: undefined,
    rides: undefined,
    backendVersion: undefined,
    cameraDays: [],
    ridesFrames: [],
    loadingFrames: 'idle',
    selectedApproachId: undefined,
    busStops: undefined,
    osmExport: [],
    loadingOsmExport: 'idle',
    segments: [],
    loadingSegments: 'idle',
    directions: [],
    loadingDirections: 'idle',
    detectionAreaCoords: [],
    loadingDetectionArea: 'idle',
    loadingAddDetectionArea: 'idle',
    geofenceAreaCoords: [],
    loadingGeofenceArea: 'idle',
    loadingAddGeofenceArea: 'idle',
    loadingAddIntersection: 'idle',
    loadingDeleteIntersection: 'idle',
    loadingAddSegment: 'idle',
    loadingDeleteSegment: 'idle',
    loadingAddApproach: 'idle',
    loadingDeleteApproach: 'idle',
    osmBusStops: [],
    loadingOsmBusStops: 'idle',
    loadingEditBusStop: 'idle',
    loadingDeleteBusStop: 'idle',
    loadingAddCorridor: 'idle',
    loadingDeleteCorridor: 'idle',
  },
  reducers: {
    clearCameraDays: (state) => {
      state.cameraDays = [];
    },
    setCurrentCameraId: (state, action) => {
      state.currentCameraId = action.payload;
    },
    setSelectedApproachId: (state, action) => {
      state.selectedApproachId = action.payload;
    },
    clearRidesFrames: (state) => {
      state.ridesFrames = [];
    },
    resetLoadingAddIntersection: (state) => {
      state.loadingAddIntersection = 'idle';
    },
    resetLoadingDeleteIntersection: (state) => {
      state.loadingDeleteIntersection = 'idle';
    },
    resetLoadingAddSegment: (state) => {
      state.loadingAddSegment = 'idle';
    },
    resetLoadingDeleteSegment: (state) => {
      state.loadingDeleteSegment = 'idle';
    },
    resetLoadingAddApproach: (state) => {
      state.loadingAddApproach = 'idle';
    },
    resetLoadingDeleteApproach: (state) => {
      state.loadingDeleteApproach = 'idle';
    },
    resetLoadingEditBusStop: (state) => {
      state.loadingEditBusStop = 'idle';
    },
    resetLoadingDeleteBusStop: (state) => {
      state.loadingDeleteBusStop = 'idle';
    },
    resetLoadingAddCorridor: (state) => {
      state.loadingAddCorridor = 'idle';
    },
    resetLoadingDeleteCorridor: (state) => {
      state.loadingDeleteCorridor = 'idle';
    },
    resetLoadingAddDetectionArea: (state) => {
      state.loadingAddDetectionArea = 'idle';
    },
    resetLoadingAddGeofenceArea: (state) => {
      state.loadingAddGeofenceArea = 'idle';
    },
    resetLoadingOSMBusStops: (state) => {
      state.loadingOSMBusStops = 'idle';
    },
  },
  extraReducers: {
    [fetchAllApproaches.fulfilled]: (state, action) => {
      state.allApproaches = action.payload;
      state.loadingAllApproaches = 'loaded';
    },
    [fetchAllApproaches.pending]: (state) => {
      state.loadingAllApproaches = 'loading';
    },
    [fetchAllCameraIds.fulfilled]: (state, action) => {
      state.allCameraIds = action.payload;
    },
    [fetchRides.fulfilled]: (state, action) => {
      state.rides = action.payload;
    },
    [fetchBackendVersion.fulfilled]: (state, action) => {
      state.backendVersion = action.payload.build.version;
    },
    [fetchCameraDays.fulfilled]: (state, action) => {
      state.cameraDays = action.payload;
    },
    [fetchRidesFrames.fulfilled]: (state, action) => {
      state.ridesFrames = action.payload.Frames;
      state.loadingFrames = 'loaded';
    },
    [fetchRidesFrames.pending]: (state) => {
      state.loadingFrames = 'loading';
    },
    [fetchRidesFrames.rejected]: (state, action) => {
      state.loadingFrames = ['ConditionError', 'AbortError'].includes(action.error.name) ? 'loaded' : 'error';
    },
    [fetchOsmExport.pending]: (state) => {
      state.loadingOsmExport = 'loading';
    },
    [fetchOsmExport.fulfilled]: (state, action) => {
      state.osmExport = action.payload.features;
      state.loadingOsmExport = 'loaded';
    },
    [fetchOsmExport.rejected]: (state) => {
      state.loadingOsmExport = 'error';
    },
    [fetchSegments.pending]: (state) => {
      state.loadingSegments = 'loading';
    },
    [fetchSegments.fulfilled]: (state, action) => {
      state.segments = action.payload.features;
      state.loadingSegments = 'loaded';
    },
    [fetchSegments.rejected]: (state) => {
      state.loadingSegments = 'error';
    },
    [fetchDirections.pending]: (state) => {
      state.loadingDirections = 'loading';
    },
    [fetchDirections.fulfilled]: (state, action) => {
      state.directions = action.payload;
      state.loadingDirections = 'loaded';
    },
    [fetchDirections.rejected]: (state) => {
      state.loadingDirections = 'error';
    },
    [fetchDetectionArea.pending]: (state) => {
      state.loadingDetectionArea = 'loading';
    },
    [fetchDetectionArea.fulfilled]: (state, action) => {
      state.detectionAreaCoords = action.payload.detectionArea;
      state.loadingDetectionArea = 'loaded';
    },
    [fetchDetectionArea.rejected]: (state) => {
      state.loadingDetectionArea = 'error';
    },
    [fetchGeofenceArea.pending]: (state) => {
      state.loadingGeofenceArea = 'loading';
    },
    [fetchGeofenceArea.fulfilled]: (state, action) => {
      state.geofenceAreaCoords = action.payload.geometry;
      state.loadingGeofenceArea = 'loaded';
    },
    [fetchGeofenceArea.rejected]: (state) => {
      state.loadingGeofenceArea = 'error';
    },
    [handleAddIntersection.pending]: (state) => {
      state.loadingAddIntersection = 'loading';
    },
    [handleAddIntersection.fulfilled]: (state) => {
      state.loadingAddIntersection = 'loaded';
    },
    [handleAddIntersection.rejected]: (state) => {
      state.loadingAddIntersection = 'error';
    },
    [handleAddSegment.pending]: (state) => {
      state.loadingAddSegment = 'loading';
    },
    [handleAddSegment.fulfilled]: (state) => {
      state.loadingAddSegment = 'loaded';
    },
    [handleAddSegment.rejected]: (state) => {
      state.loadingAddSegment = 'error';
    },
    [handleCreateApproach.pending]: (state) => {
      state.loadingAddApproach = 'loading';
    },
    [handleCreateApproach.fulfilled]: (state) => {
      state.loadingAddApproach = 'loaded';
    },
    [handleCreateApproach.rejected]: (state) => {
      state.loadingAddApproach = 'error';
    },
    [handleAddDetectionArea.pending]: (state) => {
      state.loadingAddDetectionArea = 'loading';
    },
    [handleAddDetectionArea.fulfilled]: (state) => {
      state.loadingAddDetectionArea = 'loaded';
    },
    [handleAddDetectionArea.rejected]: (state) => {
      state.loadingAddDetectionArea = 'error';
    },
    [handleAddGeofenceArea.pending]: (state) => {
      state.loadingAddGeofenceArea = 'loading';
    },
    [handleAddGeofenceArea.fulfilled]: (state) => {
      state.loadingAddGeofenceArea = 'loaded';
    },
    [handleAddGeofenceArea.rejected]: (state) => {
      state.loadingAddGeofenceArea = 'error';
    },
    [handleDeleteApproach.pending]: (state) => {
      state.loadingDeleteApproach = 'loading';
    },
    [handleDeleteApproach.fulfilled]: (state) => {
      state.loadingDeleteApproach = 'loaded';
    },
    [handleDeleteApproach.rejected]: (state) => {
      state.loadingDeleteApproach = 'error';
    },
    [handleDeleteIntersection.pending]: (state) => {
      state.loadingDeleteIntersection = 'loading';
    },
    [handleDeleteIntersection.fulfilled]: (state) => {
      state.loadingDeleteIntersection = 'loaded';
    },
    [handleDeleteIntersection.rejected]: (state) => {
      state.loadingDeleteIntersection = 'error';
    },
    [handleDeleteSegment.pending]: (state) => {
      state.loadingDeleteSegment = 'loading';
    },
    [handleDeleteSegment.fulfilled]: (state) => {
      state.loadingDeleteSegment = 'loaded';
    },
    [handleDeleteSegment.rejected]: (state) => {
      state.loadingDeleteSegment = 'error';
    },
    [fetchOsmBusStops.pending]: (state) => {
      state.loadingOsmBusStops = 'loading';
    },
    [fetchOsmBusStops.fulfilled]: (state, action) => {
      state.osmBusStops = action.payload.features || [];
      state.loadingOsmBusStops = 'loaded';
    },
    [fetchOsmBusStops.rejected]: (state) => {
      state.loadingOsmBusStops = 'error';
    },
    [handleEditBusStop.pending]: (state) => {
      state.loadingEditBusStop = 'loading';
    },
    [handleEditBusStop.fulfilled]: (state) => {
      state.loadingEditBusStop = 'loaded';
    },
    [handleEditBusStop.rejected]: (state) => {
      state.loadingEditBusStop = 'error';
    },
    [handleDeleteBusStop.pending]: (state) => {
      state.loadingDeleteBusStop = 'loading';
    },
    [handleDeleteBusStop.fulfilled]: (state) => {
      state.loadingDeleteBusStop = 'loaded';
    },
    [handleDeleteBusStop.rejected]: (state) => {
      state.loadingDeleteBusStop = 'error';
    },
    [handleCreateCorridor.pending]: (state) => {
      state.loadingAddCorridor = 'loading';
    },
    [handleCreateCorridor.fulfilled]: (state) => {
      state.loadingAddCorridor = 'loaded';
    },
    [handleCreateCorridor.rejected]: (state) => {
      state.loadingAddCorridor = 'error';
    },
    [handleDeleteCorridor.pending]: (state) => {
      state.loadingDeleteCorridor = 'loading';
    },
    [handleDeleteCorridor.fulfilled]: (state) => {
      state.loadingDeleteCorridor = 'loaded';
    },
    [handleDeleteCorridor.rejected]: (state) => {
      state.loadingDeleteCorridor = 'error';
    },
  },
});

export const selectAllApproaches = ({ admin }) => admin.allApproaches;
export const selectGeofenceAreaCoords = ({ admin }) => admin.geofenceAreaCoords;
export const selectLoadingAllApproaches = ({ admin }) => admin.loadingAllApproaches;
export const selectAllCameraIds = ({ admin }) => admin.allCameraIds;
export const selectRides = ({ admin }) => admin.rides;
export const selectBackendVersion = ({ admin }) => admin.backendVersion;
export const selectCameraDays = ({ admin }) => admin.cameraDays;
export const selectRidesFrames = ({ admin }) => admin.ridesFrames;
export const selectCurrentCameraId = ({ admin }) => admin.currentCameraId;
export const selectLoadingAdminFrames = ({ admin }) => admin.loadingFrames;
export const selectSelectedApproachId = ({ admin }) => admin.selectedApproachId;
export const selectOsmExportFeatures = ({ admin }) => admin.osmExport;
export const selectSegments = ({ admin }) => admin.segments;
export const selectDirections = ({ admin }) => admin.directions;
export const selectDetectionAreaCoords = ({ admin }) => admin.detectionAreaCoords;
export const selectLoadingDetectionArea = ({ admin }) => admin.loadingDetectionArea;
export const selectLoadingAddDetectionArea = ({ admin }) => admin.loadingAddDetectionArea;
export const selectLoadingGeofenceArea = ({ admin }) => admin.loadingGeofenceArea;
export const selectLoadingAddGeofenceArea = ({ admin }) => admin.loadingAddGeofenceArea;
export const selectLoadingAddIntersection = ({ admin }) => admin.loadingAddIntersection;
export const selectLoadingDeleteIntersection = ({ admin }) => admin.loadingDeleteIntersection;
export const selectLoadingAddSegment = ({ admin }) => admin.loadingAddSegment;
export const selectLoadingDeleteSegment = ({ admin }) => admin.loadingDeleteSegment;
export const selectLoadingAddApproach = ({ admin }) => admin.loadingAddApproach;
export const selectLoadingDeleteApproach = ({ admin }) => admin.loadingDeleteApproach;
export const selectLoadingOsmBusStops = ({ admin }) => admin.loadingOsmBusStops;
export const selectLoadingEditBusStop = ({ admin }) => admin.loadingEditBusStop;
export const selectLoadingDeleteBusStop = ({ admin }) => admin.loadingDeleteBusStop;
export const selectLoadingAddCorridor = ({ admin }) => admin.loadingAddCorridor;
export const selectLoadingDeleteCorridor = ({ admin }) => admin.loadingDeleteCorridor;

export const {
  clearCameraDays,
  setCurrentCameraId,
  setSelectedApproachId,
  clearRidesFrames,
  resetLoadingAddIntersection,
  resetLoadingDeleteIntersection,
  resetLoadingAddSegment,
  resetLoadingDeleteSegment,
  resetLoadingAddApproach,
  resetLoadingDeleteApproach,
  resetLoadingEditBusStop,
  resetLoadingDeleteBusStop,
  resetLoadingAddCorridor,
  resetLoadingDeleteCorridor,
  resetLoadingAddDetectionArea,
  resetLoadingAddGeofenceArea,
  resetLoadingOSMBusStops,
} = adminSlice.actions;

export default adminSlice.reducer;
