import { Overlay } from 'ol';

export const overlays = {};
export const featureStatsOverlay = {};
export const popupOverlays = {};

export const initOverlaysCorridors = () => {
  popupOverlays.effectiveLos = new Overlay({
    element: document.getElementById('los-popup'),
    stopEvent: false,
    offset: [0, -10],
    positioning: 'bottom-center',
  });

  popupOverlays.dwellTime = new Overlay({
    element: document.getElementById('dwelltime-popup'),
    stopEvent: false,
    offset: [0, -10],
    positioning: 'bottom-center',
  });

  popupOverlays.queueLength = new Overlay({
    element: document.getElementById('queue-popup'),
    stopEvent: false,
    offset: [0, -10],
    positioning: 'bottom-center',
  });

  popupOverlays.probabilityForBlockage = new Overlay({
    element: document.getElementById('blockage-popup'),
    stopEvent: false,
    offset: [0, -10],
    positioning: 'bottom-center',
  });

  popupOverlays.averageSpeed = new Overlay({
    element: document.getElementById('average-speed-popup'),
    stopEvent: false,
    offset: [0, -10],
    positioning: 'bottom-center',
  });

  popupOverlays.probabilityToStop = new Overlay({
    element: document.getElementById('probability-popup'),
    stopEvent: false,
    offset: [0, -10],
    positioning: 'bottom-center',
  });

  popupOverlays.waitingTime = new Overlay({
    element: document.getElementById('waiting-popup'),
    stopEvent: false,
    offset: [0, -10],
    positioning: 'bottom-center',
  });

  overlays.intersectionName = new Overlay({
    element: document.getElementById('popup'),
    stopEvent: false,
  });
};

export const initFeatureStatsOverlay = () => {
  featureStatsOverlay.featureStats = new Overlay({
    element: document.getElementById('feature-stats-tooltip'),
    positioning: 'center-left',
    id: 'feature-stats-tooltip',
    offset: [20, 0],
  });

  featureStatsOverlay.framesLocation = new Overlay({
    element: document.getElementById('frames-location'),
    positioning: 'center-center',
    id: 'frames-location',
    offset: [-15, -20],
  });
};

export default initOverlaysCorridors;
