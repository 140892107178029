import React from 'react';
import { useSelector } from 'react-redux';
import { selectSpeedUnit, selectDistanceUnit } from 'features';
import { FormattedMessage } from 'react-intl';

import './PopupOverlays.scss';

export const PopupOverlays = () => {
  const speedUnit = useSelector(selectSpeedUnit);
  const distanceUnit = useSelector(selectDistanceUnit);

  return (
    <>
      <div id="popup" className="ol-popup">
        <p id="popup-content" />
      </div>
      <div id="waiting-popup" className="ol-popup">
        <div className="ol-popup-content">
          <div className="ol-popup-child">
            <p>
              <FormattedMessage defaultMessage="Contribution to waiting time" description="Popup overlay" />
            </p>
            <div className="contributionChartWaiting" />
          </div>
          <div className="ol-popup-child">
            <p className="avg-text">
              <FormattedMessage defaultMessage="Av. waiting time" description="Popup overlay" />
            </p>
            <p className="avgWaiting" />
          </div>
          <div className="ol-popup-child">
            <p>
              <FormattedMessage defaultMessage="Contribution to driving time" description="Popup overlay" />
            </p>
            <div className="contributionChartDriving" />
          </div>
          <div className="ol-popup-child">
            <p className="avg-text">
              <FormattedMessage defaultMessage="Av. driving time" description="Popup overlay" />
            </p>
            <p className="avgDriving" />
          </div>
        </div>
      </div>
      <div id="probability-popup" className="ol-popup">
        <div className="ol-popup-content">
          <div className="ol-popup-child">
            <p>
              <FormattedMessage defaultMessage="Probability to stop" description="Popup overlay" />
            </p>
            <div className="probabilityToStopChart" />
          </div>
        </div>
      </div>
      <div id="average-speed-popup" className="ol-popup">
        <div className="ol-popup-content">
          <div className="ol-popup-child">
            <p>
              <FormattedMessage defaultMessage="Average Speed" description="Popup overlay" />
            </p>
            <p>
              <span className="avgSpeed" />
              {speedUnit}
            </p>
          </div>
        </div>
      </div>
      <div id="blockage-popup" className="ol-popup">
        <div className="ol-popup-content">
          <div className="ol-popup-child">
            <p>
              <FormattedMessage defaultMessage="Probability for Blockage" description="Popup overlay" />
            </p>
            <p className="probabilityOfBlockage" />
          </div>
        </div>
      </div>
      <div id="queue-popup" className="ol-popup">
        <div className="ol-popup-content">
          <div className="ol-popup-child">
            <p>
              <FormattedMessage defaultMessage="Average Queue Length" description="Popup overlay" />
            </p>
            <p>
              <span className="avgQueueLength" />
              {distanceUnit}
            </p>
          </div>
        </div>
      </div>
      <div id="los-popup" className="ol-popup">
        <div className="ol-popup-content">
          <div className="ol-popup-child">
            <p>
              <FormattedMessage defaultMessage="Value" description="Popup overlay" />
            </p>
            <p className="los" />
          </div>
          <div className="ol-popup-child">
            <p>
              <FormattedMessage defaultMessage="Value" description="Popup overlay" />
            </p>
            <p>
              <FormattedMessage defaultMessage="N/A" description="Popup overlay" />
            </p>
          </div>
          <div className="ol-popup-child">
            <p>
              <FormattedMessage defaultMessage="Value" description="Popup overlay" />
            </p>
            <p>
              <FormattedMessage defaultMessage="N/A" description="Popup overlay" />
            </p>
          </div>
        </div>
      </div>
      <div id="dwelltime-popup" className="ol-popup">
        <div className="ol-popup-content">
          <div className="ol-popup-child">
            <p>
              <FormattedMessage defaultMessage="Average dwell time" description="Popup overlay" />
            </p>
            <p>
              <span className="avgDwellTime" />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
