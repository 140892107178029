import { createSlice } from '@reduxjs/toolkit';
import { statisticTypes, GRAPH_START_TIME, GRAPH_END_TIME } from 'consts';
import { fetchHomeStats } from 'features/thunks';

export const homeStatsSlice = createSlice({
  name: 'homeStats',
  initialState: {
    [statisticTypes.PRIVATE]: {
      queueOccupancies: undefined,
      queueOccupanciesFiller: undefined,
      queueTravelRatio: undefined,
      queueTravelRatioFiller: undefined,
    },
    [statisticTypes.PUBLIC]: {
      queueOccupancies: undefined,
      queueOccupanciesFiller: undefined,
      queueTravelRatio: undefined,
      queueTravelRatioFiller: undefined,
    },
    xDomain: [GRAPH_START_TIME, GRAPH_END_TIME],
  },
  extraReducers: {
    [fetchHomeStats.fulfilled]: (state, action) => {
      state[statisticTypes.PRIVATE] = action.payload[statisticTypes.PRIVATE];
      state[statisticTypes.PUBLIC] = action.payload[statisticTypes.PUBLIC];
    },
  },
});

export const selectQueueOccupancies = (statisticType) => ({ homeStats }) => homeStats[statisticType]?.queueOccupancies;
export const selectQueueOccupanciesFiller = (statisticType) => ({ homeStats }) =>
  homeStats[statisticType]?.queueOccupanciesFiller;
export const selectQueueTravelRatio = (statisticType) => ({ homeStats }) => homeStats[statisticType]?.queueTravelRatio;
export const selectQueueTravelRatioFiller = (statisticType) => ({ homeStats }) =>
  homeStats[statisticType]?.queueTravelRatioFiller;
export const selectXDomain = ({ homeStats }) => homeStats.xDomain;

export default homeStatsSlice.reducer;
