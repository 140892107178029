import * as olExtent from 'ol/extent';
import store from 'store';
import {
  intersectionsLayerName,
  geofenceAreaLayerName,
  osmEdgesLayerName,
  segmentsLayerName,
  approachesLayerName,
} from 'consts';

let selectedCorridorLayer;

export const initEditGridEvents = (olMap, setActiveLayerNames, setSelectedCorridorId) => {
  const map = olMap;

  const findLayerByName = (layerName) =>
    map
      .getLayers()
      .getArray()
      .find((layer) => layer.getProperties().name === layerName);

  map.on('pointermove', (e) => {
    if (e.dragging) return;

    let hoveredLayer;
    const pixel = map.getEventPixel(e.originalEvent);
    const hoveredFeature = map.forEachFeatureAtPixel(pixel, (f) => f, {
      hitTolerance: 0,
      layerFilter: (l) => {
        hoveredLayer = l;

        return true;
      },
    });
    const hoveredLayerName = hoveredLayer.get('name');
    const tooltipOverlay = map.getOverlayById('feature-stats-tooltip');
    const tooltipEl = tooltipOverlay.getElement();

    const previousHoveredIntersection = findLayerByName(intersectionsLayerName)
      .getSource()
      .getFeatures()
      .find((feature) => feature.get('isHovered'));

    if (previousHoveredIntersection) {
      previousHoveredIntersection.set('isHovered', false);
    }

    if (hoveredFeature) {
      if (hoveredLayerName !== geofenceAreaLayerName) map.getViewport().style.cursor = 'pointer';
      if (hoveredLayerName === intersectionsLayerName) {
        hoveredFeature.set('isHovered', true);
      }
    } else {
      map.getViewport().style.cursor = 'default';
    }

    if (
      hoveredFeature &&
      [intersectionsLayerName, osmEdgesLayerName, segmentsLayerName, approachesLayerName].includes(hoveredLayerName)
    ) {
      let rows;

      const {
        unitSystem: { speedMultiplier, speedUnit },
        admin: { directions },
      } = store.getState();

      switch (hoveredLayerName) {
        case intersectionsLayerName:
          rows = hoveredFeature
            .get('features')
            .map((f) => `ID: ${f.get('id')}<br>Name: ${f.get('name')}<br>Signalized: ${f.get('signalized')}<br>`);
          break;
        case osmEdgesLayerName:
          rows = [hoveredFeature.get('name')];
          break;
        case segmentsLayerName:
          rows = [];
          rows.push(`Start Intersection: ${hoveredFeature.get('fromIntersectionId')}`);
          rows.push(`End Intersection: ${hoveredFeature.get('toIntersectionId')}`);
          rows.push(
            `Direction: ${directions.find((direction) => direction.id === hoveredFeature.get('directionId')).name}`,
          );
          rows.push(`Speed: ${hoveredFeature.get('speed') * speedMultiplier} ${speedUnit}`);
          break;
        case approachesLayerName:
          rows = [`Approach ID: ${hoveredFeature.getId()}`];
          break;
        default:
      }

      tooltipOverlay.setPosition(e.coordinate);
      tooltipEl.querySelector('.stats-text').innerHTML = `
        <small>
          <strong>${hoveredLayerName}</strong><br>
          ${rows?.join('<br>')}
        </small>
      `;
    } else {
      tooltipOverlay.setPosition(undefined);
    }
  });

  map.on('click', (e) => {
    if (e.dragging) return;

    let clickedLayer;

    const pixel = map.getEventPixel(e.originalEvent);

    const clickedFeature = map.forEachFeatureAtPixel(pixel, (feature, layer) => {
      clickedLayer = layer;

      return feature;
    });

    if (selectedCorridorLayer) {
      selectedCorridorLayer
        .getSource()
        .getFeatures()
        .forEach((feature) => {
          feature.set('isSelected', false);
        });
      setSelectedCorridorId(null);
    }

    if (clickedFeature) {
      if (clickedLayer?.get('name') === intersectionsLayerName) {
        const features = clickedFeature.get('features');

        if (features.length > 1) {
          const extent = olExtent.createEmpty();

          features.forEach((feature) => {
            olExtent.extend(extent, feature.getGeometry().getExtent());
          });
          map.getView().fit(extent, { duration: 1000, padding: [400, 400, 400, 400], minResolution: 1.5 });
        }
      }

      if (clickedLayer?.get('isCorridor')) {
        clickedLayer
          .getSource()
          .getFeatures()
          .forEach((feature) => {
            feature.set('isSelected', true);
          });
        selectedCorridorLayer = clickedLayer;
        setSelectedCorridorId(clickedLayer.get('corridorId'));
      }
    }
  });

  map.getViewport().addEventListener('contextmenu', (e) => {
    setSelectedCorridorId(null);
    const pixel = map.getEventPixel(e);

    map.forEachFeatureAtPixel(pixel, (_, layer) => {
      if (!layer) return;
      if (layer.get('isCorridor')) setSelectedCorridorId(layer.get('corridorId'));
    });
  });

  const compareLayers = (a, b) => (a.getProperties().zIndex < b.getProperties().zIndex ? 1 : -1);

  map.getLayerGroup().on('change', () => {
    setActiveLayerNames(
      map
        .getLayers()
        .getArray()
        .filter((layer) => layer.getProperties().name)
        .sort(compareLayers),
    );
  });
};
