import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { handleDeleteCorridor } from 'features';
import { TextField } from '@material-ui/core';
import { Dialog } from 'screens/EditGrid/Dialog';

export const DeleteCorridorDialog = ({ isOpen, close, corridorId, setSelectedCorridorId }) => {
  const dispatch = useDispatch();

  const submit = () => {
    dispatch(handleDeleteCorridor(corridorId));
    setSelectedCorridorId(null);
    close();
  };

  return (
    <Dialog title="Delete Corridor" isOpen={isOpen} onClose={close} onSubmit={submit}>
      <TextField label="Corridor Id" defaultValue={corridorId} InputProps={{ readOnly: true }} />
    </Dialog>
  );
};

DeleteCorridorDialog.propTypes = {
  isOpen: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  corridorId: propTypes.number,
  setSelectedCorridorId: propTypes.func.isRequired,
};

DeleteCorridorDialog.defaultProps = {
  corridorId: null,
};
