import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Slider, Typography } from '@material-ui/core';
import { fetchMobileyeRealTimeTraffic } from 'features';
import { MOBILEYE_REQUEST_POLYGONS } from 'consts';
import styles from './RealTimeTrafficForm.module.scss';

export const RealTimeTrafficForm = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */

  /* ---------------------------------- State --------------------------------- */
  const [realTrafficTimeRange, setRealTrafficTimeRange] = useState(5);

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const handleButtonClick = (event) => {
    event.preventDefault();

    dispatch(
      fetchMobileyeRealTimeTraffic({
        timestamp_range: realTrafficTimeRange,
        polygons: MOBILEYE_REQUEST_POLYGONS,
      }),
    );
  };

  const handleSliderChange = (_, value) => {
    setRealTrafficTimeRange(value);
  };

  /* --------------------------------- Effects -------------------------------- */

  return (
    <div className={styles.container}>
      <Typography id="discrete-slider" gutterBottom className={styles.title}>
        Traffic time frame in minutes
      </Typography>
      <Slider
        value={realTrafficTimeRange}
        valueLabelDisplay="on"
        step={5}
        min={5}
        max={60}
        marks
        onChange={handleSliderChange}
      />
      <Button onClick={handleButtonClick} type="submit" variant="contained" color="primary">
        Show Traffic
      </Button>
    </div>
  );
};
