import { createSlice, createSelector } from '@reduxjs/toolkit';
import { loadCorridors } from 'features/thunks';

export const corridorsSlice = createSlice({
  name: 'corridors',
  initialState: {
    geoJSON: [],
    loading: 'idle',
    selectedCorridorIndex: 0,
  },
  reducers: {
    setSelectedCorridorIndex: (state, action) => {
      state.selectedCorridorIndex = action.payload;
    },
  },
  extraReducers: {
    [loadCorridors.pending]: (state) => {
      state.loading = 'loading';
    },
    [loadCorridors.fulfilled]: (state, action) => {
      state.geoJSON = action.payload;
      state.loading = 'loaded';
    },
    [loadCorridors.rejected]: (state) => {
      state.loading = 'error';
    },
  },
});

export const selectMappedCorridorData = createSelector(
  ({ corridors }) => corridors.geoJSON,
  (geoJSON) =>
    geoJSON.reduce(
      (acc, val) => {
        const { corridorId, corridorName } = val.features[0].properties;

        if (!acc.ids.includes(corridorId)) {
          acc.ids.push(corridorId);
          acc.idsByName[corridorName] = corridorId;
        }
        if (!acc.corridorsNames.includes(corridorName)) {
          acc.corridorsNames.push({ corridorName, corridorId });
        }

        return acc;
      },
      {
        ids: [],
        corridorsNames: [],
        idsByName: {},
      },
    ),
);

export const selectCorridors = createSelector(
  [
    ({ corridors }) => corridors.geoJSON,
    ({ corridors }) => corridors.loading,
    selectMappedCorridorData,
    ({ corridors }) => corridors.selectedCorridorIndex,
  ],
  (data, loading, { ids, corridorsNames, idsByName }, selectedCorridorIndex) => ({
    data,
    loading,
    ids,
    corridorsNames,
    idsByName,
    selectedCorridorIndex,
    selectedCorridorId: corridorsNames[selectedCorridorIndex]?.corridorId,
  }),
);

export const { setSelectedCorridorIndex } = corridorsSlice.actions;

export default corridorsSlice.reducer;
