import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectToastMessage, setToastMessage } from 'features';
import { useDebounce } from 'utils/hooks';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import styles from './ToastMessage.module.scss';

export const ToastMessage = () => {
  const dispatch = useDispatch();
  const message = useSelector(selectToastMessage);
  const [isOpen, setIsOpen] = useState(false);
  const [autoHideDuration, setAutoHideDuration] = useState(2000);
  const debouncedMessage = useDebounce(message, 200);

  const handleClose = () => {
    dispatch(setToastMessage(null));
    setIsOpen(false);
  };

  useEffect(() => {
    if (!debouncedMessage) return;

    setAutoHideDuration(debouncedMessage.length > 200 ? 5000 : 2000);
    setIsOpen(true);
  }, [debouncedMessage]);

  return (
    <Snackbar open={isOpen} autoHideDuration={autoHideDuration} onClose={handleClose}>
      <MuiAlert elevation={6} variant="filled" severity="error">
        {debouncedMessage}
        <IconButton className={styles.closeButton} onClick={handleClose} size="small" color="inherit">
          <CloseIcon fontSize="small" />
        </IconButton>
      </MuiAlert>
    </Snackbar>
  );
};
