import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectMobileyeLiveEvents, selectMobileyeRealTimeTraffic } from 'features';
import { initMapboxMap, setMapboxSource } from 'utils';
import { mapboxMapNames } from 'consts';
import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './MapboxMap.module.scss';

export const MapboxMap = () => {
  /* ---------------------------------- Hooks --------------------------------- */

  /* -------------------------------- Selectors ------------------------------- */
  const { features: liveEventsFeatures } = useSelector(selectMobileyeLiveEvents);
  const { features: realTimeTrafficFeatures } = useSelector(selectMobileyeRealTimeTraffic);

  /* ---------------------------------- State --------------------------------- */
  const [mapboxMap, setMapboxMap] = useState(null);

  /* -------------------------------- Variables ------------------------------- */
  const sourceNames = {
    LIVE_EVENTS: 'mobileye-live-events',
    REAL_TIME_TRAFFIC: 'real-time-traffic',
  };

  /* -------------------------------- Functions ------------------------------- */
  const clearSources = () => {
    Object.values(sourceNames).forEach((sourceName) => setMapboxSource({ sourceName, mapboxMap, features: [] }));
  };

  /* --------------------------------- Effects -------------------------------- */
  useLayoutEffect(() => {
    const map = initMapboxMap({ mapName: mapboxMapNames.mobileye });

    map.on('load', () => setMapboxMap(map));

    return () => map.remove();
  }, []);

  useEffect(() => {
    if (mapboxMap) {
      clearSources();
      setMapboxSource({ sourceName: sourceNames.LIVE_EVENTS, mapboxMap, features: liveEventsFeatures });
    }
  }, [mapboxMap, liveEventsFeatures]); // eslint-disable-line

  useEffect(() => {
    if (mapboxMap) {
      clearSources();
      setMapboxSource({ sourceName: sourceNames.REAL_TIME_TRAFFIC, mapboxMap, features: realTimeTrafficFeatures });
    }
  }, [mapboxMap, realTimeTrafficFeatures]); // eslint-disable-line

  return <div id="map-mapboxgl" className={styles.map} />;
};
