import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { handleCreateCorridor } from 'features';
import { TextField } from '@material-ui/core';
import { Dialog } from 'screens/EditGrid/Dialog';

export const CreateCorridorDialog = ({ isOpen, close, approaches }) => {
  const dispatch = useDispatch();
  const [nameError, setNameError] = useState('');
  const [axlPrefixError, setAxlPrefixError] = useState('');
  const approachIds = approaches.map((feature) => feature.getId());

  const submit = (event) => {
    const name = event.target.name.value;
    const axlPrefix = event.target.axlPrefix.value;

    setNameError('');
    setAxlPrefixError('');
    let hasErrors = false;

    if (!name) {
      setNameError('Name is required');
      hasErrors = true;
    }

    if (!axlPrefix) {
      setAxlPrefixError('axlPrefix is required');
      hasErrors = true;
    }

    if (hasErrors) return;

    dispatch(
      handleCreateCorridor({
        approachIds,
        name,
        axlPrefix,
      }),
    );

    close();
  };

  return (
    <Dialog title="Add Corridor" isOpen={isOpen} onClose={close} onSubmit={submit}>
      <TextField label="Approach Ids" defaultValue={approachIds.join(', ')} InputProps={{ readOnly: true }} />
      <TextField label="Name" helperText={nameError} name="name" />
      <TextField label="axlPrefix" helperText={axlPrefixError} name="axlPrefix" />
    </Dialog>
  );
};

CreateCorridorDialog.propTypes = {
  isOpen: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  approaches: propTypes.array.isRequired,
};
