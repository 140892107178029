const initTrafficInsightsEvents = (olMap, intersectionName, setClickedIntersection) => {
  const map = olMap;

  map.on('pointermove', (e) => {
    if (e.dragging) return;
    const pixel = map.getEventPixel(e.originalEvent);

    let hoveredLayer;

    const feature = map.forEachFeatureAtPixel(pixel, (f) => f);

    const hasFeatureAtPixel = map.hasFeatureAtPixel(pixel, {
      hitTolerance: 0,
      layerFilter: (layer) => {
        hoveredLayer = layer.get('name');

        return true;
      },
    });

    if (hoveredLayer && hoveredLayer.includes('intersections') && !feature.get('isSelected')) {
      map.getViewport().style.cursor = hasFeatureAtPixel ? 'pointer' : 'default';
      document.getElementById('popup-content').innerText = feature.get('name');
      intersectionName.setPosition(feature.getGeometry().getCoordinates());
    } else {
      map.getViewport().style.cursor = 'default';
      intersectionName.setPosition(undefined);
    }
  });

  map.on('click', (e) => {
    if (e.dragging) return;
    const pixel = map.getEventPixel(e.originalEvent);
    const feature = map.forEachFeatureAtPixel(pixel, (f) => f);

    let clickedLayer;

    map.hasFeatureAtPixel(pixel, {
      hitTolerance: 0,
      layerFilter: (layer) => {
        clickedLayer = layer.get('name');

        return true;
      },
    });

    if (clickedLayer && clickedLayer.includes('intersections')) {
      setClickedIntersection({
        id: feature.get('id'),
        name: feature.get('name'),
      });
      map.getViewport().style.cursor = 'default';
      intersectionName.setPosition(undefined);
    }
  });
};

export default initTrafficInsightsEvents;
