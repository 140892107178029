export const corridorsMock = {
  geoJSON: [
    {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [
              [34.773801, 32.07977],
              [34.773734, 32.081157],
              [34.773744, 32.081489],
              [34.77376, 32.081654],
              [34.773778, 32.081801],
            ],
          },
          properties: {
            corridorId: 2,
            corridorName: 'Dizengoff North',
            order: 1,
            directionId: 1,
            startIntersection: {
              type: 'Point',
              coordinates: [34.773801, 32.07977],
            },
            endIntersection: {
              type: 'Point',
              coordinates: [34.773778, 32.081801],
            },
            startIntersectionId: 18,
            endIntersectionId: 17,
            length: 240,
          },
          id: '20',
        },
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [
              [34.773778, 32.081801],
              [34.773974, 32.083041],
              [34.774103, 32.083688],
              [34.774232, 32.084304],
            ],
          },
          properties: {
            corridorId: 2,
            corridorName: 'Dizengoff North',
            order: 2,
            directionId: 1,
            startIntersection: {
              type: 'Point',
              coordinates: [34.773778, 32.081801],
            },
            endIntersection: {
              type: 'Point',
              coordinates: [34.774255, 32.084392],
            },
            startIntersectionId: 17,
            endIntersectionId: 16,
            length: 290,
          },
          id: '21',
        },
      ],
    },
    {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [
              [34.775925, 32.097364],
              [34.775975, 32.097288],
              [34.775976, 32.097157],
              [34.775996, 32.096895],
              [34.77603, 32.096588],
              [34.776036, 32.096545],
              [34.776103, 32.096106],
            ],
          },
          properties: {
            corridorId: 2,
            corridorName: 'Namir South',
            order: 1,
            directionId: 2,
            startIntersection: {
              type: 'Point',
              coordinates: [34.775925, 32.097364],
            },
            endIntersection: {
              type: 'Point',
              coordinates: [34.776127, 32.095959],
            },
            startIntersectionId: 70,
            endIntersectionId: 251,
            length: 160,
          },
          id: '28',
        },
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [
              [34.776103, 32.096106],
              [34.776223, 32.095671],
              [34.776301, 32.095439],
              [34.776485, 32.09482],
              [34.776301, 32.095439],
              [34.776223, 32.095671],
            ],
          },
          properties: {
            corridorId: 2,
            corridorName: 'Namir South',
            order: 2,
            directionId: 2,
            startIntersection: {
              type: 'Point',
              coordinates: [34.776127, 32.095959],
            },
            endIntersection: {
              type: 'Point',
              coordinates: [34.776485, 32.09482],
            },
            startIntersectionId: 251,
            endIntersectionId: 122,
            length: 130,
          },
          id: '29',
        },
      ],
    },
  ],
  loading: 'loaded',
  selectedCorridorIndex: 0,
  corridorsNames: [{ corridorName: 'Dizengoff North' }],
};
