import { createAsyncThunk } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import {
  getApproachesStats,
  getPedestriansStats,
  getFrames,
  getApproachesStatDays,
  getApproachesStatSamples,
  getPedestriansStatApproaches,
  getBusStopStatSamples,
  getSnappedCoords,
} from 'api/endpoints';
import { promiseAll } from 'api/api';
import { airQualityMockedResponse } from 'api/airQualityMockedResponse';

const enableCaching = false;
const generalStatsCancelTokens = [];

let generalStatsFramesCancelToken;

let approachesStatDaysCancelToken;

let approachesStatSamplesCancelToken;

let pedestriansStatApproachesCancelToken;

let approachStatSampleFramesCancelToken;

let busStopStatSamplesCancelToken;

export const fetchGeneralStats = createAsyncThunk(
  'generalStats/fetchGeneralStats',
  async ({ statisticType, metricType, requestBody, serializedRequestBody }, thunkAPI) => {
    generalStatsCancelTokens.forEach((cancel) => cancel());

    const params = [
      requestBody,
      {},
      {
        cancelToken: new CancelToken((cancel) => {
          generalStatsCancelTokens.push(cancel);
        }),
      },
    ];

    let res;

    if (statisticType === 'eco') {
      res = JSON.parse(JSON.stringify(airQualityMockedResponse));
    } else {
      res = await (statisticType === 'pedestrians' ? getPedestriansStats(...params) : getApproachesStats(...params));
    }

    if (res.status && res.status !== 200) {
      return thunkAPI.rejectWithValue();
    }

    if (typeof res.data === 'string' && res.data.includes('No such statistic')) {
      return thunkAPI.rejectWithValue();
    }

    return [serializedRequestBody, res.data[metricType], res.data.colorScheme, metricType];
  },
  {
    condition: ({ serializedRequestBody }, thunkAPI) => {
      const currentState = thunkAPI.getState().generalStats;

      return !enableCaching || !currentState.stats[serializedRequestBody];
    },
    dispatchConditionRejection: true,
  },
);

export const fetchGeneralStatsFrames = createAsyncThunk(
  'generalStats/fetchFrames',
  async ({ postDataArr }, { rejectWithValue }) => {
    const result = [];
    const promises = [];

    if (generalStatsFramesCancelToken) {
      generalStatsFramesCancelToken.cancel({ skipLoader: true });
    }

    generalStatsFramesCancelToken = CancelToken.source();

    postDataArr.forEach((postData) => {
      promises.push(
        getFrames(
          postData,
          {},
          {
            cancelToken: generalStatsFramesCancelToken.token,
            skipLoader: true,
          },
        ),
      );
    });

    const responses = await promiseAll(promises);

    let hasError = false;

    responses.forEach((response) => {
      if (response.status && response.status !== 200) {
        hasError = true;
      }
    });

    if (hasError) return rejectWithValue();

    responses.forEach(({ data: { Frames } }) => {
      if (Frames.length) {
        result.push(Frames);
      }
    });

    if (!result.length) {
      throw new Error();
    }

    return {
      [JSON.stringify(postDataArr)]: result,
    };
  },
  {
    condition: ({ postDataArr }, thunkAPI) => {
      const currentState = thunkAPI.getState().generalStats;

      return !enableCaching || !currentState.frames[JSON.stringify(postDataArr)];
    },
  },
);

export const fetchApproachesStatDays = createAsyncThunk(
  'generalStats/fetchApproachesStatDays',
  async ({ requestBody }, thunkAPI) => {
    if (pedestriansStatApproachesCancelToken) {
      pedestriansStatApproachesCancelToken.cancel({ skipLoader: true });
    }
    if (approachesStatDaysCancelToken) {
      approachesStatDaysCancelToken.cancel({ skipLoader: true });
    }
    approachesStatDaysCancelToken = CancelToken.source();
    const res = await getApproachesStatDays(
      requestBody,
      {},
      {
        cancelToken: approachesStatDaysCancelToken.token,
        skipLoader: true,
      },
    );

    if (res.status && res.status !== 200) {
      return thunkAPI.rejectWithValue();
    }

    return res.data;
  },
);

export const fetchApproachesStatSamples = createAsyncThunk(
  'generalStats/fetchApproachesStatSamples',
  async ({ requestBody }, thunkAPI) => {
    if (pedestriansStatApproachesCancelToken) {
      pedestriansStatApproachesCancelToken.cancel({ skipLoader: true });
    }
    if (approachesStatDaysCancelToken) {
      approachesStatDaysCancelToken.cancel({ skipLoader: true });
    }
    if (approachesStatSamplesCancelToken) {
      approachesStatSamplesCancelToken.cancel({ skipLoader: true });
    }

    approachesStatSamplesCancelToken = CancelToken.source();
    const res = await getApproachesStatSamples(
      requestBody,
      {},
      {
        cancelToken: approachesStatSamplesCancelToken.token,
        skipLoader: true,
      },
    );

    if (res.status && res.status !== 200) {
      return thunkAPI.rejectWithValue();
    }

    return res.data;
  },
);

export const fetchPedestriansStatApproaches = createAsyncThunk(
  'generalStats/fetchPedestriansStatApproaches',
  async ({ requestBody }, thunkAPI) => {
    if (pedestriansStatApproachesCancelToken) {
      pedestriansStatApproachesCancelToken.cancel({ skipLoader: true });
    }
    pedestriansStatApproachesCancelToken = CancelToken.source();
    const res = await getPedestriansStatApproaches(
      requestBody,
      {},
      {
        cancelToken: pedestriansStatApproachesCancelToken.token,
        skipLoader: true,
      },
    );

    if (!res.data.approaches?.length || (res.status && res.status !== 200)) {
      return thunkAPI.rejectWithValue();
    }

    return res.data;
  },
);

export const fetchApproachStatSampleFrames = createAsyncThunk(
  'generalStats/fetchApproachStatSampleFrames',
  async ({ postData }, thunkAPI) => {
    if (pedestriansStatApproachesCancelToken) {
      pedestriansStatApproachesCancelToken.cancel({ skipLoader: true });
    }
    if (approachesStatDaysCancelToken) {
      approachesStatDaysCancelToken.cancel({ skipLoader: true });
    }
    if (approachesStatSamplesCancelToken) {
      approachesStatSamplesCancelToken.cancel({ skipLoader: true });
    }
    if (approachStatSampleFramesCancelToken) {
      approachStatSampleFramesCancelToken.cancel({ skipLoader: true });
    }

    approachStatSampleFramesCancelToken = CancelToken.source();

    const res = await getFrames(
      postData,
      {},
      {
        cancelToken: approachStatSampleFramesCancelToken.token,
        skipLoader: true,
      },
    );

    if (res.status && res.status !== 200) {
      return thunkAPI.rejectWithValue();
    }

    return res.data;
  },
);

export const fetchBusStopStatSamples = createAsyncThunk(
  'generalStats/fetchBusStopStatSamples',
  async ({ requestBody }, thunkAPI) => {
    if (busStopStatSamplesCancelToken) {
      busStopStatSamplesCancelToken.cancel({ skipLoader: true });
    }
    busStopStatSamplesCancelToken = CancelToken.source();
    const busStopStatSamplesRes = await getBusStopStatSamples(
      requestBody,
      {},
      {
        cancelToken: busStopStatSamplesCancelToken.token,
        skipLoader: true,
      },
    );

    if (busStopStatSamplesRes.status && busStopStatSamplesRes.status !== 200) {
      return thunkAPI.rejectWithValue();
    }

    return busStopStatSamplesRes.data;
  },
);

export const setActiveFrameCordsAsync = createAsyncThunk('generalStats/matchFramesCoords', async (coords, thunkAPI) => {
  const formattedCoords = `${coords.curr.lonCurr},${coords.curr.latCurr};${coords.curr.lonCurr},${coords.curr.latCurr}`;
  const activeFrameCoords = await getSnappedCoords(formattedCoords);

  if (activeFrameCoords.status && activeFrameCoords.status !== 200) {
    return thunkAPI.rejectWithValue();
  }

  const { tracepoints } = activeFrameCoords.data;

  return tracepoints ? tracepoints[0].location : [coords.curr.lonCurr, coords.curr.latCurr];
});
