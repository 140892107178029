import { metricTypes, FEATURE_COLORS } from 'consts';

export const findFeatureColor = ({ metricValue, metricType, featureProperties }) => {
  const colors = [...FEATURE_COLORS];

  if (metricType === metricTypes.SPEED) {
    colors.reverse();
  }

  let value = metricValue;

  if (!metricValue && featureProperties) {
    switch (metricType) {
      case metricTypes.QUEUE_LENGTH:
        value = featureProperties.approachOccupancy;
        break;
      case metricTypes.NUMBER_OF_STOPS:
        value = featureProperties.probabilityToStop * 100;
        break;
      case metricTypes.WAITING_TIME:
        value = (featureProperties.avgWaiting / featureProperties.avgDriving) * 100;
        break;
      case metricTypes.SPEED:
        value = (featureProperties.avgSpeed / featureProperties.speedLimit) * 100;
        break;
      default:
        break;
    }
  }

  if (value <= 20) return colors[0];
  if (value <= 50) return colors[1];
  if (value <= 80) return colors[2];

  return colors[3];
};
