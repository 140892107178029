import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCorridors } from 'api/endpoints';

export const loadCorridors = createAsyncThunk('corridors/loadCorridors', async (_, thunkAPI) => {
  const corridorsRes = await getCorridors();

  if (corridorsRes.status && corridorsRes.status !== 200) {
    return thunkAPI.rejectWithValue();
  }

  return corridorsRes.data;
});
