import * as mapboxgl from 'mapbox-gl';

export const setupNavigationControl = (mapbox) => {
  const navControl = new mapboxgl.NavigationControl({ visualizePitch: true });
  const pitchButton = document.createElement('button');
  const text2D = '2D';
  const text3D = '3D';

  pitchButton.innerHTML = text3D;
  pitchButton.style.fontWeight = 'bold';
  pitchButton.addEventListener('click', () => {
    if (pitchButton.innerHTML === text3D) {
      pitchButton.innerHTML = text2D;
      mapbox.easeTo({ pitch: 60 });
    } else {
      pitchButton.innerHTML = text3D;
      mapbox.easeTo({ pitch: 0, bearing: 0 });
    }
  });
  navControl._container.appendChild(pitchButton);

  mapbox.on('pitchend', () => {
    const pitch = mapbox.getPitch();

    if (pitch === 0) {
      pitchButton.innerHTML = text3D;
    } else {
      pitchButton.innerHTML = text2D;
    }
  });

  return navControl;
};
