import theme from 'theme';

const GraphStyles = {
  GridLines: {
    stroke: theme.color22,
    strokeDasharray: [3, 3],
  },
  Axis: {
    line: {
      stroke: theme.colorBlack,
      strokeWidth: 0.5,
    },
    ticks: {
      stroke: theme.colorBlack,
    },
    text: {
      stroke: 'none',
      fill: theme.color24,
      fontWeight: 600,
    },
  },
  LineMarkSeries: {
    strokeWidth: 3,
  },
  LineMarkSeries1_lineStyle: {
    stroke: theme.color01,
  },
  LineMarkSeries1_markStyle: {
    stroke: theme.colorWhite,
    fill: theme.color01,
  },
  LineMarkSeries2_lineStyle: {
    stroke: theme.color10,
  },
  LineMarkSeries2_markStyle: {
    stroke: theme.colorWhite,
    fill: theme.color10,
  },
};

export default GraphStyles;
