import React from 'react';
import propTypes from 'prop-types';
import styles from './SimpleTable.module.scss';

export const SimpleTable = ({ rows, rowHeadings, rowClickHandler }) => {
  const onRowClick = (index) => {
    if (rowClickHandler) {
      rowClickHandler(index);
    }
  };

  return (
    <div className={styles.root}>
      <table className={styles.table}>
        <thead>
          <tr>
            {rowHeadings.map((heading, index) => (
              <th key={index.toString()}>{heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((cells, rowIndex) => (
            <tr key={rowIndex.toString()} onClick={() => onRowClick(rowIndex)}>
              {cells.map((cellText, cellIndex) => (
                <td key={cellIndex.toString()}>{cellText}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

SimpleTable.propTypes = {
  rows: propTypes.array.isRequired,
  rowHeadings: propTypes.array.isRequired,
  rowClickHandler: propTypes.func.isRequired,
};
