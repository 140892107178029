/* eslint-disable no-nested-ternary */
export default (
  intl,
  metricType,
  metricTypes,
  feature,
  distanceMultiplier,
  distanceUnit,
  speedMultiplier,
  speedUnit,
) => {
  const impactText = {
    veryHigh: intl.formatMessage({ description: 'Very High', defaultMessage: 'Very High' }),
    high: intl.formatMessage({ description: 'High', defaultMessage: 'High' }),
    medium: intl.formatMessage({ description: 'Medium', defaultMessage: 'Medium' }),
    low: intl.formatMessage({ description: 'Low', defaultMessage: 'Low' }),
  };
  const impactTextLight = {
    veryHigh: intl.formatMessage({ description: 'Extremely High', defaultMessage: 'Extremely High' }),
    high: intl.formatMessage({ description: 'Very High', defaultMessage: 'Very High' }),
    medium: intl.formatMessage({ description: 'High', defaultMessage: 'High' }),
    low: intl.formatMessage({ description: 'Light', defaultMessage: 'Light' }),
  };

  const impactTextUnsafe = {
    veryHigh: intl.formatMessage({ description: 'Extremely Unsafe', defaultMessage: 'Extremely Unsafe' }),
    high: intl.formatMessage({ description: 'Very Unsafe', defaultMessage: 'Very Unsafe' }),
    medium: intl.formatMessage({ description: 'Unsafe', defaultMessage: 'Unsafe' }),
    low: intl.formatMessage({ description: 'Unsafe on Occasion', defaultMessage: 'Unsafe on Occasion' }),
  };

  const impactTextBusy = {
    veryHigh: intl.formatMessage({ description: 'Extremely Busy', defaultMessage: 'Extremely Busy' }),
    high: intl.formatMessage({ description: 'Very Busy', defaultMessage: 'Very Busy' }),
    medium: intl.formatMessage({ description: 'Busy', defaultMessage: 'Busy' }),
    low: intl.formatMessage({ description: 'Busy on Occasion', defaultMessage: 'Busy on Occasion' }),
  };

  const impactTextPoor = {
    veryHigh: intl.formatMessage({ description: 'Extremely Poor', defaultMessage: 'Extremely Poor' }),
    high: intl.formatMessage({ description: 'Very Poor', defaultMessage: 'Very Poor' }),
    medium: intl.formatMessage({ description: 'Poor', defaultMessage: 'Poor' }),
    low: intl.formatMessage({ description: 'Poor on Occasion', defaultMessage: 'Poor on Occasion' }),
  };

  switch (metricType) {
    case metricTypes.QUEUE_LENGTH: {
      const queueLength = Math.round(feature.get('avgQueueLength') * distanceMultiplier);
      const queueRatio = Math.round((queueLength / feature.get('approachLength')) * 100);

      return intl.formatMessage(
        { description: 'General view video title', defaultMessage: 'AVG Queue Length = {queueLength}{distanceUnit} ({queueRatio}% of Approach Length) ' }, //eslint-disable-line
        { queueLength, distanceUnit, queueRatio },
      );
    }
    case metricTypes.NUMBER_OF_STOPS: {
      const probability = Math.round(feature.get('probabilityToStop') * 100);

      return intl.formatMessage(
        { description: 'General view video title', defaultMessage: 'Probability to Stop = {probability}%' },
        { probability },
      );
    }
    case metricTypes.WAITING_TIME: {
      const waitingRatio = Math.round((feature.get('avgWaiting') / feature.get('avgDriving')) * 100);
      const waitingTime = Math.round(feature.get('avgWaiting'));

      return intl.formatMessage(
        {
          description: 'General view video title',
          defaultMessage: 'AVG Queue Time = {waitingTime}s ({waitingRatio}% of Total Travel Time)',
        },
        { waitingTime, waitingRatio },
      );
    }
    case metricTypes.SPEED: {
      const avgSpeed = Math.round(feature.get('avgSpeed') * speedMultiplier);
      const speedLimit = feature.get('speedLimit') * speedMultiplier;
      const speedToLimitRatio = Math.round((avgSpeed / speedLimit) * 100);

      return intl.formatMessage(
        {
          description: 'General view video title',
          defaultMessage: 'AVG Speed = {avgSpeed} {speedUnit} ({speedToLimitRatio}% of Speed Limit)',
        },
        { speedToLimitRatio, avgSpeed, speedUnit },
      );
    }
    case metricTypes.DWELL_TIME: {
      const time = Math.round(feature.get('avgDwellTime'));

      return intl.formatMessage(
        { description: 'General view video title', defaultMessage: 'AVG Dwell Time = {time} sec' },
        { time },
      );
    }
    case metricTypes.PASSENGERS_DENSITY: {
      return intl.formatMessage({ description: 'General view video title', defaultMessage: 'Passenger Density' });
    }
    case metricTypes.PEDESTRIANS_SIGNAL_PROGRESSION: {
      const value = feature.get('colorGroupId');

      return `
        ${intl.formatMessage({
          description: 'General view video title',
          defaultMessage: 'Dangerous Crossings: ',
        })}
            ${
              value === 1
                ? impactTextUnsafe.veryHigh
                : value === 2
                ? impactTextUnsafe.high
                : value === 3
                ? impactTextUnsafe.medium
                : impactTextUnsafe.low
            }
          `;
    }
    case metricTypes.RED_LIGHT: {
      const value = feature.get('colorGroupId');

      return `
          ${intl.formatMessage({
            description: 'General view video title',
            defaultMessage: 'Traffic Signal Progression: ',
          })}
            ${
              value === 1
                ? impactTextPoor.veryHigh
                : value === 2
                ? impactTextPoor.high
                : value === 3
                ? impactTextPoor.medium
                : impactTextPoor.low
            }
          `;
    }
    case metricTypes.JAYWALKERS: {
      const value = feature.get('colorGroupId');

      return `
          ${intl.formatMessage({
            description: 'General view video title',
            defaultMessage: 'Jaywalking: ',
          })}
            ${
              value === 1
                ? impactTextLight.veryHigh
                : value === 2
                ? impactTextLight.high
                : value === 3
                ? impactTextLight.medium
                : impactTextLight.low
            }
          `;
    }
    case metricTypes.ACTIVE_CROSSWALK: {
      const value = feature.get('colorGroupId');

      return `
          ${intl.formatMessage({
            description: 'General view video title',
            defaultMessage: 'Crosswalk: ',
          })}
            ${
              value === 1
                ? impactTextBusy.veryHigh
                : value === 2
                ? impactTextBusy.high
                : value === 3
                ? impactTextBusy.medium
                : impactTextBusy.low
            }
          `;
    }
    case metricTypes.TOTAL_DENSITY: {
      const value = feature.get('colorGroupId');

      return `
          ${intl.formatMessage({
            description: 'General view video title',
            defaultMessage: 'Pedestrian & Micromobility Density: ',
          })}
            ${
              value === 1
                ? impactText.veryHigh
                : value === 2
                ? impactText.high
                : value === 3
                ? impactText.medium
                : impactText.low
            }
          `;
    }
    case metricTypes.PEDESTRIAN_DENSITY: {
      const value = feature.get('colorGroupId');

      return `
          ${intl.formatMessage({
            description: 'General view video title',
            defaultMessage: 'Pedestrian Density: ',
          })}
            ${
              value === 1
                ? impactText.veryHigh
                : value === 2
                ? impactText.high
                : value === 3
                ? impactText.medium
                : impactText.low
            }
          `;
    }
    case metricTypes.SCOOTER_DENSITY: {
      const value = feature.get('colorGroupId');

      return `
          ${intl.formatMessage({
            description: 'General view video title',
            defaultMessage: 'Scooter Density: ',
          })}
            ${
              value === 1
                ? impactText.veryHigh
                : value === 2
                ? impactText.high
                : value === 3
                ? impactText.medium
                : impactText.low
            }
          `;
    }
    case metricTypes.CYCLIST_DENSITY: {
      const value = feature.get('colorGroupId');

      return `
          ${intl.formatMessage({
            description: 'General view video title',
            defaultMessage: 'Cyclist Density: ',
          })}
            ${
              value === 1
                ? impactText.veryHigh
                : value === 2
                ? impactText.high
                : value === 3
                ? impactText.medium
                : impactText.low
            }
          `;
    }
    case metricTypes.FACE_MASK_USAGE: {
      const value = feature.get('colorGroupId');

      return `
          ${intl.formatMessage({
            description: 'General view video title',
            defaultMessage: 'Face Mask Usage: ',
          })}
            ${
              value === 4
                ? impactText.veryHigh
                : value === 3
                ? impactText.high
                : value === 2
                ? impactText.medium
                : impactText.low
            }
          `;
    }
    default:
      throw new Error();
  }
};
