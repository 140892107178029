import { createAsyncThunk } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { getFrames, getApproachStats, getRides, getStatistics, getApproaches } from 'api/endpoints';
import { promiseAll } from 'api/api';
import { setRidesError } from 'features/appStateSlice';

export const cancelCorridorsMetricsRequests = {
  frames: [],
  generalMetricsFrames: [],
  rides: [],
  totalRides: [],
  corridorStats: [],
  approaches: [],
};

export const fetchFrames = createAsyncThunk(
  'fetchFrames',
  async ({ postDataArr }) => {
    const fetchPromises = [];

    cancelCorridorsMetricsRequests.frames.forEach((cancel) => cancel({ skipLoader: true }));

    postDataArr
      .filter((obj) => obj.cameraId !== undefined)
      .forEach((postData) => {
        fetchPromises.push(
          getFrames(
            postData,
            {},
            {
              cancelToken: new CancelToken((cancel) => {
                cancelCorridorsMetricsRequests.frames.push(cancel);
              }),
              skipLoader: true,
            },
          ),
        );
      });

    const responses = await promiseAll(fetchPromises);

    return {
      first: responses[0].data?.Frames,
      second: responses[1]?.data?.Frames,
    };
  },
  {
    condition: ({ metricType }, { getState }) => getState().corridorsStats?.frames[metricType],
    dispatchConditionRejection: true,
  },
);

export const fetchCorridorsMetricsRides = createAsyncThunk(
  'fetchCorridorsMetricsRides',
  async ({ metricType, postDataArr }, thunkAPI) => {
    const fetchPromises = [];

    cancelCorridorsMetricsRequests.rides.forEach((cancel) => cancel());

    postDataArr.forEach((postData) => {
      fetchPromises.push(
        getApproachStats(
          postData,
          {},
          {
            cancelToken: new CancelToken((cancel) => {
              cancelCorridorsMetricsRequests.rides.push(cancel);
            }),
          },
        ),
      );
    });

    const responses = await promiseAll(fetchPromises);

    let hasError = false;

    responses.forEach((response) => {
      if (response.status && response.status !== 200) {
        if (response.status === 404) {
          thunkAPI.dispatch(setRidesError(response.data));
        }
        hasError = true;
      }
    });

    if (hasError) return thunkAPI.rejectWithValue();

    return responses.map((res) => res.data[metricType]);
  },
  {
    condition: ({ metricType }, { getState }) => !Array.isArray(getState().corridorsStats?.rides[metricType]),
  },
);

export const fetchTotalRides = createAsyncThunk(
  'fetchTotalRides',
  async ({ isComparing, postDataArr, serializedPostDataArr }, thunkAPI) => {
    const ridesPromises = [];
    const responseData = [];
    const state = thunkAPI.getState().corridorsStats.totalRides;
    const cachedDataFirst = state[serializedPostDataArr[0]];
    const cachedDataSecond = state[serializedPostDataArr[1]];

    if (cachedDataFirst) {
      responseData.push(cachedDataFirst);
    } else {
      ridesPromises.push(
        getRides(
          postDataArr[0],
          {},
          {
            cancelToken: new CancelToken((cancel) => {
              cancelCorridorsMetricsRequests.totalRides.push(cancel);
            }),
          },
        ),
      );
    }

    if (isComparing) {
      if (cachedDataSecond) {
        responseData.push(cachedDataSecond);
      } else {
        ridesPromises.push(
          getRides(
            postDataArr[1],
            {},
            {
              cancelToken: new CancelToken((cancel) => {
                cancelCorridorsMetricsRequests.totalRides.push(cancel);
              }),
            },
          ),
        );
      }
    }

    const responses = await promiseAll(ridesPromises);

    let hasError = false;

    responses.forEach((response) => {
      if (response.status && response.status !== 200) {
        hasError = true;
      }
    });

    if (hasError) return thunkAPI.rejectWithValue();

    const results = {};

    responses.forEach(({ data }) => responseData.push(data));
    responseData.forEach((data, i) => {
      results[serializedPostDataArr[i]] = data;
    });

    return results;
  },
);

export const fetchCorridorStats = createAsyncThunk(
  'fetchCorridorStats',
  async ({ isComparing, postDataArr, serializedPostDataArr }, thunkApi) => {
    const ridesPromises = [];
    const responseData = [];
    const state = thunkApi.getState().corridorsStats.corridorStats;
    const cachedDataFirst = state[serializedPostDataArr[0]];
    const cachedDataSecond = state[serializedPostDataArr[1]];

    if (cachedDataFirst) {
      responseData.push(cachedDataFirst);
    } else {
      ridesPromises.push(
        getStatistics(
          postDataArr[0],
          {},
          {
            cancelToken: new CancelToken((cancel) => {
              cancelCorridorsMetricsRequests.corridorStats.push(cancel);
            }),
          },
        ),
      );
    }

    if (isComparing) {
      if (cachedDataSecond) {
        responseData.push(cachedDataSecond);
      } else {
        ridesPromises.push(
          getStatistics(
            postDataArr[1],
            {},
            {
              cancelToken: new CancelToken((cancel) => {
                cancelCorridorsMetricsRequests.corridorStats.push(cancel);
              }),
            },
          ),
        );
      }
    }

    const responses = await promiseAll(ridesPromises);
    const results = {};

    responses.forEach(({ data }) => responseData.push(data));
    responseData.forEach((data, i) => {
      results[serializedPostDataArr[i]] = data;
    });

    return results;
  },
);

export const fetchApproaches = createAsyncThunk('fetchApproaches', async (requestBody, thunkAPI) => {
  cancelCorridorsMetricsRequests.approaches.forEach((cancel) => cancel());

  const response = await getApproaches(
    requestBody,
    {},
    {
      cancelToken: new CancelToken((cancel) => {
        cancelCorridorsMetricsRequests.totalRides.push(cancel);
      }),
    },
  );

  if (response.status && response.status !== 200) {
    return thunkAPI.rejectWithValue();
  }

  return response;
});

export const fetchGeneralMetricsFrames = createAsyncThunk(
  'corridorsStats/fetchGeneralMetricsFrames',
  async ({ postData }, thunkAPI) => {
    cancelCorridorsMetricsRequests.generalMetricsFrames.forEach((cancel) => cancel({ skipLoader: true }));
    const response = await getFrames(
      postData,
      {},
      {
        cancelToken: new CancelToken((cancel) => {
          cancelCorridorsMetricsRequests.generalMetricsFrames.push(cancel);
        }),
        skipLoader: true,
      },
    );

    if (response.status && response.status !== 200) {
      return thunkAPI.rejectWithValue();
    }

    return {
      [JSON.stringify(postData)]: response.data.Frames,
    };
  },
  {
    condition: ({ postData }, { getState }) =>
      !getState().corridorsStats.generalMetricsFrames[JSON.stringify(postData)],
    dispatchConditionRejection: true,
  },
);
