import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
} from '@material-ui/core';
import {
  selectActiveFrameDetails,
  selectIsFrameDetailsOverlayVisible,
  setFrameDetailsOverlay,
  selectMetricType,
  selectStatisticType,
} from 'features';

import './FrameDetailsOverlay.scss';

export const FrameDetailsOverlay = () => {
  const dispatch = useDispatch();
  const statisticType = useSelector(selectStatisticType);
  const metricType = useSelector(selectMetricType);
  const activeFrameDetails = useSelector(selectActiveFrameDetails);
  const isOverlayVisible = useSelector(selectIsFrameDetailsOverlayVisible);
  const [menuTitle, setMenuTitle] = useState('');
  const [openTooltip, setOpenTooltip] = useState(false);
  const { frameUrl } = activeFrameDetails;

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleClose = () => {
    dispatch(setFrameDetailsOverlay(false));
  };

  const showTooltip = () => {
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 500);
  };

  const handleCopyToClipboard = () => {
    const copyObject = { statisticType, metricType: menuTitle, ...activeFrameDetails };

    navigator.clipboard.writeText(JSON.stringify(copyObject).slice(1, -1).split(',').join(', \n'));
    showTooltip();
  };

  useEffect(() => {
    const title = {
      redLight: 'Dangerous crossings',
      jaywalkers: 'Jaywalking',
      pedestrianSignalProgression: 'Pedestrian traffic signal progression',
      activeCrosswalk: 'Frequently used crosswalks',
      totalDensity: 'Pedestrian & Micromobility density',
      pedestrianDensity: 'Pedestrian density',
      scooterDensity: 'Scooter density',
      cyclistDensity: 'Cyclist density',
      queueLength: 'Queue length',
      waitingTime: 'Queue time',
      numberOfStops: 'Traffic signal progression',
      speed: 'Speed',
      dwellTime: 'Dwell time',
      density: 'Passenger density',
      faceMaskUsage: 'Face mask detection',
    }[metricType];

    setMenuTitle(title);
  }, [metricType]);

  return (
    <Dialog
      open={isOverlayVisible}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth
      maxWidth="xl"
      className="frame-details-dialog"
    >
      <DialogTitle id="dialog-title">Current frame details:</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          <span className="frame-details">
            {`"statisticType": "${statisticType}", `}
            {`"metricType": "${menuTitle}", `}
            {JSON.stringify(activeFrameDetails).slice(1, -1).split(',').join(', \n')}
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={openTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Copied!"
        >
          <Button color="primary" onClick={handleCopyToClipboard}>
            Copy details to clipboard
          </Button>
        </Tooltip>
        <Button color="primary" target="_blank" href={frameUrl}>
          Download image
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
