const SelectPlanRangeStyles = (theme) => ({
  mainContainer: {
    paddingTop: 19,
  },
  dropDownContainer: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  icon: {
    fontSize: 20,
  },
  gridContainer: {
    marginRight: 10,
  },
  formControl: {
    display: 'block',
    marginTop: 20,
  },
  formControlLabel: {
    fontSize: 14,
    marginBottom: 2,
  },
  buttonOk: {
    display: 'block',
    margin: '0 auto',
    marginTop: 20,
  },
  selectLabel: {
    display: 'flex',
    borderRadius: 5,
    padding: '8px 16px',
    cursor: 'pointer',
  },
  selectText: {
    fontSize: 14,
    fontWeight: 500,
    paddingRight: 8,
    textTransform: 'uppercase',
    marginLeft: 10,
  },
  arrowDown: { marginLeft: 'auto' },
  arrowUp: { marginLeft: 'auto' },
  selectList: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    background: theme.colorWhite,
    border: `1px solid ${theme.color02}`,
    borderRadius: 5,
    padding: '8px 16px',
    zIndex: 10,
  },
  selectListInner: {
    display: 'flex',
    cursor: 'pointer',
  },
  horizontalLine: {
    borderTop: `1px solid ${theme.color02}`,
    marginTop: 30,
  },
});

export default SelectPlanRangeStyles;
