import Pedestrians from 'assets/images/pedestrians.jpg';
import Cars from 'assets/images/cars.jpg';
import Busses from 'assets/images/busses.jpg';
import Emission from 'assets/images/clouds.jpg';

const cardTransition = {
  position: 'absolute',
  zIndex: -1,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundRepeat: 'no-repeat',
  transition: 'top 0.3s ease-in-out',

  '&.notVisible': {
    top: -350,
  },
};

const StatisticsCard = (theme) => ({
  cardWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    borderRadius: 5,
    boxShadow: `0 18px 31px 0 ${theme.color103}`,
    fontSize: 26,
    fontWeight: 500,
    position: 'relative',
    overflow: 'hidden',
  },
  popUp: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: 10,
    top: 490,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.colorWhite,
    transition: 'top 0.3s ease-in-out',

    '&.visible': {
      top: 0,
    },
  },
  header: {
    minHeight: 50,
    height: 50,
    backgroundColor: theme.color50,
    display: 'flex',
    alignItems: 'center',
    fontSize: 22,
    color: theme.colorWhite,

    '&& svg': {
      height: 30,
      width: 30,
      color: theme.colorWhite,
      marginRight: 5,
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    backgroundColor: theme.colorWhite,

    '&& span, && svg': {
      textTransform: 'uppercase',
      color: theme.color21,
    },

    '&& a, && button': {
      padding: 0,
      width: '100%',
      height: '100%',
    },
  },
  disabledOverlay: {
    position: 'absolute',
    backgroundColor: theme.color118,
    width: '100%',
    height: '100%',
  },
  pedestrians: {
    ...cardTransition,
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${Pedestrians})`,
  },
  private: {
    ...cardTransition,
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${Cars})`,
  },
  public: {
    ...cardTransition,
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${Busses})`,
  },
  eco: {
    ...cardTransition,
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${Emission})`,
  },
  chartsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  clickable: {
    cursor: 'pointer',
  },
});

export default StatisticsCard;
