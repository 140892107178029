const HeaderStyles = (theme) => ({
  boxHeader: {
    display: 'flex',
    width: 410,
    paddingTop: 25,
    paddingBottom: 25,
    '&.blackBg': {
      backgroundColor: 'black',
    },
  },
  boldTextHeader: {
    paddingLeft: 15,
    paddingRight: 10,
    letterSpacing: 1,
    fontSize: 12.5,
    fontFamily: 'InterBold',
    textTransform: 'uppercase',
    alignSelf: 'center',
    '.whiteColor &': {
      color: theme.colorWhite,
    },
  },
  plainTextHeader: {
    letterSpacing: 1,
    fontSize: 12.5,
    fontFamily: 'InterLight',
    textTransform: 'uppercase',
    alignSelf: 'center',
    '.whiteColor &': {
      color: theme.color14,
    },
  },
  icon: {
    display: 'flex',
    padding: '0 20px',
    '.whiteColor &': {
      color: theme.colorWhite,
    },
    '& *': {
      cursor: 'pointer',
    },
  },
});

export default HeaderStyles;
