import React from 'react';
import propTypes from 'prop-types';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import classes from './PhaseDiagramSelect.module.scss';

export const PhaseDiagramSelect = ({ programsData, activeProgram, handleProgramSelect }) => (
  <FormControl className={classes.formControl}>
    <Select
      labelId="select-label"
      id="select"
      value={activeProgram}
      onChange={handleProgramSelect}
      MenuProps={{ className: classes.menu }}
      data-testid="select"
    >
      {programsData.map((program) => (
        <MenuItem key={program.number} value={program.number} className={classes.item}>
          {`Program: ${program.number} `}
          {program.description && `(${program.description})`}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

PhaseDiagramSelect.propTypes = {
  programsData: propTypes.array.isRequired,
  activeProgram: propTypes.number.isRequired,
  handleProgramSelect: propTypes.func.isRequired,
};
