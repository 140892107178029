import React from 'react';
import propTypes from 'prop-types';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import styles from './Dialog.module.scss';

export const Dialog = ({ isOpen, title, onSubmit, onClose, children, ...rest }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(event);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <MuiDialog onClose={handleClose} open={isOpen} {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <DialogContentText>{children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </MuiDialog>
  );
};

Dialog.propTypes = {
  isOpen: propTypes.bool.isRequired,
  title: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
  onSubmit: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  className: propTypes.object,
};

Dialog.defaultProps = {
  className: null,
};
