const HomeView = (theme) => ({
  homeContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    minHeight: '100vh',
    '& .MuiGrid-item': {
      height: 350,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  navigation: {
    position: 'relative',
    width: 400,
    zIndex: 10000,
  },
  userMenu: {
    position: 'relative',
    top: 15,
    right: 30,
    zIndex: 110,
  },
  cards: {
    paddingBottom: 50,
    zIndex: 100,
    '& div': {
      justifyContent: 'center',
    },
  },
  title: {
    margin: '20px 0 70px 0',
    color: theme.colorWhite,
    fontSize: 43,
    textAlign: 'center',
    zIndex: 100,
    fontWeight: 300,
  },
  boxesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  cardIcon: {
    height: 60,
    width: 60,
    color: theme.color21,
    marginRight: 15,
  },
  overlay: {
    position: 'absolute',
    zIndex: 10,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.color104,
  },
  olMap: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
});

export default HomeView;
