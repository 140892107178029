import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Box } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { FramesSlider } from '@axilion/ui-components';
import { ReactComponent as GraphsIconWhite } from 'assets/images/Graphs White@3x.svg';
import { ReactComponent as VideoIconWhite } from 'assets/images/Video White@3x.svg';
import { CorridorGraphs, ChartBar, CameraDetails } from 'components';
import { useSelector } from 'react-redux';
import {
  selectExpandedPanel,
  selectIsComparing,
  selectLoadingCorridorsMetricsFrames,
  selectStatisticType,
} from 'features';
import { statisticTypes } from 'consts';
import { FormattedMessage } from 'react-intl';
import { useIntl, useFormat } from 'utils/hooks';
import { panelTitle } from 'utils';
import styles from './SidebarPanels.module.scss';

export const PanelGroup = ({
  panels,
  handleChange,
  onClickMainMetric,
  mainMetricType,
  generalMetricsFramesFirst,
  cameraDetails,
  generalMetricsFramesSecond,
}) => {
  const intl = useIntl();
  const { fromUtcToProjectTime } = useFormat();
  const expandedPanel = useSelector(selectExpandedPanel);
  const isComparing = useSelector(selectIsComparing);
  const loadingFrames = useSelector(selectLoadingCorridorsMetricsFrames);
  const statisticType = useSelector(selectStatisticType);
  const panelHasGraphsData = ({ graphsFirst }) =>
    graphsFirst && Object.keys(graphsFirst).some((key) => graphsFirst[key]);
  const panelsToRender = statisticType !== statisticTypes.PUBLIC && panels[0].name === 'dwell-time' ? [] : panels;

  const onGraphsButtonClick = () => {
    onClickMainMetric('graphs')();
  };

  return panelsToRender.map((panel) => (
    <Accordion
      key={panel.name}
      expanded={expandedPanel === panel.name}
      onChange={handleChange(panel.name)}
      classes={{
        root: styles.expansionPanelRoot,
        expanded: styles.expansionPanelExpanded,
      }}
    >
      <AccordionSummary
        id={`${panel.name}-header`}
        expandIcon={<ArrowDropDown color="primary" />}
        classes={{
          root: styles.expansionPanelSummary,
          content: styles.expansionPanelSummaryContent,
          expandIcon: styles.expansionPanelSummaryExpandIcon,
        }}
      >
        <div className={styles.dataRow}>
          <div className={styles.dataRowTitle}>
            {panel.headerIcon}
            {panelTitle(panel.name, intl)}
          </div>
          {![expandedPanel, 'dwell-time'].includes(panel.name) && (
            <>
              <div className={`${styles.dataRowItem} ${styles.dataRowItemFirst}`}>{panel.headerContent}</div>
              {isComparing && (
                <div className={`${styles.dataRowItem} ${styles.dataRowItemSecond}`}>{panel.headerContentCompare}</div>
              )}
            </>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: styles.expansionPanelDetails }}>
        <>
          {panel.detailsFirst?.map((detail, index) =>
            panel.name === 'waiting-time' && index % 2 !== 0 ? null : (
              <div className={`${styles.dataRow} ${styles.dataRowWithBorder}`} key={detail.title}>
                <div className={styles.dataRowTitle}>
                  {detail.title.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                  {panel.name === 'waiting-time' && (
                    <div>
                      {panel.detailsFirst[index + 1]?.title
                        .replace(/([A-Z])/g, ' $1')
                        .replace(/^./, (str) => str.toUpperCase())}
                    </div>
                  )}
                </div>

                <div className={`${styles.dataRowItem} ${styles.dataRowItemFirst}`}>
                  {panel.name === 'waiting-time' ? (
                    <ChartBar
                      values={[detail.value, panel.detailsFirst[index + (index % 2 !== 0 ? -1 : 1)].value]}
                      size="small"
                    />
                  ) : (
                    detail.value
                  )}
                </div>

                {isComparing && panel.detailsSecond && (
                  <div className={`${styles.dataRowItem} ${styles.dataRowItemSecond}`}>
                    {panel.name === 'waiting-time' ? (
                      <ChartBar
                        values={[detail.value, panel.detailsSecond[index + (index % 2 !== 0 ? -1 : 1)].value]}
                        size="small"
                      />
                    ) : (
                      panel.detailsSecond[index].value
                    )}
                  </div>
                )}
              </div>
            ),
          )}
          <Box className={styles.graphVideoButtonsWrap}>
            {panelHasGraphsData(panel) && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<GraphsIconWhite />}
                className={`
                  ${styles.graphVideoButton}
                  ${mainMetricType === 'graphs' && styles.graphVideoButtonActive}
                `}
                onClick={onGraphsButtonClick}
              >
                <FormattedMessage defaultMessage="Graphs" description="Sidebar panel" />
              </Button>
            )}
            {isComparing ? (
              <Box className={styles.videoButtonWrap} ml={1}>
                <Button
                  variant="outlined"
                  startIcon={<VideoIconWhite />}
                  className={`
                              ${styles.graphVideoButton}
                              ${styles.videoButtonA}
                              ${mainMetricType === 'videoA' && styles.graphVideoButtonActive}
                            `}
                  onClick={onClickMainMetric('videoA')}
                >
                  {' '}
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<VideoIconWhite />}
                  className={`
                        ${styles.graphVideoButton}
                        ${styles.videoButtonB}
                        ${mainMetricType === 'videoB' && styles.graphVideoButtonActive}
                      `}
                  onClick={onClickMainMetric('videoB')}
                >
                  {' '}
                </Button>
              </Box>
            ) : (
              <Button
                variant="outlined"
                startIcon={<VideoIconWhite />}
                className={`
                      ${styles.graphVideoButton}
                      ${mainMetricType === 'video' && styles.graphVideoButtonActive}
                    `}
                onClick={onClickMainMetric('video')}
              >
                <FormattedMessage defaultMessage="Video" description="Sidebar panel" />
              </Button>
            )}
          </Box>
          {(mainMetricType === 'video' || mainMetricType === 'videoA') && (
            <>
              <div className={styles.videoWrap}>
                <FramesSlider
                  fromUtcToProjectTime={fromUtcToProjectTime}
                  framesLists={[generalMetricsFramesFirst]}
                  showFrameDetails
                  isLoading={loadingFrames === 'loading'}
                  showNotFound={loadingFrames === 'error'}
                />
              </div>
              {loadingFrames === 'loaded' && <CameraDetails data={cameraDetails} />}
            </>
          )}
          {mainMetricType === 'videoB' && (
            <>
              <Box className={styles.videoWrap}>
                <FramesSlider
                  fromUtcToProjectTime={fromUtcToProjectTime}
                  framesLists={[generalMetricsFramesSecond]}
                  showFrameDetails
                  isLoading={loadingFrames === 'loading'}
                  showNotFound={loadingFrames === 'error'}
                />
              </Box>
              {loadingFrames === 'loaded' && <CameraDetails data={cameraDetails} />}
            </>
          )}
          {mainMetricType === 'graphs' &&
            panelHasGraphsData(panel) &&
            Object.keys(panel.graphsFirst).map(
              (key, i) =>
                /cdf/.test(key) && (
                  <CorridorGraphs
                    cdf={{
                      first: panel.graphsFirst[key],
                      second: isComparing ? panel.graphsSecond[key] : [],
                    }}
                    pdf={{
                      first: panel.graphsFirst[`pdf${key.substring(3)}`],
                      second: isComparing ? panel.graphsSecond[`pdf${key.substring(3)}`] : [],
                    }}
                    panelName={panel.name}
                    valueKey={key}
                    key={i.toString()}
                    isFullWidth
                  />
                ),
            )}
        </>
      </AccordionDetails>
    </Accordion>
  ));
};
