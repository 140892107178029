import { createSlice } from '@reduxjs/toolkit';
import {
  fetchFrames,
  fetchGeneralMetricsFrames,
  fetchCorridorsMetricsRides,
  fetchTotalRides,
  fetchCorridorStats,
  fetchApproaches,
} from 'features/thunks';

export const corridorsStatsSlice = createSlice({
  name: 'corridorsStats',
  initialState: {
    frames: {},
    loadingFrames: 'idle',
    generalMetricsFrames: {},
    loadingGeneralMetricsFrames: 'idle',
    rides: {},
    loadingRides: 'idle',
    totalRides: {},
    loadingTotalRides: 'idle',
    corridorStats: {},
    loadingCorridorStats: 'idle',
    approaches: undefined,
  },
  reducers: {
    resetCorridorsStats: (state) => {
      state.frames = {};
      state.rides = {};
    },
    resetLoadingCorridorStats: (state) => {
      state.loadingCorridorStats = 'idle';
    },
    resetLoadingTotalRides: (state) => {
      state.loadingTotalRides = 'idle';
    },
    resetTotalRidesApproaches: (state) => {
      state.approaches = undefined;
    },
  },
  extraReducers: {
    [fetchFrames.pending]: (state) => {
      state.loadingFrames = 'loading';
    },
    [fetchFrames.rejected]: (state, action) => {
      state.loadingFrames = ['ConditionError', 'AbortError'].includes(action.error.name) ? 'loaded' : 'error';
    },
    [fetchFrames.fulfilled]: (state, { payload, meta }) => {
      state.loadingFrames = 'loaded';
      state.frames[meta.arg.metricType] = payload;
    },
    [fetchGeneralMetricsFrames.pending]: (state) => {
      state.loadingGeneralMetricsFrames = 'loading';
    },
    [fetchGeneralMetricsFrames.rejected]: (state, action) => {
      state.loadingGeneralMetricsFrames = ['ConditionError', 'AbortError'].includes(action.error.name)
        ? 'loaded'
        : 'error';
    },
    [fetchGeneralMetricsFrames.fulfilled]: (state, action) => {
      const [key, data] = Object.entries(action.payload)[0];

      state.generalMetricsFrames[key] = data;
      state.loadingGeneralMetricsFrames = 'loaded';
    },
    [fetchCorridorsMetricsRides.pending]: (state) => {
      state.loadingRides = 'loading';
    },
    [fetchCorridorsMetricsRides.fulfilled]: (state, { payload, meta }) => {
      state.loadingRides = 'loaded';
      state.rides[meta.arg.metricType] = payload;
    },
    [fetchCorridorsMetricsRides.rejected]: (state) => {
      state.loadingRides = 'error';
    },
    [fetchTotalRides.pending]: (state) => {
      state.loadingTotalRides = 'loading';
    },
    [fetchTotalRides.fulfilled]: (state, { payload }) => {
      state.loadingTotalRides = 'loaded';
      Object.entries(payload).forEach(([key, val]) => {
        if (!state.totalRides[key]) {
          state.totalRides[key] = val;
        }
      });
    },
    [fetchTotalRides.rejected]: (state) => {
      state.loadingTotalRides = 'error';
    },
    [fetchCorridorStats.pending]: (state) => {
      state.loadingCorridorStats = 'loading';
    },
    [fetchCorridorStats.fulfilled]: (state, { payload }) => {
      state.loadingCorridorStats = 'loaded';
      Object.entries(payload).forEach(([key, val]) => {
        if (!state.corridorStats[key]) {
          state.corridorStats[key] = val;
        }
      });
    },
    [fetchCorridorsMetricsRides.rejected]: (state) => {
      state.loadingCorridorStats = 'error';
    },
    [fetchApproaches.fulfilled]: (state, action) => {
      state.approaches = action.payload.data;
    },
  },
});

export const selectCorridorsMetricsFramesFirst = (metric) => ({ corridorsStats: { frames } }) =>
  (frames[metric] && frames[metric].first) || [];
export const selectCorridorsMetricsFramesSecond = (metric) => ({ corridorsStats: { frames } }) =>
  (frames[metric] && frames[metric].second) || [];
export const selectLoadingCorridorsMetricsFrames = ({ corridorsStats }) => corridorsStats.loadingFrames;
export const selectCorridorsMetricsRidesFirst = (metric) => ({ corridorsStats: { rides } }) =>
  (rides[metric] && rides[metric][0]) || [];
export const selectCorridorsMetricsRidesSecond = (metric) => ({ corridorsStats: { rides } }) =>
  (rides[metric] && rides[metric][1]) || [];
export const selectLoadingCorridorsMetricsRides = ({ corridorsStats }) => corridorsStats.loadingRides;
export const selectTotalRides = (key) => ({ corridorsStats: { totalRides } }) => totalRides[key];
export const selectGeneralMetricsFrames = ({ corridorsStats }) => corridorsStats.generalMetricsFrames;
export const selectLoadingGeneralMetricsFrames = ({ corridorsStats }) => corridorsStats.loadingGeneralMetricsFrames;
export const selectApproaches = ({ corridorsStats }) => corridorsStats.approaches;
export const selectLoadingCorridorStats = ({ corridorsStats }) => corridorsStats.loadingCorridorStats;
export const selectLoadingTotalRides = ({ corridorsStats }) => corridorsStats.loadingTotalRides;

export const {
  resetCorridorsStats,
  resetLoadingCorridorStats,
  resetLoadingTotalRides,
  resetTotalRidesApproaches,
} = corridorsStatsSlice.actions;

export default corridorsStatsSlice.reducer;
