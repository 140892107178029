import { setClickedGeneralViewFeatureId } from 'features';
import store from 'store';
import { layers } from 'utils/openlayers-utils/initLayers';
import { getGeneralViewFeatureHoverText } from 'utils/generalViewFeatureHoverText';
import { metricTypes } from 'consts';

const getGeneralViewApproachesFeatures = () => layers.generalViewStats?.getSource().getFeatures() || [];

const initGeneralViewEvents = (olMap, intl) => {
  const map = olMap;

  map.on('pointermove', (e) => {
    if (e.dragging) return;
    const pixel = map.getEventPixel(e.originalEvent);
    const feature = map.forEachFeatureAtPixel(pixel, (f) => f);
    const statsOverlay = map.getOverlayById('feature-stats-tooltip');
    const statsOverlayEl = statsOverlay.getElement();

    if (feature) {
      map.getViewport().style.cursor = 'pointer';
      const statsEl = statsOverlayEl.querySelector('.stats-text');
      const textContent = getGeneralViewFeatureHoverText(feature.getProperties(), intl, store.getState());

      if (textContent) {
        statsEl.innerHTML = textContent;
        statsOverlay.setPosition(e.coordinate);
      }
    } else {
      map.getViewport().style.cursor = 'default';
      statsOverlay.setPosition(undefined);
    }
    getGeneralViewApproachesFeatures().forEach((f) => f.set('isHovered', false));

    if (feature) {
      feature.set('isHovered', true);
    }
  });

  map.on('click', (e) => {
    const { metricType } = store.getState().generalStats;

    if (e.dragging) return;
    if (metricType === metricTypes.AIR_QUALITY) return;

    let clickedFeatureLayerName;

    const pixel = map.getEventPixel(e.originalEvent);

    const activeFeature = getGeneralViewApproachesFeatures().find((f) => f.get('isClicked'));

    const clickedFeature = map.forEachFeatureAtPixel(pixel, (feature, layer) => {
      clickedFeatureLayerName = layer.get('name');
      feature.set('layerName', clickedFeatureLayerName);

      return feature;
    });

    store.dispatch(setClickedGeneralViewFeatureId(null));
    if (activeFeature) {
      activeFeature.set('isClicked', false);
    }

    if (clickedFeature) {
      clickedFeature.set('isClicked', true);
      store.dispatch(setClickedGeneralViewFeatureId(clickedFeature.getId()));
    }
  });
};

export default initGeneralViewEvents;
