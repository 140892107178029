import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { handleDeleteSegment } from 'features';
import { Dialog } from 'screens/EditGrid/Dialog';

export const DeleteSegmentDialog = ({ isOpen, close, segmentId }) => {
  const dispatch = useDispatch();

  const submit = () => {
    dispatch(handleDeleteSegment({ segmentId }));

    close();
  };

  if (!segmentId) return null;

  return (
    <Dialog title="Delete Segment" isOpen={isOpen} onClose={close} onSubmit={submit}>
      {`segment ID: ${segmentId}`}
    </Dialog>
  );
};

DeleteSegmentDialog.propTypes = {
  isOpen: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  segmentId: propTypes.string,
};

DeleteSegmentDialog.defaultProps = {
  segmentId: null,
};
