export const timeIntervalsMock = {
  loading: 'loaded',
  intervals: [
    {
      id: 1,
      label: 'other_time',
      startTime: '06:00',
      endTime: '07:00',
      default: false,
      startDay: 0,
      endDay: 4,
    },
    {
      id: 2,
      label: 'morning_rush_hour',
      startTime: '07:00',
      endTime: '10:00',
      default: true,
      startDay: 0,
      endDay: 4,
    },
    {
      id: 3,
      label: 'midday',
      startTime: '10:00',
      endTime: '15:00',
      default: false,
      startDay: 0,
      endDay: 4,
    },
    {
      id: 4,
      label: 'evening_rush_hour',
      startTime: '15:00',
      endTime: '17:00',
      default: false,
      startDay: 0,
      endDay: 4,
    },
  ],
};

export const fetchTimeIntervalsMock = {
  loading: 'loaded',
  intervals: [
    {
      id: 1,
      label: 'other_time',
      startTime: '06:00',
      endTime: '07:00',
    },
    {
      id: 2,
      label: 'morning_rush_hour',
      startTime: '07:00',
      endTime: '10:00',
    },
    {
      id: 3,
      label: 'midday',
      startTime: '10:00',
      endTime: '15:00',
    },
    {
      id: 4,
      label: 'evening_rush_hour',
      startTime: '15:00',
      endTime: '17:00',
    },
  ],
};
