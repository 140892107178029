const PieChartStyles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  value: {
    position: 'absolute',
    color: 'inherit',
    fontSize: 12,
    fontWeight: 500,
  },
  greenColor: {
    color: theme.color07,
  },
  redColor: {
    color: theme.color06,
  },
});

export default PieChartStyles;
