import { createSlice, createSelector } from '@reduxjs/toolkit';
import { loadSimulation } from 'features/thunks';

export const simulationsSlice = createSlice({
  name: 'simulations',
  initialState: {
    items: {},
    currentId: null,
    loading: 'idle',
  },
  extraReducers: {
    [loadSimulation.pending]: (state) => {
      state.loading = 'loading';
    },
    [loadSimulation.fulfilled]: (state, action) => {
      let { id } = action.payload;

      // randomize hardcoded id on subsequent simulations
      if (state.items[id]) {
        id += Date.now();
      }

      state.loading = 'loaded';
      state.items[id] = {
        id,
        stats: action.payload.stats,
        result: transformResult(action.payload.result), // eslint-disable-line
        diagram: transformDiagram(action.payload.diagram), // eslint-disable-line
      };
    },
    [loadSimulation.rejected]: (state) => {
      state.loading = 'error';
    },
  },
  reducers: {
    setCurrentSimulationId(state, action) {
      state.currentId = action.payload;
    },
  },
});

export const selectSimulations = createSelector(
  ({ simulations }) => simulations,
  (simulations) => ({
    current: simulations.items[simulations.currentId],
    ids: Object.keys(simulations.items),
    loading: simulations.loading,
    error: simulations.loading,
  }),
);

export const { setCurrentSimulationId } = simulationsSlice.actions;

export default simulationsSlice.reducer;

function transformResult(data) {
  const junctions = Object.keys(data.corridors.result.junctions).map((id) => ({ junctionId: id }));
  const approaches = Object.values(data.corridors.result.junctions).map((j) => j.approaches);

  return junctions.map((junction, i) => ({ ...junction, approaches: approaches[i] }));
}

function transformDiagram(data) {
  return data['scenarios-result-file']['junction-data-list'].junction.map((j) => ({
    junctionId: j.number,
    name: j['junction-name'],
    moveSignals: j['move-signal-info-list'],
    scenarioList: {
      scenario: {
        'block-groups-list': {
          'block-group': data['scenarios-result-file']['scenarios-list'].scenario['block-groups-list'][
            'block-group'
          ].filter((group) => group['junction-number'] === j.number),
          'cycles-list': {
            cycle: data['scenarios-result-file']['scenarios-list'].scenario['block-groups-list'][
              'cycles-list'
            ].cycle.filter((cycle) => cycle['junction-number'] === j.number),
          },
        },
        duration: data['scenarios-result-file']['scenarios-list'].scenario.duration,
        name: data['scenarios-result-file']['scenarios-list'].scenario.name,
        number: data['scenarios-result-file']['scenarios-list'].scenario.number,
        program: data['scenarios-result-file']['scenarios-list'].scenario.program,
      },
    },
  }));
}
