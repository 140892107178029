import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { handleDeleteIntersection } from 'features';
import { Dialog } from 'screens/EditGrid/Dialog';

export const DeleteIntersectionDialog = ({ isOpen, close, intersectionId }) => {
  const dispatch = useDispatch();

  const submit = () => {
    dispatch(handleDeleteIntersection({ intersectionId }));

    close();
  };

  if (!intersectionId) return null;

  return (
    <Dialog title="Delete Intersection" isOpen={isOpen} onClose={close} onSubmit={submit}>
      {`intersection ID: ${intersectionId}`}
    </Dialog>
  );
};

DeleteIntersectionDialog.propTypes = {
  isOpen: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  intersectionId: propTypes.string,
};

DeleteIntersectionDialog.defaultProps = {
  intersectionId: null,
};
