import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoading, selectIsSidebarVisible, selectIsUserAdmin, selectStats, selectTimeZone } from 'features';
import {
  GeneralViewVideo,
  GeneralViewVideoMapbox,
  MapZoomer,
  StatisticsMenu,
  GeneralViewTimeSelect,
  UserMenu,
  GeneralViewRadialChart,
  SidebarContainer,
  FrameDetailsOverlay,
  MapExtentMapBox,
  ColorLegend,
} from 'components';
import { useFetchGeneralViewStats, usePostData } from 'utils/hooks';
import { OpenlayersMap } from './OpenlayersMap/OpenlayersMap';
import { MapboxMap } from './MapboxMap/MapboxMap';
import styles from './GeneralView.module.scss';

export const GeneralView = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  useFetchGeneralViewStats();
  const { generalStatsPostData } = usePostData();

  /* -------------------------------- Selectors ------------------------------- */
  const isLoading = useSelector(selectIsLoading);
  const isSidebarVisible = useSelector(selectIsSidebarVisible);
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const timeZone = useSelector(selectTimeZone);
  const mapStats = useSelector(selectStats(timeZone && JSON.stringify(generalStatsPostData())));

  /* ---------------------------------- State --------------------------------- */
  /* -------------------------------- Variables ------------------------------- */
  /* -------------------------------- Functions ------------------------------- */
  /* --------------------------------- Effects -------------------------------- */

  return (
    <div
      className={`
      ${styles.root}
      ${isLoading ? 'loadingOverlayShown' : ''}
    `}
    >
      {process.env.REACT_APP_MAP_GENERAL_VIEW === 'mapbox' ? <MapboxMap /> : <OpenlayersMap />}
      <SidebarContainer hasCollapseButton>
        <StatisticsMenu />
      </SidebarContainer>
      <div className={`${styles.userMenu} ${isSidebarVisible ? '' : styles.hidden}`}>
        <UserMenu />
      </div>
      <div className={styles.bottomRight}>
        <GeneralViewRadialChart />
        {process.env.REACT_APP_MAP_GENERAL_VIEW === 'mapbox' ? <GeneralViewVideoMapbox /> : <GeneralViewVideo />}
        <FrameDetailsOverlay />
      </div>
      <div className={styles.bottomLeft}>
        <GeneralViewTimeSelect />
        {process.env.REACT_APP_MAP_GENERAL_VIEW === 'openlayers' && <MapZoomer />}
      </div>
      {process.env.REACT_APP_MAP_GENERAL_VIEW === 'mapbox' && isUserAdmin && (
        <div className={styles.topRight}>
          <MapExtentMapBox />
          {mapStats?.colorScheme && <ColorLegend values={mapStats?.colorScheme} />}
        </div>
      )}
    </div>
  );
};
