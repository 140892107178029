const UnitSystemStyles = () => ({
  unitSystemSelect: {
    margin: '0 0 20px',
    minWidth: 185,
    maxWidth: 185,
  },
  unitSystemSelectDropdown: {
    zIndex: '9999 !important',
    height: 350,
  },
});

export default UnitSystemStyles;
