import { createSlice, createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { sortArraysInObjectByDatesArray } from 'utils/sortArraysInObjectByDatesArray';

import { metricTypes, statisticTypes } from 'consts';
import {
  fetchGeneralStats,
  fetchGeneralStatsFrames,
  fetchApproachesStatDays,
  fetchBusStopStatSamples,
  fetchPedestriansStatApproaches,
  fetchApproachesStatSamples,
  fetchApproachStatSampleFrames,
  setActiveFrameCordsAsync,
} from 'features/thunks';

export const generalStatsSlice = createSlice({
  name: 'generalStats',
  initialState: {
    stats: {},
    frames: {},
    activeFrames: null,
    framesFetchError: false,
    statisticType: statisticTypes.PEDESTRIANS,
    metricType: metricTypes.QUEUE_LENGTH,
    daysInPast: 20,
    startDate: +new Date(),
    filterNumberOfDays: 3,
    filterNumberOfSamples: 3,
    activeFrameCords: {
      prev: {
        latPrev: null,
        lonPrev: null,
      },
      curr: {
        latCurr: null,
        lonCurr: null,
      },
    },
    approachStatDays: null,
    loadingApproachStatDays: 'idle',
    pedestrianStatApproaches: null,
    approachesStatSamples: null,
    loadingApproachesStatSamples: 'idle',
    busStopStatSamples: null,
    loadingBusStopStatSamples: 'idle',
    approachStatSampleFrames: null,
    loadingApproachStatSampleFrames: 'idle',
    activeFrameDetails: {},
    isFrameDetailsOverlayVisible: false,
  },
  reducers: {
    setStatisticType: (state, action) => {
      state.statisticType = action.payload || state.statisticType;
    },
    setMetricType: (state, action) => {
      state.metricType = action.payload;
    },
    setStatsFramesKey: (state, action) => {
      state.activeFrames = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setDaysInPast: (state, action) => {
      state.daysInPast = Number(action.payload);
    },
    setFilterNumberOfDays: (state, action) => {
      state.filterNumberOfDays = Number(action.payload);
    },
    setFilterNumberOfSamples: (state, action) => {
      state.filterNumberOfSamples = Number(action.payload);
    },
    setActiveFrameCords: (state, action) => {
      state.activeFrameCords = action.payload;
    },
    resetActiveFrameCords: (state) => {
      state.activeFrameCords = {
        prev: {
          latPrev: null,
          lonPrev: null,
        },
        curr: {
          latCurr: null,
          lonCurr: null,
        },
      };
    },
    setActiveFrameDetails: (state, action) => {
      state.activeFrameDetails = action.payload;
    },
    setFrameDetailsOverlay: (state, action) => {
      state.isFrameDetailsOverlayVisible = action.payload;
    },
    setApproachStatDays: (state, action) => {
      state.approachStatDays = action.payload;
    },
    setPedestrianStatApproaches: (state, action) => {
      state.pedestrianStatApproaches = action.payload;
    },
    setApproachStatSampleFrames: (state, action) => {
      state.approachStatSampleFrames = action.payload;
    },
  },
  extraReducers: {
    [fetchGeneralStats.fulfilled]: (state, action) => {
      const [key, data, colorScheme, metricType] = action.payload;

      Object.values(data).forEach(({ videos }) =>
        videos.videos.sort((a, b) => DateTime.fromSQL(b.videoStartTime) - DateTime.fromSQL(a.videoStartTime)),
      );

      const fieldsToGoIntoFeature = data.map((item) => {
        const res = {
          ...item,
          approachName: item.name,
          id: item.feature.id,
        };

        if (item.feature.geometry.type === 'Point') {
          res.intersectionId = item.id;
        }

        delete res.name;
        delete res.feature;

        return res;
      });

      data.forEach((item, i) => {
        item.feature.properties = {
          ...item.feature.properties,
          ...fieldsToGoIntoFeature[i],
        };
      });

      const fieldToFilter = {
        redLight: 'redLightCrossing',
        jaywalkers: 'jaywalkers',
        pedestrianSignalProgression: 'pedestrianSignalProgression',
        activeCrosswalk: 'activeCrosswalk',
      }[metricType];
      const filterForDetectionAndRides = 3;
      const dataFiltered = data.filter((item) => item[fieldToFilter] >= filterForDetectionAndRides);

      state.stats[key] = { data: fieldToFilter ? dataFiltered : data, colorScheme };
    },
    [fetchGeneralStatsFrames.pending]: (state) => {
      state.framesFetchError = false;
    },
    [fetchGeneralStatsFrames.fulfilled]: (state, action) => {
      const [key, data] = Object.entries(action.payload)[0];

      state.frames[key] = data;
    },
    [fetchGeneralStatsFrames.rejected]: (state, action) => {
      state.framesFetchError = !['ConditionError', 'AbortError'].includes(action.error.name);
    },
    [fetchApproachesStatDays.pending]: (state) => {
      state.loadingApproachStatDays = 'loading';
    },
    [fetchApproachesStatDays.fulfilled]: (state, action) => {
      state.approachStatDays = sortArraysInObjectByDatesArray(action.payload);
      state.loadingApproachStatDays = 'loaded';
    },
    [fetchApproachesStatDays.rejected]: (state) => {
      state.loadingApproachStatDays = 'error';
    },
    [fetchPedestriansStatApproaches.fulfilled]: (state, action) => {
      state.pedestrianStatApproaches = action.payload;
    },
    [fetchApproachesStatSamples.pending]: (state) => {
      state.loadingApproachesStatSamples = 'loading';
    },
    [fetchApproachesStatSamples.fulfilled]: (state, action) => {
      state.loadingApproachesStatSamples = 'loaded';
      state.approachesStatSamples = sortArraysInObjectByDatesArray(action.payload);
    },
    [fetchApproachesStatSamples.rejected]: (state) => {
      state.loadingApproachesStatSamples = 'error';
    },
    [fetchApproachStatSampleFrames.pending]: (state) => {
      state.loadingApproachStatSampleFrames = 'loading';
    },
    [fetchApproachStatSampleFrames.fulfilled]: (state, action) => {
      state.approachStatSampleFrames = [action.payload.Frames];
      state.loadingApproachStatSampleFrames = 'loaded';
    },
    [fetchApproachStatSampleFrames.rejected]: (state) => {
      state.loadingApproachStatSampleFrames = 'error';
    },
    [fetchBusStopStatSamples.pending]: (state) => {
      state.loadingBusStopStatSamples = 'loading';
    },
    [fetchBusStopStatSamples.fulfilled]: (state, action) => {
      state.busStopStatSamples = action.payload;
      state.loadingBusStopStatSamples = 'loaded';
    },
    [fetchBusStopStatSamples.rejected]: (state) => {
      state.busStopStatSamples = null;
      state.loadingApproachStatSampleFrames = 'error';
    },
    [setActiveFrameCordsAsync.fulfilled]: (state, { payload }) => {
      state.activeFrameCords = {
        prev: {
          latPrev: null,
          lonPrev: null,
        },
        curr: {
          latCurr: payload[1],
          lonCurr: payload[0],
        },
      };
    },
  },
});

export const selectStatisticType = ({ generalStats }) => generalStats.statisticType;
export const selectMetricType = ({ generalStats }) => generalStats.metricType;
export const selectStats = (dataKey) => ({ generalStats }) => generalStats.stats[dataKey];
export const selectStatsFrames = createSelector(
  ({ generalStats }) => generalStats.frames[generalStats.activeFrames],
  (frames) => frames || [[]],
);
export const selectStatsFramesKey = ({ generalStats }) => generalStats.activeFrames;
export const selectFramesFetchError = ({ generalStats }) => generalStats.framesFetchError;
export const selectStartDate = ({ generalStats }) => generalStats.startDate;
export const selectDaysInPast = ({ generalStats }) => generalStats.daysInPast;
export const selectFilterNumberOfDays = ({ generalStats }) => generalStats.filterNumberOfDays;
export const selectFilterNumberOfSamples = ({ generalStats }) => generalStats.filterNumberOfSamples;
export const selectActiveFrameCords = ({ generalStats }) => generalStats.activeFrameCords;
export const selectActiveFrameDetails = ({ generalStats }) => generalStats.activeFrameDetails;
export const selectApproachStatDays = ({ generalStats }) => generalStats.approachStatDays;
export const selectPedestrianStatApproaches = ({ generalStats }) => generalStats.pedestrianStatApproaches;
export const selectApproachStatSampleFrames = ({ generalStats }) => generalStats.approachStatSampleFrames;
export const selectApproachesStatSamples = ({ generalStats }) => generalStats.approachesStatSamples;
export const selectIsLoadingApproachesStatSamples = ({ generalStats }) =>
  generalStats.loadingApproachesStatSamples === 'loading';
export const selectIsLoadingApproachStatSampleFrames = ({ generalStats }) =>
  generalStats.loadingApproachStatSampleFrames === 'loading';
export const selectIsLoadingApproachStatDays = ({ generalStats }) => generalStats.loadingApproachStatDays === 'loading';
export const selectBusStopStatSamples = ({ generalStats }) => generalStats.busStopStatSamples;
export const selectIsLoadingBusStopStatSamples = ({ generalStats }) =>
  generalStats.loadingBusStopStatSamples === 'loading';
export const selectIsFrameDetailsOverlayVisible = ({ generalStats }) => generalStats.isFrameDetailsOverlayVisible;

export const {
  setStatisticType,
  setMetricType,
  setStatsFramesKey,
  setDaysInPast,
  setStartDate,
  setActiveFrameCords,
  resetActiveFrameCords,
  setActiveFrameDetails,
  setApproachStatDays,
  setPedestrianStatApproaches,
  setApproachStatSampleFrames,
  setFilterNumberOfDays,
  setFilterNumberOfSamples,
  setFrameDetailsOverlay,
} = generalStatsSlice.actions;

export default generalStatsSlice.reducer;
