import VectorLayer from 'ol/layer/Vector';
import LayerGroup from 'ol/layer/Group';
import VectorSource from 'ol/source/Vector';
import Cluster from 'ol/source/Cluster';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';

export const layers = {};

export const initFeatures = (features) =>
  new GeoJSON({
    featureProjection: 'EPSG:3857',
  }).readFeatures(features);

export const initLayer = (name, features, style, zIndex, visible = true) =>
  new VectorLayer({
    name,
    source: new VectorSource({
      features: features ? initFeatures(features) : undefined,
    }),
    style,
    zIndex,
    visible,
  });

export const initClusterLayer = (name, features, style, zIndex, distance) =>
  new VectorLayer({
    name,
    source: new Cluster({
      distance,
      source: new VectorSource({
        features: initFeatures(features),
      }),
    }),
    style,
    zIndex,
  });

export const initLayerGroup = (layersArr, name, target) =>
  new LayerGroup({
    layers: layersArr,
    name,
    target,
  });

export const initPolygonLayer = (name, coords, style, zIndex) => {
  const geometry = new Polygon([coords]).transform('EPSG:4326', 'EPSG:3857');
  const feature = new Feature({
    geometry,
  });

  return new VectorLayer({
    name,
    source: new VectorSource({
      features: [feature],
    }),
    style,
    zIndex,
  });
};
