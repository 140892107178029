export const sortArraysInObjectByDatesArray = (originalObject) =>
  Object.values(originalObject)[0]
    .map((_, i) => Object.fromEntries(Object.entries(originalObject).map(([key, val]) => [key, val[i]])))
    .sort((a, b) => a.days < b.days)
    .reduce((obj, item) => {
      Object.entries(item).forEach(([key, val]) => {
        if (!obj[key]) {
          // eslint-disable-next-line no-param-reassign
          obj[key] = [];
        }
        obj[key].push(val);
      });

      return obj;
    }, {});
