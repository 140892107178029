/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { ChartBar, PieChart } from 'components';
import store from 'store';
import { FormattedMessage } from 'react-intl';

/* eslint-disable react/display-name  */

export const generalMetricsTableData = (expandedPanel) => {
  const {
    unitSystem: { distanceMultiplier, speedMultiplier },
  } = store.getState();

  const data = {
    'stops-number': [
      {
        head: () => (
          <FormattedMessage
            defaultMessage="Probability to stop in each junction"
            description="Stops number table column Probability to stop"
          />
        ),
        data: (stop) => (
          <PieChart
            className="probabilityToStopChart"
            percent={Math.round(stop.probabilityToStop * 100)}
            isRed={stop.critical}
          />
        ),
      },
    ],
    'waiting-time': [
      {
        head: () => (
          <FormattedMessage
            defaultMessage="Contribution to total waiting time"
            description="Waiting time table column Contribution waiting"
          />
        ),
        data: (stop) => (
          <PieChart
            className="contributionChartWaiting"
            percent={Math.round(stop.contributionWaiting)}
            isRed={stop.critical}
          />
        ),
      },
      {
        head: () => (
          <FormattedMessage
            defaultMessage="Contribution to total driving time"
            description="Waiting time table column Contribution driving"
          />
        ),
        data: (stop) => (
          <PieChart
            className="contributionChartDriving"
            percent={Math.round(stop.contributionDriving)}
            isRed={stop.critical}
          />
        ),
      },
      {
        head: () => (
          <FormattedMessage
            defaultMessage="Average waiting/driving time"
            description="Waiting time table column Average waiting/driving"
          />
        ),
        data: (stop) => (
          <ChartBar
            values={[`${Math.round(stop.avgWaiting * 10) / 10}s`, `${Math.round(stop.avgDriving * 10) / 10}s`]}
          />
        ),
      },
      {
        head: () => (
          <FormattedMessage
            defaultMessage="Median waiting/driving time"
            description="Waiting time table column Median waiting/driving"
          />
        ),
        data: (stop) => (
          <ChartBar
            values={[`${Math.round(stop.medianWaiting * 10) / 10}s`, `${Math.round(stop.medianDriving * 10) / 10}s`]}
          />
        ),
      },
      {
        head: () => (
          <FormattedMessage
            defaultMessage="STD waiting/driving time"
            description="Waiting time table column STD waiting/driving"
          />
        ),
        data: (stop) => (
          <ChartBar
            values={[`${Math.round(stop.stdWaiting * 10) / 10}s`, `${Math.round(stop.stdDriving * 10) / 10}s`]}
          />
        ),
      },
    ],
    speed: [
      {
        head: () => (
          <FormattedMessage defaultMessage="Segment length" description="Average speed table column Segment length" />
        ),
        data: (stop) => Math.round(stop.feature?.properties.length * distanceMultiplier * 10) / 10 || '',
      },
      {
        head: () => (
          <FormattedMessage defaultMessage="Average speed" description="Average speed table column Average speed" />
        ),
        data: (stop) => Math.round(stop.avgSpeed * speedMultiplier * 10) / 10,
      },
      {
        head: () => (
          <FormattedMessage defaultMessage="Median speed" description="Average speed table column Median speed" />
        ),
        data: (stop) => Math.round(stop.medianSpeed * speedMultiplier * 10) / 10,
      },
      {
        head: () => <FormattedMessage defaultMessage="STD" description="Average speed table column STD" />,
        data: (stop) => Math.round(stop.stdSpeed * speedMultiplier * 10) / 10,
      },
    ],
    'queue-length': [
      {
        head: () => (
          <FormattedMessage
            defaultMessage="Average queue length"
            description="Queue length table column Average queue length"
          />
        ),
        data: (stop) => Math.round(stop.avgQueueLength * distanceMultiplier * 10) / 10,
      },
      {
        head: () => (
          <FormattedMessage
            defaultMessage="Median queue length"
            description="Queue length table column Median queue length"
          />
        ),
        data: (stop) => Math.round(stop.medianQueueLength * distanceMultiplier * 10) / 10,
      },
      {
        head: () => (
          <FormattedMessage
            defaultMessage="STD queue length"
            description="Queue length table column STD queue length"
          />
        ),
        data: (stop) => Math.round(stop.stdQueueLength * distanceMultiplier * 10) / 10,
      },
      {
        head: () => (
          <FormattedMessage
            defaultMessage="Approach occupancy"
            description="Queue length table column Approach occupancy"
          />
        ),
        data: (stop) => (
          <PieChart className="approachOccupancy" percent={Math.round(stop.approachOccupancy)} isRed={stop.critical} />
        ),
      },
    ],
    blockage: [
      {
        head: () => (
          <FormattedMessage
            defaultMessage="Probability for blockage"
            description="Blockage table column Probability for blockage"
          />
        ),
        data: (stop) => (
          <PieChart
            className="probabilityOfBlockage"
            percent={Math.round(stop.probabilityOfBlockage * 100)}
            isRed={stop.critical}
          />
        ),
      },
    ],
    'dwell-time': [
      {
        head: () => (
          <FormattedMessage
            defaultMessage="Average dwell time"
            description="Dwell time table column Average dwell time"
          />
        ),
        data: (stop) => Math.round(stop.avgDwellTime * 10) / 10,
      },
      {
        head: () => (
          <FormattedMessage
            defaultMessage="Median dwell time"
            description="Dwell time table column Median dwell time"
          />
        ),
        data: (stop) => Math.round(stop.medianDwellTime * 10) / 10,
      },
      {
        head: () => <FormattedMessage defaultMessage="STD" description="Dwell time table column STD" />,
        data: (stop) => Math.round(stop.stdDwellTime * 10) / 10,
      },
      {
        head: () => (
          <FormattedMessage
            defaultMessage="Contribution to total dwell time"
            description="Dwell time table column Contribution to total dwell time"
          />
        ),
        data: (stop) => <PieChart percent={stop.contribution} isRed={stop.critical} />,
      },
    ],
  };

  return data[expandedPanel];
};
