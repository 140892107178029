import { fromLonLat } from 'ol/proj';

export const animateFrameCoords = ({ olMap, activeFrameCords, iconSrc }) => {
  const {
    curr: { lonCurr, latCurr },
  } = activeFrameCords;
  const framesLocationOverlay = olMap.getOverlayById('frames-location');
  const overlayEl = framesLocationOverlay.getElement();

  overlayEl.innerHTML = `<img src="${iconSrc}" />`;
  framesLocationOverlay.setPosition(fromLonLat([lonCurr, latCurr]));
};
