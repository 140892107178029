import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Box,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  Paper,
  Tab,
  AppBar,
  Tabs,
} from '@material-ui/core';
import { selectSimulations, selectIntersections, selectDistanceMultiplier } from 'features';
import { FormattedMessage } from 'react-intl';
import styles from './SimulationTables.module.scss';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: propTypes.node.isRequired,
  index: propTypes.any.isRequired,
  value: propTypes.any.isRequired,
};

const a11yProps = (index) => ({
  id: `scrollable-auto-tab-${index}`,
  'aria-controls': `scrollable-auto-tabpanel-${index}`,
});

const renderTableHead = () => (
  <TableHead>
    <TableRow>
      <TableCell align="center">
        <FormattedMessage defaultMessage="Name" description="Simulation table column Name" />
      </TableCell>
      <TableCell align="center">
        <FormattedMessage defaultMessage="Intersection ID" description="Simulation table column Intersection ID" />
      </TableCell>
      <TableCell align="center">
        <FormattedMessage defaultMessage="E" description="Simulation table column E" />
      </TableCell>
      <TableCell align="center">
        <FormattedMessage defaultMessage="N" description="Simulation table column N" />
      </TableCell>
      <TableCell align="center">
        <FormattedMessage defaultMessage="S" description="Simulation table column S" />
      </TableCell>
      <TableCell align="center">
        <FormattedMessage defaultMessage="W" description="Simulation table column W" />
      </TableCell>
    </TableRow>
  </TableHead>
);

const renderTableBody = (items, prop, distanceMultiplier) => {
  if (!items) {
    return null;
  }

  if (prop === 'avgDelay') {
    return (
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.junctionId}>
            <TableCell align="center">{item.name}</TableCell>
            <TableCell align="center">{item.junctionId}</TableCell>
            <TableCell align="center">{Math.round(item.approaches.E[prop] * 10) / 10}</TableCell>
            <TableCell align="center">{Math.round(item.approaches.N[prop] * 10) / 10}</TableCell>
            <TableCell align="center">{Math.round(item.approaches.S[prop] * 10) / 10}</TableCell>
            <TableCell align="center">{Math.round(item.approaches.W[prop] * 10) / 10}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }

  if (prop === 'avgQueueLength') {
    return (
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.junctionId}>
            <TableCell align="center">{item.name}</TableCell>
            <TableCell align="center">{item.junctionId}</TableCell>
            <TableCell align="center">{Math.round(item.approaches.E[prop] * distanceMultiplier * 10) / 10}</TableCell>
            <TableCell align="center">{Math.round(item.approaches.N[prop] * distanceMultiplier * 10) / 10}</TableCell>
            <TableCell align="center">{Math.round(item.approaches.S[prop] * distanceMultiplier * 10) / 10}</TableCell>
            <TableCell align="center">{Math.round(item.approaches.W[prop] * distanceMultiplier * 10) / 10}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }

  return (
    <TableBody>
      {items.map((item) => (
        <TableRow key={item.junctionId}>
          <TableCell align="center">{item.name}</TableCell>
          <TableCell align="center">{item.junctionId}</TableCell>
          <TableCell align="center">{item.approaches.E[prop]}</TableCell>
          <TableCell align="center">{item.approaches.N[prop]}</TableCell>
          <TableCell align="center">{item.approaches.S[prop]}</TableCell>
          <TableCell align="center">{item.approaches.W[prop]}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export const SimulationTables = () => {
  const { current: simulation } = useSelector(selectSimulations);
  const distanceMultiplier = useSelector(selectDistanceMultiplier);
  const intersections = useSelector(selectIntersections);
  const [value, setValue] = useState(0);
  const [tableRows, setTableRows] = useState(null);
  const { intersections: intersectionsData } = intersections.signalizedByCorridor[1];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!intersectionsData.length || !simulation) {
      return;
    }
    let junctionsToRender = [];
    const junctions = simulation.result;

    junctionsToRender = junctions.reduce((acc, junction) => {
      const intersectionName = intersectionsData.find(
        (intersection) => intersection.properties.id.toString() === junction.junctionId,
      );

      if (intersectionName) {
        acc.push({
          ...junction,
          name: intersectionName.properties.name,
        });
      }

      return acc;
    }, []);

    let data = intersectionsData.map((intersection) => ({
      id: intersection.id,
      name: intersection.properties.name,
    }));

    data = data
      .map((inner) => {
        const intersection = junctionsToRender.find((row) => row.junctionId === inner.id);

        return {
          name: inner.name,
          ...intersection,
        };
      })
      .filter((item) => item.junctionId !== undefined);

    setTableRows(data);
  }, [intersectionsData.length, simulation]); // eslint-disable-line

  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
        >
          <Tab label="Queue lengths" {...a11yProps(0)} />
          <Tab label="LOS" {...a11yProps(1)} />
          <Tab label="CO2 emissions" {...a11yProps(2)} />
          <Tab label="Delay" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table size="small" aria-label="a dense table">
            {renderTableHead()}
            {renderTableBody(tableRows, 'avgQueueLength', distanceMultiplier)}
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table size="small" aria-label="a dense table">
            {renderTableHead()}
            {renderTableBody(tableRows, 'LOS', distanceMultiplier)}
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table size="small" aria-label="a dense table">
            {renderTableHead()}
            {renderTableBody(tableRows, 'avgCO2EmissionPerVehicleGram', distanceMultiplier)}
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table size="small" aria-label="a dense table">
            {renderTableHead()}
            {renderTableBody(tableRows, 'avgDelay', distanceMultiplier)}
          </Table>
        </TableContainer>
      </TabPanel>
    </>
  );
};
