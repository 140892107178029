import { createAsyncThunk } from '@reduxjs/toolkit';
import { getChordDiagramData } from 'api/endpoints';

export const fetchOdMatrix = createAsyncThunk(
  'odMatrix',
  // default threshold is set to 0.7%
  async ({ id, corridor, threshold = 0.007 }, thunkAPI) => {
    const response = await getChordDiagramData(id, corridor, threshold);

    if (response.status && response.status !== 200) {
      return thunkAPI.rejectWithValue();
    }

    return response.data;
  },
);
