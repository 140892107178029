import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CalendarDateIcon } from 'assets/images/Calendar Date@3x.svg';
import './styles/TrafficInsights.scss';
import 'ol/ol.css';
import { CircularProgress, Button as MUIButton, Box, Collapse } from '@material-ui/core';
import { Button, CollapseButton } from '@axilion/ui-components';
import {
  ArrowDropDown,
  Traffic as TrafficIcon,
  Compare as CompareIcon,
  Replay,
  VisibilityOff,
  Visibility,
  Menu,
  Search,
} from '@material-ui/icons';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { promiseAll } from 'api';
import { mapboxMapNames } from 'consts';
import {
  Header,
  SelectPlanRange,
  SearchDetails,
  SidebarPanels,
  SelectCorridors,
  SimulationContainer,
  SignalPlansContainer,
  TotalRidesContainer,
  GeneralMetricsContainer,
  GeneralMetricsContainerMapbox,
  UserMenu,
  TileLayerSwitch,
} from 'components';
import {
  selectExpandedPanel,
  selectIsComparing,
  selectDateRange,
  selectIsRunningQuery,
  selectIsSidebarVisible,
  setIsRunningQuery,
  selectIsCompareSelectVisible,
  setIsCompareSelectVisible,
  setDateRange,
  setPlanRange,
  setExpandedPanel,
  setIsComparing,
  setRidesError,
  setIsSidebarVisible,
  selectIsLoading,
  resetCorridorsStats,
  cancelCorridorsMetricsRequests,
  fetchTotalRides,
  fetchCorridorStats,
  selectCorridors,
  selectIntersections,
  fetchBusStops,
  selectUser,
  setIsNavigationOpen,
  loadCorridors,
  fetchIntersectionsByCorridor,
  selectTimeZone,
  setFromIntersection,
  setToIntersection,
  selectTimeIntervals,
} from 'features';
import { highlightIntersections, mapboxMaps } from 'utils';
import { useFormat, useIntl, usePostData } from 'utils/hooks';
import { OpenlayersMap } from './OpenlayersMap/OpenlayersMap';
import { MapboxMap } from './MapboxMap/MapboxMap';
import { preselectIntersections } from './MapboxMap/initMapboxEvents';
import TrafficInsightsStyles from './styles/TrafficInsights.styles';

const useStyles = makeStyles(TrafficInsightsStyles);

export const TrafficInsights = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const { searchPostData } = usePostData();
  const { fromClientToProjectTime } = useFormat();
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();

  /* -------------------------------- Selectors ------------------------------- */
  const dateRange = useSelector(selectDateRange);
  const user = useSelector(selectUser);
  const expandedPanel = useSelector(selectExpandedPanel);
  const corridors = useSelector(selectCorridors);
  const intersections = useSelector(selectIntersections);
  const isComparing = useSelector(selectIsComparing);
  const isRunningQuery = useSelector(selectIsRunningQuery);
  const isSidebarVisible = useSelector(selectIsSidebarVisible);
  const isCompareSelectVisible = useSelector(selectIsCompareSelectVisible);
  const isLoading = useSelector(selectIsLoading);
  const timeZone = useSelector(selectTimeZone);
  const timeIntervals = useSelector(selectTimeIntervals);

  /* ---------------------------------- State --------------------------------- */
  const [isSelectTimeRangeVisible, setIsSelectTimeRangeVisible] = useState({ first: false, second: false });
  const [clickedIntersection, setClickedIntersection] = useState(null);
  const [clickedIntersectionsArr, setClickedIntersectionsArr] = useState([]);
  const [rides, setRides] = useState({ first: [], second: [] });
  const [commonStatisticsFirst, setCommonStatisticsFirst] = useState({});
  const [commonStatisticsSecond, setCommonStatisticsSecond] = useState({});
  const [isSearchDetailsVisible, setIsSearchDetailsVisible] = useState(false);
  const [areCorridorsAvailable, setAreCorridorsAvailable] = useState(false);

  /* -------------------------------- Variables ------------------------------- */
  const showMapOnly = isLoading && !user.isLoggedIn;
  const defaultTimeInterval = timeIntervals.find((interval) => interval.default);

  /* -------------------------------- Functions ------------------------------- */
  const handleGetStatistics = () => {
    const postDataArr = [searchPostData('first'), searchPostData('second')];
    const serializedPostDataArr = [JSON.stringify(postDataArr[0]), JSON.stringify(postDataArr[1])];

    return dispatch(fetchCorridorStats({ isComparing, postDataArr, serializedPostDataArr }));
  };

  const resolveGetStatistics = (response) => {
    const statistics = Object.values(unwrapResult(response));

    if (isComparing && statistics.length === 1) {
      statistics.push(statistics[0]);
    }

    setCommonStatisticsFirst(statistics[0]);
    if (statistics[1]) {
      setCommonStatisticsSecond(statistics[1]);
    }

    if (!isComparing && !statistics[0]?.speed) {
      const message = intl.formatMessage({
        description: 'Error message',
        defaultMessage: 'There is no run of the VD for the specified query',
      });

      dispatch(setRidesError(message));
    }

    const firstQuery = !!statistics[0]?.speed;
    const secondQuery = !!statistics[1]?.speed;

    if (isComparing && (!firstQuery || !secondQuery)) {
      let affectedInputs;

      if (!firstQuery && !secondQuery) {
        affectedInputs = intl.formatMessage({
          description: 'There is no data set for the first and second inputs.',
          defaultMessage: 'first and second inputs',
        });
      } else if (!firstQuery) {
        affectedInputs = intl.formatMessage({
          description: 'There is no data set for the first input.',
          defaultMessage: 'first input',
        });
      } else {
        affectedInputs = intl.formatMessage({
          description: 'There is no data set for the second input.',
          defaultMessage: 'second input',
        });
      }

      const message = intl.formatMessage(
        {
          description: 'Error message',
          defaultMessage: 'There is no data set for the {affectedInputs}.',
        },
        { affectedInputs },
      );

      dispatch(setRidesError(message));
    }
  };

  const handleGetRides = () => {
    cancelCorridorsMetricsRequests.rides.forEach((cancel) => cancel());
    setRides({ first: [], second: [] });

    const postDataArr = [searchPostData('first'), searchPostData('second')];
    const serializedPostDataArr = [JSON.stringify(postDataArr[0]), JSON.stringify(postDataArr[1])];

    return dispatch(fetchTotalRides({ isComparing, postDataArr, serializedPostDataArr }));
  };

  const resolveGetRides = (response) => {
    const totalRides = Object.values(unwrapResult(response));

    if (isComparing && totalRides.length === 1) {
      totalRides.push(totalRides[0]);
    }

    setRides({
      first: totalRides[0]?.rides,
      second: totalRides[1]?.rides,
    });
  };

  const toggleSelectionInputs = () => {
    dispatch(setIsCompareSelectVisible(!isCompareSelectVisible));
  };

  const setResultsTopPosition = () => {
    if (isCompareSelectVisible && isComparing) {
      return 540;
    }
    if (isCompareSelectVisible && !isComparing) {
      return 440;
    }
    if (!isCompareSelectVisible) {
      return 170;
    }

    return 440;
  };

  const resetEndIntersection = () => {
    dispatch(setToIntersection(null));
    dispatch(setRidesError(null));
    if (expandedPanel !== 'signal-plan') {
      dispatch(setExpandedPanel(null));
    }
    setIsSearchDetailsVisible(false);
    highlightIntersections(null);
    dispatch(setIsCompareSelectVisible(true));
  };

  const resetQueriedInput = () => {
    const date = fromClientToProjectTime(new Date());
    const newDateRange = [date.startOf('day').minus({ weeks: 3 }).ts, date.endOf('day').ts];

    dispatch(
      setDateRange({
        first: newDateRange,
        second: newDateRange,
      }),
    );

    dispatch(
      setPlanRange({
        first: defaultTimeInterval,
        second: defaultTimeInterval,
      }),
    );

    const features = intersections.byCorridor.find(({ corridorId }) => corridorId === corridors.selectedCorridorId)
      ?.intersections;

    if (features && mapboxMaps[mapboxMapNames.corridorView])
      preselectIntersections(mapboxMaps[mapboxMapNames.corridorView], features, setClickedIntersectionsArr);

    resetEndIntersection();
    dispatch(setFromIntersection(null));
    setClickedIntersection(null);
  };

  const showSignalPlan = () => {
    if (expandedPanel !== 'signal-plan') {
      resetQueriedInput();
      dispatch(setExpandedPanel('signal-plan'));
    } else {
      dispatch(setExpandedPanel(null));
    }
  };

  const openNavigation = () => {
    dispatch(setIsNavigationOpen(true));
  };

  const onCollapseSidebarClick = () => {
    dispatch(setIsSidebarVisible(!isSidebarVisible));
  };

  const onCalendarOpen = () => {
    setIsSelectTimeRangeVisible({
      first: false,
      second: false,
    });
  };

  const onDateRangePickerChange = (firstSecond) => (val) => {
    dispatch(setDateRange({ [firstSecond]: val }));
  };

  const onToggleCompareClick = () => {
    dispatch(setIsComparing(!isComparing));
  };

  const onAnalyzeClick = async () => {
    setIsSearchDetailsVisible(false);
    dispatch(setRidesError(null));
    dispatch(setExpandedPanel(null));
    dispatch(resetCorridorsStats());
    dispatch(setIsRunningQuery(true));

    const results = await promiseAll([handleGetStatistics(), handleGetRides()]);

    let hasError = false;

    results.forEach((result) => {
      if (result.error) {
        setRides({ first: [], second: [] });
        hasError = true;
      }
    });

    if (hasError) return;

    resolveGetStatistics(results[0]);
    resolveGetRides(results[1]);

    setIsSearchDetailsVisible(true);
    dispatch(setIsCompareSelectVisible(false));
    dispatch(setIsRunningQuery(false));
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    if (user.isLoggedIn) {
      dispatch(loadCorridors());
    }
  }, [user.isLoggedIn]); //eslint-disable-line

  useEffect(() => {
    if (corridors.loading === 'loaded' && corridors.selectedCorridorId) {
      dispatch(fetchBusStops({ corridorId: corridors.selectedCorridorId }));
    }
  }, [corridors.selectedCorridorId]); // eslint-disable-line

  useEffect(() => {
    if (corridors.loading === 'loaded') {
      dispatch(fetchIntersectionsByCorridor(corridors.ids));
      setAreCorridorsAvailable(!!corridors.corridorsNames?.length);
    }
  }, [corridors.loading]); // eslint-disable-line

  useLayoutEffect(() => {
    if (clickedIntersectionsArr.length === 1) {
      resetEndIntersection();
    }
    if (clickedIntersectionsArr.length) {
      dispatch(setFromIntersection(clickedIntersectionsArr[0].id));
    }

    if (clickedIntersectionsArr.length === 2) {
      dispatch(setToIntersection(clickedIntersectionsArr[1].id));
    }
  }, [clickedIntersectionsArr]); // eslint-disable-line

  return (
    <>
      <div className={`TrafficInsights ${isSidebarVisible ? 'sidebarVisible' : 'sidebarHidden'}`}>
        {!showMapOnly && (
          <>
            <div className="map-top-left-area">
              <UserMenu />
            </div>
            <div className="map-top-right-area">
              {process.env.REACT_APP_MAP_CORRIDOR_VIEW === 'openlayers' && <TileLayerSwitch />}
              <div>
                <MUIButton
                  variant="contained"
                  color="primary"
                  className={`${classes.signalPlanButton} ${
                    areCorridorsAvailable ? '' : classes.signalPlanButtonDisabled
                  }`}
                  startIcon={<TrafficIcon />}
                  onClick={showSignalPlan}
                  disabled={!areCorridorsAvailable}
                >
                  <FormattedMessage defaultMessage="SIGNAL PLANS" description="Dashboard Signal plans button" />
                </MUIButton>
                <SelectCorridors resetQueriedInput={resetQueriedInput} disabled={!areCorridorsAvailable} />
              </div>
            </div>
          </>
        )}
        {process.env.REACT_APP_MAP_CORRIDOR_VIEW === 'openlayers' && (
          <OpenlayersMap
            clickedIntersection={clickedIntersection}
            setClickedIntersection={setClickedIntersection}
            clickedIntersectionsArr={clickedIntersectionsArr}
            setClickedIntersectionsArr={setClickedIntersectionsArr}
            resetEndIntersection={resetEndIntersection}
          />
        )}
        {process.env.REACT_APP_MAP_CORRIDOR_VIEW === 'mapbox' && (
          <MapboxMap setClickedIntersectionsArr={setClickedIntersectionsArr} />
        )}
        {!showMapOnly && (
          <>
            <CollapseButton
              className={classes.collapseSideBarButton}
              onClick={onCollapseSidebarClick}
              pointer={isSidebarVisible ? 'left' : 'right'}
            />
            <div className="sidebar">
              <Header icon={<Menu onClick={openNavigation} />} />
              <Collapse in={isCompareSelectVisible}>
                <div className="filter-components">
                  <div className={isComparing ? 'date-picker compare-one' : 'date-picker'}>
                    {timeZone && (
                      <DateRangePicker
                        onCalendarOpen={onCalendarOpen}
                        onChange={onDateRangePickerChange('first')}
                        value={dateRange.first}
                        calendarAriaLabel="Toggle calendar"
                        dayAriaLabel="Day"
                        monthAriaLabel="Month"
                        nativeInputAriaLabel="Date"
                        yearAriaLabel="Year"
                        calendarIcon={<ArrowDropDown color="primary" className={classes.arrowDown} />}
                        clearIcon={null}
                        required={false}
                        format="dd MMM y"
                        maxDate={fromClientToProjectTime(new Date()).toJSDate()}
                        minDetail="month"
                        locale={user.locale}
                      />
                    )}
                    <CalendarDateIcon className={classes.calendarIcon} />
                  </div>
                  <div className={isComparing ? 'select-plan-range compare-one' : 'select-plan-range'}>
                    <SelectPlanRange
                      firstOrSecond="first"
                      isSelectTimeRangeVisible={isSelectTimeRangeVisible.first}
                      setIsSelectTimeRangeVisible={setIsSelectTimeRangeVisible}
                    />
                  </div>
                  <Box mt={3} mb={3}>
                    <Button
                      onClick={onToggleCompareClick}
                      variant="outlined"
                      startIcon={<CompareIcon />}
                      color={isComparing ? 'default' : 'primary'}
                      style={{ borderColor: isComparing ? 'transparent' : 'primary' }}
                    >
                      {isComparing ? (
                        <FormattedMessage description="Compared to button" defaultMessage="Compared to:" />
                      ) : (
                        <FormattedMessage description="Compare to button" defaultMessage="Compare to" />
                      )}
                    </Button>
                  </Box>
                  <Collapse in={isComparing}>
                    <div className={isComparing ? 'date-picker compare-two' : 'date-picker'}>
                      {timeZone && (
                        <DateRangePicker
                          onCalendarOpen={onCalendarOpen}
                          onChange={onDateRangePickerChange('second')}
                          value={dateRange.second}
                          calendarAriaLabel="Toggle calendar"
                          dayAriaLabel="Day"
                          monthAriaLabel="Month"
                          nativeInputAriaLabel="Date"
                          yearAriaLabel="Year"
                          calendarIcon={<ArrowDropDown color="primary" className={classes.arrowDown} />}
                          clearIcon={null}
                          required={false}
                          format="dd MMM y"
                          maxDate={fromClientToProjectTime(new Date()).toJSDate()}
                          minDetail="month"
                          locale={user.locale}
                        />
                      )}
                      <CalendarDateIcon className={classes.calendarIcon} />
                    </div>
                    <div className={isComparing ? 'select-plan-range compare-two' : 'select-plan-range'}>
                      <SelectPlanRange
                        firstOrSecond="second"
                        isSelectTimeRangeVisible={isSelectTimeRangeVisible.second}
                        setIsSelectTimeRangeVisible={setIsSelectTimeRangeVisible}
                      />
                    </div>
                  </Collapse>
                  <Box mt={2} mb={2}>
                    <Button
                      disabled={clickedIntersectionsArr.length !== 2 || isRunningQuery}
                      onClick={onAnalyzeClick}
                      startIcon={<Search />}
                    >
                      <FormattedMessage description="Analyze button" defaultMessage="Analyze" />
                    </Button>
                  </Box>
                </div>
              </Collapse>
              {isRunningQuery ? (
                <CircularProgress className={classes.circularProgressSmall} size={26} thickness={4} />
              ) : (
                <Box className={classes.selectionButton} mt={isCompareSelectVisible ? 0 : 3} pl={2} pr={2}>
                  <Button model="borderless" onClick={resetQueriedInput} startIcon={<Replay />}>
                    <FormattedMessage description="Reset selection button" defaultMessage="Reset Selection" />
                  </Button>
                  <Button
                    model="borderless"
                    onClick={toggleSelectionInputs}
                    startIcon={isCompareSelectVisible ? <VisibilityOff /> : <Visibility />}
                  >
                    {isCompareSelectVisible ? (
                      <FormattedMessage description="Hide Selection button" defaultMessage="Hide Selection" />
                    ) : (
                      <FormattedMessage description="Show Selection button" defaultMessage="Show Selection" />
                    )}
                  </Button>
                </Box>
              )}
              <hr className={classes.horizontalLine} />
              {isSearchDetailsVisible && (
                <SearchDetails
                  onAnalyzeClick={onAnalyzeClick}
                  selectionData={{
                    start: clickedIntersectionsArr.length > 0 && clickedIntersectionsArr[0].name.split('-')[1],
                    end: clickedIntersectionsArr.length > 1 && clickedIntersectionsArr[1].name.split('-')[1],
                    corridor: corridors.corridorsNames[corridors.selectedCorridorIndex],
                  }}
                  topPosition={setResultsTopPosition}
                >
                  <SidebarPanels
                    statisticsDataFirst={commonStatisticsFirst}
                    statisticsDataSecond={commonStatisticsSecond}
                  />
                </SearchDetails>
              )}
            </div>
          </>
        )}

        {!showMapOnly && (
          <>
            {process.env.REACT_APP_MAP_CORRIDOR_VIEW === 'openlayers' && <GeneralMetricsContainer />}
            {process.env.REACT_APP_MAP_CORRIDOR_VIEW === 'mapbox' && <GeneralMetricsContainerMapbox />}
            <TotalRidesContainer rides={rides} />
            <SignalPlansContainer />
            <SimulationContainer />
          </>
        )}
      </div>
    </>
  );
};
