const DigitalTwin = (theme) => ({
  optimizeContainerTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 20,
  },
  digitalTwinHeaderIcon: {
    marginRight: 9,
  },
  optimizeTitle: {
    fontSize: 14,
    fontWeight: 500,
    margin: 0,
    marginRight: 'auto',
  },
  simulationLinearProgress: {
    marginLeft: '-10px',
    marginRight: '-8px',
    marginTop: 12,
  },
  optimizeContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 0',
    paddingBottom: 0,
    minHeight: 260,
  },
  simulationContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    flex: 1,
  },
  mb0: {
    marginBottom: 0,
  },
  mt16: {
    marginTop: 16,
  },
  bold: {
    fontWeight: 'bold',
  },
  cancelButton: {
    margin: '20px 0',
    padding: '10px 0',
    width: '100%',
    borderRadius: 5,
    backgroundColor: theme.colorWhite,
    color: theme.color01,
    textTransform: 'none',
  },
  visuallyHidden: {
    visibility: 'hidden',
  },
  optimizationError: {
    color: theme.color12,
    fontSize: 12,
    textAlign: 'center',
    border: `1px solid ${theme.color12}`,
    borderRadius: 5,
    padding: 5,
    marginBottom: 12,
    marginTop: 0,
  },
  formControlLabel: {
    fontSize: 14,
  },
  searchButton: {
    margin: '20px 0',
    padding: '10px 0',
    width: '100%',
    borderRadius: 0,
    backgroundColor: theme.color03,
    color: theme.color01,
    textTransform: 'none',

    '&:hover': {
      backgroundColor: theme.color13,
    },
  },
  collapseContainer: {
    padding: 20,
  },
});

export default DigitalTwin;
