import React from 'react';
import { Add, Remove } from '@material-ui/icons';
import styles from './MapZoomer.module.scss';

export const MapZoomer = () => {
  const zoomIn = () => {
    const el = document.querySelectorAll('.mapboxgl-ctrl-zoom-in, .ol-zoom-in')?.[0];

    if (el) {
      el.click();
    }
  };

  const zoomOut = () => {
    const el = document.querySelectorAll('.mapboxgl-ctrl-zoom-out, .ol-zoom-out')?.[0];

    if (el) {
      el.click();
    }
  };

  return (
    <div className={styles.root}>
      <button type="button" className={styles.control} onClick={zoomIn} data-testid="zoomIn">
        <Add />
      </button>
      <button type="button" className={styles.control} onClick={zoomOut} data-testid="zoomOut">
        <Remove />
      </button>
    </div>
  );
};
