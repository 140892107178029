import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  // MarkSeries,
  LabelSeries,
  Highlight,
  Hint,
  WhiskerSeries,
  // Crosshair,
} from 'react-vis';
import 'react-vis/dist/style.css';
import { makeStyles } from '@material-ui/core/styles';
import './PhaseDiagram.scss';
import { PhaseDiagramStyles, PhaseDiagramInlineStyles as styles } from './PhaseDiagram.styles';

const useStyles = makeStyles(PhaseDiagramStyles);

export const PhaseDiagram = ({ phaseDiagramData, size, hasZoom }) => {
  const classes = useStyles();
  const [lastDrawnArea, setLastDrawnArea] = useState(undefined);
  // const [markSeriesData, setMarkSeriesData] = useState([]);
  const [xAxisPerSecond, setXAxisPerSecond] = useState([]);

  // const [crossData, setCrossData] = useState([{ x: 0, y: 0 }]);
  const [yLabels, setYLabels] = useState([]);
  const [solidMoveData, setSolidMoveData] = useState(undefined);
  const [blinkingMoveData, setBlinkingMoveData] = useState(undefined);
  const [pulseData, setPulseData] = useState(undefined);
  const [detectorsData, setDetectorsData] = useState(undefined);
  const [stagesData, setStagesData] = useState(undefined);

  const [redYellowTime, setRedYellowTime] = useState(undefined);
  const [flashingGreenTime, setFlashingGreenTime] = useState(undefined);
  const [yellowTime, setYellowTime] = useState(undefined);
  const [cycleLength, setCycleLength] = useState(undefined);
  const [scenarioName, setScenarioName] = useState(undefined);
  const [cycleProgram, setCycleProgram] = useState(undefined);
  const [diagramHeight, setDiagramHeight] = useState(1200);

  // const onMouseLeave = () => {
  //   setCrossData([{ x: 0, y: 0 }]);
  // };

  const XAxisTitlesTickFormat = () => `
    ${scenarioName !== '' ? `Scenario Name: ${scenarioName}, ` : ''}Program: ${cycleProgram}, Length: ${cycleLength} s
  `;

  const XAxisTickFormat = (value) => {
    if (value % 1 === 0) {
      return value;
    }

    return '';
  };

  // const MarkSeriesOnNearestX = (dataPoint) => {
  //   setCrossData([dataPoint]);
  // };

  const getNull = (d) => d.x !== null;

  const onHighlightBrushEnd = (area) => setLastDrawnArea(area);

  useEffect(() => {
    if (phaseDiagramData) {
      const moveSignalInfoList = phaseDiagramData['junction-data-list'].junction;

      let allMoves;

      if (!Array.isArray(moveSignalInfoList)) {
        allMoves = phaseDiagramData['junction-data-list'].junction['move-signal-info-list'].move;
      } else if (Array.isArray(moveSignalInfoList)) {
        allMoves = phaseDiagramData['junction-data-list'].junction.map((j) => j['move-signal-info-list'].move).flat(1);
      }
      let groups = phaseDiagramData['scenarios-list'].scenario['block-groups-list']['block-group'];

      let MOVES_DATA_SOLID;

      let MOVES_DATA_BLINKING;

      let PULSES_DATA;

      let DETECTORS_DATA;

      let STAGES_DATA;

      let RED_YELLOW_TIME;

      let FLASHING_GREEN_TIME;

      let YELLOW_TIME_DATA;

      if (!groups) {
        return;
      }

      groups = [...groups].reverse().map((group) => ({
        blockGroups: group,
        movesData: allMoves.find((move) => move.name === group.name),
      }));

      allMoves = [...allMoves].reverse().map((item) => ({
        movesData: item,
        blockGroups: groups.find((groupItem) => groupItem.name === item.name),
      }));

      const scenarioDuration = phaseDiagramData['scenarios-list'].scenario.duration;
      const scenarioNameData = phaseDiagramData['scenarios-list'].scenario.name;
      const scenarioProgram = phaseDiagramData['scenarios-list'].scenario.program;
      const pulses = groups.filter((group) => group.blockGroups.type === 'Pulse');
      const moves = groups
        .filter((group) => group.movesData !== undefined)
        .filter((group) => group.blockGroups.type === 'Move');
      const stages = groups.filter((group) => group.blockGroups.type === 'Stages');
      const detectors = groups.filter((group) => group.blockGroups.type === 'Detector');
      const movesSolid = moves.filter((move) => move.movesData['painter-id'] === 'move.solid');
      const movesBlinking = moves.filter((move) => move.movesData['painter-id'] === 'move.blinking');
      const all = [...stages, ...detectors, ...pulses, ...movesBlinking, ...movesSolid];

      const labels = all.map((item, i) => ({
        x: 0,
        y: i + 1,
        label: `${item.blockGroups.name.toString()}`,
        yOffset: 10,
        xOffset: 40,
        style: styles.labels,
      }));

      labels.push({
        x: 0,
        y: labels[labels.length - 1].y + 2,
      });

      labels.push({
        x: 0,
        y: -0.5,
      });

      MOVES_DATA_SOLID = movesSolid.map((item) => {
        if (Array.isArray(item.blockGroups.block)) {
          return [...item.blockGroups?.block];
        }

        return [item.blockGroups?.block];
      });

      MOVES_DATA_BLINKING = movesBlinking.map((item) => {
        if (Array.isArray(item.blockGroups.block)) {
          return [...item.blockGroups?.block];
        }

        return [item.blockGroups?.block];
      });

      PULSES_DATA = pulses.map((item) => {
        if (Array.isArray(item.blockGroups.block)) {
          return [...item.blockGroups?.block];
        }

        return [item.blockGroups?.block];
      });

      DETECTORS_DATA = detectors.map((item) => {
        if (Array.isArray(item.blockGroups.block)) {
          return [...item.blockGroups?.block];
        }

        return [item.blockGroups?.block];
      });

      STAGES_DATA = stages.map((item) => {
        if (Array.isArray(item.blockGroups.block)) {
          return [...item.blockGroups?.block];
        }

        return [item.blockGroups?.block];
      });

      STAGES_DATA = STAGES_DATA.map((time, index) =>
        time
          .map((item) => {
            if (item.type === 'InterStage' || item.duration === 0) {
              return undefined;
            }

            return [
              {
                x: item.start,
                y: index + 1,
                start: item.start,
                duration: item.duration,
                name: item.name,
              },
              {
                x: item.start + item.duration,
                y: index + 1,
              },
            ];
          })
          .filter((item) => item !== undefined),
      );

      DETECTORS_DATA = DETECTORS_DATA.map((time, index) =>
        time.map((item) => [
          {
            x: item.start,
            y: index + STAGES_DATA.length + 1,
            value: item.duration,
          },
          {
            x: item.start + item.duration,
            y: index + STAGES_DATA.length + 1,
          },
        ]),
      );

      PULSES_DATA = PULSES_DATA.map((time, index) =>
        time.map((item) => [
          {
            x: item.start,
            y: index + STAGES_DATA.length + DETECTORS_DATA.length + 1,
            value: item.duration,
          },
          {
            x: item.start + item.duration,
            y: index + STAGES_DATA.length + DETECTORS_DATA.length + 1,
          },
        ]),
      );

      MOVES_DATA_BLINKING = MOVES_DATA_BLINKING.map((time, index) =>
        time.map((item) => {
          if (item.start + item.duration === scenarioDuration) {
            return [
              {
                x: item.start,
                y: index + STAGES_DATA.length + DETECTORS_DATA.length + PULSES_DATA.length + 1,
                value: item.duration,
              },
              {
                x: item.start + item.duration,
                y: index + STAGES_DATA.length + DETECTORS_DATA.length + PULSES_DATA.length + 1,
              },
            ];
          }

          return [
            {
              x: item.start,
              y: index + STAGES_DATA.length + DETECTORS_DATA.length + PULSES_DATA.length + 1,
              value: item.duration,
            },
            {
              x: item.start + item.duration - movesSolid[index].movesData['flashing-green-time'],
              y: index + STAGES_DATA.length + DETECTORS_DATA.length + PULSES_DATA.length + 1,
            },
          ];
        }),
      );

      MOVES_DATA_SOLID = MOVES_DATA_SOLID.map((time, index) =>
        time.map((item) => {
          if (item.start + item.duration === scenarioDuration) {
            return [
              {
                x: item.start,
                y:
                  index +
                  STAGES_DATA.length +
                  DETECTORS_DATA.length +
                  PULSES_DATA.length +
                  MOVES_DATA_BLINKING.length +
                  1,
                value: item.duration,
              },
              {
                x: item.start + item.duration,
                y:
                  index +
                  STAGES_DATA.length +
                  DETECTORS_DATA.length +
                  PULSES_DATA.length +
                  MOVES_DATA_BLINKING.length +
                  1,
              },
            ];
          }

          return [
            {
              x: item.start,
              y:
                index +
                STAGES_DATA.length +
                DETECTORS_DATA.length +
                PULSES_DATA.length +
                MOVES_DATA_BLINKING.length +
                1,
              value: item.duration,
            },
            {
              x: item.start + item.duration - (movesSolid[index]?.movesData['flashing-green-time'] || 0),
              y:
                index +
                STAGES_DATA.length +
                DETECTORS_DATA.length +
                PULSES_DATA.length +
                MOVES_DATA_BLINKING.length +
                1,
            },
          ];
        }),
      );

      RED_YELLOW_TIME = MOVES_DATA_SOLID.map((time, index) =>
        time.map((item) => {
          let hasRedYellow = false;

          if (movesSolid[index]) {
            hasRedYellow =
              item[0].x - movesSolid[index].movesData['red-yellow-time'] >
              movesSolid[index].movesData['red-yellow-time'];
          }

          if (!hasRedYellow) {
            return undefined;
          }

          return [
            {
              x: item[0].x - movesSolid[index].movesData['red-yellow-time'],
              y:
                index +
                STAGES_DATA.length +
                DETECTORS_DATA.length +
                PULSES_DATA.length +
                MOVES_DATA_BLINKING.length +
                1,
              value: movesSolid[index].movesData['red-yellow-time'],
            },
            {
              ...item[0],
              value: null,
            },
          ];
        }),
      );

      FLASHING_GREEN_TIME = MOVES_DATA_SOLID.map((time, index) =>
        time.map((item) => {
          if (item[1].x === scenarioDuration) {
            return undefined;
          }

          return [
            {
              ...item[1],
              value: null,
            },
            {
              x: item[1].x + movesSolid[index].movesData['flashing-green-time'],
              y:
                index +
                STAGES_DATA.length +
                DETECTORS_DATA.length +
                PULSES_DATA.length +
                MOVES_DATA_BLINKING.length +
                1,
              value: movesSolid[index].movesData['flashing-green-time'],
            },
          ];
        }),
      );

      YELLOW_TIME_DATA = MOVES_DATA_SOLID.map((time, index) =>
        time.map((item) => {
          if (item[1].x === scenarioDuration) {
            return undefined;
          }

          return [
            {
              x: item[1].x + movesSolid[index].movesData['flashing-green-time'],
              y:
                index +
                STAGES_DATA.length +
                DETECTORS_DATA.length +
                PULSES_DATA.length +
                MOVES_DATA_BLINKING.length +
                1,
              value: movesSolid[index].movesData['yellow-time'],
            },
            {
              x:
                item[1].x +
                movesSolid[index].movesData['flashing-green-time'] +
                movesSolid[index].movesData['yellow-time'],
              y:
                index +
                STAGES_DATA.length +
                DETECTORS_DATA.length +
                PULSES_DATA.length +
                MOVES_DATA_BLINKING.length +
                1,
            },
          ];
        }),
      );

      MOVES_DATA_SOLID = MOVES_DATA_SOLID.flat(2);
      MOVES_DATA_BLINKING = MOVES_DATA_BLINKING.flat(2);
      RED_YELLOW_TIME = RED_YELLOW_TIME.flat(2).filter((item) => item !== undefined);
      FLASHING_GREEN_TIME = FLASHING_GREEN_TIME.flat(2).filter((item) => item !== undefined);
      YELLOW_TIME_DATA = YELLOW_TIME_DATA.flat(2).filter((item) => item !== undefined);
      PULSES_DATA = PULSES_DATA.flat(2);
      DETECTORS_DATA = DETECTORS_DATA.flat(2);
      STAGES_DATA = STAGES_DATA.flat(2);

      MOVES_DATA_SOLID = MOVES_DATA_SOLID.concat(new Array(MOVES_DATA_SOLID.length).fill({ x: null, y: null }));
      MOVES_DATA_BLINKING = MOVES_DATA_BLINKING.concat(
        new Array(MOVES_DATA_BLINKING.length).fill({ x: null, y: null }),
      );
      RED_YELLOW_TIME = RED_YELLOW_TIME.concat(new Array(RED_YELLOW_TIME.length).fill({ x: null, y: null }));
      FLASHING_GREEN_TIME = FLASHING_GREEN_TIME.concat(
        new Array(FLASHING_GREEN_TIME.length).fill({ x: null, y: null }),
      );
      YELLOW_TIME_DATA = YELLOW_TIME_DATA.concat(new Array(YELLOW_TIME_DATA.length).fill({ x: null, y: null }));
      PULSES_DATA = PULSES_DATA.concat(new Array(PULSES_DATA.length).fill({ x: null, y: null }));
      DETECTORS_DATA = DETECTORS_DATA.concat(new Array(DETECTORS_DATA.length).fill({ x: null, y: null }));
      STAGES_DATA = STAGES_DATA.concat(new Array(STAGES_DATA.length).fill({ x: null, y: null }));

      MOVES_DATA_SOLID.forEach((item, index, array) => {
        if ('value' in item) {
          array.splice(index + 2, 0, { x: null, y: item?.y });
        }
      });
      MOVES_DATA_BLINKING.forEach((item, index, array) => {
        if ('value' in item) {
          array.splice(index + 2, 0, { x: null, y: item?.y });
        }
      });
      RED_YELLOW_TIME.forEach((item, index, array) => {
        if ('value' in item && item.value !== null) {
          array.splice(index + 2, 0, { x: null, y: item?.y });
        }
      });
      FLASHING_GREEN_TIME.forEach((item, index, array) => {
        if ('value' in item && item.value !== null) {
          array.splice(index + 1, 0, { x: null, y: item?.y });
        }
      });
      YELLOW_TIME_DATA.forEach((item, index, array) => {
        if ('value' in item && item.value !== null) {
          array.splice(index + 2, 0, { x: null, y: item?.y });
        }
      });
      PULSES_DATA.forEach((item, index, array) => {
        if ('value' in item && item.value !== null) {
          array.splice(index + 2, 0, { x: null, y: item?.y });
        }
      });
      DETECTORS_DATA.forEach((item, index, array) => {
        if ('value' in item && item.value !== null) {
          array.splice(index + 2, 0, { x: null, y: item?.y });
        }
      });
      STAGES_DATA.forEach((item, index, array) => {
        if ('name' in item && item.value !== null) {
          array.splice(index + 2, 0, { x: null, y: item?.y });
        }
      });

      MOVES_DATA_SOLID = MOVES_DATA_SOLID.filter((inner) => inner.y);
      MOVES_DATA_BLINKING = MOVES_DATA_BLINKING.filter((inner) => inner.y);
      RED_YELLOW_TIME = RED_YELLOW_TIME.filter((inner) => inner.y);
      FLASHING_GREEN_TIME = FLASHING_GREEN_TIME.filter((inner) => inner.y);
      YELLOW_TIME_DATA = YELLOW_TIME_DATA.filter((inner) => inner.y);
      PULSES_DATA = PULSES_DATA.filter((inner) => inner.y);
      DETECTORS_DATA = DETECTORS_DATA.filter((inner) => inner.y);
      STAGES_DATA = STAGES_DATA.filter((inner) => inner.y);

      setSolidMoveData(MOVES_DATA_SOLID);
      setBlinkingMoveData(MOVES_DATA_BLINKING);
      setPulseData(PULSES_DATA);
      setDetectorsData(DETECTORS_DATA);
      setStagesData(STAGES_DATA);
      setRedYellowTime(RED_YELLOW_TIME);
      setFlashingGreenTime(FLASHING_GREEN_TIME);
      setYellowTime(YELLOW_TIME_DATA);
      setYLabels(labels);
      setCycleLength(scenarioDuration);
      setScenarioName(scenarioNameData);
      setCycleProgram(scenarioProgram);
      setDiagramHeight(labels.length * 40);
    }
  }, [phaseDiagramData]);

  useEffect(() => {
    if (phaseDiagramData && yLabels.length !== 0 && cycleLength) {
      // setMarkSeriesData(new Array(cycleLength + 5).fill(' ').map((element, i) => ({ x: i, y: 0 })));
      setXAxisPerSecond(new Array(cycleLength + 5).fill(' ').map((element, i) => i + 1));
    }
  }, [yLabels.length, cycleLength, phaseDiagramData]);

  if (!phaseDiagramData || yLabels.length === 0) {
    return null;
  }

  return (
    <XYPlot
      height={diagramHeight}
      width={size === 'small' ? 800 : 24000}
      xDomain={lastDrawnArea && [lastDrawnArea.left, lastDrawnArea.right]}
      animation
      margin={styles.XYPlotMargin}
      // onMouseLeave={onMouseLeave}
      colorType="category"
    >
      <HorizontalGridLines className={classes.gridLines} />
      <VerticalGridLines className={classes.gridLines} tickValues={xAxisPerSecond} />
      <VerticalGridLines className={classes.borderGridLines} tickValues={[0, cycleLength]} />
      <XAxis
        title="time[s]"
        className={classes.xAxisTitles}
        orientation="top"
        tickValues={[25]}
        tickPadding={25}
        tickFormat={XAxisTitlesTickFormat}
      />
      <XAxis title="time[s]" className={classes.xAxis} orientation="top" tickTotal={20} tickFormat={XAxisTickFormat} />
      <XAxis
        title="time[s]"
        className={classes.xAxis}
        orientation="bottom"
        tickTotal={20}
        tickFormat={XAxisTickFormat}
      />
      <YAxis title="Moves" tickTotal={0} />

      {/* <MarkSeries
        data={markSeriesData}
        opacity={0}
        onNearestX={MarkSeriesOnNearestX}
        className={classes.xAxis}
      /> */}

      <LabelSeries animation allowOffsetToBeReversed data={yLabels} />

      <LineSeries
        className="solid-moves-series"
        getNull={getNull}
        data={solidMoveData}
        style={styles.LineSeries_SolidMoves}
        color={styles.LineSeries_SolidMoves_color}
      />

      {solidMoveData
        .filter((data) => data.value)
        .map((data, i) => (
          <Hint
            key={i.toString()}
            value={data}
            align={{ vertical: 'top', horizontal: 'right' }}
            className="custom-hint"
          >
            <div style={styles.solidMoveHint_Div}>
              <p style={styles.solidMoveHint_Div_P}>
                <span style={styles.solidMoveHint_Div_P_Span}>{`${data.value} s`}</span>
              </p>
            </div>
          </Hint>
        ))}

      <LineSeries
        className="blinking-moves-series"
        getNull={getNull}
        data={blinkingMoveData}
        style={styles.LineSeries_blinkingMoves}
        color={styles.LineSeries_blinkingMoves_color}
      />

      {blinkingMoveData
        .filter((data) => data.value)
        .map((data, i) => (
          <Hint
            key={i.toString()}
            value={data}
            align={{ vertical: 'top', horizontal: 'right' }}
            className="custom-hint"
          >
            <div style={styles.blinkingMove_Hint_Div}>
              <p style={styles.blinkingMove_Hint_Div_P}>
                <span style={styles.blinkingMove_Hint_Div_P_Span}>{`${data.value} s`}</span>
              </p>
            </div>
          </Hint>
        ))}

      <LineSeries
        className="yellow-series"
        getNull={getNull}
        data={redYellowTime}
        style={styles.LineSeries_Yellow}
        color={styles.LineSeries_Yellow_color}
      />

      {/* {redYellowTime.filter((data) => (data.value)).map((data, i) => {
            if (data.value === 0) {
              return null;
            }

            return (
              <Hint
                key={i.toString()}
                value={data}
                align={{ vertical: 'top', horizontal: 'left' }}
              >
                <div style={{ background: '#fff' }}>
                  <p style={{ color: 'orange', fontSize: 11 }}>
                    <span style={{ color: 'orange', fontSize: 11 }}>
                      {data.value}
                    </span>
                  </p>
                </div>
              </Hint>
            );
          })} */}

      <LineSeries
        className="flashing-green-series"
        getNull={getNull}
        data={flashingGreenTime}
        style={styles.LineSeries_FlashingGreen}
        color={styles.LineSeries_FlashingGreen_color}
      />

      {/* {flashingGreenTime.filter((data) => (data.value)).map((data, i) => {
            if (data.value === 0) {
              return null;
            }

            return (
              <Hint
                key={i.toString()}
                value={data}
                align={{ vertical: 'top', horizontal: 'right' }}
              >
                <div style={{ background: '#fff' }}>
                  <p style={{ color: 'green', fontSize: 11 }}>
                    <span style={{ color: 'green', fontSize: 11 }}>
                      {data.value}
                    </span>
                  </p>
                </div>
              </Hint>
            );
          })} */}

      <LineSeries
        className="yellow-series"
        getNull={getNull}
        data={yellowTime}
        style={styles.LineSeries_Yellow2}
        color={styles.LineSeries_Yellow2_color}
      />

      {/* {yellowTime.filter((data) => (data.value && data.value !== 0)).map((data, i) => (
            <Hint
              key={i.toString()}
              value={data}
              align={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <div
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  borderRadius: 5,
                }}
              >
                <p style={{ color: 'yellow', fontSize: 11 }}>
                  <span style={{ color: 'yellow', fontSize: 11, padding: 5 }}>
                    {data.value}
                  </span>
                </p>
              </div>
            </Hint>
          ))} */}

      <LineSeries
        className="pulse-series"
        getNull={getNull}
        data={pulseData}
        style={styles.LineSeries_Pulse}
        color={styles.LineSeries_Pulse_color}
      />

      {pulseData
        .filter((data) => data.value)
        .map((data, i) => (
          <Hint
            key={i.toString()}
            value={data}
            align={{ vertical: 'top', horizontal: 'right' }}
            className="custom-hint"
          >
            <div style={styles.Pulse_Hint_Div}>
              <p style={styles.Pulse_Hint_Div_P}>
                <span style={styles.Pulse_Hint_Div_P_Span}>{`${data.value} s`}</span>
              </p>
            </div>
          </Hint>
        ))}

      <LineSeries
        className="detectors-series"
        getNull={getNull}
        data={detectorsData}
        style={styles.LineSeries_Detectors}
        color={styles.LineSeries_Detectors_color}
      />

      {detectorsData
        .filter((data) => data.value)
        .map((data, i) => (
          <Hint
            key={i.toString()}
            value={data}
            align={{ vertical: 'top', horizontal: 'right' }}
            className="custom-hint"
          >
            <div style={styles.LineSeries_Detectors_Hint_Div}>
              <p style={styles.LineSeries_Detectors_Hint_Div_P}>
                <span style={styles.LineSeries_Detectors_Hint_Div_P_span}>{`${data.value} s`}</span>
              </p>
            </div>
          </Hint>
        ))}

      <LineSeries
        className="stages-series"
        getNull={getNull}
        data={stagesData}
        style={styles.LineSeries_Stages}
        color={styles.LineSeries_Stages_color}
      />

      <WhiskerSeries
        data={stagesData.map((data) => ({ ...data, xVariance: 0.1, yVariance: 0.5 }))}
        color={styles.WhiskerSeries_color}
      />

      {stagesData
        .filter((data) => data.name && data.duration !== 0)
        .map((data, i) => (
          <Hint
            key={i.toString()}
            value={data}
            align={{ vertical: 'bottom', horizontal: 'right' }}
            className="custom-hint"
          >
            <div style={styles.Stages_Hint_Div}>
              <p style={styles.Stages_Hint_Div_P}>
                <span style={styles.Stages_Hint_Div_P_Span}>{`${data.name}`}</span>
              </p>
            </div>
            <div style={styles.Stages_Hint_Div2}>
              <p style={styles.Stages_Hint_Div2_P}>
                <span style={styles.Stages_Hint_Div2_P_Span}>{`s: ${data.start} s`}</span>
              </p>
              <p style={styles.Stages_Hint_Div2_P2}>
                <span style={styles.Stages_Hint_Div2_P2_Span}>{`d: ${data.duration} s`}</span>
              </p>
            </div>
          </Hint>
        ))}

      {hasZoom && <Highlight enableY={false} onBrushEnd={onHighlightBrushEnd} />}

      {/* {size === 'small' && (
        <Crosshair values={crossData}>
          <p style={{ color: '#000', margin: 0 }}>{crossData[0].x}</p>
        </Crosshair>
      )} */}
    </XYPlot>
  );
};

PhaseDiagram.propTypes = {
  phaseDiagramData: propTypes.object,
  size: propTypes.string,
  hasZoom: propTypes.bool,
};

PhaseDiagram.defaultProps = {
  phaseDiagramData: null,
  size: 'large',
  hasZoom: false,
};
