import { statisticTypes } from 'consts';

export const homeStatsMock = {
  [statisticTypes.PRIVATE]: {
    queueOccupancies: [
      {
        x: 12,
        y: 7.11,
      },
      {
        x: 15,
        y: 4.1,
      },
    ],
    queueOccupanciesFiller: [
      {
        x: 12,
        y: 92.89,
      },
      {
        x: 15,
        y: 95.9,
      },
    ],
    queueTravelRatio: [
      {
        x: 12,
        y: 30.50975559663175,
      },
      {
        x: 15,
        y: 27.31298560101827,
      },
    ],
    queueTravelRatioFiller: [
      {
        x: 12,
        y: 69.49024440336825,
      },
      {
        x: 15,
        y: 72.68701439898173,
      },
    ],
  },
  [statisticTypes.PUBLIC]: {
    queueOccupancies: [
      {
        x: 12,
        y: 4.47,
      },
      {
        x: 15,
        y: 4.3,
      },
    ],
    queueOccupanciesFiller: [
      {
        x: 12,
        y: 95.53,
      },
      {
        x: 15,
        y: 95.7,
      },
    ],
    queueTravelRatio: [
      {
        x: 12,
        y: 27.430437177099996,
      },
      {
        x: 15,
        y: 25.542034891707498,
      },
    ],
    queueTravelRatioFiller: [
      {
        x: 12,
        y: 72.5695628229,
      },
      {
        x: 15,
        y: 74.4579651082925,
      },
    ],
  },
  xDomain: [6, 18],
};
