import { fitMapToFeatures } from './fitMapToFeatures';

export const setMapboxSource = ({ sourceName, mapboxMap, features, shouldFitToFeatures = true }) => {
  if (mapboxMap.getSource(sourceName)) {
    mapboxMap.getSource(sourceName).setData({
      type: 'FeatureCollection',
      features,
    });

    if (shouldFitToFeatures) fitMapToFeatures(mapboxMap, features);
  }
};
