import theme from 'theme';

export const PhaseDiagramStyles = {
  gridLines: {
    stroke: theme.color22,
  },
  borderGridLines: {
    stroke: theme.color12,
    strokeDasharray: '10 3',
    opacity: 1,
  },
  xAxis: {
    line: {
      stroke: theme.color23,
    },
    ticks: {
      stroke: theme.color23,
    },
    text: {
      stroke: 'none',
      fill: theme.color24,
      fontWeight: 600,
    },
  },
  xAxisTitles: {
    line: {
      stroke: theme.color23,
    },
    ticks: {
      stroke: theme.color23,
    },
    text: {
      stroke: 'none',
      fill: theme.color24,
      fontWeight: 600,
      fontSize: 10,
      fontStyle: 'italic',
    },
  },
  markSeriesSeconds: {
    display: 'none',
  },
};

export const PhaseDiagramInlineStyles = {
  labels: {
    fontSize: 11,
  },
  XYPlotMargin: {
    left: 40,
    right: 10,
    top: 40,
    bottom: 30,
  },
  LineSeries_SolidMoves: {
    strokeLinejoin: 'round',
    strokeWidth: 20,
  },
  LineSeries_SolidMoves_color: theme.color41,
  solidMoveHint_Div: {
    position: 'relative',
    backgroundColor: theme.colorWhite,
    borderRadius: 5,
    top: 18,
    left: 5,
  },
  solidMoveHint_Div_P: {
    color: theme.color41,
    fontSize: 11,
    fontWeight: 500,
  },
  solidMoveHint_Div_P_Span: {
    color: theme.color41,
    fontSize: 11,
    padding: 5,
  },
  LineSeries_blinkingMoves: {
    strokeLinejoin: 'round',
    strokeWidth: 20,
    strokeDasharray: [2.5, 2.5],
    opacity: 1,
  },
  LineSeries_blinkingMoves_color: theme.color42,
  blinkingMove_Hint_Div: {
    position: 'relative',
    backgroundColor: theme.colorWhite,
    borderRadius: 5,
    top: 18,
    left: 5,
  },
  blinkingMove_Hint_Div_P: {
    color: theme.colorBlack,
    fontSize: 11,
    fontWeight: 500,
  },
  blinkingMove_Hint_Div_P_Span: {
    color: theme.colorBlack,
    fontSize: 11,
    padding: 5,
  },
  LineSeries_Yellow: {
    strokeLinejoin: 'round',
    strokeWidth: 20,
  },
  LineSeries_Yellow_color: theme.color43,
  LineSeries_FlashingGreen: {
    strokeLinejoin: 'round',
    strokeWidth: 20,
    strokeDasharray: [2.5, 1],
    opacity: 1,
  },
  LineSeries_FlashingGreen_color: theme.color44,
  LineSeries_Yellow2: {
    strokeLinejoin: 'round',
    strokeWidth: 20,
  },
  LineSeries_Yellow2_color: theme.color43,
  LineSeries_Pulse: {
    strokeLinejoin: 'round',
    strokeWidth: 20,
    strokeDasharray: [2.5, 2.5],
    opacity: 1,
  },
  LineSeries_Pulse_color: theme.color42,
  Pulse_Hint_Div: {
    position: 'relative',
    backgroundColor: theme.colorWhite,
    borderRadius: 5,
    top: 18,
    left: 5,
  },
  Pulse_Hint_Div_P: {
    color: theme.colorBlack,
    fontSize: 11,
    fontWeight: 500,
  },
  Pulse_Hint_Div_P_Span: {
    color: theme.colorBlack,
    fontSize: 11,
    padding: 5,
  },
  LineSeries_Detectors: {
    strokeLinejoin: 'round',
    strokeWidth: 20,
  },
  LineSeries_Detectors_color: theme.color45,
  LineSeries_Detectors_Hint_Div: {
    position: 'relative',
    backgroundColor: theme.colorWhite,
    borderRadius: 5,
    top: 18,
    left: 5,
  },
  LineSeries_Detectors_Hint_Div_P: {
    color: theme.color45,
    fontSize: 11,
    fontWeight: 500,
  },
  LineSeries_Detectors_Hint_Div_P_span: {
    color: theme.color45,
    fontSize: 11,
    padding: 5,
  },
  LineSeries_Stages: {
    strokeLinejoin: 'round',
    strokeWidth: 20,
  },
  LineSeries_Stages_color: theme.color41,
  WhiskerSeries_color: theme.colorWhite,
  Stages_Hint_Div: {
    position: 'relative',
    backgroundColor: theme.colorWhite,
    borderRadius: 5,
    top: -17,
    left: 5,
  },
  Stages_Hint_Div_P: {
    color: theme.color41,
    fontSize: 10,
    fontWeight: 500,
    textAlign: 'center',
  },
  Stages_Hint_Div_P_Span: {
    padding: 5,
  },
  Stages_Hint_Div2: {
    position: 'relative',
    backgroundColor: theme.colorWhite,
    borderRadius: 5,
    top: -20,
    left: 5,
  },
  Stages_Hint_Div2_P: {
    color: theme.color41,
    fontSize: 10,
    fontWeight: 500,
    margin: 0,
  },
  Stages_Hint_Div2_P_Span: {
    padding: 5,
  },
  Stages_Hint_Div2_P2: {
    color: theme.color41,
    fontSize: 10,
    fontWeight: 500,
    margin: 0,
  },
  Stages_Hint_Div2_P2_Span: {
    padding: 5,
  },
};
