const requireModule = require.context('.', false, /\.json$/);
const languages = {};

requireModule.keys().forEach((fileName) => {
  const languageName = fileName.replace(/(\.\/|\.json)/g, '');

  languages[languageName] = requireModule(fileName);
});

export default languages;
