/* eslint-disable max-len */
export const corridorsStatsMock = {
  frames: {},
  loadingFrames: 'idle',
  rides: {
    numberOfStops: [
      [
        {
          graphs: {},
          videoCameraId: '100000005188daed',
          videoStartTime: '2020 - 09 - 02 05: 23: 02.000',
          videoEndTime: '2020 - 09 - 02 05: 23: 45.000',
          videoPlayTime: '2020 - 09 - 02 05: 23: 11.000',
          name: 'Namir - Glilot South',
          id: 153,
          feature: {
            type: 'Feature',
            geometry: { type: 'Point', coordinates: [34.801303, 32.141068] },
            properties: { name: 'Namir - Glilot South', id: 153, signalized: true, corridor: true },
            id: '153',
          },
          probabilityToStop: 0,
          critical: false,
        },
      ],
      [
        {
          graphs: {},
          videoCameraId: '100000005188daed',
          videoStartTime: '2020 - 09 - 02 05: 23: 45.000',
          videoEndTime: '2020 - 09 - 02 05: 36: 48.000',
          videoPlayTime: '2020 - 09 - 02 05: 24: 53.000',
          name: 'Namir - Tsvi Propes',
          id: 180,
          feature: {
            type: 'Feature',
            geometry: { type: 'Point', coordinates: [34.794455, 32.122756] },
            properties: { name: 'Namir - Tsvi Propes', id: 180, signalized: true, corridor: true },
            id: '180',
          },
          probabilityToStop: 0.84,
          critical: true,
        },
      ],
    ],
  },
  loadingRides: 'loaded',
  generalMetricsFrames: {},
  loadingGeneralMetricsFrames: 'idle',
  corridorStats: {},
  loadingCorridorStats: 'idle',
  totalRides: {
    stringifiedSearchQuery1: {
      rides: [
        {
          rideId: 6542,
          cameraId: 'MS1W192000200',
          startRideDate: '2020-02-18 06:11:12.000',
          endRideDate: '2020-02-18 06:29:57.000',
          vehicle: '605 (Netanya)',
          startIntersectionName: 'Namir-Glilot-North',
          endIntersectionName: 'Namir-Begin-ShaulHameleh',
          startIntersectionId: 1153,
          endIntersectionId: 136,
          numberOfIntersections: 12,
        },
        {
          rideId: 6547,
          cameraId: 'MS1W192000200',
          startRideDate: '2020-02-19 06:08:47.000',
          endRideDate: '2020-02-19 06:29:49.000',
          vehicle: '605 (Netanya)',
          startIntersectionName: 'Namir-Glilot-North',
          endIntersectionName: 'Namir-Begin-ShaulHameleh',
          startIntersectionId: 1153,
          endIntersectionId: 136,
          numberOfIntersections: 12,
        },
      ],
      segments: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [
                [34.801128, 32.140935],
                [34.801101, 32.140878],
                [34.801036, 32.14074],
                [34.800716, 32.140063],
                [34.800071, 32.139193],
                [34.799588, 32.138551],
                [34.799082, 32.137875],
                [34.798584, 32.137286],
                [34.79844, 32.13705],
                [34.798326, 32.136865],
                [34.797616, 32.135683],
                [34.797616, 32.135683],
                [34.797136, 32.134813],
                [34.79684, 32.134275],
                [34.7966, 32.133708],
                [34.796413, 32.133072],
                [34.796342, 32.132829],
                [34.796168, 32.132002],
                [34.795975, 32.131104],
                [34.795453, 32.128604],
                [34.794939, 32.126096],
                [34.794703, 32.124739],
                [34.794601, 32.12413],
                [34.794483, 32.123428],
                [34.794483, 32.123428],
                [34.794373, 32.12284],
                [34.794347, 32.122694],
              ],
            },
            properties: {
              corridorId: 1,
              corridorName: 'Namir',
              order: 2,
              direction: 'South',
              directionId: 2,
              startIntersection: {
                type: 'Point',
                coordinates: [34.801233, 32.140884],
              },
              endIntersection: {
                type: 'Point',
                coordinates: [34.794452, 32.122754],
              },
              startIntersectionId: 153,
              endIntersectionId: 180,
              length: 2700,
            },
            id: '10',
          },
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [
                [34.794347, 32.122694],
                [34.794306, 32.122475],
                [34.792763, 32.114298],
                [34.792474, 32.112863],
                [34.792441, 32.112697],
                [34.792408, 32.112534],
              ],
            },
            properties: {
              corridorId: 1,
              corridorName: 'Namir',
              order: 3,
              direction: 'South',
              directionId: 2,
              startIntersection: {
                type: 'Point',
                coordinates: [34.794452, 32.122754],
              },
              endIntersection: {
                type: 'Point',
                coordinates: [34.792516, 32.112631],
              },
              startIntersectionId: 180,
              endIntersectionId: 181,
              length: 1100,
            },
            id: '11',
          },
        ],
      },
    },
    stringifiedSearchQuery2: {
      rides: [
        {
          rideId: 6542,
          cameraId: 'MS1W192000200',
          startRideDate: '2020-02-18 06:11:12.000',
          endRideDate: '2020-02-18 06:29:57.000',
          vehicle: '605 (Netanya)',
          startIntersectionName: 'Namir-Glilot-North',
          endIntersectionName: 'Namir-Begin-ShaulHameleh',
          startIntersectionId: 1153,
          endIntersectionId: 136,
          numberOfIntersections: 12,
        },
        {
          rideId: 6547,
          cameraId: 'MS1W192000200',
          startRideDate: '2020-02-19 06:08:47.000',
          endRideDate: '2020-02-19 06:29:49.000',
          vehicle: '605 (Netanya)',
          startIntersectionName: 'Namir-Glilot-North',
          endIntersectionName: 'Namir-Begin-ShaulHameleh',
          startIntersectionId: 1153,
          endIntersectionId: 136,
          numberOfIntersections: 12,
        },
      ],
      segments: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [
                [34.801128, 32.140935],
                [34.801101, 32.140878],
                [34.801036, 32.14074],
                [34.800716, 32.140063],
                [34.800071, 32.139193],
                [34.799588, 32.138551],
                [34.799082, 32.137875],
                [34.798584, 32.137286],
                [34.79844, 32.13705],
                [34.798326, 32.136865],
                [34.797616, 32.135683],
                [34.797616, 32.135683],
                [34.797136, 32.134813],
                [34.79684, 32.134275],
                [34.7966, 32.133708],
                [34.796413, 32.133072],
                [34.796342, 32.132829],
                [34.796168, 32.132002],
                [34.795975, 32.131104],
                [34.795453, 32.128604],
                [34.794939, 32.126096],
                [34.794703, 32.124739],
                [34.794601, 32.12413],
                [34.794483, 32.123428],
                [34.794483, 32.123428],
                [34.794373, 32.12284],
                [34.794347, 32.122694],
              ],
            },
            properties: {
              corridorId: 1,
              corridorName: 'Namir',
              order: 2,
              direction: 'South',
              directionId: 2,
              startIntersection: {
                type: 'Point',
                coordinates: [34.801233, 32.140884],
              },
              endIntersection: {
                type: 'Point',
                coordinates: [34.794452, 32.122754],
              },
              startIntersectionId: 153,
              endIntersectionId: 180,
              length: 2700,
            },
            id: '10',
          },
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [
                [34.794347, 32.122694],
                [34.794306, 32.122475],
                [34.792763, 32.114298],
                [34.792474, 32.112863],
                [34.792441, 32.112697],
                [34.792408, 32.112534],
              ],
            },
            properties: {
              corridorId: 1,
              corridorName: 'Namir',
              order: 3,
              direction: 'South',
              directionId: 2,
              startIntersection: {
                type: 'Point',
                coordinates: [34.794452, 32.122754],
              },
              endIntersection: {
                type: 'Point',
                coordinates: [34.792516, 32.112631],
              },
              startIntersectionId: 180,
              endIntersectionId: 181,
              length: 1100,
            },
            id: '11',
          },
        ],
      },
    },
  },
};
