import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { handleAddGeofenceArea } from 'features';
import { TextField } from '@material-ui/core';
import { Dialog } from 'screens/EditGrid/Dialog';

export const AddGeofenceAreaDialog = ({ isOpen, close, submit, coords }) => {
  const dispatch = useDispatch();

  const submitForm = (event) => {
    const submittedCoords = JSON.parse(event.target[0]?.value);
    const requestBody = {
      geometry: submittedCoords,
    };

    dispatch(handleAddGeofenceArea(requestBody));
    submit();
  };

  return (
    <Dialog title="Add Geofence Area Details" isOpen={isOpen} onClose={close} onSubmit={submitForm}>
      <TextField label="Coordinates" multiline rowsMax={20} defaultValue={JSON.stringify(coords)} />
    </Dialog>
  );
};

AddGeofenceAreaDialog.propTypes = {
  isOpen: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  submit: propTypes.func.isRequired,
  coords: propTypes.array,
};

AddGeofenceAreaDialog.defaultProps = {
  coords: [],
};
