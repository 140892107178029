export const airQualityMockedResponse = {
  data: {
    airQuality: [
      {
        name: 'Dizengoff-Nordau Avenue',
        id: 13,
        feature: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [34.792523, 32.112641],
          },
          properties: {
            name: 'Dizengoff-Nordau Avenue',
            id: 13,
            signalized: true,
          },
          id: '13',
        },
        videos: {
          videos: [
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-19 05:57:25.000',
              videoEndTime: '2020-11-19 05:57:37.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-20 07:44:52.000',
              videoEndTime: '2020-11-20 07:45:12.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '1cf3970d',
              videoStartTime: '2020-11-23 07:43:31.000',
              videoEndTime: '2020-11-23 07:43:40.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-29 06:51:27.000',
              videoEndTime: '2020-11-29 06:52:35.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-29 07:30:41.000',
              videoEndTime: '2020-11-29 07:30:54.000',
              videoPlayTime: null,
            },
          ],
          objectId: 13,
        },
        numberOfSamples: 17,
        directionOrder: 0,
        colorGroupId: 4,
        density: 5,
        critical: false,
        pm25: 23,
        pm10: 41,
      },
      {
        name: 'Dizengoff-Jabotinsky',
        id: 14,
        feature: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [34.793363, 32.100079],
          },
          properties: {
            name: 'Dizengoff-Jabotinsky',
            id: 14,
            signalized: true,
          },
          id: '14',
        },
        videos: {
          videos: [
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-17 05:54:14.000',
              videoEndTime: '2020-11-17 05:54:22.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-17 06:50:46.000',
              videoEndTime: '2020-11-17 06:50:56.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-12-01 06:18:04.000',
              videoEndTime: '2020-12-01 06:18:13.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-12-01 07:22:10.000',
              videoEndTime: '2020-12-01 07:22:26.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-16 05:55:48.000',
              videoEndTime: '2020-11-16 05:55:56.000',
              videoPlayTime: null,
            },
          ],
          objectId: 14,
        },
        numberOfSamples: 13,
        directionOrder: 0,
        colorGroupId: 4,
        density: 5,
        critical: false,
        pm25: 24,
        pm10: 41,
      },
      {
        name: 'Dizengoff-Arlosorov',
        id: 15,
        feature: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [34.795249, 32.091254],
          },
          properties: {
            name: 'Dizengoff-Arlosorov',
            id: 15,
            signalized: true,
          },
          id: '15',
        },
        videos: {
          videos: [
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-16 05:56:25.000',
              videoEndTime: '2020-11-16 05:56:34.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-16 06:52:57.000',
              videoEndTime: '2020-11-16 06:53:14.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-19 07:09:09.000',
              videoEndTime: '2020-11-19 07:09:22.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-16 07:41:37.000',
              videoEndTime: '2020-11-16 07:41:45.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-17 06:50:09.000',
              videoEndTime: '2020-11-17 06:50:19.000',
              videoPlayTime: null,
            },
          ],
          objectId: 15,
        },
        numberOfSamples: 12,
        directionOrder: 0,
        colorGroupId: 3,
        density: 4,
        critical: false,
        pm25: 22,
        pm10: 42,
      },
      {
        name: 'Dizengoff-David Ben Gurion Avenue',
        id: 16,
        feature: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [34.794595, 32.082636],
          },
          properties: {
            name: 'Dizengoff-David Ben Gurion Avenue',
            id: 16,
            signalized: true,
          },
          id: '16',
        },
        videos: {
          videos: [
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-16 06:51:24.000',
              videoEndTime: '2020-11-16 06:51:54.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-29 07:33:10.000',
              videoEndTime: '2020-11-29 07:33:47.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-16 05:56:56.000',
              videoEndTime: '2020-11-16 05:57:04.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-17 06:48:55.000',
              videoEndTime: '2020-11-17 06:49:04.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-19 05:59:23.000',
              videoEndTime: '2020-11-19 05:59:32.000',
              videoPlayTime: null,
            },
          ],
          objectId: 16,
        },
        numberOfSamples: 12,
        directionOrder: 0,
        colorGroupId: 2,
        density: 7,
        critical: false,
        pm25: 20,
        pm10: 47,
      },
      {
        name: 'Dizengoff-Gordon',
        id: 17,
        feature: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [34.779349, 32.072073],
          },
          properties: {
            name: 'Dizengoff-Gordon',
            id: 17,
            signalized: true,
          },
          id: '17',
        },
        videos: {
          videos: [
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-16 05:57:28.000',
              videoEndTime: '2020-11-16 05:57:36.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-19 07:06:57.000',
              videoEndTime: '2020-11-19 07:07:11.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-29 07:34:29.000',
              videoEndTime: '2020-11-29 07:34:39.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-17 05:56:07.000',
              videoEndTime: '2020-11-17 05:56:15.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-18 06:48:12.000',
              videoEndTime: '2020-11-18 06:48:21.000',
              videoPlayTime: null,
            },
          ],
          objectId: 17,
        },
        numberOfSamples: 11,
        directionOrder: 0,
        colorGroupId: 2,
        density: 5,
        critical: false,
        pm25: 20,
        pm10: 59,
      },
      {
        name: 'Dizengoff-Frishman',
        id: 18,
        feature: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [34.770495, 32.069891],
          },
          properties: {
            name: 'Dizengoff-Frishman',
            id: 18,
            signalized: true,
          },
          id: '18',
        },
        videos: {
          videos: [
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-17 05:56:30.000',
              videoEndTime: '2020-11-17 05:56:42.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-16 05:57:49.000',
              videoEndTime: '2020-11-16 05:57:58.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-16 06:49:48.000',
              videoEndTime: '2020-11-16 06:50:08.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-18 06:47:32.000',
              videoEndTime: '2020-11-18 06:47:41.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '164506e5',
              videoStartTime: '2020-11-19 06:00:22.000',
              videoEndTime: '2020-11-19 06:00:31.000',
              videoPlayTime: null,
            },
          ],
          objectId: 18,
        },
        numberOfSamples: 12,
        directionOrder: 0,
        colorGroupId: 2,
        density: 6,
        critical: false,
        pm25: 20,
        pm10: 59,
      },
      {
        name: 'Dizengoff-Hatarocha',
        id: 70,
        feature: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [34.768492, 32.0776],
          },
          properties: {
            name: 'Dizengoff-Hatarocha',
            id: 70,
            signalized: true,
          },
          id: '70',
        },
        videos: {
          videos: [
            {
              videoCameraId: '1000000033663d12',
              videoStartTime: '2020-11-18 06:39:03.000',
              videoEndTime: '2020-11-18 06:39:14.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '1000000033663d12',
              videoStartTime: '2020-11-17 07:40:29.000',
              videoEndTime: '2020-11-17 07:40:38.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '1000000033663d12',
              videoStartTime: '2020-11-18 07:21:55.000',
              videoEndTime: '2020-11-18 07:22:03.000',
              videoPlayTime: null,
            },
            {
              videoCameraId: '1000000099b5a26c',
              videoStartTime: '2020-11-28 06:37:16.000',
              videoEndTime: '2020-11-28 06:37:24.000',
              videoPlayTime: null,
            },
          ],
          objectId: 70,
        },
        numberOfSamples: 4,
        directionOrder: 0,
        colorGroupId: 2,
        density: 2,
        critical: false,
        pm25: 20,
        pm10: 53,
      },
    ],
  },
};
