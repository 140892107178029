import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { handleAddDetectionArea } from 'features';
import { TextField } from '@material-ui/core';
import { Dialog } from 'screens/EditGrid/Dialog';

export const AddDetectionAreaDialog = ({ isOpen, close, submit, coords, intersectionId }) => {
  const dispatch = useDispatch();

  const submitForm = (event) => {
    const submittedCoords = JSON.parse(event.target.coords.value);
    const requestBody = {
      intersectionId,
      detectionArea: submittedCoords,
    };

    dispatch(handleAddDetectionArea(requestBody));
    submit();
  };

  return (
    <Dialog title="Add Detection Area Details" isOpen={isOpen} onClose={close} onSubmit={submitForm}>
      <TextField label="Coordinates" multiline rowsMax={20} defaultValue={JSON.stringify(coords)} name="coords" />
    </Dialog>
  );
};

AddDetectionAreaDialog.propTypes = {
  isOpen: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  submit: propTypes.func.isRequired,
  coords: propTypes.array,
  intersectionId: propTypes.string,
};

AddDetectionAreaDialog.defaultProps = {
  coords: [],
  intersectionId: '',
};
