import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { handleAddSegment, selectDirections } from 'features';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Dialog } from 'screens/EditGrid/Dialog';

export const AddSegmentDialog = ({ isOpen, close, edges, speed, startSegmentId, endSegmentId }) => {
  const dispatch = useDispatch();
  const directions = useSelector(selectDirections);
  const [selectedDirection, setSelectedDirection] = useState('');

  const submit = (event) => {
    const fromIntersectionId = event.target.startId.value;
    const toIntersectionId = event.target.endId.value;
    const targetSpeed = event.target.speed.value;

    dispatch(
      handleAddSegment({
        fromIntersectionId,
        toIntersectionId,
        edges,
        speed: targetSpeed,
        directionId: selectedDirection,
      }),
    );

    close();
  };

  const selectDirection = (event) => {
    setSelectedDirection(String(event.target.value));
  };

  return (
    <Dialog title="Add Segment Details" isOpen={isOpen} onClose={close} onSubmit={submit}>
      <TextField label="Start intersection ID" defaultValue={startSegmentId} name="startId" />
      <TextField label="End intersection ID" defaultValue={endSegmentId} name="endId" />
      <TextField label="Speed (km/h)" defaultValue={speed} name="speed" />
      <FormControl>
        <InputLabel>Direction</InputLabel>
        <Select value={selectedDirection} onChange={selectDirection}>
          {directions?.map((direction) => (
            <MenuItem value={direction.id} key={direction.id}>
              {direction.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Dialog>
  );
};

AddSegmentDialog.propTypes = {
  isOpen: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  edges: propTypes.array.isRequired,
  speed: propTypes.string.isRequired,
  startSegmentId: propTypes.string.isRequired,
  endSegmentId: propTypes.string.isRequired,
};
