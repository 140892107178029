export const MAPPED_TENANT_POSITIONS = {
  '20daceeb-7a05-47db-944e-4bb42cff60f7': { center: [34.7818, 32.0853], zoom: 13 }, // Tel Aviv
  'a0ef4bae-3886-4687-a672-03b9a93d5d87': { center: [34.7818, 32.0853], zoom: 13 }, // Tel Aviv
  'aa640f10-95f8-4f05-96f1-529dbbc11897': { center: [34.7818, 32.0853], zoom: 13 }, // Tel Aviv
  'ce6a8964-5bcc-4ed2-88f5-04034f04c923': { center: [34.7818, 32.0853], zoom: 13 }, // Tel Aviv
  '9afd522f-56aa-4b54-8a26-cc56773d60fb': { center: [-71.0589, 42.3601], zoom: 12 }, // Boston
  'c464fb65-7f37-4274-84bf-0ccc8f4d937f': { center: [-74.006, 40.7128], zoom: 11 }, // New York
  '4c15c98f-5e6f-4b9c-abe0-8590ed61dc5a': { center: [35.2137, 31.7683], zoom: 12 }, // Jerusalem
  'b6154596-da83-4670-948f-6b39a948e9a1': { center: [11.3548, 46.4983], zoom: 14 }, // Bolzano
  '3c673c8b-29d8-4c42-929f-a346a66a881f': { center: [11.3548, 46.4983], zoom: 14 }, // Bolzano
  '44c25cb8-ff50-405c-9368-6f5304e02a7b': { center: [34.7818, 32.0853], zoom: 13 }, // Tel Aviv
};
