import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPrograms, getPhaseDiagramData } from 'api/endpoints';

export const loadPrograms = createAsyncThunk(
  'signalPlans/programs/load',

  async ({ corridorId, stopId }, thunkAPI) => {
    const programsRes = await getPrograms(corridorId, stopId);

    if (programsRes.status && programsRes.status !== 200) {
      return thunkAPI.rejectWithValue();
    }

    return programsRes.data;
  },
  {
    condition({ stopId }, { getState }) {
      const {
        signalPlans: { programs },
      } = getState();

      return !(stopId in programs);
    },
  },
);

export const loadPhaseDiagram = createAsyncThunk(
  'signalPlans/phaseDiagram/load',

  async ({ corridorId, stopId, programNumber }, thunkAPI) => {
    const response = await getPhaseDiagramData(corridorId, stopId, programNumber);

    if (response.status && response.status !== 200) {
      return thunkAPI.rejectWithValue();
    }

    return response.data;
  },
  {
    condition({ stopId, programNumber }, { getState }) {
      const {
        signalPlans: { phaseDiagrams },
      } = getState();

      // eslint-disable-next-line no-prototype-builtins
      return !phaseDiagrams[stopId]?.hasOwnProperty(programNumber);
    },
  },
);
