import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TenantSelect } from 'components';
import TenantsStyles from './Tenants.styles';

const useStyles = makeStyles(TenantsStyles);

export const Tenants = () => {
  const classes = useStyles();

  return (
    <Container className={classes.pageContainer}>
      <p>Select tenant</p>
      <TenantSelect />
    </Container>
  );
};
