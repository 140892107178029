import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { setIsNavigationOpen, selectIsSidebarVisible, setIsSidebarVisible } from 'features';
import { CollapseButton } from '@axilion/ui-components';
import { Header } from 'components';
import { Menu } from '@material-ui/icons';
import styles from './SidebarContainer.module.scss';

export const SidebarContainer = ({ children, hasCollapseButton }) => {
  const dispatch = useDispatch();
  const isSidebarVisible = useSelector(selectIsSidebarVisible);

  const openNavigation = () => {
    dispatch(setIsNavigationOpen(true));
  };

  const onCollapseSidebarClick = () => {
    dispatch(setIsSidebarVisible(!isSidebarVisible));
  };

  return (
    <>
      <div className={`${styles.root} ${!isSidebarVisible ? styles.isHidden : ''}`}>
        <div className={styles.header}>
          <Header icon={<Menu onClick={openNavigation} />} />
        </div>
        {children}
      </div>
      {hasCollapseButton && (
        <CollapseButton
          className={`${styles.collapseSidebarButton} ${!isSidebarVisible ? styles.isHidden : ''}`}
          onClick={onCollapseSidebarClick}
          pointer={isSidebarVisible ? 'left' : 'right'}
        />
      )}
    </>
  );
};

SidebarContainer.propTypes = {
  children: propTypes.node,
  hasCollapseButton: propTypes.bool,
};

SidebarContainer.defaultProps = {
  children: null,
  hasCollapseButton: false,
};
