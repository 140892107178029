import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchOdMatrix } from 'features/thunks/odMatrixThunks';
import { getSimulationResult, getSimulationStatistics, getSimulationDiagram } from 'api/endpoints';
import { promiseAll } from 'api/api';

export const loadSimulation = createAsyncThunk(
  'simulations/load',

  async (corridor, thunkAPI) => {
    const id = 'simulation-20200828-1245-d32b3de4-97b4-48e9-97d2-258826da0727';

    const fetchData = () =>
      new Promise((resolve) => {
        const waitCompleted = async () => {
          // const { data: status } = await getSimulationStatus(id);

          // if (status === 'active') {
          //   window.setTimeout(waitResult, 10000);
          // }
          // if (['completed_with_errors', 'terminating'].includes(status)) {
          //     reject('The simulation has encountered an error.');
          //   }
          // }
          // if (status === 'completed') {
          const res = await promiseAll([
            getSimulationStatistics(id),
            getSimulationResult(id),
            getSimulationDiagram(id),
          ]);

          resolve({
            id,
            stats: res[0].data,
            result: res[1].data,
            diagram: res[2].data,
          });
          // }
        };

        waitCompleted();
      });

    thunkAPI.dispatch(fetchOdMatrix({ id, corridor }));
    const result = await fetchData();

    if (result.status && result.status !== 200) {
      return thunkAPI.rejectWithValue();
    }

    return result;
  },
);
