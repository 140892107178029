import React from 'react';
import propTypes from 'prop-types';
import { curveCatmullRom } from 'd3-shape';
import { FlexibleXYPlot, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, LineMarkSeries } from 'react-vis';
import styles from './GraphStyles.styles';
import 'react-vis/dist/style.css';

export const Graph = ({ data, xTitle, yTitle, xTickFormat }) => {
  let dataToRender = data;

  if (Array.isArray(data)) {
    dataToRender = {
      first: data,
      second: [],
    };
  }

  const tickValuesArr = dataToRender.first.concat(dataToRender.second).filter((tickValue) => tickValue);

  const yTickFormat = (v) => v.toFixed(2);

  return (
    <FlexibleXYPlot>
      <HorizontalGridLines style={styles.GridLines} tickValues={tickValuesArr.map(({ y }) => y)} />
      <VerticalGridLines style={styles.GridLines} tickValues={tickValuesArr.map(({ x }) => x)} />
      <XAxis title={xTitle} style={styles.Axis} tickFormat={xTickFormat} tickSize={0} />
      <YAxis
        title={yTitle}
        style={styles.Axis}
        tickValues={tickValuesArr.map(({ y }) => y)}
        tickFormat={yTickFormat}
        tickSize={0}
      />
      <LineMarkSeries
        curve={curveCatmullRom.alpha(0.4)}
        data={dataToRender.first}
        style={styles.LineMarkSeries}
        lineStyle={styles.LineMarkSeries1_lineStyle}
        markStyle={styles.LineMarkSeries1_markStyle}
        size={4}
      />

      {dataToRender.second?.length > 0 && (
        <LineMarkSeries
          curve={curveCatmullRom.alpha(0.4)}
          data={dataToRender.second}
          style={LineMarkSeries}
          lineStyle={styles.LineMarkSeries2_lineStyle}
          markStyle={styles.LineMarkSeries2_markStyle}
          size={4}
        />
      )}
    </FlexibleXYPlot>
  );
};

Graph.propTypes = {
  data: propTypes.oneOfType([propTypes.object, propTypes.array]).isRequired,
  xTitle: propTypes.string,
  yTitle: propTypes.string,
  xTickFormat: propTypes.func,
};

Graph.defaultProps = {
  xTitle: null,
  yTitle: null,
  xTickFormat: null,
};
