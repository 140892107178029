import { metricTypes } from 'consts';

import CrowdedRefugeGreen from 'assets/images/Crowded Refuge Islands Green@3x.svg';
import CrowdedRefugeGreenSelected from 'assets/images/Crowded Refuge Islands Green@3x Selected.svg';
import CrowdedRefugeYellow from 'assets/images/Crowded Refuge Islands Yellow@3x.svg';
import CrowdedRefugeYellowSelected from 'assets/images/Crowded Refuge Islands Yellow@3x Selected.svg';
import CrowdedRefugeOrange from 'assets/images/Crowded Refuge Islands Orange@3x.svg';
import CrowdedRefugeOrangeSelected from 'assets/images/Crowded Refuge Islands Orange@3x Selected.svg';
import CrowdedRefugeRed from 'assets/images/Crowded Refuge Islands Red@3x.svg';
import CrowdedRefugeRedSelected from 'assets/images/Crowded Refuge Islands Red@3x Selected.svg';

import RedLineCrossingsGreen from 'assets/images/Red Light Crossings Green@3x.svg';
import RedLineCrossingsGreenSelected from 'assets/images/Red Light Crossings Green@3x Selected.svg';
import RedLineCrossingsYellow from 'assets/images/Red Light Crossings Yellow@3x.svg';
import RedLineCrossingsYellowSelected from 'assets/images/Red Light Crossings Yellow@3x Selected.svg';
import RedLineCrossingsOrange from 'assets/images/Red Light Crossings Orange@3x.svg';
import RedLineCrossingsOrangeSelected from 'assets/images/Red Light Crossings Orange@3x Selected.svg';
import RedLineCrossingsRed from 'assets/images/Red Light Crossings Red@3x.svg';
import RedLineCrossingsRedSelected from 'assets/images/Red Light Crossings Red@3x Selected.svg';

import PedestriansDensityGreen from 'assets/images/Density Green@3x.svg';
import PedestriansDensityGreenSelected from 'assets/images/Density Green@3x Selected.svg';
import PedestriansDensityYellow from 'assets/images/Density Yellow@3x.svg';
import PedestriansDensityYellowSelected from 'assets/images/Density Yellow@3x Selected.svg';
import PedestriansDensityOrange from 'assets/images/Density Orange@3x.svg';
import PedestriansDensityOrangeSelected from 'assets/images/Density Orange@3x Selected.svg';
import PedestriansDensityRed from 'assets/images/Density Red@3x.svg';
import PedestriansDensityRedSelected from 'assets/images/Density Red@3x Selected.svg';

import CrosswalkGreen from 'assets/images/Crosswalk Green.svg';
import CrosswalkGreenSelected from 'assets/images/Crosswalk Green Selected.svg';
import CrosswalkYellow from 'assets/images/Crosswalk Yellow.svg';
import CrosswalkYellowSelected from 'assets/images/Crosswalk Yellow Selected.svg';
import CrosswalkOrange from 'assets/images/Crosswalk Orange.svg';
import CrosswalkOrangeSelected from 'assets/images/Crosswalk Orange Selected.svg';
import CrosswalkRed from 'assets/images/Crosswalk Red.svg';
import CrosswalkRedSelected from 'assets/images/Crosswalk Red Selected.svg';

import PedestriansGreen from 'assets/images/Pedestrians Green@3x.svg';
import PedestriansGreenSelected from 'assets/images/Pedestrians Green@3x Selected.svg';
import PedestriansYellow from 'assets/images/Pedestrians Yellow@3x.svg';
import PedestriansYellowSelected from 'assets/images/Pedestrians Yellow@3x Selected.svg';
import PedestriansOrange from 'assets/images/Pedestrians Orange@3x.svg';
import PedestriansOrangeSelected from 'assets/images/Pedestrians Orange@3x Selected.svg';
import PedestriansRed from 'assets/images/Pedestrians Red@3x.svg';
import PedestriansRedSelected from 'assets/images/Pedestrians Red@3x Selected.svg';

import ScootersGreen from 'assets/images/Scooters Green@3x.svg';
import ScootersGreenSelected from 'assets/images/Scooters Green@3x Selected.svg';
import ScootersYellow from 'assets/images/Scooters Yellow@3x.svg';
import ScootersYellowSelected from 'assets/images/Scooters Yellow@3x Selected.svg';
import ScootersOrange from 'assets/images/Scooters Orange@3x.svg';
import ScootersOrangeSelected from 'assets/images/Scooters Orange@3x Selected.svg';
import ScootersRed from 'assets/images/Scooters Red@3x.svg';
import ScootersRedSelected from 'assets/images/Scooters Red@3x Selected.svg';

import CyclistsGreen from 'assets/images/Cyclists Green@3x.svg';
import CyclistsGreenSelected from 'assets/images/Cyclists Green@3x Selected.svg';
import CyclistsYellow from 'assets/images/Cyclists Yellow@3x.svg';
import CyclistsYellowSelected from 'assets/images/Cyclists Yellow@3x Selected.svg';
import CyclistsOrange from 'assets/images/Cyclists Orange@3x.svg';
import CyclistsOrangeSelected from 'assets/images/Cyclists Orange@3x Selected.svg';
import CyclistsRed from 'assets/images/Cyclists Red@3x.svg';
import CyclistsRedSelected from 'assets/images/Cyclists Red@3x Selected.svg';

import FaceMaskGreen from 'assets/images/Face Green@3x.svg';
import FaceMaskGreenSelected from 'assets/images/Face Green Selected@3x.svg';
import FaceMaskYellow from 'assets/images/Face Yellow@3x.svg';
import FaceMaskYellowSelected from 'assets/images/Face Yellow Selected@3x.svg';
import FaceMaskOrange from 'assets/images/Face Orange@3x.svg';
import FaceMaskOrangeSelected from 'assets/images/Face Orange Selected@3x.svg';
import FaceMaskRed from 'assets/images/Face Red@3x.svg';
import FaceMaskRedSelected from 'assets/images/Face Red Selected@3x.svg';

import DwellTimeIconGreen from 'assets/images/Dwell Time Green@3x.svg';
import DwellTimeIconGreenSelected from 'assets/images/Dwell Time Green@3x Selected.svg';
import DwellTimeIconOrange from 'assets/images/Dwell Time Orange@3x.svg';
import DwellTimeIconOrangeSelected from 'assets/images/Dwell Time Orange@3x Selected.svg';
import DwellTimeIconRed from 'assets/images/Dwell Time Red@3x.svg';
import DwellTimeIconRedSelected from 'assets/images/Dwell Time Red@3x Selected.svg';
import DwellTimeIconYellow from 'assets/images/Dwell Time Yellow@3x.svg';
import DwellTimeIconYellowSelected from 'assets/images/Dwell Time Yellow@3x Selected.svg';

import PassengersDensityGreen from 'assets/images/Passenger Density Green@3x.svg';
import PassengersDensityGreenSelected from 'assets/images/Passenger Density Green@3x Selected.svg';
import PassengersDensityYellow from 'assets/images/Passenger Density Yellow@3x.svg';
import PassengersDensityYellowSelected from 'assets/images/Passenger Density Yellow@3x Selected.svg';
import PassengersDensityOrange from 'assets/images/Passenger Density Orange@3x.svg';
import PassengersDensityOrangeSelected from 'assets/images/Passenger Density Orange@3x Selected.svg';
import PassengersDensityRed from 'assets/images/Passenger Density Red@3x.svg';
import PassengersDensityRedSelected from 'assets/images/Passenger Density Red@3x Selected.svg';

import EcoGreen from 'assets/images/Eco Green.svg';
import EcoYellow from 'assets/images/Eco Yellow.svg';
import EcoOrange from 'assets/images/Eco Orange.svg';
import EcoRed from 'assets/images/Eco Red.svg';

export const icons = [
  CrowdedRefugeGreen,
  CrowdedRefugeGreenSelected,
  CrowdedRefugeYellow,
  CrowdedRefugeYellowSelected,
  CrowdedRefugeOrange,
  CrowdedRefugeOrangeSelected,
  CrowdedRefugeRed,
  CrowdedRefugeRedSelected,
  RedLineCrossingsGreen,
  RedLineCrossingsGreenSelected,
  RedLineCrossingsYellow,
  RedLineCrossingsYellowSelected,
  RedLineCrossingsOrange,
  RedLineCrossingsOrangeSelected,
  RedLineCrossingsRed,
  RedLineCrossingsRedSelected,
  PedestriansDensityGreen,
  PedestriansDensityGreenSelected,
  PedestriansDensityYellow,
  PedestriansDensityYellowSelected,
  PedestriansDensityOrange,
  PedestriansDensityOrangeSelected,
  PedestriansDensityRed,
  PedestriansDensityRedSelected,
  CrosswalkGreen,
  CrosswalkGreenSelected,
  CrosswalkYellow,
  CrosswalkYellowSelected,
  CrosswalkOrange,
  CrosswalkOrangeSelected,
  CrosswalkRed,
  CrosswalkRedSelected,
  PedestriansGreen,
  PedestriansGreenSelected,
  PedestriansYellow,
  PedestriansYellowSelected,
  PedestriansOrange,
  PedestriansOrangeSelected,
  PedestriansRed,
  PedestriansRedSelected,
  ScootersGreen,
  ScootersGreenSelected,
  ScootersYellow,
  ScootersYellowSelected,
  ScootersOrange,
  ScootersOrangeSelected,
  ScootersRed,
  ScootersRedSelected,
  CyclistsGreen,
  CyclistsGreenSelected,
  CyclistsYellow,
  CyclistsYellowSelected,
  CyclistsOrange,
  CyclistsOrangeSelected,
  CyclistsRed,
  CyclistsRedSelected,
  FaceMaskGreen,
  FaceMaskGreenSelected,
  FaceMaskYellow,
  FaceMaskYellowSelected,
  FaceMaskOrange,
  FaceMaskOrangeSelected,
  FaceMaskRed,
  FaceMaskRedSelected,
  DwellTimeIconGreen,
  DwellTimeIconGreenSelected,
  DwellTimeIconOrange,
  DwellTimeIconOrangeSelected,
  DwellTimeIconRed,
  DwellTimeIconRedSelected,
  DwellTimeIconYellow,
  DwellTimeIconYellowSelected,
  PassengersDensityGreen,
  PassengersDensityGreenSelected,
  PassengersDensityYellow,
  PassengersDensityYellowSelected,
  PassengersDensityOrange,
  PassengersDensityOrangeSelected,
  PassengersDensityRed,
  PassengersDensityRedSelected,
  EcoGreen,
  EcoYellow,
  EcoOrange,
  EcoRed,
];

export const findFeatureIcon = ({ metricType, featureProperties, isSelected }) => {
  const { colorGroupId, avgDwellTime, density } = featureProperties;

  if (metricType === metricTypes.PEDESTRIANS_SIGNAL_PROGRESSION) {
    if (colorGroupId === 4) return isSelected ? CrowdedRefugeGreenSelected : CrowdedRefugeGreen;
    if (colorGroupId === 3) return isSelected ? CrowdedRefugeYellowSelected : CrowdedRefugeYellow;
    if (colorGroupId === 2) return isSelected ? CrowdedRefugeOrangeSelected : CrowdedRefugeOrange;

    return isSelected ? CrowdedRefugeRedSelected : CrowdedRefugeRed;
  }

  if (metricType === metricTypes.RED_LIGHT) {
    if (colorGroupId === 4) return isSelected ? RedLineCrossingsGreenSelected : RedLineCrossingsGreen;
    if (colorGroupId === 3) return isSelected ? RedLineCrossingsYellowSelected : RedLineCrossingsYellow;
    if (colorGroupId === 2) return isSelected ? RedLineCrossingsOrangeSelected : RedLineCrossingsOrange;

    return isSelected ? RedLineCrossingsRedSelected : RedLineCrossingsRed;
  }

  if (metricType === metricTypes.TOTAL_DENSITY) {
    if (colorGroupId === 4) return isSelected ? PedestriansDensityGreenSelected : PedestriansDensityGreen;
    if (colorGroupId === 3) return isSelected ? PedestriansDensityYellowSelected : PedestriansDensityYellow;
    if (colorGroupId === 2) return isSelected ? PedestriansDensityOrangeSelected : PedestriansDensityOrange;

    return isSelected ? PedestriansDensityRedSelected : PedestriansDensityRed;
  }

  if (metricType === metricTypes.ACTIVE_CROSSWALK) {
    if (colorGroupId === 4) return isSelected ? CrosswalkGreenSelected : CrosswalkGreen;
    if (colorGroupId === 3) return isSelected ? CrosswalkYellowSelected : CrosswalkYellow;
    if (colorGroupId === 2) return isSelected ? CrosswalkOrangeSelected : CrosswalkOrange;

    return isSelected ? CrosswalkRedSelected : CrosswalkRed;
  }

  if (metricType === metricTypes.PEDESTRIAN_DENSITY) {
    if (colorGroupId === 4) return isSelected ? PedestriansGreenSelected : PedestriansGreen;
    if (colorGroupId === 3) return isSelected ? PedestriansYellowSelected : PedestriansYellow;
    if (colorGroupId === 2) return isSelected ? PedestriansOrangeSelected : PedestriansOrange;

    return isSelected ? PedestriansRedSelected : PedestriansRed;
  }

  if (metricType === metricTypes.SCOOTER_DENSITY) {
    if (colorGroupId === 4) return isSelected ? ScootersGreenSelected : ScootersGreen;
    if (colorGroupId === 3) return isSelected ? ScootersYellowSelected : ScootersYellow;
    if (colorGroupId === 2) return isSelected ? ScootersOrangeSelected : ScootersOrange;

    return isSelected ? ScootersRedSelected : ScootersRed;
  }

  if (metricType === metricTypes.CYCLIST_DENSITY) {
    if (colorGroupId === 4) return isSelected ? CyclistsGreenSelected : CyclistsGreen;
    if (colorGroupId === 3) return isSelected ? CyclistsYellowSelected : CyclistsYellow;
    if (colorGroupId === 2) return isSelected ? CyclistsOrangeSelected : CyclistsOrange;

    return isSelected ? CyclistsRedSelected : CyclistsRed;
  }

  if (metricType === metricTypes.FACE_MASK_USAGE) {
    if (colorGroupId === 4) return isSelected ? FaceMaskGreenSelected : FaceMaskGreen;
    if (colorGroupId === 3) return isSelected ? FaceMaskYellowSelected : FaceMaskYellow;
    if (colorGroupId === 2) return isSelected ? FaceMaskOrangeSelected : FaceMaskOrange;

    return isSelected ? FaceMaskRedSelected : FaceMaskRed;
  }

  if (metricType === metricTypes.DWELL_TIME) {
    if (avgDwellTime < 10) return isSelected ? DwellTimeIconGreenSelected : DwellTimeIconGreen;
    if (avgDwellTime < 15) return isSelected ? DwellTimeIconYellowSelected : DwellTimeIconYellow;
    if (avgDwellTime < 18) return isSelected ? DwellTimeIconOrangeSelected : DwellTimeIconOrange;

    return isSelected ? DwellTimeIconRedSelected : DwellTimeIconRed;
  }

  if (metricType === metricTypes.PASSENGERS_DENSITY) {
    if (density < 2) return isSelected ? PassengersDensityGreenSelected : PassengersDensityGreen;
    if (density < 4) return isSelected ? PassengersDensityYellowSelected : PassengersDensityYellow;
    if (density < 8) return isSelected ? PassengersDensityOrangeSelected : PassengersDensityOrange;

    return isSelected ? PassengersDensityRedSelected : PassengersDensityRed;
  }

  if (metricType === metricTypes.AIR_QUALITY) {
    if (colorGroupId === 4) return EcoGreen;
    if (colorGroupId === 3) return EcoYellow;
    if (colorGroupId === 2) return EcoOrange;

    return EcoRed;
  }

  return null;
};
