export const userMock = {
  loading: 'loaded',
  fullName: 'Test User',
  profilePictureUrl: '/static/media/default-profile.734f1564.svg',
  isLoggedIn: true,
  roles: ['ROLE_DIGITAL_TWIN', 'ROLE_USER'],
  ID: '123',
  email: 'testu@test.com',
  locale: 'en',
  tenants: {
    userLoginTenant: '123',
    currentTenant: '123',
  },
};
