import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  selectStatsFrames,
  selectFramesFetchError,
  selectMetricType,
  selectStatsFramesKey,
  setStatsFramesKey,
  selectUnitSystemName,
  selectSpeedMultiplier,
  selectDistanceMultiplier,
  selectSpeedUnit,
  selectDistanceUnit,
  selectApproachStatDays,
  fetchApproachesStatSamples,
  selectPedestrianStatApproaches,
  setApproachStatDays,
  setPedestrianStatApproaches,
  fetchApproachesStatDays,
  fetchApproachStatSampleFrames,
  selectApproachStatSampleFrames,
  setApproachStatSampleFrames,
  selectIsLoadingApproachesStatSamples,
  selectIsLoadingApproachStatSampleFrames,
  selectIsLoadingApproachStatDays,
  fetchGeneralStatsFrames,
  selectStatisticType,
  selectIsUserAdmin,
  fetchPedestriansStatApproaches,
  fetchBusStopStatSamples,
  selectBusStopStatSamples,
  selectIsLoadingBusStopStatSamples,
  selectApproachesStatSamples,
  selectClickedGeneralViewFeatureId,
  setGeneralViewRadialChart,
  setFrameDetailsOverlay,
  setActiveFrameCords,
  setActiveFrameDetails,
} from 'features';
import { layers, maps } from 'utils';
import { useFormat, useIntl, usePostData } from 'utils/hooks';
import { metricTypes, pedestriansMetricTypes, statisticTypes } from 'consts';
import { MediaBox, SimpleTable, IconButton } from 'components';
import Slide from '@material-ui/core/Slide';
import { Theaters, TableChart, ArrowBack } from '@material-ui/icons';
import { FramesSlider } from '@axilion/ui-components';
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import getGeneralViewVideoBoxTitle from './generalViewVideoBoxTitles';

import styles from './GeneralViewVideo.module.scss';

export const GeneralViewVideo = () => {
  const { fromUtcToProjectTime } = useFormat();
  const intl = useIntl();
  const { generalStatsPostData, framesPostData } = usePostData();
  const dispatch = useDispatch();
  const framesLists = useSelector(selectStatsFrames);
  const isVisible = !!useSelector(selectStatsFramesKey);
  const showNotFound = useSelector(selectFramesFetchError);
  const metricType = useSelector(selectMetricType);
  const unitSystemName = useSelector(selectUnitSystemName);
  const speedMultiplier = useSelector(selectSpeedMultiplier);
  const distanceMultiplier = useSelector(selectDistanceMultiplier);
  const speedUnit = useSelector(selectSpeedUnit);
  const distanceUnit = useSelector(selectDistanceUnit);
  const approachStatDays = useSelector(selectApproachStatDays);
  const pedestrianStatApproaches = useSelector(selectPedestrianStatApproaches);
  const approachStatSampleFrames = useSelector(selectApproachStatSampleFrames);
  const isLoadingApproachesStatSamples = useSelector(selectIsLoadingApproachesStatSamples);
  const isLoadingBusStopStatSamples = useSelector(selectIsLoadingBusStopStatSamples);
  const approachesStatSamples = useSelector(selectApproachesStatSamples);
  const isLoadingApproachStatSampleFrames = useSelector(selectIsLoadingApproachStatSampleFrames);
  const isLoadingApproachStatDays = useSelector(selectIsLoadingApproachStatDays);
  const clickedFeatureId = useSelector(selectClickedGeneralViewFeatureId);
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const busStopStatSamples = useSelector(selectBusStopStatSamples);
  const statisticType = useSelector(selectStatisticType);
  const [listsTitles, setListsTitles] = useState(null);
  const [headerContent, setHeaderContent] = useState();
  const [overlayContent, setOverlayContent] = useState(null);
  const [listIndex, setListIndex] = useState(0);
  const [isFromApproachesTable, setIsFromApproachesTable] = useState();
  const [previousHeaderContent, setPreviousHeaderContent] = useState();
  const [approachStatDaysRowData, setApproachStatDaysRowData] = useState();
  const hasFrames = framesLists[0].length;
  const isLoading =
    !hasFrames ||
    isLoadingApproachStatDays ||
    isLoadingApproachesStatSamples ||
    isLoadingApproachStatSampleFrames ||
    isLoadingBusStopStatSamples;

  const getClickedFeature = () => layers.generalViewStats.get('source').getFeatureById(clickedFeatureId);

  const makeListsTitles = () => {
    const feature = getClickedFeature();
    const text = getGeneralViewVideoBoxTitle(
      intl,
      metricType,
      metricTypes,
      feature,
      distanceMultiplier,
      distanceUnit,
      speedMultiplier,
      speedUnit,
    );

    return framesLists.map((_, index) => (
      <>
        {framesLists.length > 1 && (
          <span className={styles.titleIcon} data-testid="videoTitleIcon">
            {`${index + 1}/${framesLists.length}`}
          </span>
        )}
        <span className={styles.titleText} data-testid="videoTitleText">
          {pedestriansMetricTypes.includes(metricType) && (
            <>
              {feature.get('approachName')}
              <br />
            </>
          )}
          {text}
        </span>
      </>
    ));
  };

  const boxHeader = <div className={styles.title}>{headerContent}</div>;

  const onListIndexChange = (index) => {
    setListIndex(index);
    if (listsTitles?.length && !approachStatSampleFrames) {
      setHeaderContent(listsTitles[index]);
    }
  };

  const onInterestingVideosTableRowClick = (rowIndex) => {
    setListIndex(rowIndex);
    setOverlayContent(null);
    setHeaderContent(listsTitles[rowIndex]);
    dispatch(setApproachStatSampleFrames(null));
  };

  const onInterestingVideosButtonClick = (prevHeaderContent) => () => {
    const tableRows = framesLists.map((list, index) => [
      `#${index + 1}`,
      fromUtcToProjectTime(list[0].frameTime, 'DATE_MED'),
      fromUtcToProjectTime(list[0].frameTime, 'TIME_SIMPLE'),
    ]);

    setOverlayContent(
      <SimpleTable
        rows={tableRows}
        rowHeadings={['Title', 'Date', 'Time']}
        rowClickHandler={onInterestingVideosTableRowClick}
      />,
    );
    setHeaderContent(
      <>
        <FormattedMessage defaultMessage="Video Gallery" description="VideoList heading" />
        <button
          type="button"
          className={styles.titleBackBtn}
          onClick={() => {
            setHeaderContent(prevHeaderContent);
            setOverlayContent(null);
          }}
        >
          <ArrowBack />
        </button>
      </>,
    );
  };

  const onApproachStatDayVideoClick = async (video, videoIndex, formattedDate, fromApproachesTable) => {
    setHeaderContent(`Approach stat sample #${videoIndex + 1} for ${formattedDate}`);
    setOverlayContent(null);

    const postData = {
      ...framesPostData({
        cameraId: video.videoCameraId,
        startDate: video.videoStartTime,
        endDate: video.videoEndTime,
        playTime: video.videoPlayTime,
      }),
    };

    dispatch(fetchApproachStatSampleFrames({ postData }));
    if (fromApproachesTable) {
      dispatch(setApproachStatDays(null));
    }
  };

  const onApproachStatDaysRowClick = async (rowIndex, data, fromApproachesTable, prevHeaderContent) => {
    const requestBody = {
      ...generalStatsPostData(),
      resultDate: data.days[rowIndex],
      objectId: getClickedFeature().get('id'),
    };

    if (pedestriansMetricTypes.includes(metricType)) {
      requestBody.vehicleTypes = [statisticTypes.PUBLIC, statisticTypes.PRIVATE];
    }

    dispatch(fetchApproachesStatSamples({ requestBody }));

    setIsFromApproachesTable(fromApproachesTable);
    setPreviousHeaderContent(prevHeaderContent);
    setApproachStatDaysRowData(data);
  };

  const onApproachStatDaysButtonClick = (data, fromApproachesTable, prevHeaderContent) => {
    if (!data) {
      setOverlayContent('No data!');

      return;
    }
    const { days, stats, samples, totals } = data;
    const tableRows = days.map((day, index) => [
      day,
      Math.round(stats[index] * 100) / 100,
      samples[index],
      totals[index],
    ]);

    setOverlayContent(
      <SimpleTable
        rows={tableRows}
        rowHeadings={[
          'Date',
          'Metric',
          `Samples (${samples.reduce((a, b) => a + b)})`,
          `Total (${totals.reduce((a, b) => a + b)})`,
        ]}
        rowClickHandler={
          (rowIndex) => onApproachStatDaysRowClick(rowIndex, data, fromApproachesTable, prevHeaderContent) //eslint-disable-line
        }
      />,
    );
    setHeaderContent(
      <>
        {`Approach stat days — (${Math.round((stats.reduce((a, b) => a + b) / stats.length) * 100) / 100})`}
        <button
          type="button"
          className={styles.titleBackBtn}
          onClick={() => {
            if (fromApproachesTable) {
              onPedestrianStatApproachesButtonClick(prevHeaderContent); // eslint-disable-line
            } else {
              setHeaderContent(prevHeaderContent);
              setOverlayContent(null);
            }
          }}
        >
          <ArrowBack />
        </button>
      </>,
    );
  };

  const onPedestrianStatApproachesRowClick = async (objectId) => {
    const requestBody = {
      ...generalStatsPostData({ forceVehicleTypes: true }),
      vehicleTypes: [statisticTypes.PUBLIC, statisticTypes.PRIVATE],
      objectId,
    };

    getClickedFeature().set('id', objectId);

    dispatch(fetchApproachesStatDays({ requestBody }));

    onApproachStatDaysButtonClick(approachStatDays, true, previousHeaderContent);
  };

  const onPedestrianStatApproachesButtonClick = (prevHeaderContent) => {
    const { approaches, densities, samples, totals } = pedestrianStatApproaches;
    const tableRows = approaches.map((approach, index) => [approach, densities[index], samples[index], totals[index]]);

    setOverlayContent(
      <SimpleTable
        rows={tableRows}
        rowHeadings={['ID', 'Metric', 'Samples', 'Total']}
        rowClickHandler={(index) => onPedestrianStatApproachesRowClick(approaches[index], prevHeaderContent)}
      />,
    );
    setHeaderContent(
      <>
        Approaches
        <button
          type="button"
          className={styles.titleBackBtn}
          onClick={() => {
            setHeaderContent(prevHeaderContent);
            setOverlayContent(null);
          }}
        >
          <ArrowBack />
        </button>
      </>,
    );
  };

  const onBusStopStatSamplesButtonClick = (prevHeaderContent) => {
    if (!busStopStatSamples) {
      setOverlayContent('No data!');
    }

    const { metric, video } = busStopStatSamples;

    const formattedDate = fromUtcToProjectTime(video[0].videoStartTime, 'DATE_MED');

    const tableRows = video.map(({ videoStartTime }, index) => [
      `#${index + 1}`,
      fromUtcToProjectTime(videoStartTime, 'TIME_SIMPLE'),
      Math.round(metric[index] * 100) / 100,
    ]);

    setOverlayContent(
      <SimpleTable
        rows={tableRows}
        rowHeadings={['Title', 'Time', 'Metric']}
        rowClickHandler={(index) => onApproachStatDayVideoClick(video[index], index, formattedDate, false)}
      />,
    );
    setHeaderContent(
      <>
        {`Approach stat samples for ${formattedDate} `}
        <button
          type="button"
          className={styles.titleBackBtn}
          onClick={() => {
            setHeaderContent(prevHeaderContent);
            setOverlayContent(null);
          }}
        >
          <ArrowBack />
        </button>
      </>,
    );
  };

  const zoomOutIfMapZoomIsBiggerThan = (zoom) => {
    const mapView = maps.GeneralView.getView();

    if (mapView.getZoom() >= zoom) {
      mapView.animate({
        center: mapView.getCenter(),
        zoom: zoom - 2,
      });
    }
  };

  const onClose = () => {
    const approachFeatures = layers.generalViewStats.getSource().getFeatures();

    approachFeatures.forEach((f) => f.set('isClicked', false));
    setOverlayContent(false);
    zoomOutIfMapZoomIsBiggerThan(16);

    dispatch(setStatsFramesKey(null));
    dispatch(setGeneralViewRadialChart(null));
    maps.GeneralView.getOverlayById('frames-location').setPosition(null);
  };

  const onTableChartIconClick = () => {
    if (approachStatDays?.days?.length) {
      onApproachStatDaysButtonClick(approachStatDays, false, headerContent);
    } else if (pedestrianStatApproaches?.approaches?.length) {
      onPedestrianStatApproachesButtonClick(headerContent);
    } else if (busStopStatSamples) {
      onBusStopStatSamplesButtonClick(headerContent);
    }
  };

  const onInfoClick = () => {
    dispatch(setFrameDetailsOverlay(true));
  };

  const onActiveFrameCoordsChange = (frameCoords) => {
    dispatch(setActiveFrameCords(frameCoords));
  };

  const onActiveFrameDetailsChange = (frameDetails) => {
    dispatch(setActiveFrameDetails(frameDetails));
  };

  const bottomRightContent = (
    <>
      {(approachStatDays?.days?.length || pedestrianStatApproaches?.approaches?.length || busStopStatSamples) && (
        <IconButton
          icon={<TableChart />}
          buttonProps={{ onClick: onTableChartIconClick }}
          dataTestid="approachStatDays"
        />
      )}
      {(framesLists.length > 1 || approachStatSampleFrames) && (
        <IconButton
          icon={<Theaters />}
          buttonProps={{ onClick: onInterestingVideosButtonClick(headerContent) }}
          dataTestid="videoGallery"
        />
      )}
    </>
  );

  useEffect(() => {
    if (!clickedFeatureId) {
      setHeaderContent(null);
      dispatch(setStatsFramesKey(null));
      dispatch(setGeneralViewRadialChart(null));
      dispatch(setApproachStatSampleFrames(null));
      dispatch(setApproachStatDays(null));
      dispatch(setPedestrianStatApproaches(null));
      if (maps.GeneralView) maps.GeneralView.getOverlayById('frames-location').setPosition(null);
    }

    if (clickedFeatureId) {
      const clickedFeature = getClickedFeature();
      const featureIsLineString = clickedFeature.getGeometry().getType() === 'LineString';
      const isPedestrianMetric = pedestriansMetricTypes.includes(metricType);
      const isPedestrianDensityMetric = [
        metricTypes.TOTAL_DENSITY,
        metricTypes.PEDESTRIAN_DENSITY,
        metricTypes.SCOOTER_DENSITY,
        metricTypes.CYCLIST_DENSITY,
      ].includes(metricType);
      const metricHasBusStopStatSamples = [
        metricTypes.DWELL_TIME,
        metricTypes.PASSENGERS_DENSITY,
        metricTypes.FACE_MASK_USAGE,
      ].includes(metricType);
      const videosPostData = [];

      clickedFeature.get('videos').videos.forEach((video) => {
        videosPostData.push(
          framesPostData({
            cameraId: video.videoCameraId,
            startDate: video.videoStartTime,
            endDate: video.videoEndTime,
            playTime: video.videoPlayTime,
          }),
        );
      });

      dispatch(fetchGeneralStatsFrames({ postDataArr: videosPostData }));
      dispatch(setStatsFramesKey(JSON.stringify(videosPostData)));
      dispatch(setGeneralViewRadialChart(clickedFeature.get('radialChartData')));

      let endIntersectionFeature;

      if (featureIsLineString) {
        const endIntersectionCoordinates = clickedFeature.getProperties().endIntersection.coordinates;

        endIntersectionFeature = new Feature({
          geometry: new Point(fromLonLat(endIntersectionCoordinates)),
        });
      }

      maps.GeneralView.getView().fit(
        featureIsLineString ? endIntersectionFeature.getGeometry() : clickedFeature.getGeometry(),
        {
          duration: 1000,
          padding: [10, 10, 10, 10],
          minResolution: 1.9,
        },
      );

      if (isUserAdmin) {
        const requestBody = generalStatsPostData();

        if (isPedestrianMetric && !isPedestrianDensityMetric) {
          requestBody.vehicleTypes = [statisticTypes.PUBLIC, statisticTypes.PRIVATE];
        }

        if (isPedestrianDensityMetric) {
          requestBody.intersectionId = clickedFeature.get('intersectionId');
          dispatch(fetchPedestriansStatApproaches({ requestBody }));
        } else if (metricHasBusStopStatSamples && statisticType === statisticTypes.PUBLIC) {
          requestBody.objectId = clickedFeature.get('id');
          dispatch(fetchBusStopStatSamples({ requestBody }));
        } else {
          requestBody.objectId = clickedFeature.get('id');
          dispatch(fetchApproachesStatDays({ requestBody }));
        }
      }
    }
  }, [clickedFeatureId]); //eslint-disable-line

  useEffect(() => {
    if (listsTitles) {
      setHeaderContent(listsTitles[0]);
    }
  }, [listsTitles]);

  useEffect(() => {
    setOverlayContent(false);
    dispatch(setApproachStatSampleFrames(null));
  }, [framesLists]); //eslint-disable-line

  useEffect(() => {
    if (hasFrames) {
      setListsTitles(makeListsTitles());
    }
  }, [unitSystemName, framesLists]); //eslint-disable-line

  useEffect(() => {
    if (!approachesStatSamples || !approachesStatSamples.video[0]) {
      setOverlayContent('No data!');

      return;
    }

    const { metric, video } = approachesStatSamples;

    const formattedDate = fromUtcToProjectTime(video[0].videoStartTime, 'DATE_MED');

    const tableRows = video.map(({ videoStartTime }, index) => [
      `#${index + 1}`,
      fromUtcToProjectTime(videoStartTime, 'TIME_SIMPLE'),
      Math.round(metric[index] * 100) / 100,
    ]);

    setOverlayContent(
      <SimpleTable
        rows={tableRows}
        rowHeadings={['Title', 'Time', 'Metric']}
        rowClickHandler={
          (index) => onApproachStatDayVideoClick(video[index], index, formattedDate, isFromApproachesTable) // eslint-disable-line
        }
      />,
    );
    setHeaderContent(
      <>
        {`Approach stat samples for ${formattedDate} `}
        <button
          type="button"
          className={styles.titleBackBtn}
          onClick={
            () => onApproachStatDaysButtonClick(approachStatDaysRowData, isFromApproachesTable, previousHeaderContent) // eslint-disable-line
          }
        >
          <ArrowBack />
        </button>
      </>,
    );
  }, [approachesStatSamples]); // eslint-disable-line

  return (
    <Slide in={isVisible} direction="up" mountOnEnter unmountOnExit timeout={{ enter: 300, exit: 0 }}>
      <div className={styles.root}>
        <MediaBox closeHandler={onClose} headerContent={boxHeader} className={styles.mediaBox}>
          <div className={styles.sliderWrap}>
            <FramesSlider
              showFrameDetails
              fromUtcToProjectTime={fromUtcToProjectTime}
              showInfoButton={isUserAdmin}
              showBoxes={isUserAdmin}
              showTags={isUserAdmin}
              showCountInLegend={isUserAdmin}
              metricType={metricType}
              frameTimePosition="left"
              framesLists={approachStatSampleFrames || framesLists}
              onInfoClick={onInfoClick}
              onActiveFrameCoordsChange={onActiveFrameCoordsChange}
              onActiveFrameDetailsChange={onActiveFrameDetailsChange}
              isLoading={isLoading}
              showNotFound={showNotFound}
              onListIndexChange={onListIndexChange}
              overlayContent={overlayContent}
              bottomRightContent={bottomRightContent}
              listIndex={listIndex}
            />
          </div>
        </MediaBox>
      </div>
    </Slide>
  );
};
