import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SidebarContainer } from 'components';
import { fetchBackendVersion, selectBackendVersion } from 'features';
import { ListItem, ListItemText } from '@material-ui/core';

export const About = () => {
  const dispatch = useDispatch();
  const backendVersion = useSelector(selectBackendVersion);

  useEffect(() => {
    dispatch(fetchBackendVersion());
  }, []); //eslint-disable-line

  return (
    <SidebarContainer>
      <ListItem>
        <ListItemText primary={`Backend version: ${backendVersion}`} />
      </ListItem>
      <ListItem>
        <ListItemText primary={`Frontend version: ${process.env.REACT_APP_VERSION}`} />
      </ListItem>
    </SidebarContainer>
  );
};
