import React from 'react';

import './FeatureStatsPopupOverlay.scss';

export const FeatureStatsPopupOverlay = () => (
  <>
    <div id="feature-stats-tooltip">
      <span className="stats-text" />
    </div>
    <div id="frames-location" />
  </>
);
