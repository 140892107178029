const SearchDetails = (theme) => ({
  tabContent: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    transition: 'top 300ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
  wrapper: {
    margin: '20px 18px 0',
    height: 'calc(100% - 125px)',
  },
  content: {
    height: '100%',
    padding: '5px 9px',
    border: `1px solid ${theme.color02}`,
    borderRadius: 5,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  innerInfo: { fontSize: 14 },
  innerTitle: { fontSize: 14, display: 'block' },
  boxHeaderInner: {
    maxWidth: 65,
    height: 5,
  },
  searchButton: {
    margin: '20px 0',
    padding: '10px 0',
    width: '100%',
    borderRadius: 0,
    backgroundColor: theme.color03,
    color: theme.color01,
    textTransform: 'none',

    '&:hover': {
      backgroundColor: theme.color13,
    },
  },
  ridesError: {
    color: 'red',
    fontSize: 12,
    textAlign: 'center',
    border: '1px solid red',
    borderRadius: 5,
    padding: 5,
    marginBottom: 12,
  },
});

export default SearchDetails;
