import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  layers,
  initLayer,
  layerStyles,
  centerMap,
  initFeatureStatsOverlay,
  initMap,
  featureStatsOverlay,
  initGeneralViewEvents,
  findFeatureColor,
  animateFrameCoords,
} from 'utils';
import { usePostData, useIntl } from 'utils/hooks';
import { FeatureStatsPopupOverlay, TileLayerSwitch, ColorLegend, MapExtentOpenLayers } from 'components';
import { metricTypes, FEATURE_COLORS } from 'consts';
import {
  selectMetricType,
  selectIsUserAdmin,
  selectStats,
  selectTimeZone,
  selectClickedGeneralViewFeatureId,
  selectStatisticType,
  selectTenants,
  selectActiveFrameCords,
} from 'features';
import BreezoMeter from 'assets/images/BreezoMeter.svg';
import CarBlue from 'assets/images/Car Blue@3x.svg';
import CarGreen from 'assets/images/Car Green@3x.svg';
import CarYellow from 'assets/images/Car Yellow@3x.svg';
import CarOrange from 'assets/images/Car Orange@3x.svg';
import CarRed from 'assets/images/Car Red@3x.svg';
import styles from './OpenlayersMap.module.scss';

export const OpenlayersMap = () => {
  /* ---------------------------------- State --------------------------------- */
  const [olMap, setOlMap] = useState(null);

  /* ---------------------------------- Hooks --------------------------------- */
  const { generalStatsPostData } = usePostData();
  const intl = useIntl();

  /* -------------------------------- Selectors ------------------------------- */
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const metricType = useSelector(selectMetricType);
  const timeZone = useSelector(selectTimeZone);
  const mapStats = useSelector(selectStats(timeZone && JSON.stringify(generalStatsPostData())));
  const clickedFeatureId = useSelector(selectClickedGeneralViewFeatureId);
  const statisticType = useSelector(selectStatisticType);
  const tenants = useSelector(selectTenants);
  const activeFrameCords = useSelector(selectActiveFrameCords);

  /* -------------------------------- Variables ------------------------------- */
  /* -------------------------------- Functions ------------------------------- */
  const getClickedFeature = () => {
    if (clickedFeatureId) {
      return layers.generalViewStats?.get('source').getFeatureById(clickedFeatureId);
    }

    return undefined;
  };

  const makeRadialChartData = () => {
    const title = {
      queueLength: intl.formatMessage({
        defaultMessage: 'AVG Queue Length % of Approach Length',
        description: 'Queue Title radial chart',
      }),
      waitingTime: intl.formatMessage({
        defaultMessage: 'AVG Queue Time % of Total Travel Time',
        description: 'Waiting/Driving Title radial chart',
      }),
      numberOfStops: intl.formatMessage({
        defaultMessage: 'Traffic Signal Progression',
        description: 'Number of stops Title radial chart',
      }),
      speed: intl.formatMessage({
        defaultMessage: 'AVG Speed % of Speed Limit',
        description: 'Speed Title radial chart',
      }),
    }[metricType];

    if (!title) {
      return null;
    }

    const data = {};

    mapStats.data.forEach((stat) => {
      if (!data[stat.id]) {
        data[stat.id] = {
          approachName: stat.feature.properties.approachName,
          title,
          metricType,
          items: [],
        };
      }
      data[stat.id].items.push({
        value: {
          queueLength: stat.approachOccupancy * 0.01,
          numberOfStops: stat.probabilityToStop,
          waitingTime: stat.avgWaiting / stat.avgDriving,
          speed: stat.avgSpeed / stat.speedLimit,
        }[metricType],
        directionCode: stat.feature.properties.directionCode,
      });
    });

    return data;
  };

  const initStatsLayer = () => {
    const radialChartData = makeRadialChartData();
    const features = mapStats.data.map((item) => ({
      ...item.feature,
      properties: {
        ...item.feature.properties,
        radialChartData: radialChartData?.[item.id],
      },
    }));

    layers.generalViewStats = initLayer(
      'generalViewStats',
      {
        features,
        type: 'FeatureCollection',
      },
      layerStyles.generalViewStats[metricType],
    );

    olMap.addLayer(layers.generalViewStats);
    centerMap(olMap, layers.generalViewStats);
  };

  /* --------------------------------- Effects -------------------------------- */
  useLayoutEffect(() => {
    if (!tenants?.currentTenant) return;

    initFeatureStatsOverlay();
    const map = initMap({
      name: 'GeneralView',
      overlays: [...Object.values(featureStatsOverlay)],
      layersToAdd: ['corridors', 'intersections', 'busStops'],
    });

    setOlMap(map);
    initGeneralViewEvents(map, intl);
  }, [tenants?.currentTenant]); // eslint-disable-line

  useEffect(() => {
    if (olMap && mapStats) {
      Object.keys(layers).forEach((layer) => olMap.removeLayer(layers[layer]));
      initStatsLayer();
      olMap.getOverlayById('frames-location').setPosition(null);
    }
  }, [olMap, mapStats]); // eslint-disable-line

  useEffect(() => {
    if (!olMap || !layers.generalViewStats) return;
    centerMap(olMap, layers.generalViewStats);
  }, [statisticType]); // eslint-disable-line

  useEffect(() => {
    if (!olMap || !layers.generalViewStats) {
      return;
    }
    const selectedFeature = getClickedFeature();

    if (!selectedFeature) {
      return;
    }
    let iconSrc;

    if (statisticType === 'pedestrians') {
      iconSrc = CarBlue;
    } else {
      let colorGroupId = selectedFeature?.get('colorGroupId');

      if (!colorGroupId) {
        colorGroupId = 0;
      }
      switch (colorGroupId) {
        case 0:
          const color = findFeatureColor({
            featureProperties: selectedFeature.getProperties(),
            metricType,
          });

          iconSrc = [CarGreen, CarYellow, CarOrange, CarRed][FEATURE_COLORS.indexOf(color)];
          break;
        case 1:
          iconSrc = CarBlue;
          break;
        case 2:
          iconSrc = CarOrange;
          break;
        case 3:
          iconSrc = CarYellow;
          break;
        case 4:
          iconSrc = CarGreen;
          break;
        default:
          iconSrc = CarBlue;
      }
    }
    animateFrameCoords({ olMap, activeFrameCords, iconSrc });
  }, [activeFrameCords]); //eslint-disable-line

  return (
    <>
      <div id="map" className={styles.map} />
      <FeatureStatsPopupOverlay />
      <div className={styles.topRight}>
        <div className={styles.row}>
          {metricType === metricTypes.AIR_QUALITY && (
            <div className={styles.box}>
              <img src={BreezoMeter} alt="BreezoMeter" className={styles.breezoMeterLogo} />
            </div>
          )}
          <TileLayerSwitch />
        </div>
        <div className={styles.row}>
          {isUserAdmin && mapStats?.colorScheme && (
            <div className={styles.box}>
              <ColorLegend values={mapStats?.colorScheme} />
            </div>
          )}
        </div>
        <div className={styles.row}>{isUserAdmin && <MapExtentOpenLayers map={olMap} />}</div>
      </div>
    </>
  );
};
