export const intersectionsMock = {
  all: [],
  byCorridor: [
    {
      corridorId: 1,
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [34.777376, 32.094934],
          },
          properties: {
            name: 'Yirmiyahu-Zecharya',
            id: 20022,
            signalized: false,
            corridor: false,
          },
          id: '20022',
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [34.773974, 32.083041],
          },
          properties: {
            name: 'Dizengoff-Jean Jaures',
            id: 20050,
            signalized: true,
            corridor: true,
          },
          id: '20050',
        },
      ],
    },
    {
      corridorId: 2,
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [34.793161, 32.078566],
          },
          properties: {
            name: 'Namir-Menachem Begin',
            id: 136,
            signalized: true,
            corridor: true,
          },
          id: '136',
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [34.794601, 32.082632],
          },
          properties: {
            name: 'Namir-Arlosorov',
            id: 146,
            signalized: true,
            corridor: true,
          },
          id: '146',
        },
      ],
    },
  ],
  loadingByCorridor: 'loaded',
  loadingAll: 'loaded',
};
