import { createSlice, createSelector } from '@reduxjs/toolkit';
import { fetchIntersectionsByCorridor, fetchAllIntersections } from 'features/thunks';

export const intersectionsSlice = createSlice({
  name: 'intersections',
  initialState: {
    all: [],
    byCorridor: [],
    loadingAll: 'idle',
    loadingByCorridor: 'idle',
  },
  extraReducers: {
    [fetchIntersectionsByCorridor.pending]: (state) => {
      state.loadingByCorridor = 'loading';
    },
    [fetchIntersectionsByCorridor.rejected]: (state) => {
      state.loadingByCorridor = 'error';
    },
    [fetchIntersectionsByCorridor.fulfilled]: (state, action) => {
      state.loadingByCorridor = 'loaded';
      state.byCorridor = action.payload;
    },
    [fetchAllIntersections.pending]: (state) => {
      state.loadingAll = 'loading';
    },
    [fetchAllIntersections.rejected]: (state) => {
      state.loadingAll = 'error';
    },
    [fetchAllIntersections.fulfilled]: (state, action) => {
      state.loadingAll = 'loaded';
      state.all = action.payload.features;
    },
  },
});

export const selectIntersectionsByCorridor = createSelector(
  ({ intersections }) => intersections.byCorridor,
  (byCorridor) =>
    byCorridor.map((corridorData) => {
      const { features } = corridorData;
      const corridor = features[0].properties.name.split('-')[0];

      return {
        intersections: features.map((feature) => ({
          ...feature,
          intersection: feature.properties.name.split('-').slice(1).join('-'),
          corridor,
        })),
        corridor,
        corridorId: corridorData.corridorId,
      };
    }),
);

export const selectSignalizedIntersectionsByCorridor = createSelector(selectIntersectionsByCorridor, (byCorridor) =>
  byCorridor.map((corridorData) => ({
    ...corridorData,
    intersections: corridorData.intersections.filter((feature) => feature.properties.signalized),
  })),
);

export const selectIntersections = createSelector(
  [
    ({ intersections }) => intersections.all,
    selectIntersectionsByCorridor,
    selectSignalizedIntersectionsByCorridor,
    ({ intersections }) => intersections.loadingByCorridor,
    ({ intersections }) => intersections.loadingAll,
  ],
  (all, byCorridor, signalizedByCorridor, loadingByCorridor, loadingAll) => ({
    all,
    byCorridor,
    signalizedByCorridor,
    loadingByCorridor,
    loadingAll,
  }),
);

export default intersectionsSlice.reducer;
