import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { handleCreateApproach } from 'features';
import { TextField } from '@material-ui/core';
import { Dialog } from 'screens/EditGrid/Dialog';

export const CreateApproachDialog = ({ isOpen, close, segments }) => {
  const dispatch = useDispatch();
  const maxSpeed = Math.max(...segments.map((feature) => feature.get('speed')));
  const segmentIds = segments.map((feature) => feature.getId());

  const submit = (event) => {
    dispatch(
      handleCreateApproach({
        segmentIds,
        speed: event.target.speed.value,
      }),
    );

    close();
  };

  return (
    <Dialog title="Add Approach" isOpen={isOpen} onClose={close} onSubmit={submit}>
      <TextField label="Segments Ids" defaultValue={segmentIds.join(', ')} InputProps={{ readOnly: true }} />
      <TextField label="Speed (km/h)" defaultValue={maxSpeed} type="number" name="speed" />
    </Dialog>
  );
};

CreateApproachDialog.propTypes = {
  isOpen: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  segments: propTypes.array.isRequired,
};
