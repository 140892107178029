const names = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const WEEKDAYS = [];

names.forEach((fullName) => {
  WEEKDAYS.push({
    fullName,
    shortName: fullName.substring(0, 3),
    code: fullName.charAt(0),
  });
});
