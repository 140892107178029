export const descendingComparator = (a, b, orderBy) => {
  let aField = a[orderBy] || a.startRideDate;

  let bField = b[orderBy] || b.startRideDate;

  if (orderBy === 'startRideTime') {
    [, aField] = aField.split(' ');
    [, bField] = bField.split(' ');
  }

  if (bField < aField) {
    return -1;
  }
  if (bField > aField) {
    return 1;
  }

  return 0;
};

export const getComparator = (order, orderBy) =>
  order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};
