export const generalStatsMock = {
  frames: {
    serializedRequestBody: [
      /* eslint-disable max-len */
      [
        'https://axilioncameradev.blob.core.windows.net/100000005188daed/2020-09-02_anonymized/2020-09-02.05-23-11.000.jpg?sig=9vo%2FwOG%2BAmuKalkjrAWGylwmYp6sNvXuWwZl9zsKbp8%3D&st=2020-09-24T12%3A28%3A05Z&se=2020-09-24T13%3A28%3A05Z&sv=2019-02-02&sp=rl&sr=b',
        'https://axilioncameradev.blob.core.windows.net/100000005188daed/2020-09-02_anonymized/2020-09-02.05-23-12.000.jpg?sig=WehUmBphBntbOodTt%2B55bGksKy5RFuyEnoV8ZJzBBII%3D&st=2020-09-24T12%3A28%3A05Z&se=2020-09-24T13%3A28%3A05Z&sv=2019-02-02&sp=rl&sr=b',
      ],
      [
        'https://axilioncameradev.blob.core.windows.net/100000005188daed/2020-09-02_anonymized/2020-09-02.05-23-13.000.jpg?sig=RppVcrnjSkl%2BkPtajHd4kOXIS5l8rQoHqCiUEnRe9Yg%3D&st=2020-09-24T12%3A29%3A14Z&se=2020-09-24T13%3A29%3A14Z&sv=2019-02-02&sp=rl&sr=b',
        'https://axilioncameradev.blob.core.windows.net/100000005188daed/2020-09-02_anonymized/2020-09-02.05-23-14.000.jpg?sig=7af%2Fn%2FSQelOFN1L%2Bfa5LNVoBwCJ6aZKnhLr7MKOgaDM%3D&st=2020-09-24T12%3A30%3A18Z&se=2020-09-24T13%3A30%3A18Z&sv=2019-02-02&sp=rl&sr=b',
      ],
    ],
    /* eslint-enable max-len */
  },
  activeFrames: '',
  framesFetchError: false,
  stats: {
    serializedRequestBody: {
      colorScheme: [7, 1, 2],
      data: [
        {
          name: 'Namir-Glilot South',
          id: 153,
          feature: {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [
                [34.80078, 32.139753],
                [34.801324, 32.140789],
              ],
            },
            properties: {
              corridorId: 1,
              corridorName: 'Namir',
              order: 110,
              direction: 'North',
              directionId: 2,
              startIntersection: {
                type: 'Point',
                coordinates: [34.794455, 32.122756],
              },
              endIntersection: {
                type: 'Point',
                coordinates: [34.801303, 32.141068],
              },
              startIntersectionId: 180,
              endIntersectionId: 153,
              length: 1878,
            },
            id: '10000',
          },
          videos: {
            videos: [
              {
                videoCameraId: '10000000c1afad00',
                videoStartTime: '2020-09-09 06:32:23.000',
                videoEndTime: '2020-09-09 06:35:52.000',
                videoPlayTime: '2020-09-09 06:34:37.000',
              },
              {
                videoCameraId: '10000000c1afad00',
                videoStartTime: '2020-09-14 06:53:16.000',
                videoEndTime: '2020-09-14 06:56:13.000',
                videoPlayTime: '2020-09-14 06:55:23.000',
              },
            ],
            approachId: 10000,
          },
          avgQueueLength: 48.76,
          approachOccupancy: 2.6,
          approachLength: 1878,
        },
        {
          name: 'Namir-Glilot North',
          id: 1153,
          feature: {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [
                [34.801717, 32.141577],
                [34.80178418566, 32.14172889814],
              ],
            },
            properties: {
              corridorId: 1,
              corridorName: 'Namir',
              order: 111,
              direction: 'North',
              directionId: 2,
              startIntersection: {
                type: 'Point',
                coordinates: [34.801303, 32.141068],
              },
              endIntersection: {
                type: 'Point',
                coordinates: [34.801608, 32.141813],
              },
              startIntersectionId: 153,
              endIntersectionId: 1153,
              length: 100,
            },
            id: '10001',
          },
          videos: {
            videos: [
              {
                videoCameraId: '100000005188daed',
                videoStartTime: '2020-09-02 06:20:59.000',
                videoEndTime: '2020-09-02 06:21:06.000',
                videoPlayTime: null,
              },
              {
                videoCameraId: '10000000c1afad00',
                videoStartTime: '2020-08-30 06:40:53.000',
                videoEndTime: '2020-08-30 06:41:00.000',
                videoPlayTime: null,
              },
            ],
            approachId: 10001,
          },
          avgQueueLength: 0.04,
          approachOccupancy: 0.04,
          approachLength: 100,
        },
      ],
    },
  },
  statisticType: 'private',
  metricType: 'queueLength',
  daysInPast: 20,
  startDate: 1604991771446,
  activeFrameCords: {
    prev: {
      latPrev: null,
      lonPrev: null,
    },
    curr: {
      latCurr: null,
      lonCurr: null,
    },
  },
  approachStatDays: { stats: [1] },
  approachesStatSamples: { stats: [1] },
  pedestrianStatApproaches: 'mock data',
  activeFrameDetails: {},
  isFrameDetailsOverlayVisible: false,
};
