import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  selectTotalRides,
  setExpandedPanel,
  selectIsComparing,
  setCurrentTotalRides,
  cancelCorridorsMetricsRequests,
} from 'features';
import { Button } from '@material-ui/core';
import { usePostData } from 'utils/hooks';
import { highlightIntersections } from 'utils';
import { ReactComponent as VideoCamIconBlue } from 'assets/images/ic_videocam_blue@3x.svg';
import { makeStyles } from '@material-ui/core/styles';
import TotalRidesStyles from './TotalRides.styles';

const useStyles = makeStyles(TotalRidesStyles);

export const TotalRides = () => {
  const { searchPostData } = usePostData();
  const classes = useStyles();
  const dispatch = useDispatch();
  const totalRidesFirst = useSelector(selectTotalRides(JSON.stringify(searchPostData('first'))))?.rides;
  const totalRidesSecond = useSelector(selectTotalRides(JSON.stringify(searchPostData('second'))))?.rides;
  const isComparing = useSelector(selectIsComparing);

  const showRides = (firstOrSecond) => {
    cancelCorridorsMetricsRequests.rides.forEach((cancel) => cancel());
    dispatch(setCurrentTotalRides(firstOrSecond));
    dispatch(setExpandedPanel('total-rides'));
    highlightIntersections(null);
  };

  return (
    <div
      className={`
    ${classes.totalRidesButtonsContainer}
    ${isComparing ? 'isComparing' : ''}
  `}
    >
      <Button
        variant="contained"
        color="primary"
        className={`
        ${classes.searchButton}
        ${classes.totalRidesButton}
        ${isComparing ? 'isComparing' : ''}
      `}
        startIcon={<VideoCamIconBlue className={classes.videoCamIconBlue} />}
        onClick={() => showRides('first')}
      >
        <FormattedMessage
          description="Total rides button"
          defaultMessage="Total rides ({totalRides})"
          values={{ totalRides: totalRidesFirst?.length || 0 }}
        />
      </Button>

      {isComparing && (
        <Button
          variant="contained"
          color="primary"
          className={`
          ${classes.searchButton}
          ${classes.totalRidesButton}
          ${isComparing ? 'isComparing' : ''}
          totalRidesButtonSecond
        `}
          startIcon={<VideoCamIconBlue className={classes.videoCamIconBlue} />}
          onClick={() => showRides('second')}
        >
          <FormattedMessage
            description="Total rides button"
            defaultMessage="Total rides ({totalRides})"
            values={{ totalRides: totalRidesSecond?.length || 0 }}
          />
        </Button>
      )}
    </div>
  );
};
