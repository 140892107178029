const CorridorGraphsStyles = (theme) => ({
  root: {
    backgroundColor: theme.colorWhite,
    width: '45%',
    marginTop: 30,
    padding: 5,
    display: 'inline-block',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  rootFullWidth: {
    width: '95% !important',
  },
});

export default CorridorGraphsStyles;
